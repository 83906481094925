import Tools from '../../Tools.js';
import Scaling from '../../Scaling.js';
import BaseCharacter from '../base.js';

//TODO: implement character
var Sucrose = (props) => {
  
  let scaleTable = {
    ascension0: {
      min: {level:1, maxHP:775, baseAtk:14, defense:59, attr:0},
      max: {level:20, maxHP:1991, baseAtk:37, defense:151, attr:0}
    },
    ascension1: {
      min: {level:20, maxHP:2570, baseAtk:47, defense:195, attr:0},
      max: {level:40, maxHP:3850, baseAtk:71, defense:293, attr:0}
    },
    ascension2: {
      min: {level:40, maxHP:4261, baseAtk:79, defense:324, attr:0.06},
      max: {level:50, maxHP:4901, baseAtk:90, defense:373, attr:0.06}
    },
    ascension3: {
      min: {level:50, maxHP:5450, baseAtk:100, defense:414, attr:0.12},
      max: {level:60, maxHP:6090, baseAtk:112, defense:463, attr:0.12}
    },
    ascension4: {
      min: {level:60, maxHP:6501, baseAtk:120, defense:494, attr:0.12},
      max: {level:70, maxHP:7141, baseAtk:132, defense:543, attr:0.12}
    },
    ascension5: {
      min: {level:70, maxHP:7552, baseAtk:139, defense:574, attr:0.18},
      max: {level:80, maxHP:8192, baseAtk:151, defense:623, attr:0.18}
    },
    ascension6: {
      min: {level:80, maxHP:8603, baseAtk:159, defense:654, attr:0.24},
      max: {level:90, maxHP:9243, baseAtk:170, defense:703, attr:0.24}
    }
  };
  return BaseCharacter({
    ...props,
    name: 'Sucrose',
    element: 'anemo',
    equip: 'catalyst',
    scaleTable: scaleTable,
    scaleAttr: 'damageBonus.anemo',
    attackTalent: {
      description: 'Wind Spirit Creation',
      level: props.attack,
      type: "NormalAtk",
      defaultImpl: {
        talentProperties: {
          comboTable: [
            {dmg:0.335, anim:0.5}, 
            {dmg:0.306, anim:0.2}, 
            {dmg:0.384, anim:0.5}, 
            {dmg:0.479, anim:1.2}
          ]
        },
        duration: 1,
        Prepare(result) {
          result.attributes.element = 'anemo';
          return result;
        },
        Resolve(result) {
          result = Scaling.ScaleAttackCombo(result);
          return result;
        }
      },
      chargedImpl: {
        type: "ChargeAtk",
        duration: 1.3,
        Prepare(result) {
          result.attributes.element = 'anemo';
          return result;
        },
        Resolve(result) {
          result = Scaling.ScaleTalent(1.20, result);
          return result;
        }
      }
    },
    skillTalent: {
      description:'Astable Anemohypostasis Creation - 6308',
      level: props.skill,
      type: 'Skill',
      duration: 1.1,
      cooldown: 15,
      defaultImpl: {
        Prepare(result) {
          result.attributes.element = 'anemo';
          return result;
        },
        Resolve(result) {
          result = Scaling.ScaleTalent(2.11, result);
          return result;
        }
      }
    },
    burstTalent: {
      description: 'Forbidden Creation - Isomer 75 / Type II',
      level: props.burst,
      type: 'Burst',
      cooldown: 20,
      defaultImpl: {
        Prepare(result) {
          result.attributes.element = 'anemo';
          return result;
        },
        Resolve(result) {
          result.effects.push(Tools.CreateTalentSideEffect(result, 'spawn'));
          return result;
        }
      },
      spawnEffect: {
        description: 'Forbidden Creation - Large Wind Spirit',
        entity: 'Sucrose\'s Forbidden Creation - Isomer 75 / Type II',
        duration: 2,
        relativeTime: -0.66,
        Prepare(result) {
          result.attributes.element = 'anemo';
          return result;
        },
        Resolve(result) {
          return result;
        },
        Finalize(result) {
          result.effects.push(Tools.CreateTalentSideEffect(result, 'windSpirit'));
          return result;
        }
      },
      windSpiritEffect: {
        description: 'Forbidden Creation - Large Wind Spirit',
        entity: 'Sucrose\'s Forbidden Creation - Isomer 75 / Type II',
        duration: 2,
        talentProperties: {
          number: 1,
          element: ''
        },
        Prepare(result) {
          result.attributes.element = 'anemo';
          result = Tools.AbsorbElement(result);
          return result;
        },
        Resolve(result) {
          result = Scaling.ScaleTalent(1.48, result);
          return result;
        },
        Finalize(result) {
          if(result.talentProperties.element) {
            result.effects.push(Tools.CreateTalentSideEffect(result, 'elementalDmg', {
              talentProperties: {
                element: result.talentProperties.element
              }
            }));
          }
          if(result.talentProperties.number<2) {
            result.effects.push(Tools.CreateTalentSideEffect(result, 'windSpirit', {
              talentProperties: {
                number: result.talentProperties.number+1,
                element: result.talentProperties.element
              }
            }));
          } else if(result.talentProperties.number<3){
            result.effects.push(Tools.CreateTalentSideEffect(result, 'windSpirit', {
              duration: 0.2,
              talentProperties: {
                number: result.talentProperties.number+1,
                element: result.talentProperties.element
              }
            }));
          }
          return result;
        }
      },
      elementalDmgEffect: {
        description: 'Forbidden Creation - Additional Elemental Damage',
        entity: 'Sucrose\'s Forbidden Creation - Isomer 75 / Type II',
        duration: 0.1,
        relativeTime: (r => -r.precedingResult.duration),
        talentProperties: {
          element: ''
        },
        Prepare(result) {
          if(result.talentProperties.element) {
            result.attributes.element = result.talentProperties.element;
          }
          return result;
        },
        Resolve(result) {
          if(result.talentProperties.element) {
            result = Scaling.ScaleTalent(0.44, result);
            result.logs.push('Additionnal Elemental Damage deals '+(Math.round(0.44*10000)/100)+'%');
          }
          return result;
        }
      }
    },
    passiveAsc1: {
      description: 'Catalyst Conversion',
      PersistentResolve(result, context) {
        if(result.initiator===context && result.type==='Reaction' && result.description==='Swirl') {
          let swirl = result;
          result.effects.push(Tools.CreateSideEffect(result, {
            description: 'Catalyst Conversion',
            entity: 'Sucrose\'s Passive',
            duration: 8,
            PersistentResolve(result, context) {
              if(result.initiator.element===swirl.attributes.element) {
                result.logs.push('Catalyst Conversion increases elemental mastery by 50 of characters matching element of her swirl ('+swirl.attributes.element+')');
                result.attributes.elemMastery += 50;
              }
              return result;
            }
          }));
        }
        return result;
      }
    },
    passiveAsc4: {
      description: 'Mollis Favonius',
      PersistentResolve(result, context) {
        if(result.description.startsWith('Astable Anemohypostasis Creation - 6308') 
           || result.description.startsWith('Forbidden Creation - Isomer 75 / Type II')) {
          result.effects.push(Tools.CreateSideEffect(result, {
            description: 'Mollis Favonius',
            entity: 'Sucrose\'s Passive',
            duration: 8,
            PersistentResolve(result, context) {
              if(result.initiator !== context.initiator) {
                result.logs.push('Mollis Favonius increases all party members\' (excluding Sucrose) Elemental Mastery based on 20% of Sucrose\'s Elemental Mastery');
                result.attributes.elemMastery += context.attributes.elemMastery * 0.20;
              }
              return result;
            }
          }));
        }
        return result;
      }
    },
    passiveOther: [{
      description: 'Astable Invention',
      PersistentResolve(result, context) {
        return result;
      }
    }],
    constellations: [
      ...BaseCharacter.Constellation35
    ]
  });
};

export default Sucrose;