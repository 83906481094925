import {BaseSet} from './base.js';

const BraveHeart = (props) => {
  
  return BaseSet({
    ...props,
    name: 'Brave Heart',
    two: {
      attributes: {
        atkPct: 0.18
      },
      PersistentResolve(result, context) {
        return result;
      }
    },
    four: {
      PersistentResolve(result, context) {
        if(!this.IsSelf(result)) return result;
        result.attributes.damageBonus.all += 0.3;
        result.logs.push("Brave Heart increases damage by 30% on ennemies with more than 50% HP");
        return result;
      }
    }
  });
};

export default BraveHeart;