const Characters = [
    { 
      name: 'None', element: '-', rarity: 0, equip:'None',     
      attack: {name: '', impl:[]}, 
      skill: {name: '', impl:[]}, 
      burst: {name: '', impl:[]}
    }, {
      name: 'Albedo', element: 'geo', rarity: 5, equip:'sword',      
      attack: {
        name: 'Favonius Bladework - Weiss', impl:[
          {name: 'default', },
          {name: 'charged', description: 'Charged', 
                            color: 'blue', 
                            border: 'aqua'}
        ]}, 
      skill: {
        name: 'Abiogenesis: Solar Isotoma', impl:[
          {name: 'default', }
        ]}, 
      burst:{
        name: 'Rite of Progeniture: Tectonic Tide', impl:[
          {name: 'default', }
        ]}, 
    }, { 
      name: 'Amber', element: 'pyro', rarity: 4, equip:'bow',      
      attack: {
        name: 'Sharpshooter', impl:[
          {name: 'default'},
          {name: 'aimed', description: 'Aimed', 
                            color: 'blue', 
                            border: 'aqua'}, 
          {name: 'fullyAimed', description: 'Fully-Aimed', 
                            color: 'maroon', 
                            border: 'mediumvioletred'}
        ]}, 
      skill: {
        name: 'Explosive Puppet', impl:[
          {name: 'default'}
        ]}, 
      burst:{
        name: 'Fiery Rain', impl:[
          {name: 'default'}
        ]}, 
    }, { 
      name: 'Barbara', element: 'hydro', rarity: 4, equip:'catalyst', 
      attack: {
        name:'Whisper of Water', impl:[
          {name: 'default'},
          {name: 'charged', description: 'Charged', 
                            color: 'blue', 
                            border: 'aqua'}
        ]}, 
      skill:{
        name: 'Let the Show Begin', impl:[
          {name: 'default'}
        ]}, 
      burst:{
        name: 'Shining Miracle', impl:[
          {name: 'default'}
        ]}, 
    }, { 
      name: 'Beidou', element: 'electro', rarity: 4, equip:'claymore', 
      attack: {
        name:'Oceanborne', impl:[
          {name: 'default'},
          {name: 'charged', description: 'Charged', 
                            color: 'blue', 
                            border: 'aqua'}, 
          {name: 'spinning', description: 'Spinning', 
                            color: 'maroon', 
                            border: 'mediumvioletred'}
        ]}, 
      skill: {
        name:'Tidecaller', impl:[
          {name:'default', devStage:'NotImpl'}
        ]}, 
      burst: {
        name:'Stormbreaker', impl:[
          {name:'default', devStage:'NotImpl'}
        ]}, 
    }, { 
      name: 'Bennett', element: 'pyro', rarity: 4, equip:'sword',    
      attack: {
        name:'Strike of Fortune', impl:[
          {name: 'default'},
          {name: 'charged', description: 'Charged', 
                            color: 'blue', 
                            border: 'aqua'}, 
        ]}, 
      skill: {
        name:'Passion Overload', impl:[
          {name: 'default'},
          {name: 'hold1', description: 'Hold Level 1', 
                            color: 'blue', 
                            border: 'aqua'},
          {name: 'hold2', description: 'Hold Level 2', 
                            color: 'maroon', 
                            border: 'mediumvioletred'}
        ]}, 
      burst: {
        name:'Fantastic Voyage', impl:[
          {name: 'default'}
        ]}, 
    }, { 
      name: 'Chongyun', element: 'cryo', rarity: 4, equip:'claymore', 
      attack: {
        name: 'Demonbane', impl:[
          {name: 'default', devStage:'NotImpl'},
          {name: 'charged', description: 'Charged', 
                            color: 'blue', 
                            border: 'aqua', 
                            devStage:'NotImpl'}, 
          {name: 'spinning', description: 'Spinning', 
                            color: 'maroon', 
                            border: 'mediumvioletred', 
                            devStage:'NotImpl'}
        ]}, 
      skill: {
        name: 'Spirit Blade: Chonghua\'s Layered Frost', impl:[
          {name: 'default', devStage:'NotImpl'}
        ]}, 
      burst: {
        name: 'Spirit Blade: Cloud-Parting Star', impl:[
          {name: 'default', devStage:'NotImpl'}
        ]}, 
    }, { 
      name: 'Diona', element: 'cryo', rarity: 4, equip:'bow',      
      attack: {
        name: 'Katzlein Style', impl:[
          {name: 'default'},
          {name: 'aimed', description: 'Aimed', 
                            color: 'blue', 
                            border: 'aqua'}, 
          {name: 'fullyAimed', description: 'Fully-Aimed', 
                            color: 'maroon', 
                            border: 'mediumvioletred'}
        ]}, 
      skill: {
        name: 'Icy Paws', impl:[
          {name: 'default'},
          {name: 'hold', description: 'Hold', 
                            color: 'blue', 
                            border: 'aqua'}
        ]}, 
      burst: {
        name: 'Signature Mix', impl:[
          {name: 'default'}
        ]}, 
    }, { 
      name: 'Diluc', element: 'pyro', rarity: 5, equip:'claymore', 
      attack: {
        name: 'Tempered Sword', impl:[
          {name: 'default'},
          {name: 'charged', 
                            description: 'Charged', 
                            color: 'blue', 
                            border: 'aqua'}, 
          {name: 'spinning', 
                            description: 'Spinning', 
                            color: 'maroon', 
                            border: 'mediumvioletred'}
        ]}, 
      skill: {
        name: 'Searing Onslaught', impl:[
          {name: 'default'}
        ]}, 
      burst: {
        name: 'Dawn', impl:[
          {name: 'default'}
        ]}, 
    }, {
      name: 'Fischl', element: 'electro', rarity: 4, equip:'bow',      
      attack: {
        name: 'Bolts of Downfall', impl:[
          {name: 'default'},
          {name: 'aimed', description: 'Aimed', 
                            color: 'blue', 
                            border: 'aqua'}, 
          {name: 'fullyAimed', description: 'Fully-Aimed', 
                            color: 'maroon', 
                            border: 'mediumvioletred'}
        ]}, 
      skill: {
        name: 'Nightrider', impl:[
          {name: 'default'}
        ]}, 
      burst: {
        name: 'Midnight Phantasmagoria', impl:[
          {name: 'default'}
        ]}, 
    }, { 
      name: 'Ganyu', element: 'cryo', rarity: 5, equip:'bow',      
      attack: {
        name: 'Liutian Archery', impl:[
          {name: 'default'},
          {name: 'aimed', description: 'Aimed', 
                            color: 'blue', 
                            border: 'aqua'}, 
          {name: 'fullyAimed', description: 'Charge Level 1', 
                            color: 'maroon', 
                            border: 'mediumvioletred'},
          {name: 'fullyAimed2', description: 'Frostflake Arrow', 
                            color: 'red', 
                            border: 'red'}
        ]}, 
      skill: {
        name: 'Trail of the Qilin', impl:[
          {name: 'default'}
        ]}, 
      burst: {
        name: 'Celestial Shower', impl:[
          {name: 'default'}
        ]}, 
    }, { 
      name: 'Jean', element: 'anemo', rarity: 5, equip:'sword',    
      attack: {
        name: 'Favonius Bladework', impl:[
          {name: 'default'},
          {name: 'charged', description: 'Charged', 
                            color: 'blue', 
                            border: 'aqua'}, 
        ]}, 
      skill: {
        name: 'Gale Blade', impl:[
          {name: 'default'},
          {name: 'hold', description: 'Hold', 
                            color: 'blue', 
                            border: 'aqua'}, 
        ]}, 
      burst: {
        name: 'Dandelion Breeze', impl:[
          {name: 'default'}
        ]}, 
    }, { 
      name: 'Kaeya', element: 'cryo', rarity: 4, equip:'sword',    
      attack: {
        name: 'Ceremonial Bladework', impl:[
          {name: 'default', devStage:'NotImpl'},
          {name: 'charged', description: 'Charged', 
                            color: 'blue', 
                            border: 'aqua', 
                            devStage:'NotImpl'}, 
        ]}, 
      skill: {
        name: 'Frostgnaw', impl:[
          {name: 'default', devStage:'NotImpl'}
        ]}, 
      burst: {
        name: 'Glacial Waltz', impl:[
          {name: 'default', devStage:'NotImpl'}
        ]}, 
    }, { 
      name: 'Keqing', element: 'electro', rarity: 5, equip:'sword',    
      attack: {
        name: 'Yunlai Swordsmanship', impl:[
          {name: 'default'},
          {name: 'charged', description: 'Charged', 
                            color: 'blue', 
                            border: 'aqua'}, 
        ]}, 
      skill: {
        name: 'Stellar Restoration', impl:[
          {name: 'default'},
          {name: 'hold', description: 'Hold', 
                            color: 'blue', 
                            border: 'aqua'}, 
        ]}, 
      burst: {
        name: 'Starward Sword', impl:[
          {name: 'default'}
        ]}, 
    }, { 
      name: 'Klee', element: 'pyro', rarity: 5, equip:'catalyst', 
      attack: {
        name: 'Kaboom!', impl:[
          {name: 'default'},
          {name: 'charged', description: 'Charged', 
                            color: 'blue', 
                            border: 'aqua'}, 
        ]}, 
      skill: {
        name: 'Jumpy Dumpty', impl:[
          {name: 'default'}
        ]}, 
      burst: {
        name: 'Sparks \'n\' Splash', impl:[
          {name: 'default'}
        ]}, 
    }, { 
      name: 'Lisa', element: 'electro', rarity: 4, equip:'catalyst', 
      attack: {
        name: 'Lightning Touch', impl:[
          {name: 'default', devStage:'NotImpl'},
          {name: 'charged', description: 'Charged', 
                            color: 'blue', 
                            border: 'aqua', 
                            devStage:'NotImpl'}
        ]}, 
      skill: {
        name: 'Violet Arc', impl:[
          {name: 'default', devStage:'NotImpl'},
          {name: 'hold', description: 'Hold', 
                            color: 'blue', 
                            border: 'aqua', 
                            devStage:'NotImpl'}
        ]}, 
      burst: {
        name: 'Lightning Rose', impl:[
          {name: 'default', devStage:'NotImpl'}
        ]}, 
    }, { 
      name: 'Mona', element: 'hydro', rarity: 5, equip:'catalyst', 
      attack: {
        name: 'Ripple of Fate', impl:[
          {name: 'default'},
          {name: 'charged', description: 'Charged', 
                            color: 'blue', 
                            border: 'aqua'}
        ]}, 
      skill: {
        name: 'Mirror Reflection of Doom', impl:[
          {name: 'default'}
        ]}, 
      burst: {
        name: 'Stellaris Phantasm', impl:[
          {name: 'default'}
        ]}, 
    }, { 
      name: 'Ningguang', element: 'geo', rarity: 4, equip:'catalyst', 
      attack: {
        name: 'Sparkling Scatter', impl:[
          {name: 'default', devStage:'NotImpl'},
          {name: 'charged', description: 'Charged', 
                            color: 'blue', 
                            border: 'aqua', 
                            devStage:'NotImpl'}
        ]}, 
      skill: {
        name: 'Jade Screen', impl:[
          {name: 'default', devStage:'NotImpl'}
        ]}, 
      burst: {
        name: 'Starshatter', impl:[
          {name: 'default', devStage:'NotImpl'}
        ]}, 
    }, { 
      name: 'Noelle', element: 'geo', rarity: 4, equip:'claymore', 
      attack: {
        name: 'Favonius Bladework - Maid', impl:[
          {name: 'default', devStage:'NotImpl'},
          {name: 'charged', description: 'Charged', 
                            color: 'blue', 
                            border: 'aqua', 
                            devStage:'NotImpl'},
          {name: 'spinning', description: 'Spinning', 
                            color: 'maroon', 
                            border: 'mediumvioletred', 
                            devStage:'NotImpl'}
        ]}, 
      skill: {
        name: 'Breastplate', impl:[
          {name: 'default', devStage:'NotImpl'}
        ]}, 
      burst: {
        name: 'Sweeping Time', impl:[
          {name: 'default', devStage:'NotImpl'}
        ]}, 
    }, { 
      name: 'Qiqi', element: 'cryo', rarity: 5, equip:'sword',    
      attack: {
        name: 'Ancient Sword Art', impl:[
          {name: 'default'},
          {name: 'charged', description: 'Charged', 
                            color: 'blue', 
                            border: 'aqua'}
        ]}, 
      skill: {
        name: 'Adeptus Art: Herald of Frost', impl:[
          {name: 'default'}
        ]}, 
      burst: {
        name: 'Adeptus Art: Preserver of Fortune', impl:[
          {name: 'default'}
        ]}, 
    }, { 
      name: 'Razor', element: 'electro', rarity: 4, equip:'claymore', 
      attack: {
        name: 'Steel Fang', impl:[
          {name: 'default', devStage:'NotImpl'},
          {name: 'charged', description: 'Charged', 
                            color: 'blue', 
                            border: 'aqua', 
                            devStage:'NotImpl'},
          {name: 'spinning', description: 'Spinning', 
                            color: 'maroon', 
                            border: 'mediumvioletred', 
                            devStage:'NotImpl'}
        ]}, 
      skill: {
        name: 'Claw and Thunder', impl:[
          {name: 'default', devStage:'NotImpl'},
          {name: 'hold', description: 'Hold', 
                            color: 'blue', 
                            border: 'aqua', 
                            devStage:'NotImpl'}
        ]}, 
      burst: {
        name: 'Lightning Fang', impl:[
          {name: 'default', devStage:'NotImpl'}
        ]}, 
    }, { 
      name: 'Sucrose', element: 'anemo', rarity: 4, equip:'catalyst', 
      attack: {
        name: 'Wind Spirit Creation', impl:[
          {name: 'default'},
          {name: 'charged', description: 'Charged', 
                            color: 'blue', 
                            border: 'aqua'}
        ]}, 
      skill: {
        name: 'Astable Anemohypostasis Creation - 6308', impl:[
          {name: 'default'}
        ]}, 
      burst: {
        name: 'Forbidden Creation - Isomer 75 / Type II', impl:[
          {name: 'default'}
        ]}, 
    }, { 
      name: 'Tartaglia', element: 'hydro', rarity: 5, equip:'bow',      
      attack: {
        name: 'Cutting Torrent', impl:[
          {name: 'default'},
          {name: 'aimed', description: 'Aimed', 
                            color: 'blue', 
                            border: 'aqua'},
          {name: 'fullyAimed', description: 'Fully-Aimed', 
                            color: 'maroon', 
                            border: 'mediumvioletred'}
        ]}, 
      skill: {
        name: 'Foul Legacy:Raging Tide', impl:[
          {name: 'default'}
        ]}, 
      burst: {
        name: 'Havoc: Obliteration', impl:[
          {name: 'default'}
        ]}, 
    }, { 
      name: 'TravelerAnemo', element: 'anemo', rarity: 5, equip:'sword',    
      attack: {
        name: 'Foreign Ironwind', impl:[
          {name: 'default'},
          {name: 'charged', description: 'Charged', 
                            color: 'blue', 
                            border: 'aqua'}
        ]}, 
      skill: {
        name: 'Palm Vortex', impl:[
          {name: 'default'},
          {name: 'hold', description: 'Hold', 
                            color: 'blue', 
                            border: 'aqua'}
        ]}, 
      burst: {
        name: 'Gust Surge', impl:[
          {name: 'default'}
        ]}, 
    }, { 
      name: 'TravelerGeo', element: 'geo', rarity: 5, equip:'sword',    
      attack: {
        name: 'Foreign Rockblade', impl:[
          {name: 'default'},
          {name: 'charged', description: 'Charged', 
                            color: 'blue', 
                            border: 'aqua'}
        ]}, 
      skill: {
        name: 'Starfell Sword', impl:[
          {name: 'default'},
          {name: 'hold', description: 'Hold', 
                            color: 'blue', 
                            border: 'aqua'}
        ]}, 
      burst: {
        name: 'Wake of Earth', impl:[
          {name: 'default'}
        ]}, 
    }, { 
      name: 'Venti', element: 'anemo', rarity: 5, equip:'bow',      
      attack: {
        name: 'Divine Marksmanship', impl:[
          {name: 'default'},
          {name: 'aimed', description: 'Aimed', 
                            color: 'blue', 
                            border: 'aqua'},
          {name: 'fullyAimed', description: 'Fully-Aimed', 
                            color: 'maroon', 
                            border: 'mediumvioletred'}
        ]}, 
      skill: {
        name: 'Skyward Sonnet', impl:[
          {name: 'default'},
          {name: 'hold', description: 'Hold', 
                            color: 'blue', 
                            border: 'aqua'}
        ]}, 
      burst: {
        name: 'Wind\'s Grand Ode', impl:[
          {name: 'default'}
        ]}, 
    }, { 
      name: 'Xiangling', element: 'pyro', rarity: 4, equip:'polearm',  
      attack: {
        name: 'Dough-Fu', impl:[
          {name: 'default'},
          {name: 'charged', description: 'Charged', 
                            color: 'blue', 
                            border: 'aqua'}
        ]}, 
      skill: {
        name: 'Guoba Attack', impl:[
          {name: 'default'}
        ]}, 
      burst: {
        name: 'Pyronado', impl:[
          {name: 'default'}
        ]}, 
    }, { 
      name: 'Xingqiu', element: 'hydro', rarity: 4, equip:'sword',    
      attack: {
        name: 'Guhua Style', impl:[
          {name: 'default'},
          {name: 'charged', description: 'Charged', 
                            color: 'blue', 
                            border: 'aqua'}
        ]}, 
      skill: {
        name: 'Guhua Sword: Fatal Rainscreen', impl:[
          {name: 'default'}
        ]}, 
      burst: {
        name: 'Guhua Sword: Raincutter', impl:[
          {name: 'default'}
        ]}, 
    }, { 
      name: 'Xinyan', element: 'pyro', rarity: 4, equip:'claymore', 
      attack: {
        name: 'Dance on Fire', impl:[
          {name: 'default', devStage:'NotImpl'},
          {name: 'charged', description: 'Charged', 
                            color: 'blue', 
                            border: 'aqua', 
                            devStage:'NotImpl'}
        ]},  
      skill: {
        name: 'Sweeping Fervor', impl:[
          {name: 'default', devStage:'NotImpl'}
        ]}, 
      burst: {
        name: 'Riff Revolution', impl:[
          {name: 'default', devStage:'NotImpl'}
        ]}, 
    }, { 
      name: 'Zhongli', element: 'geo', rarity: 5, equip:'polearm',  
      attack: {
        name: 'Rain of Stone', impl:[
          {name: 'default'},
          {name: 'charged', description: 'Charged', 
                            color: 'blue', 
                            border: 'aqua'}
        ]}, 
      skill: {
        name: 'Dominus Lapidis', impl:[
          {name: 'default'},
          {name: 'hold', description: 'Hold', 
                            color: 'blue', 
                            border: 'aqua'}
        ]}, 
      burst: {
        name: 'Planet Befall', impl:[
          {name: 'default'}
        ]}, 
    }
  ];
  
export default Characters;