const Artifacts = [
  {name:'Archaic Petra', rarity:5, two:'Gain a 15% Geo DMG Bonus', four:'Upon obtaining a crystal created through a Geo Elemental Reaction, all party members gain 35% Elemental DMG Bonus to that particular element for 10s. Only one form of Elemental DMG Bonus can be gained in this manner at any one time.'},
  {name:'Blizzard Strayer', rarity:5, two:'Cryo DMG Bonus +15%', four:'When a character attacks an opponent affected by Cryo, their CRIT Rate is increased by 20%. If the opponent is Frozen, CRIT Rate is increased by an additional 20%.'},
  {name:'Bloodstained Chivalry', rarity:5, two:'Physical DMG +25%', four:'After defeating an opponent, increases Charged Attack DMG by 50%, and reduces its Stamina cost to 0 for 10s.'},
  {name:'Crimson Witch of Flames', rarity:5, two:'Pyro DMG Bonus +15%', four:'Increases Overloaded and Burning DMG by 40%. Increases Vaporize and Melt DMG by 15%. Using an Elemental Skill increases 2-Piece Set effects by 50% for 10s. Max 3 stacks.'},
  {name:'Gladiator\'s Finale', rarity:5, two:'ATK +18%', four:'If the wielder of this artifact set uses a Sword, Claymore or Polearm, increases their Normal Attack DMG by 35%.'},
  {name:'Heart of Depth', rarity:5, two:'Hydro DMG +15%', four:'After using Elemental Skill, increases Normal Attack and Charged Attack by 30% for 15s'},
  {name:'Lavawalker', rarity:5, two:'Pyro RES increased by 40%', four:'Increases DMG against enemies that are Burning or affected by Pyro by 35%.'},
  {name:'Maiden Beloved', rarity:5, two:'Character Healing Effectiveness +15%', four:'Using an Elemental Skill or Burst increases healing received by all party members by 20% for 10s.'},
  {name:'Noblesse Oblige', rarity:5, two:'Elemental Burst DMG +20%', four:'Using an Elemental Burst increases all party members\' ATK by 20% for 12s. This effect cannot stack.'},
  {name:'Retracing Bolide', rarity:5, two:'Increases the effectiveness of shields by 35%', four:'Gain an additional 40% Normal and Charged Attack DMG while under the protection of a shield.'},
  {name:'Thundering Fury', rarity:5, two:'Electro DMG Bonus +15%', four:'Increases damage caused by Overloaded, Electro-Charged, and Superconduct DMG by 40%. Triggering such effects decreases Elemental Skill CD by 1s. Can only occur once every 0.8s.'},
  {name:'Thundersoother', rarity:5, two:'Electro RES increased by 40%', four:'Increases DMG against enemies affected by Electro by 35%.'},
  {name:'Viridescent Venerer', rarity:5, two:'Anemo DMG Bonus +15%', four:'Increases Swirl DMG by 60%. Decreases opponent\'s Elemental RES to the element infused in the Swirl by 40% for 10s.'},
  {name:'Wanderer\'s Troupe', rarity:5, two:'Elemental Mastery +80', four:'Increases Charged Attack DMG by 35% if the character uses a Catalyst or Bow.'},
  
  {name:'Berserker', rarity:4, two:'CRIT Rate +12%', four:'When HP is below 70%, CRIT Rate increases by an additional 24%.'},
  {name:'Brave Heart', rarity:4, two:'ATK +18%', four:'Increases DMG by 30% against enemies with more than 50% HP.'},
  {name:'Defender\'s Will', rarity:4, two:'DEF +30%', four:'For each different element present in your own party, the wearer\'s Elemental RES to that corresponding element is increased by 30%.'},
  {name:'Gambler', rarity:4, two:'Elemental Skill DMG increased by 20%', four:'Defeating an enemy has 100% chance to remove Elemental Skill CD. Can only occur once every 15s.'},
  {name:'Instructor', rarity:4, two:'Increases Elemental Mastery by 80.', four:'Upon triggering an Elemental Reaction, increases all party members\' Elemental Mastery by 120 for 8s.'},
  {name:'Martial Artist', rarity:4, two:'Increases Normal Attack and Charged Attack DMG by 15%.', four:'After using Elemental Skill, increases Normal Attack and Charged Attack DMG by 25% for 8s.'},
  {name:'Resolution of Sojourner', rarity:4, two:'ATK +18%', four:'Increases Charged Attack CRIT Rate by 30%.'},
  {name:'Scholar', rarity:4, two:'Energy Recharge +20%', four:'Gaining Energy gives 3 Energy to all party members who have a bow or a catalyst equipped. Can only occur once every 3s.'},
  {name:'The Exile', rarity:4, two:'Energy Recharge +20%', four:'Using an Elemental Burst regenerates 2 Energy for all party members (excluding the wearer) every 2s for 6s. This effect cannot stack.'},
  {name:'Tiny Miracle', rarity:4, two:'All Elemental RES increased by 20%', four:'Incoming elemental DMG increases corresponding Elemental RES by 30% for 10s. Can only occur once every 10s.'},
  
  {name:'Adventurer', rarity:3, two:'HP increased by 1,000.', four:'Opening chest regenerates 30% HP over 5s.'},
  {name:'Lucky Dog', rarity:3, two:'DEF increased by 100.', four:'Picking up Mora restores 300 HP.'},
  {name:'Traveling Doctor', rarity:3, two:'Increases incoming healing by 20%.', four:'Using Elemental Burst restores 20% HP.'},
];

export default Artifacts;