import Tools from '../../Tools.js';
import Scaling from '../../Scaling.js';
import BaseCharacter from '../base.js';

//TODO: implement character
var Tartaglia = (props) => {
  
  let scaleTable = {
    ascension0: {
      min: {level:1, maxHP:1020, baseAtk:47, defense:63, attr:0},
      max: {level:20, maxHP:2646, baseAtk:61, defense:165, attr:0}
    },
    ascension1: {
      min: {level:20, maxHP:3521, baseAtk:81, defense:219, attr:0},
      max: {level:40, maxHP:5268, baseAtk:121, defense:328, attr:0}
    },
    ascension2: {
      min: {level:40, maxHP:5889, baseAtk:136, defense:366, attr:0.072},
      max: {level:50, maxHP:6776, baseAtk:156, defense:421, attr:0.072}
    },
    ascension3: {
      min: {level:50, maxHP:7604, baseAtk:175, defense:473, attr:0.144},
      max: {level:60, maxHP:8500, baseAtk:196, defense:528, attr:0.144}
    },
    ascension4: {
      min: {level:60, maxHP:9121, baseAtk:210, defense:567, attr:0.144},
      max: {level:70, maxHP:10025, baseAtk:231, defense:623, attr:0.144}
    },
    ascension5: {
      min: {level:70, maxHP:10647, baseAtk:245, defense:662, attr:0.216},
      max: {level:80, maxHP:11561, baseAtk:266, defense:719, attr:0.216}
    },
    ascension6: {
      min: {level:80, maxHP:12182, baseAtk:280, defense:757, attr:0.288},
      max: {level:90, maxHP:13103, baseAtk:301, defense:815, attr:0.288}
    }
  };
  
  function isInRagingTideStance(result) {
    return result.ongoing.effects.find(e => e.description==='Foul Legacy: Raging Tide - Stance')?true:false;
  }
  
  function hasRiptide(result) {
    return result.ongoing.effects.find(e => e.description==='Riptide')?true:false;
  }
  
  let riptideTrack = {
    lastFlashTrigger:-999,
    lastSlashTrigger:-999
  };
  let riptide = {
    riptideEffect: {
      description: 'Riptide',
      entity: 'Tartaglia\'s Riptide',
      type: "Debuff",
      duration: 10,
      PersistentResolve(result, context) {
        if(result.description==='Cutting Torrent - Fully-Aimed' && result.time-context.talent.track.lastFlashTrigger>0.7) {
          result.effects.push(Tools.CreateTalentSideEffect(result, 'riptideFlash'));
          context.talent.track.lastFlashTrigger = result.time;
        } else if(isInRagingTideStance(result) && ["NormalAtk", "ChargeAtk"].find(f => result.type.startsWith(f)) && result.time-context.talent.track.lastSlashTrigger>1.5) {
          result.effects.push(Tools.CreateTalentSideEffect(result, 'riptideSlash'));
          context.talent.track.lastSlashTrigger = result.time;
        } else if(isInRagingTideStance(result) && result.description.startsWith('Havoc: Obliteration')) {
          result.effects.push(Tools.CreateTalentSideEffect(result, 'riptideBlastExplosion'));
          context.setDuration(result.time-context.time);
          context.logs.push('Havoc: Obliteration - Riptide Blast clears Riptide and Triggers a Hydro Explosion')
        }
        return result;
      }
    },
    riptideFlashEffect: {
      description: 'Riptide Flash',
      entity: 'Tartaglia\'s Riptide',
      type: "NormalAtk",
      duration: 0.1,
      relativeTime: (r => -r.precedingResult.duration),
      talentProperties: {
        flashNumber:1
      },
      Prepare(result) {
        result.attributes.element = 'hydro';
        return result;
      },
      Resolve(result) {
        result = Scaling.ScaleTalent(0.124, result);
        return result;
      },
      Finalize(result) {
        if(result.talentProperties.flashNumber<3) {
          result.effects.push(Tools.CreateTalentSideEffect(result, 'riptideFlash', {
            relativeTime: 0,
            talentProperties: {
              flashNumber: result.talentProperties.flashNumber+1
            }
          }));
        }
        return result;
      }
    },
    riptideSlashEffect: {
      description: 'Riptide Slash',
      entity: 'Tartaglia\'s Riptide',
      type: 'Skill',
      duration: 0.1,
      relativeTime: (r => -r.precedingResult.duration),
      Prepare(result) {
        result.attributes.element = 'hydro';
        return result;
      },
      Resolve(result) {
        result = Scaling.ScaleTalent(0.60, result);
        return result;
      }
    },
    riptideBlastExplosionEffect: {
      description: 'Riptide Blast - Explosion',
      entity: 'Tartaglia\'s Riptide',
      type: 'Burst',
      duration: 0.1,
      relativeTime: (r => -r.precedingResult.duration),
      Prepare(result) {
        result.attributes.element = 'hydro';
        return result;
      },
      Resolve(result) {
        result = Scaling.ScaleTalent(1.2, result);
        return result;
      }
    }
  }
  
  return BaseCharacter({
    ...props,
    name: 'Tartaglia',
    element: 'hydro',
    equip: 'bow',
    scaleTable: scaleTable,
    scaleAttr: 'damageBonus.hydro',
    attackTalent: {
      description: 'Cutting Torrent',
      level: props.attack,
      type: "NormalAtk",
      track: riptideTrack,
      defaultImpl: {
        talentProperties: {
          comboTable: [
            {dmg:0.413, anim:0.33}, 
            {dmg:0.463, anim:0.4}, 
            {dmg:0.554, anim:0.5}, 
            {dmg:0.57, anim:0.6}, 
            {dmg:0.609, anim:0.66},
            {dmg:0.728, anim:0.7}
          ],
          stanceComboTable: [
            {dmg:0.389, anim:0.33}, 
            {dmg:0.416, anim:0.4}, 
            {dmg:0.563, anim:0.5}, 
            {dmg:0.599, anim:0.6}, 
            {dmg:0.553, anim:0.66},
            {dmg:0.354, anim:0.7, extra:[{dmg:.377, anim:0.1}]}
          ],
          noStanceComboTable: [
            {dmg:0.413, anim:0.33}, 
            {dmg:0.463, anim:0.4}, 
            {dmg:0.554, anim:0.5}, 
            {dmg:0.57, anim:0.6}, 
            {dmg:0.609, anim:0.66},
            {dmg:0.728, anim:0.7}
          ]
        },
        duration: 1,
        Prepare(result) {
          if(isInRagingTideStance(result)) {
            result.talentProperties.comboTable = result.talentProperties.stanceComboTable;
            result.attributes.element = 'hydro';
          }
          return result;
        },
        Resolve(result) {
          result = Scaling.ScaleAttackCombo(result);
          return result;
        }
      },
      aimedImpl: {
        description: 'Cutting Torrent - Aimed',
        type: "ChargeAtk",
        duration: 0.625,
        Prepare(result) {
          if(isInRagingTideStance(result)) {
            let charged = result.talent.chargedEffect;
            result.description = charged.description;
            result.impl = 'charged';
            result.setDuration(charged.duration);
            result.Prepare = (r => charged.Prepare(r));
            result.Resolve = (r => charged.Resolve(r));
            result = result.Prepare(result);
          }
          return result;
        },
        Resolve(result) {
          result = Scaling.ScaleTalent(0.439, result);
          result.attributes.element='physical';
          return result;
        }
      },
      fullyAimedImpl: {
        description: 'Cutting Torrent - Fully-Aimed',
        type: "ChargeAtkHold",
        duration: 1.5,
        Prepare(result) {
          if(isInRagingTideStance(result)) {
            let charged = result.talent.chargedEffect;
            result.description = charged.description;
            result.setDuration(charged.duration);
            result.Prepare = (r => charged.Prepare(r));
            result.Resolve = (r => charged.Resolve(r));
            result = result.Prepare(result);
          } else {
            result.attributes.element = 'hydro';
            result.effects.push(Tools.CreateTalentSideEffect(result, 'riptide'));
          }
          return result;
        },
        Resolve(result) {
          result = Scaling.ScaleTalent(1.24, result);
          return result;
        }
      },
      ...riptide,
      chargedEffect: {
        description: 'Cutting Torrent - Charged A-Hit',
        duration:0.3,
        Prepare(result) {
          result.attributes.element = 'hydro';
          return result;
        },
        Resolve(result) {
          let slash = Tools.CreateTalentSideEffect(result, 'default');
          slash.precedingResult = result.precedingResult;
          result.talentEffects.push(slash);
          
          result.talentEffects.push(Tools.CreateTalentSideEffect(result, 'chargedSecond'));
          result = Scaling.ScaleAttackTalent(0.602, result);
          return result;
        }
      },
      chargedSecondEffect: {
        description: 'Cutting Torrent - Charged B-Hit',
        type: "ChargeAtk",
        duration: 0.3,
        Prepare(result) {
          result.attributes.element = 'hydro';
          return result;
        },
        Resolve(result) {
          result = Scaling.ScaleAttackTalent(0.72, result);
          return result;
        }
      }
    },
    skillTalent: {
      description:'Foul Legacy: Raging Tide',
      level: props.skill,
      type: 'Skill',
      track: {
        lastDuration: 0,
        lastEnter: -999,
        lastExit: -999
      },
      defaultImpl: {
        duration: 0.25, //changeback= 0.15
        Prepare(result) {
          
          if(!isInRagingTideStance(result)) {
            if(result.talent.track.lastExit + result.talent.track.lastDuration + (result.talent.track.lastDuration<30?6:15) > result.time) {
              result = Tools.CreateCooldownEffect(result, {
                cooldown: result.talent.track.lastDuration*2 + (result.talent.track.lastDuration<30?6:15),
                lastUsed: result.talent.track.lastEnter
              });
            }
            result.effects.push(Tools.CreateTalentSideEffect(result, 'stance'));
          } else {
            if(result.talent.track.lastEnter + 1 > result.time) {
              result = Tools.CreateCooldownEffect(result, {
                cooldown: result.talent.track.lastUsed + 1 - result.time
              });
            }
            result.setDuration(0.15);
          }
          return result;
        },
        Resolve(result) {
          if(!isInRagingTideStance(result)) {
            result = Scaling.ScaleTalent(0.72, result);
          }
          return result;
        }
      },
      stanceEffect: {
        description: 'Foul Legacy: Raging Tide - Stance',
        entity: 'Tartaglia\'s Raging Tide',
        duration: 30,
        Prepare(result) {
          result.talent.track.lastEnter = result.time;
          return result;
        },
        PersistentPrepare(result, context) {
          if(result!==context && result.description==="Foul Legacy: Raging Tide") {
            context.logs.push('Preemptively Leaving Raging Tide Stance');
            context.setDuration(result.time - context.time + 0.1);
            result.talent.track.lastDuration = context.duration;
            result.talent.track.lastExit = result.time;
          }
          return result;
        },
        PersistentResolve(result, context) {
          return result;
        },
        Finalize(result) {
          result.talent.track.lastDuration = result.duration;
          result.talent.track.lastExit = result.time+result.duration;
          return result;
        }
      }
    },
    burstTalent: {
      description: 'Havoc: Obliteration',
      level: props.burst,
      type: 'Burst',
      track: riptideTrack,
      defaultImpl: {
        enforceCooldown: true,
        cooldown: 15,
        Prepare(result) {
          return result;
        },
        Resolve(result) {
          if(isInRagingTideStance(result)) {
            result.description = 'Havoc: Obliteration - Light of Obliteration';
            result = Scaling.ScaleTalent(4.64, result);
          } else {
            result.description = 'Havoc: Obliteration - Flash of Havoc';
            result = Scaling.ScaleTalent(3.78, result);
          }
          return result;
        },
        Finalize(result) {
          if(!isInRagingTideStance(result)) {
            result.effects.push(Tools.CreateTalentSideEffect(result, 'riptide'));
          }
          return result;
        }
      },
      ...riptide
    },
    passiveAsc1: {
      description: 'Never Ending',
      PersistentResolve(result, context) {
        return result;
      }
    },
    passiveAsc4: {
      description: 'Sword of Torrents',
      PersistentResolve(result, context) {
        return result;
      }
    },
    passiveOther: [{
      description: 'Master of Weaponry',
      PersistentResolve(result, context) {
        return result;
      }
    }],
    constellations: [
      ...BaseCharacter.Constellation35
    ]
  });
};

export default Tartaglia;