import Scaling from '../../Scaling.js';
import BaseWeapon from '../base.js';

const PrototypeMalice = (props) => {
  
  return BaseWeapon({
    ...props,
    type: 'catalyst',
    name: 'Prototype Malice',
    lvl1Atk: 42,
    lvl1Sub: 0.09,
    subType: 'hpPct',
    PersistentResolve(result, context) {
      /**
       * effect: 'Using an Elemental Burst regenerates [4+0.5] Energy every 2s 
       * for 6s. Additionally, all party members will regenerate [4+0.5%] HP 
       * every 2s for this duration.'
       */
      if(this.IsSelf(result)) {
      }
      return result;
    }
  });
};

export default PrototypeMalice;