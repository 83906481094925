import Scaling from '../../Scaling.js';
import BaseWeapon from '../base.js';
import Tools from '../../Tools.js'

const SkywardHarp = (props) => {
  
  return BaseWeapon({
    ...props,
    type: 'bow',
    name: 'Skyward Harp',
    lvl1Atk: 48,
    lvl1Sub: 0.048,
    subType: 'critRate',
    track: {lastTrigger: -999},
    PersistentResolve(result, context) {
      /**
       * effect: 'Increases CRIT DMG by [20%]. Hits have a [60+10%] chance to 
       * inflict a small AoE attack, dealing 125% Physical ATK DMG. Can only 
       * occur once every [4-]s.'
       */
      if(this.IsSelf(result)) {
        let effectValues = {
          critDmg: Scaling.ScaleWeaponPassive(0.20, this.refinement),
          triggerChance: Scaling.ScaleWeaponPassive([0.6, 0.1], this.refinement),
          triggerCD: Scaling.ScaleWeaponPassive([4, -0.5], this.refinement)
        };
        result.attributes.critDmg += effectValues.critDmg;
        if(result.attributes.skillPct>0) {
          if(result.time - this.track.lastTrigger > effectValues.triggerCD
            && Tools.ProcChance(effectValues.triggerChance, this.track)) {
            result.logs.push(`Skyward Harp increases CRIT DMG by ${Math.round(effectValues.critDmg*100)}% and deals 125% dmg as AOE (${Math.round(effectValues.triggerChance*100)}% chance every ${effectValues.triggerCD}s)`);
            result.effects.push(Tools.CreateSideEffect(result, {
              description: "Echoing Ballad",
              entity: result.initiator.name+"'s Skyward Harp",
              duration: 0.1,
              type: 'Summon',
              relativeTime: (r => -r.precedingResult.duration),
              Resolve(result) {
                result.attributes.skillPct = 1.25;
                return result;
              }
            }));
            this.track.lastTrigger = result.time;
          } else {
            result.logs.push(`Skyward Harp increases CRIT DMG by ${Math.round(effectValues.critDmg*100)}%`);
          }
        }
      }
      return result;
    }
  });
};

export default SkywardHarp;