import Scaling from '../../Scaling.js';
import BaseWeapon from '../base.js';

const FerrousShadow = (props) => {
  
  return BaseWeapon({
    ...props,
    type: 'claymore',
    name: 'Ferrous Shadow',
    lvl1Atk: 39,
    lvl1Sub: 0.077,
    subType: 'hpPct',
    PersistentResolve(result, context) {
      /**
       * effect: 'When HP falls below [70+5%], increases Charged Attack DMG 
       * by [30+5%], and Charged Attacks become much harder to interrupt.'
       */
      if(this.IsSelf(result)) {
      }
      return result;
    }
  });
};

export default FerrousShadow;