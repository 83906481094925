import Scaling from '../../Scaling.js';
import BaseWeapon from '../base.js';

const FavoniusWarbow = (props) => {
  
  return BaseWeapon({
    ...props,
    type: 'bow',
    name: 'Favonius Warbow',
    lvl1Atk: 41,
    lvl1Sub: 0.133,
    subType: 'energyRecharge',
    PersistentResolve(result, context) {
      /**
       * effect: 'CRIT hits have a [60+10%] chance to generate 1 Elemental Orb, 
       * which will regenerate 6 Energy for the character. Can only occur once 
       * every [12-]s.'
       */
      if(this.IsSelf(result)) {
      }
      return result;
    }
  });
};

export default FavoniusWarbow;