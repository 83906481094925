import React, {Fragment, useState, useRef} from 'react';
import { Grid, Popup, Header, Item, Rail, Sticky, Ref, Segment, Menu } from 'semantic-ui-react';
import UserGuide from '../../components/db/user-guide.js';
import './UserGuidePage.css';

export default (props) => {
  const [activeItem, setActiveItem] = useState(UserGuide.articles[0].title);
  const contextRef = useRef();
  
  function handleItemClick(e, { name }){
    setActiveItem(name);
  }

  
  return <Grid centered columns={2}>
    <Grid.Column width={3}>
    </Grid.Column>
    <Grid.Column width={13}>
      <Ref innerRef={contextRef}>
        <Segment size="big">
          <Rail position='left' attached style={{width:"15rem"}} close="very">
            <Sticky context={contextRef} offset={50} style={{zIndex:100}}>
              <Menu vertical>
                {UserGuide.articles.map(article => {
                  return <Menu.Item
                    name={article.title}
                    active={activeItem === article.title}
                    onClick={handleItemClick}
                  >
                    {article.title}
                  </Menu.Item>;
                })}
              </Menu>
            </Sticky>
          </Rail>
          {UserGuide.articles.find(a => a.title===activeItem).content}
        </Segment>
      </Ref>
    </Grid.Column>
  </Grid>;
};