import * as Articles from './user-guide/';

export default {
  articles: [
    Articles.WhatIs,
    Articles.GettingStarted,
    Articles.SetupCharacter,
    Articles.SetupMonster,
    Articles.CreateComposition,
    Articles.Interpreting,
    Articles.HistoryTab,
    Articles.AdvancedUsage,
    Articles.Sharing,
    Articles.ThingsWorthNoting
  ]
};