import Scaling from '../../Scaling.js';
import BaseWeapon from '../base.js';

const TheUnforged = (props) => {
  
  return BaseWeapon({
    ...props,
    type: 'claymore',
    name: 'The Unforged',
    lvl1Atk: 46,
    lvl1Sub: 0.108,
    subType: 'atkPct',
    PersistentResolve(result, context) {
      /**
       * effect: 'Increases Shield Strength by [20%], scoring hits on opponents 
       * increases ATK by [4%] for 8s. Max 5 stacks. Can only occur once every 
       * 0.3s. While protected by a shield this ATK increase effect is 
       * increased by 100%'
       */
      if(this.IsSelf(result)) {
        let effectValues = {
          shieldStrength: Scaling.ScaleWeaponPassive(0.20, this.refinement),
          atkIncrease: Scaling.ScaleWeaponPassive(0.04, this.refinement)
        }
      }
      return result;
    }
  });
};

export default TheUnforged;