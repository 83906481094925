import Scaling from '../../Scaling.js';
import BaseWeapon from '../base.js';

const TheAlleyFlash = (props) => {
  
  return BaseWeapon({
    ...props,
    type: 'sword',
    name: 'The Alley Flash',
    lvl1Atk: 44,
    lvl1Sub: 0.04,
    subType: 'critRate',
    PersistentResolve(result, context) {
      /**
       * effect: 'Continuosly sprinting for at least 1s increases ATK by 
       * 28% for 6s. This effect cannot stack.'
       */
      if(this.IsSelf(result)) {
      }
      return result;
    }
  });
};

export default TheAlleyFlash;