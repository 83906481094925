import {BaseSet} from './base.js';

const GladiatorsFinale = (props) => {
  
  return BaseSet({
    ...props,
    name: 'Gladiator\'s Finale',
    two: {
      attributes: {
        atkPct: 0.18
      },
      PersistentResolve(result, context) {
        return result;
      }
    },
    four: {
      PersistentResolve(result, context) {
        if(!this.IsSelf(result)) return result;
        if(["sword","claymore","polearm"].find(w => w===result.initiator.equip)
          && result.type.startsWith("NormalAtk")) {
          result.attributes.damageBonus.all += 0.35;
          result.logs.push("Gladiator's Finale increases normal attack damage by 35%");
        }
        return result;
      }
    }
  });
};

export default GladiatorsFinale;