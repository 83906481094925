/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createComposition = /* GraphQL */ `
  mutation CreateComposition(
    $input: CreateCompositionInput!
    $condition: ModelCompositionConditionInput
  ) {
    createComposition(input: $input, condition: $condition) {
      id
      team {
        name
        build
        level
        ascension
        constellation
        attack {
          level
        }
        skill {
          level
        }
        burst {
          level
        }
        equip
        weapon {
          name
          level
          ascension
          refinement
        }
        artifacts {
          sets {
            name
            setPieces
          }
          stats {
            flower {
              edmg
              pdmg
              bonus
              atk
              atkpct
              cr
              cd
              er
              em
              def
              defpct
              hp
              hppct
            }
            feather {
              edmg
              pdmg
              bonus
              atk
              atkpct
              cr
              cd
              er
              em
              def
              defpct
              hp
              hppct
            }
            time {
              edmg
              pdmg
              bonus
              atk
              atkpct
              cr
              cd
              er
              em
              def
              defpct
              hp
              hppct
            }
            goblet {
              edmg
              pdmg
              bonus
              atk
              atkpct
              cr
              cd
              er
              em
              def
              defpct
              hp
              hppct
            }
            head {
              edmg
              pdmg
              bonus
              atk
              atkpct
              cr
              cd
              er
              em
              def
              defpct
              hp
              hppct
            }
          }
        }
      }
      history {
        entity
        talent
        impl
        description
      }
      monsters {
        name
        level
      }
      password
      sticky
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateComposition = /* GraphQL */ `
  mutation UpdateComposition(
    $input: UpdateCompositionInput!
    $condition: ModelCompositionConditionInput
  ) {
    updateComposition(input: $input, condition: $condition) {
      id
      team {
        name
        build
        level
        ascension
        constellation
        attack {
          level
        }
        skill {
          level
        }
        burst {
          level
        }
        equip
        weapon {
          name
          level
          ascension
          refinement
        }
        artifacts {
          sets {
            name
            setPieces
          }
          stats {
            flower {
              edmg
              pdmg
              bonus
              atk
              atkpct
              cr
              cd
              er
              em
              def
              defpct
              hp
              hppct
            }
            feather {
              edmg
              pdmg
              bonus
              atk
              atkpct
              cr
              cd
              er
              em
              def
              defpct
              hp
              hppct
            }
            time {
              edmg
              pdmg
              bonus
              atk
              atkpct
              cr
              cd
              er
              em
              def
              defpct
              hp
              hppct
            }
            goblet {
              edmg
              pdmg
              bonus
              atk
              atkpct
              cr
              cd
              er
              em
              def
              defpct
              hp
              hppct
            }
            head {
              edmg
              pdmg
              bonus
              atk
              atkpct
              cr
              cd
              er
              em
              def
              defpct
              hp
              hppct
            }
          }
        }
      }
      history {
        entity
        talent
        impl
        description
      }
      monsters {
        name
        level
      }
      password
      sticky
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteComposition = /* GraphQL */ `
  mutation DeleteComposition(
    $input: DeleteCompositionInput!
    $condition: ModelCompositionConditionInput
  ) {
    deleteComposition(input: $input, condition: $condition) {
      id
      team {
        name
        build
        level
        ascension
        constellation
        attack {
          level
        }
        skill {
          level
        }
        burst {
          level
        }
        equip
        weapon {
          name
          level
          ascension
          refinement
        }
        artifacts {
          sets {
            name
            setPieces
          }
          stats {
            flower {
              edmg
              pdmg
              bonus
              atk
              atkpct
              cr
              cd
              er
              em
              def
              defpct
              hp
              hppct
            }
            feather {
              edmg
              pdmg
              bonus
              atk
              atkpct
              cr
              cd
              er
              em
              def
              defpct
              hp
              hppct
            }
            time {
              edmg
              pdmg
              bonus
              atk
              atkpct
              cr
              cd
              er
              em
              def
              defpct
              hp
              hppct
            }
            goblet {
              edmg
              pdmg
              bonus
              atk
              atkpct
              cr
              cd
              er
              em
              def
              defpct
              hp
              hppct
            }
            head {
              edmg
              pdmg
              bonus
              atk
              atkpct
              cr
              cd
              er
              em
              def
              defpct
              hp
              hppct
            }
          }
        }
      }
      history {
        entity
        talent
        impl
        description
      }
      monsters {
        name
        level
      }
      password
      sticky
      createdAt
      updatedAt
      owner
    }
  }
`;
