import Scaling from '../Scaling.js';

const BaseWeapon = (props) => {
  
  return {
    type: props.type,
    name: props.name,
    level: props.level || 1,
    ascension: props.ascension || 0,
    refinement: props.refinement,
    attributes: {
      ...props.attributes, 
      ...Scaling.ScaleWeaponStats(props.lvl1Atk, props.lvl1Sub, props.subType, props.level, props.ascension)
    },
    track: {...(props.track || {})},
    PersistentPrepare: props.PersistentPrepare || (r=>r),
    PersistentResolve: props.PersistentResolve || (r=>r),
    PersistentFinalize: props.PersistentFinalize || (r=>r),
    IsSelf(result) {
      return result && result.initiator && result.initiator.weapon === this;
    }
  };
};

export default BaseWeapon;