import React, {useState, useEffect, Fragment} from 'react';
import { Grid, Segment, Label, Popup } from 'semantic-ui-react'

const varColors = {
  "defense":"#AAA",
  "defense percent":"#888",
  "base attack":"rgb(230 0 0 / 70%)",
  "base attack percent":"rgb(230 0 0 / 40%)",
  "attack percent":"rgb(255 0 0 / 40%)",
  "flat attack":"rgb(189 0 0 / 60%)",
  "damage bonus":"#F08",
  "pyro damage bonus":"#e97a38",
  "cryo damage bonus":"#98eef0",
  "hydro damage bonus":"#32c6f8",
  "electro damage bonus":"#d195f5",
  "anemo damage bonus":"#59d6a7",
  "geo damage bonus":"#f4d860",
  "dendro damage bonus":"#b3eb31",
  "skill percent":"#D22",
  "character level":"#AAA",
  "target level":"#A66",
  "defense reduction":"#888",
  "resistance":"#F08",
  "pyro resistance":"#e97a38",
  "cryo resistance":"#98eef0",
  "hydro resistance":"#32c6f8",
  "electro resistance":"#d195f5",
  "anemo resistance":"#59d6a7",
  "geo resistance":"#f4d860",
  "dendro resistance":"#b3eb31",
  "resistance reduction":"#F08",
  "pyro resistance reduction":"#e97a38",
  "cryo resistance reduction":"#98eef0",
  "hydro resistance reduction":"#32c6f8",
  "electro resistance reduction":"#d195f5",
  "anemo resistance reduction":"#59d6a7",
  "geo resistance reduction":"#f4d860",
  "dendro resistance reduction":"#b3eb31",
  "critical damage":"rgb(223 226 42)",
  "critical rate":"rgb(228 139 47)",
  "melt multiplier":"#f2d0bb",
  "melt bonus":"#f2d0bb",
  "vaporize multiplier":"#94e2fd",
  "vaporize bonus":"#94e2fd",
  "shattered multiplier":"#98eef0",
  "shattered bonus":"#98eef0",
  "elemental mastery":"#2aab46",
  "overloaded reaction bonus":"#e77a37",
  "superconduct reaction bonus":"#7eb4b9",
  "electro-charged reaction bonus":"#bd95da",
  "shattered reaction bonus":"#98eef0",
  "swirl reaction bonus":"#6dd1ac",
  "damage":""
}

function renderValue(v, format) {
  let formattedValue = v;
  if(typeof v === "number") {
    if(format==="percent") {
      formattedValue = (Math.round(v*10000)/100).toLocaleString('en-US')+"%";
    } else if(format==="float") {
      formattedValue = v;
    } else if(format==="int") {
      formattedValue = Math.round(v).toLocaleString('en-US');
    } else {
      formattedValue = (Math.round(v*10000)/10000).toLocaleString('en-US');
    }
  }
  return formattedValue;
}

function styleNameLabel(name) {
  return {
    maxHeight:"2rem", 
    overflow:"hidden", 
    textTransform: "capitalize", 
    whiteSpace: "nowrap"
  }
}

const compactLabelStyle = {paddingLeft:"0.05rem", paddingRight:"0.05rem", backgroundColor:"#FFF"};
const typeTraductor = {
  "plus":"+",
  "minus":"-",
  "mult":"*",
  "div":"/",
  "equals":"=",
  "exp":"^",
  "e":"e"
}

function renderExplainTree(node) {
  let {name, items} = node.struct;
  return <Segment basic style={{display:"inline-block", margin:"0", padding:"0"}}>
    {name?<Label attached="top" style={styleNameLabel(name)}>{name}</Label>:''}
    {items.map(i => {
      let r;
      if(i.struct) {
        r = <Fragment><Label color="white" style={compactLabelStyle}>(</Label>{renderExplainTree(i)}<Label color="white" style={compactLabelStyle}>)</Label></Fragment>;
      } else {
        if(i.type==='var' || i.type==='rad') {
          r = renderValue(i.value, i.format);
        } else {
          r = typeTraductor[i.type];
        }
        if(i.name) {
          r = <Popup 
              trigger={
                <Label style={{...compactLabelStyle, backgroundColor:varColors[i.name]}}>{r}</Label>
              }
              style={{textTransform:"capitalize"}}>
              {i.name}
            </Popup>;
        } else {
          r = <Label style={compactLabelStyle}>{r}</Label>;
        }
      }
      return r;
    }).flat()}
  </Segment>;
}

const Explainer = (props) => {
  const [explainTree, setExplainTree] = useState(props.explainTree);
  
  useEffect(() => {
    setExplainTree(props.explainTree);
  }, [props.explainTree]);
  
  let e = renderExplainTree(explainTree);
  return <Segment basic>
    {e}
  </Segment>;
};

export default Explainer;