import React, {useState, useEffect, Fragment} from 'react';
import { Confirm, Segment, Button, List, Image, Header, Icon, Popup } from 'semantic-ui-react';
import ReactGA from 'react-ga';

const HistoryList = (props) => {
    const [history, setHistory] = useState(props.history);
    const [activeChars, setActiveChars] = useState(props.activeChars);
    useEffect(() => {
        setHistory(props.history);
        setActiveChars(props.activeChars);
    }, [props.history, props.activeChars]);
    return <div>
        <List divided relaxed style={{
          height: "calc(100% - 3.5em)",
          overflowY: "scroll",
          position: "fixed",
          width: "calc(100% - 1em)"
        }}>
          {history.entries.length===0?<List.Item><Segment style={{width: "93%"}}><Header size='small'>Your history is Empty!</Header><br/>Talents and abilities you use will be listed here. You can then click them to go back in time.</Segment></List.Item>:
            [{
              description: 'Start'
            }, ...history.entries]
            .map((e,i) => <Fragment key={"historyList"+i}>
              <List.Item onClick={() =>{
                ReactGA.event({
                  category: 'History Action',
                  action: 'GoTo'
                });
                history.GoTo(i);
                props.OnHistoryChange();
              }} style={{
                opacity: (i>history.firstVersion && i<=history.currentVersion) || (history.firstVersion===0 && e.description==='Start')
                         ? 1
                         : 0.5
              }}>
                {
                  e.entity
                  ? <Fragment>
                      {
                        !activeChars.find(a => a.name===e.entity)
                        ? <Popup trigger={
                            <Icon name="warning sign" style={{display:'inline-block', color:'orangered', marginTop:'0.55em'}} />
                          }>{e.entity} is not currently in active team</Popup>
                        : ''
                      }
                      <Image avatar src={"/static/characters/"+e.entity.replaceAll(/[^0-9a-z]/gi, "").toLowerCase()+".png"} aria-label={e.entity} />
                    </Fragment>
                  : e.description==="Reset"
                    ? ''//<Icon circular name="eraser" />
                    : ''
                }
                <List.Content style={{width:"113px"}}>
                  <List.Header as='a'>{e.description}</List.Header>
                  <List.Description as='a'>{e.talent}</List.Description>
                </List.Content>
              </List.Item>
            </Fragment>
            ).reverse()
          }
        </List>
      </div>;
}

export default HistoryList;