import Scaling from '../../Scaling.js';
import BaseWeapon from '../base.js';

const WhiteTassel = (props) => {
  
  return BaseWeapon({
    ...props,
    type: 'polearm',
    name: 'White Tassel',
    lvl1Atk: 39,
    lvl1Sub: 0.051,
    subType: 'critRate',
    PersistentResolve(result, context) {
      /**
       * effect: 'Increases Normal Attack DMG by [24%].'
       */
      if(this.IsSelf(result)) {
      }
      return result;
    }
  });
};

export default WhiteTassel;