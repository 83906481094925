import React, {Fragment, useState, useEffect} from 'react';
import { Label, Icon, Header, Image, Segment, Popup, Modal, Dropdown, Rating, Input, Button, Form } from 'semantic-ui-react';
import './character-edit.css';
import Weapons from './db/weapons.js';

const weaponItemRenderer = (name, rarity, image, devStage=undefined) => {
  return <Header>
    <Image src={image} aria-label={name} />
    <Header.Content style={{fontSize: (name.length>=30?(30/name.length)+"em":"1em")}}>
      {
        devStage==="Testing"
        ? <Popup trigger={<Icon name="warning sign" style={{color:"orange"}} />}>Effect in testing</Popup>
        : devStage==="NotImpl"
          ? <Popup trigger={<Icon name="times circle" style={{color:"red"}} />}>Weapon Stats ONLY. Effect not implemented</Popup>
          : ''
      }
      {name}
      <Header.Subheader className='rarity'>{[...Array(rarity)].map(i => <Icon name='star' />)}</Header.Subheader>
    </Header.Content>
  </Header>;
};

const WeaponSelect = (props) => {
  const weapons = {
    bow:Weapons.bow.map(w => {
      let key = w.name.replaceAll(/[^a-z0-9]+/gi, '').toLowerCase();
      return {
        key: key,
        value: w.name,
        text: weaponItemRenderer(w.name, w.rarity, '/static/weapons/bow/'+key+'.png', w.devStage)
      };
    }),
    catalyst:Weapons.catalyst.map(w => {
      let key = w.name.replaceAll(/[^a-z0-9]+/gi, '').toLowerCase();
      return {
        key: key,
        value: w.name,
        text: weaponItemRenderer(w.name, w.rarity, '/static/weapons/catalyst/'+key+'.png', w.devStage)
      };
    }),
    sword:Weapons.sword.map(w => {
      let key = w.name.replaceAll(/[^a-z0-9]+/gi, '').toLowerCase();
      return {
        key: key,
        value: w.name,
        text: weaponItemRenderer(w.name, w.rarity, '/static/weapons/sword/'+key+'.png', w.devStage)
      };
    }),
    polearm:Weapons.polearm.map(w => {
      let key = w.name.replaceAll(/[^a-z0-9]+/gi, '').toLowerCase();
      return {
        key: key,
        value: w.name,
        text: weaponItemRenderer(w.name, w.rarity, '/static/weapons/polearm/'+key+'.png', w.devStage)
      };
    }),
    claymore:Weapons.claymore.map(w => {
      let key = w.name.replaceAll(/[^a-z0-9]+/gi, '').toLowerCase();
      return {
        key: key,
        value: w.name,
        text: weaponItemRenderer(w.name, w.rarity, '/static/weapons/claymore/'+key+'.png', w.devStage)
      };
    }),
    "None":[{key:'none',value:'None',text:weaponItemRenderer('None', 0, '/static/weapons/bow/none.png')}]
  };
  
  const [weapon, setWeapon] = useState(props.value);
  const [equip, setEquip] = useState(props.value);
  
  useEffect(() => {
    if(weapons[props.equip]) {
      setWeapon(weapons[props.equip].find(c => c.value===props.value)?props.value:{name:'None'});
      setEquip(props.equip);
    }
  }, [props.equip, props.value]);

  return <Fragment>
      <Dropdown
        placeholder='Select Weapon'
        label='Weapon'
        className='weapon'
        disabled={equip==="-"}
        selection
        options={weapons[equip]} value={weapon} onChange={(e,d) => {
          setWeapon(d.value);
          props.onChange(e,d);
        }}
        />
    </Fragment>;
};

export default WeaponSelect;