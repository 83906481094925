import Scaling from '../../Scaling.js';
import BaseWeapon from '../base.js';

const TheBell = (props) => {
  
  return BaseWeapon({
    ...props,
    type: 'claymore',
    name: 'The Bell',
    lvl1Atk: 42,
    lvl1Sub: 0.09,
    subType: 'hpPct',
    PersistentResolve(result, context) {
      /**
       * effect: 'Taking DMG generates a shield which absorbs DMG up to [20+3%] 
       * of max HP. This shield lasts for 10s or until broken, and can only be 
       * triggered once every 45s. While protected by the shield, the character 
       * gains [12%] increased DMG.'
       */
      if(this.IsSelf(result)) {
      }
      return result;
    }
  });
};

export default TheBell;