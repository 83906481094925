import Scaling from '../../Scaling.js';
import BaseWeapon from '../base.js';

const LionsRoar = (props) => {
  
  return BaseWeapon({
    ...props,
    type: 'sword',
    name: 'Lion\'s Roar',
    lvl1Atk: 42,
    lvl1Sub: 0.09,
    subType: 'atkPct',
    PersistentResolve(result, context) {
      /**
       * effect: 'Increases DMG against enemies affected by Pyro or Electro 
       * by [20+4%].'
       */
      if(this.IsSelf(result)) {
      }
      return result;
    }
  });
};

export default LionsRoar;