import Scaling from '../../Scaling.js';
import BaseWeapon from '../base.js';

const TheStringless = (props) => {
  
  return BaseWeapon({
    ...props,
    type: 'bow',
    name: 'The Stringless',
    lvl1Atk: 42,
    lvl1Sub: 36,
    subType: 'elemMastery',
    PersistentResolve(result, context) {
      /**
       * effect: 'Increases Elemental Skill and Elemental Burst DMG by 24%.'
       */
      if(this.IsSelf(result)) {
      }
      return result;
    }
  });
};

export default TheStringless;