

const Explainer = (props={}) => {
  return {
    struct:{name:props.name, items:[]},
    CreateGroup(name=undefined) {
      let g = Explainer({name:name});
      this.struct.items.push(g);
      return g;
    },
    Add(type, value=undefined, name=undefined, format=undefined) {
      this.struct.items.push({type:type, name:name, value:value, format:format});
      return this;
    }
  };
};
export default Explainer;