import Scaling from '../../Scaling.js';
import BaseWeapon from '../base.js';

const FavoniusCodex = (props) => {
  
  return BaseWeapon({
    ...props,
    type: 'catalyst',
    name: 'Favonius Codex',
    lvl1Atk: 42,
    lvl1Sub: 0.10,
    subType: 'energyRecharge',
    PersistentResolve(result, context) {
      /**
       * effect: 'CRIT hits have a [60%] chance to generate a small amount of 
       * Elemental Particles, which will regenerate 6 Energy for the character. 
       * Can only occur once every [12-1.5]s.'
       */
      if(this.IsSelf(result)) {
      }
      return result;
    }
  });
};

export default FavoniusCodex;