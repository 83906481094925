import Scaling from '../../Scaling.js';
import BaseWeapon from '../base.js';

const PrimordialJadeWingedSpear = (props) => {
  
  return BaseWeapon({
    ...props,
    type: 'polearm',
    name: 'Primordial Jade Winged-Spear',
    lvl1Atk: 48,
    lvl1Sub: 0.048,
    subType: 'critRate',
    PersistentResolve(result, context) {
      /**
       * effect: 'On hit, increases ATK by [3.2+0.7%] for 6s. Max 7 stacks. 
       * This effect can only occur once every 0.3s. While in possession of the 
       * maximum possible stacks, DMG dealt is increased by [12%].'
       */
      if(this.IsSelf(result)) {
        let effectValues = {
          atkIncrease: Scaling.ScaleWeaponPassive([0.032, 0.007], this.refinement),
          maxStackDmgIncrease: Scaling.ScaleWeaponPassive(0.12, this.refinement)
        }
      }
      return result;
    }
  });
};

export default PrimordialJadeWingedSpear;