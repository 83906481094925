import React, {useState, useEffect, Fragment} from 'react';
import {Auth} from 'aws-amplify';
import { Modal, Button, Form, Input } from 'semantic-ui-react';

var Register = (props) => {
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState('');
  const [pass, setPass] = useState('');
  const [terms, setTerms] = useState(false);
  
  async function register(user, pass, terms) {
    let ret;
    try {
      const { user } = await Auth.signUp({
        username: user, 
        password: pass,
        attributes: {
          email: user,
          terms
        }
      });
      console.log("SignUp", user);
    } catch (error) {
      console.log(`Error signing up: ${error}`);
      ret = false;
    }
    return ret;
  }
  
  return <Modal 
    trigger={props.children}
    onClose={() => {
      setOpen(false);
    }}
    onOpen={() => setOpen(true)} 
    open={open}
    size='mini'>
      <Modal.Header>Register</Modal.Header>
      <Modal.Content>
        <Form>
          <Input label="Email" value={user} onChange={(e,d) => setUser(d.value)} />
          <Input type="password" label="Password" value={pass} onChange={(e,d) => setPass(d.value)} />
          <Input type="checkbox" label="I accept the terms" value={terms} onChange={(e,d) => setTerms(!!d.value)} />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => setOpen(false)}>Cancel</Button>
        <Button onClick={() => register(user, pass, terms)}>Register</Button>
      </Modal.Actions>
    </Modal>
}

export default Register;