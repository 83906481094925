import Scaling from '../../Scaling.js';
import BaseWeapon from '../base.js';

const RoyalBow = (props) => {
  
  return BaseWeapon({
    ...props,
    type: 'bow',
    name: 'Royal Bow',
    lvl1Atk: 42,
    lvl1Sub: 0.09,
    subType: 'atkPct',
    PersistentResolve(result, context) {
      /**
       * effect: 'Upon damaging an opponent, increases CRIT Rate by 8%. Max 5 
       * stacks. A CRIT hit removes all stacks.'
       */
      if(this.IsSelf(result)) {
      }
      return result;
    }
  });
};

export default RoyalBow;