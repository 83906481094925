import Scaling from '../../Scaling.js';
import BaseWeapon from '../base.js';

const AmosBow = (props) => {
  
  return BaseWeapon({
    ...props,
    type: 'bow',
    name: 'Amos\' Bow',
    lvl1Atk: 46,
    lvl1Sub: 0.108,
    subType: 'atkPct',
    PersistentResolve(result, context) {
      /**
       * effect: 'Increases Normal Attack and Aimed Shot DMG by [12%]. Increases 
       * DMG from arrows shot by a further [8%] for every 0.1s that the arrow 
       * is in flight, up to 0.5s.'
       */
      if(this.IsSelf(result)) {
        if(result.type.startsWith("NormalAtk") 
        || result.type==="ChargeAtk" 
        || result.type==="ChargeAtkHold") {
          result.logs.push('Amos\' Bow Increases Normal Attack and Aimed Shot DMG by '+(Scaling.ScaleWeaponPassive(0.12, this.refinement)*100)+'% + '+(Scaling.ScaleWeaponPassive(0.08, this.refinement)*100)+'% (assuming max range).');
          result.attributes.damageBonus.all += Scaling.ScaleWeaponPassive(0.20, this.refinement);
        }
      }
      return result;
    }
  });
};

export default AmosBow;