import Scaling from '../../Scaling.js';
import BaseWeapon from '../base.js';

const BlackcliffLongsword = (props) => {
  
  return BaseWeapon({
    ...props,
    type: 'sword',
    name: 'Blackcliff Longsword',
    lvl1Atk: 44,
    lvl1Sub: 0.08,
    subType: 'critDmg',
    PersistentResolve(result, context) {
      /**
       * effect: 'After defeating an enemy, ATK is increased by [12%] for 30s. 
       * This effect has a maximum of 3 stacks, and the duration of each stack 
       * is independent of the others.'
       */
      if(this.IsSelf(result)) {
      }
      return result;
    }
  });
};

export default BlackcliffLongsword;