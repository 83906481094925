import Scaling from '../../Scaling.js';
import BaseWeapon from '../base.js';

const AmberCatalyst = (props) => {
  
  return BaseWeapon({
    ...props,
    type: 'catalyst',
    name: 'Amber Catalyst',
    lvl1Atk: 40,
    lvl1Sub: 16,
    subType: 'elemMastery',
    PersistentResolve(result, context) {
      /**
       * effect: 'Normal Attack hits increase all Elemental DMG by [6%] for 6s. 
       * Max 2 stacks.'
       */
      if(this.IsSelf(result)) {
      }
      return result;
    }
  });
};

export default AmberCatalyst;