import Scaling from '../../Scaling.js';
import BaseWeapon from '../base.js';

const SkyriderGreatsword = (props) => {
  
  return BaseWeapon({
    ...props,
    type: 'claymore',
    name: 'Skyrider Greatsword',
    lvl1Atk: 39,
    lvl1Sub: 0.096,
    subType: 'damageBonus.physical',
    PersistentResolve(result, context) {
      /**
       * effect: 'On hit, Normal or Charged Attacks increase ATK by [6+1%] 
       * for 6s. Max 4 stacks. Can only occur once every 0.5s.'
       */
      if(this.IsSelf(result)) {
      }
      return result;
    }
  });
};

export default SkyriderGreatsword;