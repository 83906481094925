import {BaseSet} from './base.js';
import Tools from '../Tools.js';

const Instructor = (props) => {
  
  return BaseSet({
    ...props,
    name: 'Instructor',
    two: {
      attributes: {
        elemMastery: 80
      },
      PersistentResolve(result, context) {
        return result;
      }
    },
    four: {
      PersistentResolve(result, context) {
        if(!this.IsSelf(result)) return result;
        return result;
      },
      PersistentFinalize(result) {
        if(!this.IsSelf(result)) return result;
        if(result.type==='Reaction') {
          result.effects.push(Tools.CreateSideEffect(result, {
            description: 'Instructor 4pc',
            entity: 'Buffs',
            duration: 8,
            relativeTime: (r => -r.precedingResult.duration),//result.duration,
            type: 'Buff',
            PersistentResolve(result) {
              result.attributes.elemMastery += 120;
              result.logs.push("Instructor 4pc increases party Elemental Mastery by 120");
              return result;
            }
          }));
        }
        return result;
      }
    }
  });
};

export default Instructor;