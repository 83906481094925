import {BaseSet} from './base.js';

const DefendersWill = (props) => {
  
  return BaseSet({
    ...props,
    name: 'Defender\'s Will',
    two: {
      attributes: {
        defensePct: 0.30
      },
      PersistentResolve(result, context) {
        return result;
      }
    },
    four: {
      PersistentResolve(result, context) {
        return result;
      }
    }
  });
};

export default DefendersWill;