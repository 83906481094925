import * as Genshin from './';
import Tools from './Tools.js';

const Simulator = (props) => {
  let timetrack = Genshin.TimeTrack({});
  //timetrack.AddTalentEffect(Tools.CreateStartEffect());
  let simulator = {
    team: props.team || [],
    monsters: props.monsters || [],
    history: props.history || [],
    timetrack: timetrack,
    timeSpentCheckingTime: 0,
    lastSwap: -999,
    ProcessHistory(history) {
      let precedingResult;
      if(this.team[0] && this.monsters[0]) {
        precedingResult = this.timetrack.AddTalentEffect(Tools.CreateStartEffect(this.team[0], this.monsters[0]));
        precedingResult.setTime(-0.2);
      }
      history.forEach(e => {
        if(precedingResult && precedingResult.initiator!==e.initiator) {
          let swap = Tools.CreateSideEffect({...e, target:precedingResult.target}, {
            description: "Character Swap",
            type: "Swap",
            duration: 0.1,
            cooldown: 1,
            Resolve: (result) => {
              if(this.lastSwap + 1 > result.time) {
                result = Tools.CreateCooldownEffect(result, {cooldown:1, lastUsed:this.lastSwap});
                result.talentEffects[0].logs = ['Swapping character has a 1s cooldown'];
              }
              this.lastSwap = result.time;
              return result;
            }
          });
          swap.precedingResult = precedingResult;
          precedingResult = this.timetrack.AddTalentEffect(swap);
        }
        let result = this.timetrack.AddTalentEffect(Tools.HistoryEntryToResult(e, precedingResult, this.monsters[0]));
        if(result.enforceCooldown && result.cooldown) {
          if(result.talent.track.lastUsed+result.cooldown>result.time) {
            result = Tools.CreateCooldownEffect(result);
            this.timetrack.AddTalentEffect(result.talentEffects[0]);
            result.talentEffects = [];
          }
          result.talent.track.lastUsed = result.time;
        }
        precedingResult = result;
      });
    },
    ResetCooldowns() {
      this.team.forEach(c => c.ResetCooldowns());
    },
    Run() {
      console.time('Simulator.Run');
      this.timetrack.ClearTimeTrack();
      this.ResetCooldowns();
      
      this.ProcessHistory(this.history);
      let act;
      while((act = this.timetrack.Next()) !== undefined) {
        let result = act.obj;
        let effectTime = result.effectExtension ? result.effectExtension.time : result.time;
        result.ongoing = {
          status: this.timetrack.GetStatusEffects(effectTime),
          effects: this.timetrack.GetEffectsAt(effectTime)
        };
        if(result[act.type]) {
          result = result[act.type](result);
          this.ProcessNewEffects(result);
        }
        switch(act.type) {
          case "Prepare": {
            //if(result.type==="Status") {
            //}
            this.team.forEach(c => {
              result = c.PersistentPrepare(result, c);
              this.ProcessNewEffects(result);
            });
            this.timetrack.GetStatusEffects(effectTime)
            .forEach((c, j) => {
              if(c!==result) {
                result = c.PersistentPrepare(result, c);
                this.ProcessNewEffects(result);
              }
            });
            this.timetrack.GetEffectsAt(effectTime)
            .forEach((c, j) => {
              if(c!==result) {
                result = c.PersistentPrepare(result, c);
                this.ProcessNewEffects(result);
              }
            });
          } break;
          case "Resolve": {
            if(result.attributes.skillPct>0) {
              result.logs.push(result.initiator.name+'->'+result.description+' is '+(Math.round(result.attributes.skillPct*1000)/10)+'% of ATK and is type '+result.attributes.element);
            }
            if(result.attributes.skillPct>0 || result.forceStatus) {
              result.ApplyStatus(result);
            }
            this.team.forEach(c => {
              result = c.PersistentResolve(result, c);
              this.ProcessNewEffects(result);
            });
            this.timetrack.GetStatusEffects(effectTime)
            .forEach((c, j) => {
              if(c!==result) {
                result = c.PersistentResolve(result, c);
                this.ProcessNewEffects(result);
              }
            });
            this.timetrack.GetEffectsAt(effectTime)
            .forEach((c, j) => {
              if(c!==result) {
                result = c.PersistentResolve(result, c);
                this.ProcessNewEffects(result);
              }
            });
            if(result.attributes && (result.attributes.skillPct || result.type==="Reaction")) {
              result = this.timetrack.HandleAtkSpeed(result);
              result.ValueCalc();
            }
          } break;
          case "Finalize": {
            this.team.forEach(c => {
              result = c.PersistentFinalize(result, c);
              this.ProcessNewEffects(result);
            });
            this.timetrack.GetEffectsAt(effectTime)
            .forEach((c, j) => {
              if(c!==result) {
                result = c.PersistentFinalize(result, c);
                this.ProcessNewEffects(result);
              }
            });
          } break;
        }
      }
      console.timeEnd('Simulator.Run');
      console.log(`${this.history.length} items in history resulted in ${this.timetrack.talentEffects.length+this.timetrack.effects.length+this.timetrack.statuses.length} items in the timetrack`);
      console.log(`Spent ${this.timetrack.timeSpentFixTimesOnChange}ms fixing time on change`);
    },
    ProcessNewEffects(result) {
      let r;
      while((r = result.statusEffects.shift()) !== undefined) {
        this.timetrack.AddStatusEffect(r);
      }
      let e;
      while((e = result.effects.shift()) !== undefined) {
        this.timetrack.AddEffect(e);
      }
      let t;
      while((t = result.talentEffects.shift()) !== undefined) {
        this.timetrack.AddTalentEffect(t);
      }
    }
  };
  return simulator;
};

export default Simulator;