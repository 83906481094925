import React, {Fragment, useState, useEffect} from 'react';
import { Label, Icon, Header, Image, Segment, Popup, Modal, Dropdown, Rating, Input, Button, Form } from 'semantic-ui-react';
import './character-edit.css';
import Characters from './db/characters.js';

const characterItemRenderer = (name, rarity, image, devStage) => {
  return <Header>
    <Image src={image} aria-label={name} />
    <Header.Content>
      {
        devStage==="Testing"
        ? <Popup trigger={<Icon name="warning sign" style={{color:"orange"}} />}>Talents in testing</Popup>
        : devStage==="NotImpl"
          ? <Popup trigger={<Icon name="times circle" style={{color:"red"}} />}>Character Stats ONLY. Talents not implemented</Popup>
          : ''
      }
      {name}
      <Header.Subheader className='rarity'>{[...Array(rarity)].map(i => <Icon name='star' />)}</Header.Subheader>
    </Header.Content>
  </Header>;
};

const CharacterSelect = (props) => {
  const characters = Characters.map(c => {
    let key = c.name.replaceAll(/[^a-z0-9]+/gi, '').toLowerCase();
    return {
      key: key,
      value: c.name,
      text: characterItemRenderer(c.name, c.rarity, '/static/characters/'+key+'.png',
        c.name !== 'None'
        ? [...c.attack.impl, ...c.skill.impl, ...c.burst.impl].reduce(
            (acc, cur) => {
              switch(cur.devStage) {
                case 'Testing':
                  acc.hasTesting = true;
                  break;
                case 'NotImpl':
                  acc.hasNotImpl = true;
                  break;
                default:
                  acc.hasReady = true;
                  break;
              }
              acc.devStage = (
                acc.hasTesting
                ? 'Testing'
                : acc.hasNotImpl && acc.hasReady
                  ? 'Testing'
                  : acc.hasNotImpl
                    ? 'NotImpl'
                    : undefined
              )
              return acc;
            }
            , {
              hasTesting:false, 
              hasNotImpl:false, 
              hasReady: false, 
              devStage:'NotImpl'
            }
          ).devStage
        : undefined
      ),
      equip: c.equip
    }
  });
  const [defaultCharacter] = useState(characters.find(c => c.value===props.default) ? props.default : 'none');
  const [character, setCharacter] = useState(props.value);
  
  useEffect(() => {
    setCharacter(characters.find(c => c.value===props.value)?props.value:defaultCharacter);
  }, [props])

  return <Fragment>
      <Dropdown
        placeholder='Select Character'
        label='Character'
        className='character'
        selection
        options={characters} value={character} onChange={(e,d) => {
          setCharacter(d.value);
          props.onChange(e,d);
        }}/>
        {
          character !== defaultCharacter
          ? <Button className='undo' attached='right' icon='undo' aria-label='Undo' onClick={e => {
              setCharacter(defaultCharacter);
              props.onChange(e,{value:defaultCharacter,options:characters});
            }} />
          :''
        }
    </Fragment>;
};

export default CharacterSelect;