import React, {Fragment} from 'react';
import { List, Header } from 'semantic-ui-react';

const PrivacyPolicyPage = () => {
  return <div style={{textAlign:"left"}}>
  <Header as='h1'>Our Privacy Policy</Header>
  
  <Header as='h2'>Privacy Policy</Header>{
    <p>Our Privacy Policy was posted on 16 December 2020 and last updated on 
      16 December 2020. It governs the privacy terms of our website, located at 
      GameHoned.com, and the tools we provide you (the "Website" or the 
      "Service"). Any capitalized terms not defined in our Privacy Policy, 
      have the meaning as specified in our Terms of Use accessible at 
      https://gamehoned.com/legal/terms-of-use</p>}

  <Header as='h2'>Your Privacy</Header>{
    <p>Our Website follows all legal requirements to protect your privacy. 
      Our Privacy Policy is a legal statement that explains how we may collect 
      information from you, how we may share your information, and how you can 
      limit our sharing of your information. You will see terms in our Privacy 
      Policy that are capitalized. These terms have meanings as described in the 
      Definitions section below.</p>}

  <Header as='h2'>Definitions</Header>{<Fragment>
    <p>Personal Data: Personal Data means data about a living individual who 
      can be identified from those data (or from those and other information 
      either in our possession or likely to come into our possession).</p>
    <p>Usage Data: Usage Data is data collected automatically either generated 
      by the use of the Service or from the Service infrastructure itself 
      (for example, the duration of a page visit).</p>
    <p>Cookies: Cookies are small pieces of data stored on a User's device.</p>
    <p>Data Controller: Data Controller means a natural or legal person who 
      (either alone or jointly or in common with other persons) determines the 
      purposes for which and the manner in which any personal data are, or are 
      to be, processed. For the purpose of this Privacy Policy, we are a Data 
      Controller of your data.</p>
    <p>Data Processors (or Service Providers): Data Processor (or Service 
      Provider) means any natural or legal person who processes the data on 
      behalf of the Data Controller. We may use the services of various Service 
      Providers in order to process your data more effectively.</p>
    <p>Data Subject: Data Subject is any living individual who is the subject 
      of Personal Data.</p>
    <p>User: The User is the individual using our Service. The User corresponds 
      to the Data Subject, who is the subject of Personal Data.</p></Fragment>}
      
  <Header as='h2'>Information Collection And Use</Header>{
    <p>We collect several different types of information for various purposes 
      to provide and improve our Service to you.</p>}

  <Header as='h3'>Types of Data Collected</Header>{
    <List bulleted>
      <List.Item>
        <p><b>Personal Data</b></p>
        <p>While using our Service, we may ask you to provide us with certain 
          personally identifiable information that can be used to contact or 
          identify you ("Personal Data"). Personally identifiable information may 
          include, but is not limited to: Email address, Name, Address, State, 
          Province, ZIP/Postal code, City, Cookies and Usage Data.</p>
        <p>We may use your Personal Data to contact you with newsletters, 
          marketing or promotional materials and other information that may be 
          of interest to you. You may opt out of receiving any, or all, of these 
          communications from us by following the unsubscribe link or 
          instructions provided in any email we send.</p>
      </List.Item>
      <List.Item>
        <p><b>Usage Data</b></p>
        <p>We may also collect information how the Service is accessed and used 
          ("Usage Data"). This Usage Data may include information such as your 
          computer's Internet Protocol address (e.g. IP address), browser type, 
          browser version, the pages of our Service that you visit, the time 
          and date of your visit, the time spent on those pages, unique device 
          identifiers and other diagnostic data.</p>
      </List.Item>
      <List.Item>
        <p><b>Tracking & Cookies Data</b></p>
        <p>We use cookies and similar tracking technologies to track the 
          activity on our Service and hold certain information.</p>
        <p>Cookies are files with small amount of data which may include an 
          anonymous unique identifier. Cookies are sent to your browser from a 
          website and stored on your device. Tracking technologies also used 
          are beacons, tags, and scripts to collect and track information and 
          to improve and analyze our Service.</p>
        <p>You can instruct your browser to refuse all cookies or to indicate 
          when a cookie is being sent. However, if you do not accept cookies, 
          you may not be able to use some portions of our Service.</p>
        <p>Examples of Cookies we use: session cookies (we use these cookies to 
          operate our Service) and preference cookies (we use these cookies to 
          remember your preferences and various settings).</p>
      </List.Item>
    </List>}
    
  <Header as='h2'>Use of Data</Header>{<Fragment>
    <p>We use the collected data for various purposes:</p>
    <List bulleted>
      <List.Item>To provide and maintain our Service</List.Item>
      <List.Item>To notify you about changes to our Service</List.Item>
      <List.Item>To allow you to participate in interactive features of our 
        Service when you choose to do so</List.Item>
      <List.Item>To provide customer support</List.Item>
      <List.Item>To gather analysis or valuable information so that we can 
        improve our Service</List.Item>
      <List.Item>To monitor the usage of our Service</List.Item>
      <List.Item>To detect, prevent and address technical issues</List.Item>
      <List.Item>To provide you with news, special offers and general 
        information about other goods, services and events which we offer 
        that are similar to those that you have already purchased or enquired 
        about unless you have opted not to receive such information</List.Item>
    </List></Fragment>}
    
  <Header as='h2'>Retention of Data</Header>{<Fragment>
    <p>We will retain your Personal Data only for as long as is necessary 
      for the purposes set out in this Privacy Policy. We will retain and use 
      your Personal Data to the extent necessary to comply with our legal 
      obligations (for example, if we are required to retain your data to 
      comply with applicable laws), resolve disputes, and enforce our legal 
      agreements and policies.</p>
    <p>We will also retain Usage Data for internal analysis purposes. Usage 
      Data is generally retained for a shorter period of time, except when 
      this data is used to strengthen the security or to improve the 
      functionality of our Service, or we are legally obligated to retain 
      this data for longer time periods.</p></Fragment>}
      
  <Header as='h2'>Transfer Of Data</Header>{<Fragment>
    <p>Your information, including Personal Data, may be transferred to — and 
      maintained on — computers located outside of your state, province, 
      country or other governmental jurisdiction where the data protection 
      laws may differ than those from your jurisdiction.</p>
    <p>We will take all steps reasonably necessary to ensure that your data 
      is treated securely and in accordance with this Privacy Policy and no 
      transfer of your Personal Data will take place to an organization or a 
      country unless there are adequate controls in place including the 
      security of your data and other personal information.</p>
    <p>Your consent to this Privacy Policy followed by your submission of 
      such information represents your agreement to that transfer.</p></Fragment>}
      
  <Header as='h2'>Disclosure Of Data</Header>{
    <List bulleted>
      <List.Item><b>Business Transaction.</b> If we are involved in a merger, 
        acquisition or asset sale, your Personal Data may be transferred. We 
        will provide notice before your Personal Data is transferred and 
        becomes subject to a different Privacy Policy.</List.Item>
      <List.Item><b>Disclosure for Law Enforcement.</b> Under certain 
        circumstances, we may be required to disclose your Personal Data if 
        required to do so by law or in response to valid requests by public 
        authorities (e.g. a court or a government agency).</List.Item>
    </List>}
    
  <Header as='h2'>Security Of Data</Header>{
    <p>The security of your data is important to us, but remember that no 
      method of transmission over the Internet, or method of electronic 
      storage is 100% secure. While we strive to use commercially acceptable 
      means to protect your Personal Data, we cannot guarantee its absolute 
      security.</p>}
      
  <Header as='h2'>Legal Basis for Processing Personal Data Under General 
    Data Protection Regulation (GDPR)</Header>{<Fragment>
    <p>If you are from the European Economic Area (EEA), GameHoned legal 
      basis for collecting and using the personal information described in 
      this Privacy Policy depends on the Personal Data we collect and the 
      specific context in which we collect it.</p>
    <p>GameHoned may process your Personal Data because:</p>
    <List bulleted>
      <List.Item>We need to perform a contract with you</List.Item>
      <List.Item>You have given us permission to do so</List.Item>
      <List.Item>The processing is in our legitimate interests and it's not 
        overridden by your rights</List.Item>
      <List.Item>For payment processing purposes</List.Item>
      <List.Item>To comply with the law</List.Item>
    </List></Fragment>}
    
  <Header as='h2'>Your Data Protection Rights Under General Data Protection 
    Regulation (GDPR)</Header>{<Fragment>
    <p>If you are a resident of the European Economic Area (EEA), 
      you have certain data protection rights. GameHoned aims to take 
      reasonable steps to allow you to correct, amend, delete, or limit 
      the use of your Personal Data.</p>
    <p>If you wish to be informed what Personal Data we hold about you and 
      if you want it to be removed from our systems, please contact us.</p>
    <p>In certain circumstances, you have the following data protection 
      rights:</p>
    <List bulleted>
      <List.Item>The right to access, update or to delete the information we 
        have on you.</List.Item>
      <List.Item>The right of rectification. You have the right to have your 
        information rectified if that information is inaccurate or 
        incomplete.</List.Item>
      <List.Item>The right to object. You have the right to object to our 
        processing of your Personal Data.</List.Item>
      <List.Item>The right of restriction. You have the right to request that 
        we restrict the processing of your personal information.</List.Item>
      <List.Item>The right to data portability. You have the right to be 
        provided with a copy of your Personal Data in a structured, 
        machine-readable and commonly used format.</List.Item>
      <List.Item>The right to withdraw consent. You also have the right to 
        withdraw your consent at any time where GameHoned relied on your 
        consent to process your personal information.</List.Item>
    </List>
    <p>Please note that we may ask you to verify your identity before 
      responding to such requests.</p>
    <p>You have the right to complain to a Data Protection Authority about 
      our collection and use of your Personal Data. For more information, 
      please contact your local data protection authority in the 
      European Economic Area (EEA).</p></Fragment>}
      
  <Header as='h2'>"Do Not Sell My Personal Information" Notice for California 
    consumers under California Consumer Privacy Act (CCPA)</Header>{<Fragment>
    <p>Under the CCPA, California consumers have the right to:</p>
    <List bulleted>
      <List.Item>Request that a business that collects a consumer's personal 
        data disclose the categories and specific pieces of personal data 
        that a business has collected about consumers.</List.Item>
      <List.Item>Request that a business delete any personal data about the 
        consumer that a business has collected.</List.Item>
      <List.Item>Request that a business that sells a consumer's personal 
        data, not sell the consumer's personal data.</List.Item>
    </List>
    <p>If you make a request, we have 30 days to respond to you. 
      If you would like to exercise any of these rights, please 
      contact us.</p></Fragment>}
      
  <Header as='h2'>Service Providers</Header>{<Fragment>
    <p>We may employ third party companies and individuals to facilitate our 
      Service ("Service Providers"), to provide the Service on our behalf, 
      to perform Service-related services or to assist us in analyzing how 
      our Service is used.</p>
    <p>These third parties have access to your Personal Data only to perform 
      these tasks on our behalf and are obligated not to disclose or use it 
      for any other purpose.</p></Fragment>}
      
  <Header as='h3'>Analytics</Header>{<Fragment>
    <p>We may use third-party Service Providers to monitor and analyze the 
      use of our Service.</p>
    <List bulleted>
      <List.Item>
        <p><b>Google Analytics</b></p>
        <p>Google Analytics is a web analytics service offered by Google that 
          tracks and reports website traffic. Google uses the data collected 
          to track and monitor the use of our Service. This data is shared 
          with other Google services. Google may use the collected data to 
          contextualize and personalize the ads of its own advertising 
          network.</p>
        <p>You can opt-out of having made your activity on the Service 
          available to Google Analytics by installing the Google Analytics 
          opt-out browser add-on. The add-on prevents the Google Analytics 
          JavaScript (ga.js, analytics.js, and dc.js) from sharing 
          information with Google Analytics about visits activity.</p>
        <p>For more information on the privacy practices of Google, please 
          visit the Google Privacy & Terms web page: 
          <a href="http://www.google.com/intl/en/policies/privacy/">
            http://www.google.com/intl/en/policies/privacy/
          </a></p>
      </List.Item>
    </List></Fragment>}
    {/*
  <Header as='h3'>Payments</Header>
    <p>We may provide paid products and/or services within the Service. 
      In that case, we use third-party services for payment processing 
      (e.g. payment processors).</p>
    <p>We will not store or collect your payment card details. That 
      information is provided directly to our third-party payment processors 
      whose use of your personal information is governed by their Privacy 
      Policy. These payment processors adhere to the standards set by 
      PCI-DSS as managed by the PCI Security Standards Council, which is a 
      joint effort of brands like Visa, Mastercard, American Express and 
      Discover. PCI-DSS requirements help ensure the secure handling of 
      payment information.</p>
    <p>The payment processors we work with are:</p>
    <List bulleted>
      <List.Item>PayPal or Braintree. Their Privacy Policy can be viewed at 
        <a href="https://www.paypal.com/webapps/mpp/ua/privacy-full">
          https://www.paypal.com/webapps/mpp/ua/privacy-full
        </a></List.Item>
      <List.Item>FastSpring. Their Privacy Policy can be viewed at 
        <a href="http://fastspring.com/privacy/">
          http://fastspring.com/privacy/
        </a></List.Item>
    </List>
    */}
  <Header as='h2'>Links To Other Sites</Header>{<Fragment>
    <p>Our Service may contain links to other sites that are not operated 
      by us. If you click on a third party link, you will be directed to 
      that third party's site. We strongly advise you to review the Privacy 
      Policy of every site you visit.</p>
    <p>We have no control over and assume no responsibility for the content, 
      privacy policies or practices of any third party sites or services.</p>
    </Fragment>}
      
  <Header as='h2'>Children's Privacy</Header>{<Fragment>
    <p>Our Service does not address anyone under the age of 18 ("Children").</p>
    <p>We do not knowingly collect personally identifiable information from 
      anyone under the age of 18. If you are a parent or guardian and you are 
      aware that your Children has provided us with Personal Data, please 
      contact us. If we become aware that we have collected Personal Data 
      from children without verification of parental consent, we take steps 
      to remove that information from our servers.</p></Fragment>}
      
  <Header as='h2'>Changes To This Privacy Policy</Header>{<Fragment>
    <p>We may update our Privacy Policy from time to time. We will notify 
      you of any changes by posting the new Privacy Policy on this page.</p>
    <p>We will let you know via email and/or a prominent notice on our 
      Service, prior to the change becoming effective and update the 
      "effective date" at the top of this Privacy Policy.</p>
    <p>You are advised to review this Privacy Policy periodically for any 
      changes. Changes to this Privacy Policy are effective when they are 
      posted on this page.</p></Fragment>}
      
  <Header as='h2'>Contact Us</Header>{
    <p>If you have any questions about this Privacy Policy, please contact us 
      by using the contact information we provided on our Contact page.</p>}
</div>;
};

export default PrivacyPolicyPage;