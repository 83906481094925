import Scaling from '../../Scaling.js';
import BaseWeapon from '../base.js';

const BlackTassel = (props) => {
  
  return BaseWeapon({
    ...props,
    type: 'polearm',
    name: 'Black Tassel',
    lvl1Atk: 38,
    lvl1Sub: 0.102,
    subType: 'hpPct',
    PersistentResolve(result, context) {
      /**
       * effect: 'Increases DMG against slimes by [40%].'
       */
      if(this.IsSelf(result)) {
      }
      return result;
    }
  });
};

export default BlackTassel;