import {BaseSet} from './base.js';

const ThunderingFury = (props) => {
  
  return BaseSet({
    ...props,
    name: 'Thundering Fury',
    two: {
      attributes: {
        damageBonus: {
          electro: 0.15
        }
      },
      PersistentResolve(result, context) {
        return result;
      }
    },
    four: {
      PersistentResolve(result, context) {
        if(!this.IsSelf(result)) return result;
        if(result.type==="Reaction") {
          result.attributes.reactionBonus.overloaded += 0.4;
          result.attributes.reactionBonus.electroCharged += 0.4;
          result.attributes.reactionBonus.superconduct += 0.4;
          result.logs.push(this.name+" 4pc increases overloaded, electro-charged and superconduct damage by 40%");
        }
        return result;
      }
    }
  });
};

export default ThunderingFury;