import Tools from '../../Tools.js';
import Scaling from '../../Scaling.js';
import BaseCharacter from '../base.js';

var Mona = (props) => {
  
  let scaleTable = {
    ascension0:{
      min: {level:1, maxHP:810, baseAtk:46, defense:51, energyRecharge:0},
      max: {level:20, maxHP:2102, baseAtk:58, defense:132, energyRecharge:0}
    },
    ascension1:{
      min: {level:20, maxHP:2797, baseAtk:77, defense:176, energyRecharge:0},
      max: {level:40, maxHP:4185, baseAtk:116, defense:263, energyRecharge:0}
    },
    ascension2:{
      min: {level:40, maxHP:4678, baseAtk:129, defense:294, energyRecharge:0.08},
      max: {level:50, maxHP:5383, baseAtk:149, defense:338, energyRecharge:0.08}
    },
    ascension3:{
      min: {level:50, maxHP:6041, baseAtk:167, defense:379,  energyRecharge:0.16},
      max: {level:60, maxHP:6752, baseAtk:186, defense:424, energyRecharge:0.16}
    },
    ascension4:{
      min: {level:60, maxHP:7246, baseAtk:199, defense:455, energyRecharge:0.16},
      max: {level:70, maxHP:7964, baseAtk:219, defense:500, energyRecharge:0.16}
    },
    ascension5:{
      min: {level:70, maxHP:8458, baseAtk:233, defense:531, energyRecharge:0.16},
      max: {level:80, maxHP:9184, baseAtk:253, defense:576, energyRecharge:0.24}
    },
    ascension6:{
      min: {level:80, maxHP:9677, baseAtk:266, defense:607, energyRecharge:0.32},
      max: {level:90, maxHP:10409, baseAtk:287, defense:653, energyRecharge:0.32}
    }
  };
  //TODO: check anim speed
  return BaseCharacter({
    ...props,
    name: 'Mona',
    element: 'hydro',
    equip: 'catalyst',
    scaleTable: scaleTable,
    scaleAttr: 'energyRecharge',
    attackTalent: {
      description: 'Ripple of Fate',
      level: props.attack,
      type: "NormalAtk",
      defaultImpl: {
        talentProperties: {
          comboTable: [
            {dmg:0.376, anim:0.5}, 
            {dmg:0.360, anim:0.6}, 
            {dmg:0.448, anim:1}, 
            {dmg:0.562, anim:0.75}
          ]
        },
        duration: 0.5,
        Prepare(result) {
          result.attributes.element = 'hydro';
          return result;
        },
        Resolve(result) {
          result = Scaling.ScaleAttackCombo(result);
          return result;
        }
      },
      chargedImpl: {
        type: "ChargeAtk",
        duration: 1.25,
        Prepare(result) {
          result.attributes.element = 'hydro';
          return result;
        },
        Resolve(result) {
          result = Scaling.ScaleTalent(1.5, result);
          return result;
        }
      }
    },
    skillTalent: {
      description:'Mirror Reflection of Doom',
      level: props.skill,
      type: 'Skill',
      defaultImpl: {
        duration: 0.6,
        cooldown: 12,
        Prepare(result) {
          result.attributes.element='hydro';
          for(let i=0; i<4; i++) {
            result.effects.push(Tools.CreateTalentSideEffect(result, 'mirrorDot', {
              relativeTime:i+1,
            }));
          }
          result.effects.push(Tools.CreateTalentSideEffect(result, 'mirrorExplosion'));
          return result;
        },
        Resolve(result) {
          return result;
        }
      },
      mirrorDotEffect:{
        description:'Mirror Reflection of Doom - DoT',
        entity:'Mona\'s Mirror',
        relativeTime:1,
        duration:1,
        Prepare(result) {
          result.attributes.element = 'hydro';
          return result;
        },
        Resolve: function(result) {
          result = Scaling.ScaleTalent(0.32, result);
          return result;
        }
      },
      mirrorExplosionEffect: {
        description:'Mirror Reflection of Doom - Explosion',
        entity:'Mona\'s Mirror',
        relativeTime:5,
        duration:0.1,
        Prepare(result) {
          result.attributes.element = 'hydro';
          return result;
        },
        Resolve: function(result) {
          result = Scaling.ScaleTalent(1.33, result);
          return result;
        }
      }
    },
    burstTalent: {
      description: 'Stellaris Phantasm',
      level: props.burst,
      type: 'Burst',
      defaultImpl: {
        duration: 2.3,
        cooldown: 15,
        Prepare(result) {
          result.attributes.element = 'hydro';
          result.forceStatus=true;
          //Bubble
          result.effects.push(Tools.CreateTalentSideEffect(result, 'illusoryBubble'));
          
          //Omen
          result.effects.push(Tools.CreateTalentSideEffect(result, 'omen'));
          return result;
        },
        Resolve(result) {
          return result;
        }
      },
      illusoryBubbleEffect: {
        description: 'Illusory Bubble',
        effectExtension: true,
        entity: 'Mona\'s Stellaris Phantasm',
        relativeTime: 0.0,
        duration: 8,
        Prepare(result) {
          result.attributes.element = 'hydro';
          return result;
        },
        PersistentResolve(result, context) {
          if(result.attributes.skillPct>0 && context.duration>(result.time - context.time)) {
            context.setDuration(result.time - context.time);
            context.logs.push("Illusory Bubble interrupted by "+result.initiator.name+"'s "+result.description);
          }
          return result;
        },
        Finalize(result) {
          //Bubble Explosion
          result.effects.push(Tools.CreateTalentSideEffect(result, 'illusoryBubbleExplosion'));
          return result;
        }
      },
      illusoryBubbleExplosionEffect: {
        description: 'Illusory Bubble - Explosion',
        effectExtension: true,
        entity: 'Mona\'s Stellaris Phantasm',
        duration: 0.1,
        Prepare(result) {
          result.attributes.element = 'hydro';
          return result;
        },
        Resolve(result) {
          result = Scaling.ScaleTalent(4.42, result);
          return result;
        }
      },
      omenEffect: {
        description: 'Omen',
        effectExtension: true,
        entity: 'Mona\'s Stellaris Phantasm',
        duration: (
          (props.burst)<4 
          ? 4 
          : (props.burst)<7 
            ? 4.5 
            : 5 
        ),
        Prepare(result) {
          result.attributes.element = 'hydro';
          return result;
        },
        PersistentResolve(result, context) {
          let dmgBonus = 0.42 + (0.02 * (context.initiator.burst.level - 1));
          result.attributes.damageBonus.all += dmgBonus;
          result.logs.push("Mona's Omen increases dmg by "+(Math.round(dmgBonus*10000)/100)+"%");
          return result;
        }
      }
    },
    passiveAsc1: {
      description: 'Come \'n\' Get Me, Hag!',
      PersistentResolve(result, context) {
        return result;
      }
    },
    passiveAsc4: {
      description: 'Waterborne Destiny',
      attributes: {
        postProcesses: [attributes => {
          attributes.damageBonus.hydro += ((1 + attributes.energyRecharge) * 0.2);
          return attributes;
        }]
      },
      PersistentResolve(result, context) {
        //result.attributes.damageBonus.hydro += result.attributes.energyRecharge * 0.2;
        //result.logs.push('Waterborne Destiny increases hydro dmg by 20% of energy recharge -> '+(result.attributes.energyRecharge * 0.2 * 100))
        return result;
      }
    },
    passiveOther: [{
      description: 'Mein Hausgarten',
      PersistentResolve(result, context) {
        return result;
      }
    }],
    constellations: [
      ...BaseCharacter.Constellation35,
      {
        description: 'Prophecy of Submersion',
        level:1,
        PersistentResolve(result, context) {
          if(result.ongoing.effects.filter(e => e.description==="Omen").length>0
             && result.attributes.skillPct>0) {
            result.effects.push(Tools.CreateSideEffect(result, {
              description: 'Prophecy of Submersion',
              entity: 'Mona\'s Constellations',
              duration: 8,
              relativeTime: (r => -r.precedingResult.duration),
              type: 'Constellation',
              PersistentResolve(result) {
                if(result.type==="Reaction" && result.description==="Electro-Charged") {
                  result.attributes.reactionBonus.electroCharged+=0.15;
                  result.logs.push("Mona's constellation 1 improves hydro reactions. +15% to Electro-Charged");
                }
                if(result.reactionMultiplier.vaporize>0) {
                  result.attributes.reactionBonus.vaporize+=0.15;
                  result.logs.push("Mona's constellation 1 improves hydro reactions. +15% to Vaporize");
                }
                if(result.type==="Reaction" && result.description==="Swirl" && result.attributes.element==="hydro") {
                  result.attributes.reactionBonus.swirl+=0.15;
                  result.logs.push("Mona's constellation 1 improves hydro reactions. +15% to Swirl");
                }
                if(result.type==="Reaction" && result.description==="Frozen") {
                  result.setDuration(result.duration*1.15);
                  result.logs.push("Mona's constellation 1 improves hydro reactions. Increases Frozen status duration by 15%");
                }
                return result;
              }
            }));
          }
          return result;
        }
      },
      {
        description:'Lunar Chain',
        level:2,
        PersistentResolve(result, context) {
          if(context.IsSelf(result)) {
            if(result.type.startsWith("NormalAtk") && (context.track.lastUsed===null || result.time-context.track.lastUsed>5)) {
              result.effects.push(Tools.HistoryEntryToResult(result.initiator.UseChargedAttack(), result, result.target));
              context.track.lastUsed = result.time;
            }
          }
          return result;
        }
      }
    ]
  });
};

export default Mona;