import React, {Fragment, useState, useEffect} from 'react';
import { Grid } from 'semantic-ui-react';
import CharacterCard from './character-card.js';
import MonsterCard from './monster-card.js';
import APIClient from './api-client.js';
import Persistence from './persistence.js';

const TeamGrid = (props) => {
  const [team, setTeam] = useState(props.team);
  const [monsters, setMonsters] = useState(props.monsters);
  const noneChar = { name:'None', element:'None', equip:'None', artifacts:{sets:[],attributes:{}, stats:{flower:{},feather:{},time:{},goblet:{},head:{}}}, weapon:{name:'None'}, attack:{level:1}, skill:{level:1}, burst:{level:1}};
  const [builds, setBuilds] = useState(Persistence.getTeamBuilds(props.team));
  
  useEffect(() => {
    setTeam(props.team);
    setBuilds(Persistence.getTeamBuilds(props.team))
  }, [props.team]);
  
  useEffect(() => {
    setMonsters(props.monsters);
  }, [props.monsters]);
  
  return <Fragment>
    <Grid className="teamGrid" columns={Math.max(6, monsters.length+5)}>
      <Grid.Row>
        {[...Array(4)].map((_, i) => {
          let character = team[i] || noneChar;
          return <Grid.Column key={"Team-"+i+"-"+character.name} width={3}>
            <CharacterCard 
              character={character} 
              builds={builds[character.name.replaceAll(/[^0-9a-z]/gi, '')]}
              onTalent={props.onTalent} 
              onChange={((c, b) => {
                b = b || 'default';
                
                setTeam(t => {
                  let newTeam = [...t];
                  newTeam.forEach((k,j,a) => {
                    if(c.name!=="None" && c.name===k.name && j!==i) {
                      //clear dupliate character in different team location
                      a[j]=noneChar;
                    }
                  });
                  
                  Persistence.saveBuild(b, c);
                  
                  newTeam[i] = APIClient.SelectCharacter(c);
                  if(newTeam[i]) {
                    newTeam[i].build = b;
                  }
                  newTeam = newTeam.filter(c => c!==null);
                  Persistence.saveTeam(newTeam);
                  setBuilds(Persistence.getTeamBuilds(newTeam))
                  
                  props.onChange(newTeam);
                  return newTeam;
                });
                
              })} 
              onBuildChange={b => {
                setTeam(t => {
                  let newTeam = [...t];
                  let charBuilds = Persistence.getBuilds(character.name);
                  if(charBuilds) {
                    let build = charBuilds[b || 'default'];
                  
                    newTeam[i] = APIClient.SelectCharacter(build);
                    if(newTeam[i]) {
                      newTeam[i].build = b || 'default';
                    }
                    newTeam = newTeam.filter(c => c!==null);
                    Persistence.saveTeam(newTeam);
                    
                    props.onChange(newTeam);
                  }
                  return newTeam;
                });
              }} />
          </Grid.Column>;
        })}
        
        <Grid.Column width={1} className="vs">/</Grid.Column>
        
        {[...Array(Math.max(1, monsters.length))].map((_, i) => {
          let monster = monsters[i] ? monsters[i] : { name:'None', level:'-' };
          return <Grid.Column key={"Monster-"+i+"-"+monster.name} width={3}>
            <MonsterCard monster={monster} />
          </Grid.Column>;
        })}
        
      </Grid.Row>
    </Grid>
  </Fragment>;
};

export default TeamGrid;