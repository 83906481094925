import Tools from '../../Tools.js';
import Scaling from '../../Scaling.js';
import BaseCharacter from '../base.js';

//TODO: implement character
var Xingqiu = (props) => {
  
  let scaleTable = {
    ascension0: {
      min: {level:1, maxHP:857, baseAtk:17, defense:64, attr:0},
      max: {level:20, maxHP:2202, baseAtk:43, defense:163, attr:0}
    },
    ascension1: {
      min: {level:20, maxHP:2842, baseAtk:56, defense:211, attr:0},
      max: {level:40, maxHP:4257, baseAtk:84, defense:316, attr:0}
    },
    ascension2: {
      min: {level:40, maxHP:4712, baseAtk:93, defense:349, attr:0.06},
      max: {level:50, maxHP:5420, baseAtk:107, defense:402, attr:0.06}
    },
    ascension3: {
      min: {level:50, maxHP:6027, baseAtk:119, defense:447, attr:0.12},
      max: {level:60, maxHP:6735, baseAtk:133, defense:499, attr:0.12}
    },
    ascension4: {
      min: {level:60, maxHP:7190, baseAtk:142, defense:533, attr:0.12},
      max: {level:70, maxHP:7897, baseAtk:156, defense:585, attr:0.12}
    },
    ascension5: {
      min: {level:70, maxHP:8352, baseAtk:165, defense:619, attr:0.18},
      max: {level:80, maxHP:9060, baseAtk:179, defense:671, attr:0.18}
    },
    ascension6: {
      min: {level:80, maxHP:9515, baseAtk:188, defense:705, attr:0.24},
      max: {level:90, maxHP:10223, baseAtk:202, defense:758, attr:0.24}
    }
  };
  
  let rainSword = {
    duration: 1.66,
    stacks: 3,
    maxStacks: 3,
    stackable: false,
    talentProperties: {
      rotation: 1,
      renewed: false
    },
    Prepare(result) {
      result.attributes.element = 'hydro';
      result.forceStatus = true;
      return result;
    },
    PersistentPrepare(result, context) {
      if(result.description==='Guhua Sword: Raincutter - Rain Swords'
         || result.description==='Guhua Sword: Fatal Rainscreen - Rain Swords') {
        if(result !== context) {
          context.logs.push('Rain Sword renewed by '+result.description);
          context.talentProperties.renewed = true;
          context.setDuration(result.time-context.time);
        }
      }
      return result;
    },
    Finalize(result) {
      if(result.talentProperties.rotation<9 && !result.talentProperties.renewed) {
        result.effects.push(Tools.CreateTalentSideEffect(result, 'rainSword', {
          talentProperties: {
            ...result.talentProperties,
            rotation: result.talentProperties.rotation + 1
          }
        }));
      }
      return result;
    }
  }
  
  return BaseCharacter({
    ...props,
    name: 'Xingqiu',
    element: 'hydro',
    equip: 'sword',
    scaleTable: scaleTable,
    scaleAttr: 'atkPct',
    attackTalent: {
      description: 'Guhua Style',
      level: props.attack,
      type: "NormalAtk",
      defaultImpl: {
        talentProperties: {
          comboTable: [
            {dmg:0.466, anim:0.33}, 
            {dmg:0.476, anim:0.5}, 
            {dmg:0.286, anim:0.25, extra: [{dmg:.286, anim:0.25}]}, 
            {dmg:0.560, anim:0.4}, 
            {dmg:.359, anim:0.33, extra: [{dmg:.359, anim:0.8}]}
          ]
        },
        duration: 1,
        Resolve(result) {
          result = Scaling.ScaleAttackCombo(result);
          return result;
        }
      },
      chargedImpl: {
        description: 'Guhua Style - Charged A-Hit',
        type: "ChargeAtk",
        duration: 1,
        Resolve(result) {
          let slash = Tools.CreateTalentSideEffect(result, 'default');
          slash.precedingResult = result.precedingResult;
          result.talentEffects.push(slash);
          
          result.talentEffects.push(Tools.CreateTalentSideEffect(result, 'chargedSecond'));
          result = Scaling.ScaleTalent(0.473, result);
          return result;
        }
      },
      chargedSecondEffect: {
        description: 'Guhua Style - Charged B-Hit',
        type: "ChargeAtk",
        duration: 0.3,
        Resolve(result) {
          result = Scaling.ScaleTalent(0.562, result);
          return result;
        }
      }
    },
    skillTalent: {
      description: 'Guhua Sword: Fatal Rainscreen',
      level: props.skill,
      type: 'Skill',
      cooldown: 21,
      defaultImpl: {
        description: 'Guhua Sword: Fatal Rainscreen - 1st Hit',
        duration: 0.3,
        Prepare(result) {
          result.attributes.element = 'hydro';
          return result;
        },
        Resolve(result) {
          result = Scaling.ScaleTalent(1.68, result);
          return result;
        },
        Finalize(result) {
          result.talentEffects.push(Tools.CreateTalentSideEffect(result, 'second'));
          return result;
        }
      },
      secondEffect: {
        description: 'Guhua Sword: Fatal Rainscreen - 2nd Hit',
        duration: 1,
        Prepare(result) {
          result.attributes.element = 'hydro';
          return result;
        },
        Resolve(result) {
          result = Scaling.ScaleTalent(1.91, result);
          return result;
        },
        Finalize(result) {
          result.effects.push(Tools.CreateTalentSideEffect(result, 'rainSword'));
          return result;
        }
      },
      rainSwordEffect: {
        ...rainSword,
        description: 'Guhua Sword: Fatal Rainscreen - Rain Swords',
        entity: 'Xingqiu\'s Guhua Sword: Fatal Rainscreen'
      }
    },
    burstTalent: {
      description: 'Guhua Sword: Raincutter',
      level: props.burst,
      type: 'Burst',
      cooldown: 20,
      defaultImpl: {
        duration: 1.3,
        Prepare(result) {
          result.attributes.element = 'hydro';
          return result;
        },
        Resolve(result) {
          return result;
        },
        Finalize(result) {
          result.effects.push(Tools.CreateTalentSideEffect(result, 'rainSword'));
          return result;
        }
      },
      rainSwordEffect: {
        ...rainSword,
        description: 'Guhua Sword: Raincutter - Rain Swords',
        entity: 'Xingqiu\'s Guhua Sword: Raincutter',
        talentProperties: {
          ...rainSword.talentProperties,
          lastTrigger: -999,
          lastProcCount: 0,
          maxProcCount: 3
        },
        PersistentResolve(result, context) {
          if(result.type.startsWith('NormalAtk')) {
            if(result.time-context.talentProperties.lastTrigger>0.5) {
              let lastProcCount = context.talentProperties.lastProcCount;
              let maxProcCount = context.talentProperties.maxProcCount;
              if(lastProcCount===0 || lastProcCount>=maxProcCount) {
                lastProcCount=2;
              } else if(lastProcCount===2) {
                lastProcCount=3;
              } else if(maxProcCount===5 && lastProcCount===3) {
                lastProcCount=5;
              }
              context.talentProperties.lastProcCount=lastProcCount;
              context.talentProperties.lastTrigger=result.time;
              [...Array(context.talentProperties.lastProcCount)].forEach(() => {
                result.effects.push(Tools.CreateTalentSideEffect(context, 'strike', {
                  precedingResult: result
                }));
              });
            }
          }
          return result;
        }
      },
      strikeEffect: {
        description: 'Guhua Sword: Raincutter - Rain Swords Strike',
        entity: 'Xingqiu\'s Guhua Sword: Raincutter',
        duration: 0.1,
        Prepare(result) {
          result.attributes.element = 'hydro';
          return result;
        },
        Resolve(result) {
          result = Scaling.ScaleTalent(0.543, result);
          return result;
        }
      }
    },
    passiveAsc1: {
      description: 'Hydropathic',
      PersistentResolve(result, context) {
        return result;
      }
    },
    passiveAsc4: {
      description: 'Blades Amidst Raindrops',
      PersistentResolve(result, context) {
        if(result.initiator===context) {
          result.logs.push('Blades Amidst Raindrops increases Xingqiu\'s Hydro DMG by 20%');
          result.attributes.damageBonus.hydro += 0.20;
        }
        return result;
      }
    },
    passiveOther: [{
      description: 'Flash of Genius',
      PersistentResolve(result, context) {
        return result;
      }
    }],
    constellations: [
      ...BaseCharacter.Constellation53,
    ]
  });
};

export default Xingqiu;