import Scaling from '../../Scaling.js';
import BaseWeapon from '../base.js';

const WineAndSong = (props) => {
  
  return BaseWeapon({
    ...props,
    type: 'catalyst',
    name: 'Wine and Song',
    lvl1Atk: 42,
    lvl1Sub: 0.10,
    subType: 'energyRecharge',
    PersistentResolve(result, context) {
      /**
       * effect: 'For every character in the party who hails from Mondstadt, 
       * the character who equips this weapon gains [8%] ATK increase and 
       * [3+0.5%] Movement SPD increase.'
       */
      if(this.IsSelf(result)) {
      }
      return result;
    }
  });
};

export default WineAndSong;