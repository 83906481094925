import Tools from './Tools.js';

const Scaling = {
      
  attackTalentTable: {
    '1':	1.000,
    '2':	1.081,
    '3':	1.159,
    '4':	1.282,
    '5':	1.360,
    '6':	1.449,
    '7':	1.583,
    '8':	1.706,
    '9':	1.837,
    '10':	1.973,
    '11':	2.116,
    '12':	2.258,
    '13':	2.396,
    '14':	2.537,
    '15':	2.676
  },
  
  talentTable: {
    '1':	1.000,
    '2':	1.075,
    '3':	1.150,
    '4':	1.250,
    '5':	1.325,
    '6':	1.400,
    '7':	1.500,
    '8':	1.600,
    '9':	1.700,
    '10':	1.800,
    '11':	1.900,
    '12':	2.000,
    '13':	2.130,
    '14':	2.252,
    '15':	2.382
  },
  
  higherTalentTable: {
    '1':  1.000,
    '2':  1.107,
    '3':  1.217,
    '4':  1.352,
    '5':  1.474,
    '6':  1.596,
    '7':  1.758,
    '8':  1.920,
    '9':  2.082,
    '10': 2.244,
    '11': 2.406,
    '12': 2.569,
    '13': 2.703,
    '14': 2.838,
    '15': 2.973
  },
  
  weaponScaling: {
    mainStat: {
      type48: {
        asc0:{min:48,max:133},
        asc1:{min:164,max:261},
        asc2:{min:292,max:341},
        asc3:{min:373,max:423},
        asc4:{min:455,max:506},
        asc5:{min:537,max:590},
        asc6:{min:621,max:674}
      },
      type46: {
        asc0:{min:46,max:122},
        asc1:{min:153,max:235},
        asc2:{min:266,max:308},
        asc3:{min:340,max:382},
        asc4:{min:414,max:457},
        asc5:{min:488,max:532},
        asc6:{min:563,max:608}
      },
      type44: {
        asc0:{min:44,max:119},
        asc1:{min:144,max:226},
        asc2:{min:252,max:293},
        asc3:{min:319,max:361},
        asc4:{min:387,max:429},
        asc5:{min:455,max:497},
        asc6:{min:523,max:565}
      },
      type42: {
        asc0:{min:42,max:109},
        asc1:{min:135,max:205},
        asc2:{min:231,max:266},
        asc3:{min:292,max:327},
        asc4:{min:353,max:388},
        asc5:{min:414,max:449},
        asc6:{min:475,max:510}
      },
      type41: {
        asc0:{min:41,max:99},
        asc1:{min:125,max:184},
        asc2:{min:210,max:238},
        asc3:{min:264,max:293},
        asc4:{min:319,max:347},
        asc5:{min:373,max:401},
        asc6:{min:427,max:454}
      },
      type40: {
        asc0:{min:40,max:102},
        asc1:{min:121,max:187},
        asc2:{min:207,max:239},
        asc3:{min:259,max:292},
        asc4:{min:311,max:344},
        asc5:{min:363,max:396},
        asc6:{min:415,max:448}
      },
      type39: {
        asc0:{min:39,max:94},
        asc1:{min:113,max:169},
        asc2:{min:189,max:216},
        asc3:{min:236,max:263},
        asc4:{min:282,max:309},
        asc5:{min:329,max:355},
        asc6:{min:375,max:401}
      },
      type38: {
        asc0:{min:38,max:86},
        asc1:{min:105,max:151},
        asc2:{min:171,max:193},
        asc3:{min:212,max:234},
        asc4:{min:253,max:274},
        asc5:{min:294,max:314},
        asc6:{min:334,max:334}
      },
      type33: {
        asc0:{min:33,max:80},
        asc1:{min:91,max:139},
        asc2:{min:151,max:174},
        asc3:{min:186,max:209},
        asc4:{min:220,max:243},
        asc5:{min:243,max:243},
        asc6:{min:243,max:243}
      },
      type23: {
        asc0:{min:23,max:56},
        asc1:{min:68,max:102},
        asc2:{min:113,max:130},
        asc3:{min:141,max:158},
        asc4:{min:169,max:185},
        asc5:{min:185,max:185},
        asc6:{min:185,max:185}
      },
    },
    subStat: {
      incPerNonAsc5Lvls: 0.2
    },
    level: {
      asc0:{min:1,max:20},
      asc1:{min:20,max:40},
      asc2:{min:40,max:50},
      asc3:{min:50,max:60},
      asc4:{min:60,max:70},
      asc5:{min:70,max:80},
      asc6:{min:80,max:90}
    }
  },
  
  ScaleWeaponStats(baseAtk, baseSecondaryStat, secondaryType, level, ascension) {
    let baseAtkScale = baseAtk ? Scaling.weaponScaling.mainStat['type'+baseAtk]['asc'+ascension] : 0;
    let levelScale = baseAtk ? Scaling.weaponScaling.level['asc'+ascension] : {min:0, max:0};
    let levelPct = (level - levelScale.min) / (levelScale.max - levelScale.min);
    
    let secondarySteps = Math.floor(level/5);
    let [subStat1, subStat2] = secondaryType.split(".");
    let subValue = Math.round((baseSecondaryStat*(1+(Scaling.weaponScaling.subStat.incPerNonAsc5Lvls*secondarySteps)) + Number.EPSILON)*1000)/1000;
    if(subStat2) {
      subValue = {[subStat2]: subValue};
    }
    
    return {
      level: level,
      baseAtk: Math.round(((baseAtkScale.max - baseAtkScale.min) * levelPct) + baseAtkScale.min),
      [subStat1]: subValue
    };
  },
  
  ScaleWeaponPassive(baseValue, refinement) {
    let value = 0;
    if(typeof baseValue === "number") {
      value = baseValue*(1+0.25*(refinement-1));
    } else {
      let [start, step] = baseValue;
      value = start + (refinement-1) * step;
    }
    return value;
  },
  
  ScaleCharacterStats(scaleTable, level, ascension, secondaryStat) {
    let {min, max} = scaleTable['ascension'+ascension];
    let levelPct = (level - min.level) 
                  / (max.level - min.level);
    let [subStat1, subStat2] = secondaryStat.split(".");
    let subValue = Math.round((((max["attr"] - min["attr"]) * levelPct) + min["attr"])*1000)/1000;
    if(subStat2) {
      subValue = {[subStat2]: subValue};
    }
    let attributes = {
      level: level,
      maxHP: Math.round(((max.maxHP - min.maxHP) * levelPct) + min.maxHP),
      baseAtk: Math.round(((max.baseAtk - min.baseAtk) * levelPct) + min.baseAtk),
      defense: Math.round(((max.defense - min.defense) * levelPct) + min.defense),
      [subStat1]: subValue
    };
    //console.log('ScaleCharacterStats', attributes);
    return attributes;
  },
  
  ScaleAttackTalent(baseValue, result) {
    let level = result.talentProperties.level || result.talent.level;
    result.attributes.skillPct = this.ScaleValue(baseValue, this.attackTalentTable, level);
    return result;
  },
  
  ScaleAttackCombo(result) {
    let comboTable = result.talentProperties.comboTable;
    
    let nextComboStep = Tools.GetComboStep(result).next;
    result = this.ScaleAttackTalent(comboTable[nextComboStep-1].dmg, result);
    result.setDuration(result.talentProperties.comboTable[nextComboStep-1].anim);
    result.comboStep = nextComboStep;
    result.type = "NormalAtk-"+(nextComboStep);
    
    if(comboTable[result.comboStep-1].extra) {
      let prec = result;
      comboTable[result.comboStep-1].extra.forEach(e => {
        prec = Tools.CreateSideEffect(prec, {
          description: result.description,
          type: "NormalAtk-"+result.comboStep,
          comboStep: result.comboStep,
          duration: e.anim,
          talentProperties: result.talentProperties,
          Resolve: (result) => {
            result = Scaling.ScaleAttackTalent(e.dmg, result);
            return result;
          }
        });
        result.talentEffects.push(prec);
      });
    }
    
    return result;
  },
  
  ScaleTalent(baseValue, result, table='') {
    let talentTable = this[table+'TalentTable'] || this.talentTable;
    let level = result.talentProperties.level || result.talent.level;
    result.attributes.skillPct = this.ScaleValue(baseValue, talentTable, level);
    return result;
  },
  
  ScaleValue(baseValue, scaleTable, level) {
    return baseValue * scaleTable[level.toString()];
  }

};

export default Scaling;