import Scaling from '../../Scaling.js';
import BaseWeapon from '../base.js';

const RoyalLongsword = (props) => {
  
  return BaseWeapon({
    ...props,
    type: 'sword',
    name: 'Royal Longsword',
    lvl1Atk: 42,
    lvl1Sub: 0.09,
    subType: 'atkPct',
    PersistentResolve(result, context) {
      /**
       * effect: 'Upon dealing damage to an opponent, increases CRIT Rate 
       * by [8%]. Max 5 stacks. A CRIT hit removes all existing stacks.'
       */
      if(this.IsSelf(result)) {
      }
      return result;
    }
  });
};

export default RoyalLongsword;