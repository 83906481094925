import Scaling from '../../Scaling.js';
import BaseWeapon from '../base.js';

const Deathmatch = (props) => {
  
  return BaseWeapon({
    ...props,
    type: 'polearm',
    name: 'Deathmatch',
    lvl1Atk: 41,
    lvl1Sub: 0.08,
    subType: 'critRate',
    PersistentResolve(result, context) {
      /**
       * effect: 'If there are at least 2 enemies nearby, ATK is increased by 
       * [16%] and DEF is increased by [16%]. If there are less than 2 enemies 
       * nearby, ATK is increased by [24%].'
       */
      if(this.IsSelf(result)) {
      }
      return result;
    }
  });
};

export default Deathmatch;