import React, {useState, useEffect, Fragment} from 'react';
import {Auth} from 'aws-amplify';
import { Form, Modal, Button, Input } from 'semantic-ui-react';
import Register from './register.js';

const Login = (props) => {
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState('');
  const [pass, setPass] = useState('');
  const [remember, setRemember] = useState(false);
  
  async function login(user, pass) {
    let ret;
    try {
      ret = await Auth.signIn(user, pass);
      console.log("Logged in");
    } catch (error) {
      console.log(`Error logging in: ${error}`);
      ret = false;
    }
    return !!ret;
  }
  
  return <Fragment>
  <Modal 
    trigger={<Button>Login</Button>}
    onClose={() => {
      setOpen(false);
    }}
    onOpen={() => setOpen(true)} 
    open={open}
    size='mini'>
      <Modal.Header>Login</Modal.Header>
      <Modal.Content>
        <Form>
          <Input label="Email" value={user} onChange={(e,d) => setUser(d.value)} />
          <Input type="password" label="Password" value={pass} onChange={(e,d) => setPass(d.value)} />
          <Input type="checkbox" label="Remember me" value={remember} onChange={(e,d) => setRemember(!!d.value)} />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => setOpen(false)}>Cancel</Button>
        <Button onClick={() => setOpen(!login(user, pass))}>Login</Button>
      </Modal.Actions>
    </Modal>
    <Register />
  </Fragment>
}

export default Login;