import Scaling from '../../Scaling.js';
import BaseWeapon from '../base.js';
import Tools from '../../Tools.js';

const ThrillingTalesOfDragonSlayers = (props) => {
  
  return BaseWeapon({
    ...props,
    type: 'catalyst',
    name: 'Thrilling Tales of Dragon Slayers',
    lvl1Atk: 39,
    lvl1Sub: 0.077,
    subType: 'hpPct',
    track: {lastTrigger:-999},
    PersistentResolve(result, context) {
      /**
       * effect: 'When switching characters, the new character taking the field 
       * has their ATK increased by [24%] for 10s. This effect can only occur 
       * once every 20s.'
       */
      if(this.IsSelf(result.precedingResult) && result.type==='Swap') {
        if(result.time - this.track.lastTrigger > 20) {
          result.effects.push(Tools.CreateSideEffect(result, {
            description: 'Thrilling Tales of Dragon Slayers - Legacy',
            entity: 'Buffs',
            type: 'Buff',
            duration: 10,
            relativeTime: (r => -r.precedingResult.duration),
            talentProperties: {
              atkPct: Scaling.ScaleWeaponPassive(0.24, this.refinement)
            },
            PersistentResolve(result, context) {
              if(context.IsSelf(result) && result.attributes.skillPct>0) {
                result.logs.push('Thrilling Tales of Dragon Slayers - Legacy increases Attack by '+(Math.round(context.talentProperties.atkPct*10000)/100)+'%');
                result.attributes.atkPct += context.talentProperties.atkPct;
              }
              return result;
            }
          }));
          this.track.lastTrigger = result.time;
        }
      }
      return result;
    }
  });
};

export default ThrillingTalesOfDragonSlayers;