const ArtifactStatsPreset = {
  ArtifactBaseStatsPreset: {
    flower:{  hp:4780 },
    feather:{ atk:311 },
    time:{},
    goblet:{},
    head:{}
  },
  ArtifactTimeStatsPreset: {
    builds: [
      {name: "None", artifactsDefault: {time: { }}},
      {name: "ATK", artifactsDefault: {time: { atkpct:0.466 }}},
      {name: "ER%", artifactsDefault: {time: { er:0.518 }}},
      {name: "EM", artifactsDefault: {time: { em:187 }}},
      {name: "DEF", artifactsDefault: {time: { defpct:0.583 }}},
      {name: "HP", artifactsDefault: {time: { hppct:0.466 }}},
    ]
  },
  ArtifactGobletStatsPreset: {
    builds: [
      {name: "None", artifactsDefault: {goblet: { }}},
      {name: "PHYS%", artifactsDefault: {goblet: { pdmg:0.583, bonus:'physical' }}},
      {name: "PYRO%", artifactsDefault: {goblet: { edmg:0.466, bonus:'pyro' }}},
      {name: "HYDRO%", artifactsDefault: {goblet: { edmg:0.466, bonus:'hydro' }}},
      {name: "GEO%", artifactsDefault: {goblet: { edmg:0.466, bonus:'geo' }}},
      {name: "CRYO%", artifactsDefault: {goblet: { edmg:0.466, bonus:'cryo' }}},
      {name: "ELECTRO%", artifactsDefault: {goblet: { edmg:0.466, bonus:'electro' }}},
      {name: "ANEMO%", artifactsDefault: {goblet: { edmg:0.466, bonus:'anemo' }}},
      {name: "ATK%", artifactsDefault: {goblet: { atkpct:0.466 }}},
      {name: "EM", artifactsDefault: {goblet: { em:187 }}},
      {name: "HP%", artifactsDefault: {goblet: { hppct:0.466 }}},
      {name: "DEF%", artifactsDefault: {goblet: { defpct:0.583 }}},
    ]
  },
  ArtifactCircletStatsPreset: {
    builds: [
      {name: "None", artifactsDefault: {head: { }}},
      {name: "CR%", artifactsDefault: {head: { cr:0.311 }}},
      {name: "CD%", artifactsDefault: {head: { cd:0.622 }}},
      {name: "ATK%", artifactsDefault: {head: { atkpct:0.466 }}},
      {name: "EM", artifactsDefault: {head: { em:187 }}},
      {name: "HEAL%", artifactsDefault: {head: { }}},
      {name: "HP%", artifactsDefault: {head: { hppct:0.466 }}},
      {name: "DEF%", artifactsDefault: {head: { defpct:0.583 }}},
    ]
  },
  ArtifactSubStatsPreset: {
    builds: [
      {name:"CRIT > ATK > ER", 
        artifactsDefault: {
          flower:{  atkpct:0.0495,   cr:0.033*2.5, cd:0.066*2.5, er:0.055},
          feather:{ atkpct:0.0495,   cr:0.033*2.5, cd:0.066*2.5, er:0.055},
          time:{    atkpct:0.0495,   cr:0.033*2.5, cd:0.066*2.5, er:0.055},
          goblet:{  atkpct:0.0495,   cr:0.033*2.5, cd:0.066*2.5, er:0.055},
          head:{    atkpct:0.0495,   cr:0.033*2.5, cd:0.066*2.5, er:0.055}
        }},
      {name:"CRIT > ATK > EM", 
        artifactsDefault: {
          flower:{  atkpct:0.0495,   cr:0.033*2.5, cd:0.066*2.5, em:20},
          feather:{ atkpct:0.0495,   cr:0.033*2.5, cd:0.066*2.5, em:20},
          time:{    atkpct:0.0495,   cr:0.033*2.5, cd:0.066*2.5, em:20},
          goblet:{  atkpct:0.0495,   cr:0.033*2.5, cd:0.066*2.5, em:20},
          head:{    atkpct:0.0495,   cr:0.033*2.5, cd:0.066*2.5, em:20}
        }},
      {name:"CRIT > DEF > ER",
        artifactsDefault: {
          flower:{  defpct:0.062,   cr:0.033*2.5, cd:0.066*2.5, er:0.055},
          feather:{ defpct:0.062,   cr:0.033*2.5, cd:0.066*2.5, er:0.055},
          time:{    defpct:0.062,   cr:0.033*2.5, cd:0.066*2.5, er:0.055},
          goblet:{  defpct:0.062,   cr:0.033*2.5, cd:0.066*2.5, er:0.055},
          head:{    defpct:0.062,   cr:0.033*2.5, cd:0.066*2.5, er:0.055}
        }},
      {name:"CRIT > HP > ER",
        artifactsDefault: {
          flower:{  hppct:0.0495,   cr:0.033*2.5, cd:0.066*2.5, er:0.055},
          feather:{ hppct:0.0495,   cr:0.033*2.5, cd:0.066*2.5, er:0.055},
          time:{    hppct:0.0495,   cr:0.033*2.5, cd:0.066*2.5, er:0.055},
          goblet:{  hppct:0.0495,   cr:0.033*2.5, cd:0.066*2.5, er:0.055},
          head:{    hppct:0.0495,   cr:0.033*2.5, cd:0.066*2.5, er:0.055}
        }},
      {name:"ER > ATK",
        artifactsDefault: {
          flower:{  atkpct:0.0495,   cr:0.033, cd:0.066, er:0.055*4},
          feather:{ atkpct:0.0495,   cr:0.033, cd:0.066, er:0.055*4},
          time:{    atkpct:0.0495,   cr:0.033, cd:0.066, er:0.055*4},
          goblet:{  atkpct:0.0495,   cr:0.033, cd:0.066, er:0.055*4},
          head:{    atkpct:0.0495,   cr:0.033, cd:0.066, er:0.055*4}
        }},
      {name:"None",
        artifactsDefault: {
          flower:{},
          feather:{},
          time:{},
          goblet:{},
          head:{}
        }},
      
    ]
  },
  MergePreset(p1, p2) {
    let merged = p1;
    ["flower", "feather", "time", "goblet", "head"]
    .forEach(p => {
      if(p2[p]) {
        if(!merged[p]) {
          merged[p] = p2[p];
        } else {
          Object.keys(p2[p]).forEach(e => {
            if(!merged[p][e]) {
              merged[p][e] = 0;
            }
            if(e==="bonus") {
              merged[p][e] = p2[p][e];
            } else {
              merged[p][e] += p2[p][e];
            }
          });
        }
      }
    });
    return merged;
  },
  GetPreset(time, goblet, head, sub) {
    const baseBuild = {
      flower:{...this.ArtifactBaseStatsPreset.flower},
      feather:{...this.ArtifactBaseStatsPreset.feather},
      time:{...this.ArtifactBaseStatsPreset.time},
      goblet:{...this.ArtifactBaseStatsPreset.goblet},
      head:{...this.ArtifactBaseStatsPreset.head}
    };
    const timeBuild = (this.ArtifactTimeStatsPreset.builds.find(e => e.name===time) || this.ArtifactTimeStatsPreset.builds.find(e => e.name==="None")).artifactsDefault;
    const gobletBuild = (this.ArtifactGobletStatsPreset.builds.find(e => e.name===goblet) || this.ArtifactGobletStatsPreset.builds.find(e => e.name==="None")).artifactsDefault;
    const headBuild = (this.ArtifactCircletStatsPreset.builds.find(e => e.name===head) || this.ArtifactCircletStatsPreset.builds.find(e => e.name==="None")).artifactsDefault;
    const subBuild = (this.ArtifactSubStatsPreset.builds.find(e => e.name===sub) || this.ArtifactSubStatsPreset.builds.find(e => e.name==="None")).artifactsDefault;
    return this.MergePreset(this.MergePreset(this.MergePreset(this.MergePreset(
        baseBuild, 
        timeBuild), 
        gobletBuild),
        headBuild),
        subBuild);
  }
};

export default ArtifactStatsPreset;