import Scaling from '../../Scaling.js';
import BaseWeapon from '../base.js';

const SacrificialSword = (props) => {
  
  return BaseWeapon({
    ...props,
    type: 'sword',
    name: 'Sacrificial Sword',
    lvl1Atk: 41,
    lvl1Sub: 0.133,
    subType: 'energyRecharge',
    PersistentResolve(result, context) {
      /**
       * effect: 'After damaging an opponent with an Elemental Skill, the skill 
       * has a [40%] chance to end its own CD. 
       * Can only occur once every [30-4s].'
       */
      if(this.IsSelf(result)) {
      }
      return result;
    }
  });
};

export default SacrificialSword;