import Scaling from '../../Scaling.js';
import BaseWeapon from '../base.js';

const SacrificialBow = (props) => {
  
  return BaseWeapon({
    ...props,
    type: 'bow',
    name: 'Sacrificial Bow',
    lvl1Atk: 44,
    lvl1Sub: 0.067,
    subType: 'energyRecharge',
    PersistentResolve(result, context) {
      /**
       * effect: 'After dealing damage to an enemy with an Elemental Skill, 
       * the skill has a 40% chance to end its own CD. Can only occur once 
       * every 30s.'
       */
      if(this.IsSelf(result)) {
      }
      return result;
    }
  });
};

export default SacrificialBow;