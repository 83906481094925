import Tools from '../../Tools.js';
import Scaling from '../../Scaling.js';
import BaseCharacter from '../base.js';

var Keqing = (props) => {
  
  let scaleTable = {
    ascension0: {
      min: {level:1, maxHP:1020, baseAtk:25, defense:62, attr:0},
      max: {level:20, maxHP:2646, baseAtk:65, defense:161, attr:0}
    },
    ascension1: {
      min: {level:20, maxHP:3521, baseAtk:87, defense:215, attr:0},
      max: {level:40, maxHP:5268, baseAtk:130, defense:321, attr:0}
    },
    ascension2: {
      min: {level:40, maxHP:5889, baseAtk:145, defense:359, attr:0.096},
      max: {level:50, maxHP:6776, baseAtk:167, defense:413, attr:0.096}
    },
    ascension3: {
      min: {level:50, maxHP:7604, baseAtk:187, defense:464, attr:0.192},
      max: {level:60, maxHP:8500, baseAtk:209, defense:519, attr:0.192}
    },
    ascension4: {
      min: {level:60, maxHP:9121, baseAtk:225, defense:556, attr:0.192},
      max: {level:70, maxHP:10025, baseAtk:247, defense:612, attr:0.192}
    },
    ascension5: {
      min: {level:70, maxHP:10647, baseAtk:262, defense:649, attr:0.288},
      max: {level:80, maxHP:11561, baseAtk:285, defense:705, attr:0.288}
    },
    ascension6: {
      min: {level:80, maxHP:12182, baseAtk:300, defense:743, attr:0.384},
      max: {level:90, maxHP:13103, baseAtk:323, defense:799, attr:0.384}
    }
  };
  return BaseCharacter({
    ...props,
    name: 'Keqing',
    element: 'electro',
    equip: 'sword',
    scaleTable: scaleTable,
    scaleAttr: 'critDmg',
    attackTalent: {
      description: 'Yunlai Swordsmanship',
      level: props.attack,
      type: "NormalAtk",
      defaultImpl: {
        talentProperties: {
          comboTable: [
            {dmg:0.41, anim:0.3}, 
            {dmg:0.41, anim:0.43}, 
            {dmg:0.544, anim:0.5}, 
            {dmg:0.315, anim:0.23, extra:[{dmg:0.344, anim:0.6}]},
            {dmg:0.67, anim:0.8}
          ]
        },
        duration: 1,
        Resolve(result) {
          result = Scaling.ScaleAttackCombo(result);
          return result;
        }
      },
      chargedImpl: {
        description: 'Yunlai Swordmanship - Charged A-Hit',
        type: "ChargeAtk",
        duration: 0.3,
        Resolve(result) {
          let stiletto = result.ongoing.effects.find(e => e.description==='Stellar Restoration - Stiletto');
          if(stiletto) {
            result.logs.push('Stiletto on the field, ignite it');
            let tclap = Tools.CreateTalentSideEffect(result, 'thunderclap', {
              relativeTime: (r => -r.precedingResult.duration)
            });
            result.effects.push(tclap);
            result.effects.push(Tools.CreateTalentSideEffect(tclap, 'thunderclap'));
            stiletto.setDuration(result.time - stiletto.time + result.duration);
            stiletto.logs.push('Stiletto interrupted by reactivation of Stellar Restoration');
          }
          let slash = Tools.CreateTalentSideEffect(result, 'default');
          slash.precedingResult = result.precedingResult;
          result.talentEffects.push(slash);
          
          result.talentEffects.push(Tools.CreateTalentSideEffect(result, 'chargedSecond'));
          result = Scaling.ScaleTalent(0.768, result);
          return result;
        }
      },
      chargedSecondEffect: {
        description: 'Yunlai Swordmanship - Charged B-Hit',
        type: "ChargeAtk",
        duration: 0.3,
        Resolve(result) {
          result = Scaling.ScaleTalent(0.86, result);
          return result;
        }
      },
      thunderclapEffect: {
        description: 'Stellar Restoration - Stiletto Thunderclap',
        entity: 'Keqing\'s Stellar Restoration',
        duration: 0.3,
        Prepare(result) {
          result.attributes.element = 'electro';
          return result;
        },
        Resolve(result) {
          result = Scaling.ScaleTalent(0.84, result);
          return result;
        }
      }
    },
    skillTalent: {
      description:'Stellar Restoration',
      level: props.skill,
      type: 'Skill',
      defaultImpl: {
        duration: 0.5,
        Prepare(result) {
          result.attributes.element = 'electro';
          return result;
        },
        Resolve(result) {
          let stiletto = result.ongoing.effects.find(e => e.description==='Stellar Restoration - Stiletto');
          if(stiletto) {
            result.logs.push('Stiletto on the field, teleporting to it');
            result.description = 'Stellar Restoration - Slashing';
            result = Scaling.ScaleTalent(1.68, result);
            result.setDuration(1.5);
            stiletto.setDuration(result.time - stiletto.time + 0.1);
            stiletto.logs.push('Stiletto interrupted by reactivation of Stellar Restoration');
          } else {
            result.effects.push(Tools.CreateTalentSideEffect(result, 'stiletto'));
            result = Scaling.ScaleTalent(0.504, result);
          }
          return result;
        }
      },
      holdImpl: {
        description:'Stellar Restoration - Hold',
        duration: 1,
        Prepare(result) {
          result.attributes.element = 'electro';
          return result;
        },
        Resolve(result) {
          let stiletto = result.ongoing.effects.find(e => e.description==='Stellar Restoration - Stiletto');
          if(stiletto) {
            result.logs.push('Stiletto on the field, teleporting to it');
            result.description = 'Stellar Restoration - Slashing';
            result = Scaling.ScaleTalent(1.68, result);
            result.setDuration(1.5);
            stiletto.setDuration(result.time - stiletto.time + 0.1);
            stiletto.logs.push('Stiletto interrupted by reactivation of Stellar Restoration');
          } else {
            result.effects.push(Tools.CreateTalentSideEffect(result, 'stiletto'));
            result = Scaling.ScaleTalent(0.504, result);
          }
          return result;
        }
      },
      stilettoEffect: {
        description: 'Stellar Restoration - Stiletto',
        entity: 'Keqing\'s Stellar Restoration',
        duration: 8,
        relativeTime: -0.2
      },
      infusionEffect: {
        description: 'Stellar Restoration - Infusion',
        entity: 'Keqing\'s Stellar Restoration',
        duration: 5,
        relativeTime: (r => -r.precedingResult.duration),
        PersistentResolve(result, context) {
          if(context.initiator===result.initiator && ["ChargeAtk", "NormalAtk"].find(t => result.type.startsWith(t))) {
            result.logs.push('Stellar Restoration - Slashing infuses Keqing\'s weapon with electro');
            result.attributes.element = 'electro';
          }
          return result;
        }
      }
    },
    burstTalent: {
      description: 'Starward Sword',
      level: props.burst,
      type: 'Burst',
      defaultImpl: {
        cooldown:12,
        duration: 3,
        Prepare(result) {
          result.attributes.element = 'electro';
          result.effects.push(Tools.CreateTalentSideEffect(result, 'initial'));
          return result;
        },
        Resolve(result) {
          return result;
        }
      },
      initialEffect: {
        description: 'Starward Sword - Initial Slash',
        entity: 'Keqing\'s Starward Sword',
        duration: 0.3,
        relativeTime: -2,
        Prepare(result) {
          result.attributes.element = 'electro';
          return result;
        },
        Resolve(result) {
          result = Scaling.ScaleTalent(0.88, result);
          return result;
        },
        Finalize(result) {
          result.effects.push(Tools.CreateTalentSideEffect(result, "slash", {
            talentProperties: {
              slashAttackNumber: 1
            }
          }));
          return result;
        }
      },
      slashEffect: {
        description: 'Starward Sword - Slash (1 of 8)',
        entity: 'Keqing\'s Starward Sword',
        duration: 0.2,
        talentProperties: {
          slashAttackNumber:1
        },
        Prepare(result) {
          result.attributes.element = 'electro';
          return result;
        },
        Resolve(result) {
          result = Scaling.ScaleTalent(0.24, result);
          return result;
        },
        Finalize(result) {
          if(result.talentProperties.slashAttackNumber<8) {
            result.effects.push(Tools.CreateTalentSideEffect(result, "slash", {
              description: `Starward Sword - Slash (${result.talentProperties.slashAttackNumber+1} of 8)`,
              talentProperties: {
                slashAttackNumber: result.talentProperties.slashAttackNumber+1
              }
            }));
          } else {
            result.effects.push(Tools.CreateTalentSideEffect(result, "last"));
          }
          return result;
        }
      },
      lastEffect: {
        description: 'Starward Sword - Final Slash',
        entity: 'Keqing\'s Starward Sword',
        duration: 1,
        Prepare(result) {
          result.attributes.element = 'electro';
          return result;
        },
        Resolve(result) {
          result = Scaling.ScaleTalent(1.89, result);
          return result;
        }
        
      }
    },
    passiveAsc1: {
      description: 'Thundering Penance',
      PersistentResolve(result, context) {
        if(result.description === "Stellar Restoration - Slashing") {
          result.effects.push(Tools.CreateTalentSideEffect(result, 'infusion'));
        }
        return result;
      }
    },
    passiveAsc4: {
      description: '',
      PersistentResolve(result, context) {
        return result;
      }
    },
    passiveOther: [{
      description: '',
      PersistentResolve(result, context) {
        return result;
      }
    }],
    constellations: [
      ...BaseCharacter.Constellation53,
    ]
  });
};

export default Keqing;