import {BaseSet} from './base.js';

const TinyMiracle = (props) => {
  
  return BaseSet({
    ...props,
    name: 'Tiny Miracle',
    two: {
      attributes: {
        resistance: {
          all:0.2
        }
      },
      PersistentResolve(result, context) {
        return result;
      }
    },
    four: {
      PersistentResolve(result, context) {
        return result;
      }
    }
  });
};

export default TinyMiracle;