/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_appsync_graphqlEndpoint": "https://leszsr2m6jhsrhsbq6may2oxp4.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-east-2:04a8ae3d-e91e-4863-9850-8a01012ee285",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_I4mw9H4gI",
    "aws_user_pools_web_client_id": "47mc4rekjasue6hg3bvst7up75",
    "oauth": {}
};


export default awsmobile;
