import Scaling from '../../Scaling.js';
import BaseWeapon from '../base.js';

const DragonsBane = (props) => {
  
  return BaseWeapon({
    ...props,
    type: 'polearm',
    name: 'Dragon\'s Bane',
    lvl1Atk: 41,
    lvl1Sub: 48,
    subType: 'elemMastery',
    PersistentResolve(result, context) {
      /**
       * effect: 'Increases DMG against enemies affected by Hydro or Pyro 
       * by [20+4%].'
       */
      if(this.IsSelf(result)) {
      }
      return result;
    }
  });
};

export default DragonsBane;