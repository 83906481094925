import {BaseSet} from './base.js';

const RetracingBolide = (props) => {
  
  return BaseSet({
    ...props,
    name: 'Retracing Bolide',
    two: {
      attributes: {
        //TODO: implement shields strength
      },
      PersistentResolve(result, context) {
        return result;
      }
    },
    four: {
      PersistentResolve(result, context) {
        if(!this.IsSelf(result)) return result;
        if(result.ongoing.status.filter(e => e.type==="Reaction" && e.description==="Crystallize Shield").length>0) {
          if(["NormalAtk", "ChargeAtk", "ChargeAtkHold"].find(f => result.type.startsWith(f))) {
            result.attributes.damageBonus.all += 0.40;
            result.logs.push("While protected by a shield, Retracing Bolide increases Normal Attack and Charged Attack by 40%");
          }
        }
        return result;
      }
    }
  });
};

export default RetracingBolide;