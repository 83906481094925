import {BaseSet} from './base.js';

const Berserker = (props) => {
  
  return BaseSet({
    ...props,
    name: 'Berserker',
    two: {
      attributes: {
        critRate: 0.12
      },
      PersistentResolve(result, context) {
        return result;
      }
    },
    four: {
      PersistentResolve(result, context) {
        //TODO: implement hp tracking?
        return result;
      }
    }
  });
};

export default Berserker;