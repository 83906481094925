import React, {Fragment, useEffect, useState} from 'react';
import { Confirm, Menu, Segment, Sidebar, Grid, Popup, Advertisement, Header, Icon, Button, Modal, Form, Input, Label } from 'semantic-ui-react';
import API, {graphqlOperation} from '@aws-amplify/api/lib-esm/index.js';
//import { useStyletron } from "styletron-react";
// make sure you include the timeline stylesheet or the timeline will not be styled
import 'react-calendar-timeline/lib/Timeline.css';

import APIClient from '../../components/api-client.js';
import TeamGrid from '../../components/team-grid.js';
import TimeGrid from '../../components/time-grid.js';
import HistoryList from '../../components/history-list.js';
import History from '../../components/History.js';
import Characters from '../../components/db/characters.js';
import DpsChart from '../../components/dps-charts.js';
import Share from '../../components/share.js';
import * as queries from '../../graphql/queries.js';

import Persistence from '../../components/persistence.js';
import _ from 'lodash';

import './SimulatorPage.css';

const SimulatorPage = (props) => {
  const [debouncedRefreshTimeline, setDebouncedRefreshTimeline] = useState(() => _.debounce(RefreshTimeline, 300));
  const [team, setTeam] = useState([]);
  const [monsters, setMonsters] = useState([]);
  const [history, setHistory] = useState(History());
  const [showHistory, setShowHistory] = useState(false);
  const [showConfirmTrash, setShowConfirmTrash] = useState(false);
  
  const [groups, setGroups] = useState([]);
  const [items, setItems] = useState([]);
  
  const [stats, setStats] = useState({
      total: 0,
      time: 0,
      dps: 0
    });
  
  
  const [key, setKey] = useState(0);
  
  
    
  useEffect(() => {
    console.time('debouncing');
    if(props.shared) {
      //load shared config
      API.graphql({query: queries.getComposition, variables: {id:props.shared}, authMode:"AWS_IAM"})
      .then(cfg => {
        console.log(cfg);
        let defaultCfg = {
          team: cfg.data.getComposition.team.map(c => {
            let char = APIClient.SelectCharacter(c);
            let buildName = c.build.endsWith(' (Imported)') ? c.build : c.build+' (Imported)';
            Persistence.saveBuild(buildName, char);
            char.build = buildName;
            return char;
          }),
          monsters: cfg.data.getComposition.monsters,
          history: {
            entries: cfg.data.getComposition.history, 
            version: cfg.data.getComposition.history.length
          }
        };
        initCfg(defaultCfg);
      });
    } else {
      let defaultCfg = {
        team: (Persistence.getTeam()).map(c => {
          let character = Persistence.getBuilds(c.name);
          let char = APIClient.SelectCharacter(character[c.build || 'default']);
          char.build = c.build || 'default';
          return char;
        }),
        monsters: (Persistence.getMonsters()),
        history: (Persistence.getHistory())
      };
      initCfg(defaultCfg);
    }
  }, []);
  
  function initCfg(cfg) {
    
    setTeam(cfg.team);
    setMonsters(cfg.monsters);
    let h = History();
    cfg.history.entries.forEach(e => h.AddEntry(e));
    h.GoTo(cfg.history.version);
    setHistory(h);
    
  }
  
  function DebouncedRefreshTimeline() {
    if(debouncedRefreshTimeline) {
      console.timeLog('debouncing');
      debouncedRefreshTimeline(team, monsters, history);
    }
  }
  
  function RefreshTimeline(team, monsters, history) {
    console.timeEnd('debouncing');
    let timetrack = APIClient.RunSimulator({
      team:team,
      monsters:monsters,
      history:{
        entries: history.GetEntries()
      }
    });
    
    let grpid=0;
    let grp = [
      ...timetrack.statuses
        .filter((effect, i, arr) => {
          let exists = arr.slice(0,i).find((e, i2) => {
            return e.entity === effect.entity;
          });
          return !exists;
        })
        .map((effect) => {
          return {
            id:++grpid, 
            title:effect.entity, 
            data:effect.initiator,
            talent:false
          };
        }),
      ...timetrack.talents
        .filter((effect, i, arr) => {
          let exists = arr.slice(0,i).find((e, i2) => {
            return e.entity === effect.entity;
          });
          return !exists;
        })
        .map((effect) => {
          return {
            id:++grpid, 
            title:effect.entity, 
            data:effect.initiator,
            talent:true
          };
        }),
      ...timetrack.effects
        .filter((effect, i, arr) => {
          let exists = arr.slice(0,i).find((e, i2) => {
            return e.entity === effect.entity;
          });
          return !exists;
        })
        .map((effect) => {
          return {
            id:++grpid, 
            title:effect.entity, 
            data:effect.initiator,
            talent:false
          };
        })
    ];
    setGroups(grp);
    let itm = [
      ...timetrack.statuses,
      ...timetrack.effects,
      ...timetrack.talents
    ].map((e, i) => {
      return {
        id:i,
        group:grp.find(c => e.entity===c.title).id,
        title:e.description,
        start_time:e.time*1000,
        end_time:(e.time+e.duration)*1000,
        data:e,
        selectedBgColor: 'rgba(225, 166, 244, 1)',
        bgColor : 'rgba(225, 166, 244, 0.6)'
      };
    });
    setItems(itm);
    setStats(GetStats(timetrack));
    setKey(Math.random());
    console.log('RefreshTimeline end...');
  }
  
  function GetStats(timetrack) {
    let totalDamage=0;
    let lastTalentTime = [
      ...timetrack.talents,
      ...timetrack.effects
    ].reduce(
      (max, value) => ((value.time+value.duration)>max && value.values.averageDamage>0?(value.time+value.duration):max)
      , 0
    );
    [
      ...timetrack.effects
      , ...timetrack.talents
      , ...timetrack.statuses
    ].forEach(e => {if(e.time<=lastTalentTime) totalDamage += e.values.averageDamage});
    return {
      total: totalDamage,
      time: lastTalentTime,
      dps: Math.round(totalDamage / lastTalentTime * 100) / 100
    };
  }
  
  useEffect(() => {
    DebouncedRefreshTimeline();
  }, [team, monsters, history]);
  
  useEffect(() => {
    //console.log('Groups', groups);
  }, [groups]);
  
  useEffect(() => {
    //console.log('Items', items);
  }, [items]);
  
  function StoreHistory(h) {
    Persistence.saveHistory(h);
  };
  
  //console.log('pre-render', simulator, visibleTime);
  return (
    <Fragment>
      <Advertisement unit="top banner" test="Ad Unit" style={{margin:"0 auto 2em auto"}}/>
      
      <TeamGrid team={team} monsters={monsters} onTalent={
          (character, talent) => {
            let h = History();
            history.entries.forEach(e => h.AddEntry(e));
            h.GoTo(history.currentVersion);
            h.AddEntry({
              entity: character.name, 
              talent: talent.talent, 
              impl: talent.impl.name, 
              description: talent.description
            });
            StoreHistory(h);
            setHistory(h);
          }
        }
        onChange={
          (newTeam) => {
            setTeam(newTeam);
          }
        }
        />
      
      <Sidebar.Pushable as={Segment} basic style={{minHeight:"415px"}}>
        <Sidebar
          direction='right'
          animation='overlay'
          icon='labeled'
          visible={showHistory}
          style={{width:"18.75%", padding:"1rem", backgroundColor:"#FFF"}}
        >
          <Header>History
            <Button style={{backgroundColor:"#FFF"}} icon="close" floated="right" className="closehistory" visible={showHistory} aria-label='Close History' onClick={() => setShowHistory(!showHistory)} />
          </Header>
          <HistoryList history={history} activeChars={team} OnHistoryChange={() => {
            StoreHistory(history);
            DebouncedRefreshTimeline();
          }}/>
        </Sidebar>

        <Sidebar.Pusher>
          <Grid columns={2}>
            <Grid.Column width={4}>
            </Grid.Column>
            <Grid.Column width={6} textAlign="right">
              <div style={{fontSize:"2em", lineHeight:"1em"}}>DPS: {stats.dps?stats.dps.toLocaleString('en-US'):"-"} </div>
              <div>({stats.total.toLocaleString('en-US')} Damage over {(Math.round(stats.time*100)/100).toLocaleString('en-US')} seconds)</div>
            </Grid.Column>
            <Grid.Column width={6} textAlign="left">
              <Modal trigger={
                  <Button aria-label='DPS Charts' color="green"><Icon name="chart line" />Charts</Button>
                }>
                <Modal.Header>Charts</Modal.Header>
                <Modal.Content>
                  <DpsChart dps={stats.dps} timeline={items.map(i => { return {
                    time:i.start_time, 
                    value:i.data.values.averageDamage
                  }})} />
                </Modal.Content>
              </Modal>
              
              <Modal size="mini" trigger={
                  <Button color="blue" aria-label='Share' style={{display:"inline-flex"}}>
                    <Icon name="linkify" />
                    Share
                  </Button>
                }>
                <Modal.Header>Share</Modal.Header>
                <Modal.Content>
                  <Share team={team} history={history} monsters={monsters} />
                </Modal.Content>
              </Modal>
              
              <Button.Group floated="right">
                <Button onClick={(e,d) => {
                  history.Undo();
                  StoreHistory(history);
                  DebouncedRefreshTimeline();
                }} icon="undo" aria-label='Undo' />
                
                <Button onClick={(e,d) => {
                  history.Redo();
                  StoreHistory(history);
                  DebouncedRefreshTimeline();
                }} icon="redo" aria-label='Redo' />
                
                <Button onClick={(e,d) => {
                  setShowConfirmTrash(true);
                }} icon="trash" aria-label='Trash' negative />
                <Confirm
                  open={showConfirmTrash}
                  header={<div className="header"><Icon name='warning sign' /> History Trashing</div>}
                  content={<div className="content">Are you sure you want to permanently Trash the history</div>}
                  onCancel={() => setShowConfirmTrash(false)}
                  onConfirm={() => {
                    history.Trash();
                    StoreHistory(history);
                    DebouncedRefreshTimeline();
                    setShowConfirmTrash(false);
                  }}
                />
                <Popup trigger={
                  <Button className="openhistory" icon="th list" floated="right" visible={!showHistory} aria-label='Show History' onClick={() => setShowHistory(!showHistory)} />
                }>Show History</Popup>
              </Button.Group>
            </Grid.Column>
          </Grid>
          <TimeGrid groups={groups} items={items} key={key} />
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </Fragment>
  );
}

export default SimulatorPage;