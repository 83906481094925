import Scaling from '../../Scaling.js';
import BaseWeapon from '../base.js';

const IronSting = (props) => {
  
  return BaseWeapon({
    ...props,
    type: 'sword',
    name: 'Iron Sting',
    lvl1Atk: 42,
    lvl1Sub: 36,
    subType: 'elemMastery',
    PersistentResolve(result, context) {
      /**
       * effect: 'Dealing Elemental DMG increases all DMG by [6%] for 6s. 
       * Max 2 stacks. Can only occur once every 1s.'
       */
      if(this.IsSelf(result)) {
      }
      return result;
    }
  });
};

export default IronSting;