import Scaling from '../../Scaling.js';
import BaseWeapon from '../base.js';

const EmeraldOrb = (props) => {
  
  return BaseWeapon({
    ...props,
    type: 'catalyst',
    name: 'Emerald Orb',
    lvl1Atk: 40,
    lvl1Sub: 20,
    subType: 'elemMastery',
    PersistentResolve(result, context) {
      /**
       * effect: 'Upon causing a Vaporize, Electro-Charged, Frozen, or a 
       * Hydro-infused Swirl reaction, increases ATK by [20%] for 12s.'
       */
      if(this.IsSelf(result)) {
      }
      return result;
    }
  });
};

export default EmeraldOrb;