import React, {useState, useEffect, Fragment} from 'react';
import { Checkbox, Segment } from 'semantic-ui-react';
import ReactApexChart from 'react-apexcharts';

const DpsGraph = (props) => {
  const [series, setSeries] = useState([{data: timelineToData(props.timeline, props.dps, false)}]);
  const [options, setOptions] = useState({
    chart: {
      id: 'chart2',
      type: 'line',
      height: 230,
      toolbar: {
        autoSelected: 'pan',
        show: false
      }
    },
    colors: ['#2E93fA', '#66DA26', '#546E7A', '#E91E63', '#FF9800'],
    stroke: {
      width: 3
    },
    dataLabels: {
      enabled: false
    },
    fill: {
      opacity: 1,
    },
    markers: {
      size: 0
    },
    xaxis: {
      type: 'datetime',
      labels: {
        formatter: function(value, timestamp, opts) {
          let d = new Date(timestamp);
          let str = d.getMinutes()+":"+(d.getSeconds()<10?"0"+d.getSeconds():d.getSeconds());
          return str;
        }
      }
    }
  });
  const [optionsLine, setOptionsLine] = useState({
    chart: {
      id: 'chart1',
      height: 130,
      type: 'area',
      brush:{
        target: 'chart2',
        enabled: true
      },
      selection: {
        enabled: true,
        xaxis: {
          min: 0,
          max: 5000
        }
      },
    },
    annotations: {
      yaxis: []
    },
    colors: ['#2E93fA', '#66DA26', '#546E7A', '#E91E63', '#FF9800'],
    fill: {
      type: 'gradient',
      gradient: {
        opacityFrom: 0.91,
        opacityTo: 0.1,
      }
    },
    xaxis: {
      type: 'datetime',
      labels: {
        formatter: function(value, timestamp, opts) {
          let d = new Date(timestamp);
          let str = d.getMinutes()+":"+(d.getSeconds()<10?"0"+d.getSeconds():d.getSeconds());
          return str;
        }
      },
      tooltip: {
        enabled: false
      }
    },
    yaxis: {
      tickAmount: 2
    }
  });
  const [movingAvgEnabled, setMovingAvgEnabled] = useState(false);
  
  useEffect(() => {
    let series = timelineToData(props.timeline, props.dps, movingAvgEnabled);
    setSeries(series);
    setOptions(s => {
      let n = {
        ...s,
        annotations: {
          yaxis:[{
            y: props.dps,
            borderColor: '#00E396',
            label: {
              borderColor: '#00E396',
              style: {
                color: '#fff',
                background: '#00E396',
              },
              text: 'Overall DPS: '+props.dps,
            }
          }]
        }
      };
      return n;
    })
    setOptionsLine(s => {
      let n = {
        ...s,
        chart: {
          ...s.chart,
          selection: {
            ...s.chart.selection,
            xaxis: {
              ...s.chart.selection.xaxis,
              max: series[0].data.reduce((a,v) => v[0]>a?v[0]:a, 0)
            }
          }
        }
      };
      return n;
    });
  }, [props.timeline, props.dps, movingAvgEnabled]);
  
  function timelineToData(timeline, dps, movingAvgEnabled) {
    let liveDataSerie = {}, maxIdx=0;
    timeline.forEach(e => {
      if(e.value===0) return;
      let idx = e.time;
      if(Math.ceil(idx)>maxIdx) maxIdx = Math.ceil(idx/1000)*1000;
      if(!liveDataSerie[idx]) liveDataSerie[idx] = 0;
      liveDataSerie[idx] += e.value;
    });
    let liveSerie = Object.entries(liveDataSerie).map(e => [parseFloat(e[0]), Math.round(e[1])]).sort((a,b) => a[0]-b[0]);
    let normalized = [...Array(maxIdx/1000+1)].map((i,k) => {
      return [k*1000, liveSerie.reduce((a,c) => {
        return c[0]>k*1000-1000 && c[0]<=k*1000?a+c[1]:a;
      }, 0)];
    });
    //let dpsLine = normalized
    //  .map(c => [c[0], dps]);
    let movingAvg = normalized
      .map((c,i,a) => {
        return [c[0], a.slice(Math.max(0, i-2), Math.min(a.length, i+1))];
      })
      .map(c => {
        return [c[0], Math.round(c[1].reduce((a,b) => a+b[1], 0) / (c[1].length || 1))];
      });
    return movingAvgEnabled
      ? [{name: "Moving Avg", data: movingAvg}]
      : [{name: "Live Values", data: normalized}];
  }
  return (
    <Fragment>
      <Segment basic>
        <label>Use Moving Average : </label><Checkbox toggle onChange={(e,d) => setMovingAvgEnabled(d.checked)}/>
      </Segment>
      <Segment basic>
        <ReactApexChart options={options} series={series} type="line" height={230} />
      </Segment>
      <Segment basic>
        <ReactApexChart options={optionsLine} series={series} type="area" height={130} />
      </Segment>
    </Fragment>
  );
};

export default DpsGraph;