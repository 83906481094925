import React, {Fragment, useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { Card, Button, Image, Header, Segment } from 'semantic-ui-react';

const IndexPage = (props) => {
  return <Fragment>
    <Header>Hone your Gaming skills</Header>
    <Segment>
      Welcome to Game Honed, Our goal is to help you improve your gaming skills 
      by offering various tools, guides and resources.
    </Segment>
    <Card.Group>
      <Card href="/genshin">
        <Image src='/static/genshin/logo.png' wrapped ui={false} style={{backgroundColor:"rgba(17,17,17,0.75)"}}/>
        <Card.Content>
          <Card.Header>Genshin Impact</Card.Header>
          <Card.Description>
            Improve your team building with our Simulator and various Guides
          </Card.Description>
        </Card.Content>
        <Card.Content extra>
          <Button as={Link} to="/genshin/simulator">Simulator</Button>
          <Button as={Link} to="/genshin/guides">Guides</Button>
        </Card.Content>
      </Card>
      <Card>
        <Card.Content>
          <Card.Header>More games to come</Card.Header>
          <Card.Description></Card.Description>
        </Card.Content>
        <Card.Content extra>
        </Card.Content>
      </Card>
    </Card.Group>
  </Fragment>;
}

export default IndexPage;