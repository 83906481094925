import Scaling from '../../Scaling.js';
import BaseWeapon from '../base.js';

const SwordOfDescension = (props) => {
  
  return BaseWeapon({
    ...props,
    type: 'sword',
    name: 'Sword of Descension',
    lvl1Atk: 39,
    lvl1Sub: 0.077,
    subType: 'atkPct',
    PersistentResolve(result, context) {
      /**
       * effect: 'Hitting enemies with Normal or Charged Attacks grants a 
       * 50% chance to deal 200% ATK as DMG in a small AoE. This effect can 
       * only occur once every 10s. Additionally, if the Traveler equips the 
       * Sword of Descension, their ATK is increased by 66.'
       */
      if(this.IsSelf(result)) {
      }
      return result;
    }
  });
};

export default SwordOfDescension;