import Scaling from '../../Scaling.js';
import BaseWeapon from '../base.js';

const TheBlackSword = (props) => {
  
  return BaseWeapon({
    ...props,
    type: 'sword',
    name: 'The Black Sword',
    lvl1Atk: 42,
    lvl1Sub: 0.06,
    subType: 'critRate',
    PersistentResolve(result, context) {
      /**
       * effect: 'Increases DMG dealt by Normal and Charged Attacks by [20%]. 
       * Additionally, regenerates [60+10%] of ATK as HP when Normal and 
       * Charged Attacks score a CRIT Hit. This effect can occur once every 5s.'
       */
      if(this.IsSelf(result)) {
        //console.log('Rust resolving...');
        if(result.type.startsWith("NormalAtk") || result.type==="ChargeAtk" || result.type==="ChargeAtkHold") {
          result.logs.push('Attacks with '+this.name+' increased by '+(Scaling.ScaleWeaponPassive(0.20, this.refinement)*100)+'%');
          result.attributes.damageBonus.all += Scaling.ScaleWeaponPassive(0.20, this.refinement);
        }
      }
      return result;
    }
  });
};

export default TheBlackSword;