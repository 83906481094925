import {BaseSet} from './base.js';

const Scholar = (props) => {
  
  return BaseSet({
    ...props,
    name: 'Scholar',
    two: {
      attributes: {
        energyRecharge: 0.20
      },
      PersistentResolve(result, context) {
        return result;
      }
    },
    four: {
      PersistentResolve(result, context) {
        return result;
      }
    }
  });
};

export default Scholar;