import Scaling from '../../Scaling.js';
import BaseWeapon from '../base.js';

const SerpentSpine = (props) => {
  
  return BaseWeapon({
    ...props,
    type: 'claymore',
    name: 'Serpent Spine',
    lvl1Atk: 42,
    lvl1Sub: 0.06,
    subType: 'critRate',
    PersistentResolve(result, context) {
      /**
       * effect: 'Every 4s a character is on the field, they will deal [6+1%] 
       * more DMG and take [3-0.3%] more DMG. This effect has a maximum of 5 
       * stacks and will not be reset if the character leaves the field, but 
       * will be reduced by 1 stack when the character takes DMG.'
       */
      if(this.IsSelf(result)) {
      }
      return result;
    }
  });
};

export default SerpentSpine;