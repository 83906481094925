import Tools from '../../Tools.js';
import Scaling from '../../Scaling.js';
import BaseCharacter from '../base.js';

//TODO: implement character
var Lisa = (props) => {
  
  let scaleTable = {
    ascension0: {
      min: {level:1, maxHP:802, baseAtk:20, defense:48, attr:0},
      max: {level:20, maxHP:2061, baseAtk:50, defense:123, attr:0}
    },
    ascension1: {
      min: {level:20, maxHP:2661, baseAtk:65, defense:159, attr:0},
      max: {level:40, maxHP:3985, baseAtk:96, defense:239, attr:0}
    },
    ascension2: {
      min: {level:40, maxHP:4411, baseAtk:107, defense:264, attr:24},
      max: {level:50, maxHP:5074, baseAtk:123, defense:304, attr:24}
    },
    ascension3: {
      min: {level:50, maxHP:5642, baseAtk:137, defense:338, attr:48},
      max: {level:60, maxHP:6305, baseAtk:153, defense:378, attr:48}
    },
    ascension4: {
      min: {level:60, maxHP:6730, baseAtk:164, defense:403, attr:48},
      max: {level:70, maxHP:7393, baseAtk:180, defense:443, attr:48}
    },
    ascension5: {
      min: {level:70, maxHP:7818, baseAtk:191, defense:468, attr:72},
      max: {level:80, maxHP:8481, baseAtk:207, defense:508, attr:72}
    },
    ascension6: {
      min: {level:80, maxHP:8906, baseAtk:218, defense:533, attr:96},
      max: {level:90, maxHP:9569, baseAtk:234, defense:573, attr:96}
    }
  };
  return BaseCharacter({
    ...props,
    name: 'Lisa',
    element: 'electro',
    equip: 'catalyst',
    scaleTable: scaleTable,
    scaleAttr: 'elemMastery',
    attackTalent: {
      description: 'Lightning Touch',
      level: props.attack,
      type: "NormalAtk",
      defaultImpl: {
        comboTable: [
          {dmg:0.711, anim:0.5}, 
          {dmg:0.709, anim:0.6}, 
          {dmg:0.883, anim:1}, 
          {dmg:0.865, anim:0.75}
        ],
        duration: 1,
        Prepare(result) {
          result.attributes.element = 'electro';
          return result;
        },
        Resolve(result) {
          result = Scaling.ScaleAttackCombo(result);
          return result;
        }
      },
      chargedImpl: {
        type: "ChargeAtk",
        duration: 1,
        Prepare(result) {
          result.attributes.element = 'electro';
          return result;
        },
        Resolve(result) {
          result = Scaling.ScaleTalent(1.02, result);
          return result;
        }
      }
    },
    skillTalent: {
      description:'Violet Arc',
      level: props.skill,
      type: 'Skill',
      defaultImpl: {
        Prepare(result) {
          return result;
        },
        Resolve(result) {
          return result;
        }
      },
      holdImpl: {
        Prepare(result) {
          return result;
        },
        Resolve(result) {
          return result;
        }
      }
    },
    burstTalent: {
      description: 'Lightning Rose',
      level: props.burst,
      type: 'Burst',
      defaultImpl: {
        Prepare(result) {
          return result;
        },
        Resolve(result) {
          return result;
        }
      }
    },
    passiveAsc1: {
      description: 'Induced Aftershock',
      PersistentResolve(result, context) {
        return result;
      }
    },
    passiveAsc4: {
      description: 'Static Electricity Field',
      PersistentResolve(result, context) {
        return result;
      }
    },
    passiveOther: [{
      description: 'General Pharmaceutics',
      PersistentResolve(result, context) {
        return result;
      }
    }],
    constellations: [
      ...BaseCharacter.Constellation53,
    ]
  });
};

export default Lisa;