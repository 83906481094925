import Scaling from '../../Scaling.js';
import BaseWeapon from '../base.js';

const Frostbearer = (props) => {
  
  return BaseWeapon({
    ...props,
    type: 'catalyst',
    name: 'Frostbearer',
    lvl1Atk: 42,
    lvl1Sub: 0.09,
    subType: 'atkPct',
    PersistentResolve(result, context) {
      /**
       * effect: 'Hitting an opponent with Normal and Charged Attacks has a 
       * [60+10%] chance of forming and dropping an Everfrost Icicle above 
       * them, dealing [80+15]% AoE ATK DMG. Opponents affected by Cryo are 
       * dealt [200+40%] ATK DMG. Can only occur once every 10s.'
       */
      if(this.IsSelf(result)) {
      }
      return result;
    }
  });
};

export default Frostbearer;