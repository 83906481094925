import Scaling from '../../Scaling.js';
import BaseWeapon from '../base.js';

const EyeOfPerception = (props) => {
  
  return BaseWeapon({
    ...props,
    type: 'catalyst',
    name: 'Eye Of Perception',
    lvl1Atk: 41,
    lvl1Sub: 0.12,
    subType: 'atkPct',
    PersistentResolve(result, context) {
      /**
       * effect: 'Normal and Charged Attacks have a 50% chance to fire a Bolt 
       * of Perception, dealing [240%] ATK as DMG. This bolt can bounce between 
       * enemies a maximum of 4 times. This effect can occur once every 
       * [12-1]s.'
       */
      if(this.IsSelf(result)) {
      }
      return result;
    }
  });
};

export default EyeOfPerception;