import Scaling from '../../Scaling.js';
import BaseWeapon from '../base.js';

const SkywardSpine = (props) => {
  
  return BaseWeapon({
    ...props,
    type: 'polearm',
    name: 'Skyward Spine',
    lvl1Atk: 48,
    lvl1Sub: 0.08,
    subType: 'energyRecharge',
    PersistentResolve(result, context) {
      /**
       * effect: 'Increases CRIT Rate by [8%] and increases Normal ATK SPD by 
       * 12%. Additionally, Normal and Charged Attacks hits on enemies have a 
       * 50% chance to trigger a vaccuum blade that deals [40+15%] of ATK as 
       * DMG in a small AoE. This effect can occur no more than once every 2s.'
       */
      if(this.IsSelf(result)) {
        let effectValues = {
          critRate: Scaling.ScaleWeaponPassive(0.08, this.refinement),
          vacuumBladeDmg: Scaling.ScaleWeaponPassive([0.40, 0.15], this.refinement)
        }
      }
      return result;
    }
  });
};

export default SkywardSpine;