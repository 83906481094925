import React, {Fragment, useEffect, useState, useRef} from 'react';
import { Confirm, Menu, Segment, Sidebar, Grid, Popup, Advertisement, Header, Icon, Button, Modal, Form, Input, Label } from 'semantic-ui-react';
import API, {graphqlOperation} from '@aws-amplify/api'

import * as queries from '../graphql/queries.js';
import * as mutations from '../graphql/mutations.js';
import * as subscriptions from '../graphql/subscriptions.js';

export default (props) => {
  const [team, setTeam] = useState(props.team);
  const [history, setHistory] = useState(props.history);
  const [monsters, setMonsters] = useState(props.monsters);
  const [shareLink, setShareLink] = useState('');
  const [published, setPublished] = useState(false);
  const [copySuccess, setCopySuccess] = useState(null);
  
  const textRef = useRef(null);
  
  useEffect(() => {
    setTeam(props.team);
    setHistory(props.history);
    setMonsters(props.monsters);
  }, [props]);
  
  function FilterObjectsInArray(raw, allowedKeys={}, path='root') {
    return raw.map(o => typeof o==='object' && !Array.isArray(o)
      ? FilterObject(o, allowedKeys, path+'[]') 
      : Array.isArray(o)
        ? FilterObjectsInArray(o, allowedKeys, path+'[]')
        : o
    )
  }
  
  function FilterObject(raw, allowedKeys={}, path='root') {
    return raw===null
      ? raw
      : Object.keys(raw)
        .filter(key => allowedKeys[path] && allowedKeys[path].includes(key))
        .reduce((obj, key) => {
          return {
            ...obj,
            [key]: typeof raw[key]==='object' && !Array.isArray(raw[key])
              ? FilterObject(raw[key], allowedKeys, path+'.'+key) 
              : Array.isArray(raw[key])
                ? FilterObjectsInArray(raw[key], allowedKeys, path+'.'+key)
                : raw[key]
          };
        }, {});
  }
  
  function Publish(password = '') {
    let t = team.map(m => FilterObject(m, {
        "root":["name", "build", "level", "ascension", "constellation", "equip", "attack", "skill", "burst", "weapon", "artifacts"],
        "root.attack":["level"],
        "root.skill":["level"],
        "root.burst":["level"],
        "root.weapon":["name", "level", "ascension", "refinement"],
        "root.artifacts":["sets", "stats"],
        "root.artifacts.sets[]":["name", "setPieces"],
        "root.artifacts.stats":["flower", "feather", "time", "goblet", "head"],
        "root.artifacts.stats.flower":["edmg", "pdmg", "bonus", "atk", "atkpct", "cr", "cd", "er", "em", "def", "defpct", "hp", "hppct"],
        "root.artifacts.stats.feather":["edmg", "pdmg", "bonus", "atk", "atkpct", "cr", "cd", "er", "em", "def", "defpct", "hp", "hppct"],
        "root.artifacts.stats.time":["edmg", "pdmg", "bonus", "atk", "atkpct", "cr", "cd", "er", "em", "def", "defpct", "hp", "hppct"],
        "root.artifacts.stats.goblet":["edmg", "pdmg", "bonus", "atk", "atkpct", "cr", "cd", "er", "em", "def", "defpct", "hp", "hppct"],
        "root.artifacts.stats.head":["edmg", "pdmg", "bonus", "atk", "atkpct", "cr", "cd", "er", "em", "def", "defpct", "hp", "hppct"]
      }));
    let ops = graphqlOperation(mutations.createComposition, {input: {
      history: history.GetEntries(),
      team: t,
      monsters,
      password
    }});
    API.graphql(ops).then((result) => {
      console.log(result);
      setShareLink('https://www.gamehoned.com/genshin/simulator?shared='+result.data.createComposition.id);
    });
  }

  function copyToClipboard(e) {
    /*global navigator*/
    navigator.clipboard.writeText(shareLink)
    textRef.current.select();
    setCopySuccess('Copied!');
  };
  
  return <Fragment>
    <Button disabled={false && published} onClick={() => {
      setPublished(true);
      Publish();
    }}>Publish</Button>
    <Input value={shareLink} 
      ref={textRef}
      action={{
        icon: 'copy',
        color: 'green',
        content: copySuccess,
        onClick: copyToClipboard
      }} />
  </Fragment>;
}