import {BaseSet} from './base.js';
import Tools from '../Tools.js';

const ViridescentVenerer = (props) => {
  
  return BaseSet({
    ...props,
    name: 'Viridescent Venerer',
    two: {
      attributes: {
        damageBonus: {
          anemo: 0.15
        }
      },
      PersistentResolve(result, context) {
        return result;
      }
    },
    four: {
      PersistentFinalize(result){
        if(!this.IsSelf(result)) return result;
        if(result.type==="Reaction" && result.description==="Swirl") {
          let swirl = result;
          result.attributes.reactionBonus.swirl += 0.6;
          result.logs.push(this.name + ' increases Swirl Damage by 60%');
          result.effects.push(Tools.CreateSideEffect(result, {
            description: 'Viridescent Venerer 4pc',
            entity: 'Buffs',
            duration: 10,
            relativeTime: (r => -r.precedingResult.duration),
            type: 'Buff',
            PersistentResolve(result, context) {
              result.attributes.resistanceReduction[swirl.attributes.element] += 0.40;
              result.logs.push("Viridescent Venerer 4pc reduces opponent's Elemental RES of the element infused in Swirl ("+swirl.attributes.element+") by 40%");
              return result;
            },
            logs: []
          }));
        }
        return result;
      }
    }
  });
};

export default ViridescentVenerer;