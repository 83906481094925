import Scaling from '../../Scaling.js';
import BaseWeapon from '../base.js';

const SacrificialFragments = (props) => {
  
  return BaseWeapon({
    ...props,
    type: 'catalyst',
    name: 'Sacrificial Fragments',
    lvl1Atk: 41,
    lvl1Sub: 48,
    subType: 'elemMastery',
    PersistentResolve(result, context) {
      /**
       * effect: 'After dealing damage to an enemy with an Elemental Skill, 
       * the skill has a [40%] chance to end its own CD. 
       * Can only occur once every [30-4s].'
       */
      if(this.IsSelf(result)) {
      }
      return result;
    }
  });
};

export default SacrificialFragments;