import React, {useState, useEffect, Fragment} from 'react';
import { Header, Popup, Input, Form, Dropdown } from 'semantic-ui-react';

const ArtifactEdit = (props) => {
  const statsDetails = {
    'hp': {name:'Health Points', description:''},
    'hppct': {name:'Health Points %', description:''},
    'atk': {name:'Flat ATK', description:''},
    'atkpct': {name:'ATK %', description:''},
    'def': {name:'Defence', description:''},
    'defpct': {name:'Defence %', description:''},
    'em': {name:'Elemental Mastery', description:''},
    'er': {name:'Energy Recharge', description:''},
    'cr': {name:'CRIT Rate', description:''},
    'cd': {name:'CRIT Damage', description:''},
    'hb': {name:'Healing Bonus', description:''},
    'edmg': {name:'Elemental Damage', description:''},
    'pdmg': {name:'Physical Damage', description:''},
    'bonus': {name:'Damage Type', description:''},
  };
  const statsConfig = {
    flower: [
      [
        {key:'hp', min:430, max:4780}
      ],[
        {key:'atk', min:3, max:95},
        {key:'atkpct', min:1.6, max:29, pct:true}
      ],[
        {key:'cr', min:1.1, max:19.5, pct:true},
        {key:'cd', min:2.2, max:39, pct:true}
      ],[
        {key:'em', min:7, max:115},
        {key:'er', min:1.8, max:32.5, pct:true}
      ],[
        {key:'hppct', min:1.6, max:29, pct:true}
      ],[
        {key:'def', min:4, max:115},
        {key:'defpct', min:2, max:36.5, pct:true}
      ]
    ],
    feather: [
      [
        {key:'atk', min:28, max:311}
      ],[
        {key:'atkpct', min:1.6, max:29, pct:true}
      ],[
        {key:'cr', min:1.1, max:19.5, pct:true},
        {key:'cd', min:2.2, max:39, pct:true}
      ],[
        {key:'em', min:7, max:115},
        {key:'er', min:1.8, max:32.5, pct:true}
      ],[
        {key:'hp', min:50, max:1495},
        {key:'hppct', min:1.6, max:29, pct:true}
      ],[
        {key:'def', min:4, max:115},
        {key:'defpct', min:2, max:36.5, pct:true}
      ]
    ],
    time: [
      [
        {key:'atkpct', min:1.6, max:46.6, pct:true},
        {key:'em', min:7, max:187},
        {key:'er', min:1.8, max:51.8, pct:true}
      ],[
        {key:'hppct', min:1.6, max:46.6, pct:true},
        {key:'defpct', min:2, max:58.3, pct:true}
      ],[
        {key:'atk', min:3, max:95},
      ],[
        {key:'cr', min:1.1, max:19.5, pct:true},
        {key:'cd', min:2.2, max:39, pct:true}
      ],[
        {key:'hp', min:50, max:1495}
      ],[
        {key:'def', min:4, max:115},
      ]
    ],
    goblet: [
      [
        {key:'atkpct', min:1.6, max:46.6, pct:true},
        {key:'bonus', choices:["Physical", "Pyro", "Hydro", "Geo", "Electro", "Cryo", "Anemo"]},
        {key:'edmg', min:5.2, max:46.6, pct:true},
        {key:'pdmg', min:6.6, max:58.3, pct:true}
      ],[
        {key:'em', min:7, max:187},
        {key:'hppct', min:1.6, max:46.6, pct:true},
        {key:'defpct', min:2, max:58.3, pct:true}
      ],[
        {key:'atk', min:50, max:95},
        {key:'er', min:1.8, max:32.5, pct:true}
      ],[
        {key:'cr', min:1.1, max:19.5, pct:true},
        {key:'cd', min:2.2, max:39, pct:true}
      ],[
        {key:'hp', min:430, max:4780}
      ],[
        {key:'def', min:4, max:115}
      ]
    ],
    head: [
      [
        {key:'atkpct', min:1.6, max:46.6, pct:true},
        {key:'cr', min:1.1, max:31.1, pct:true},
        {key:'cd', min:2.2, max:62.2, pct:true}
      ],[
        {key:'em', min:7, max:187},
        {key:'hppct', min:1.6, max:46.6, pct:true},
        {key:'defpct', min:2, max:58.3, pct:true}
      ],[
        {key:'hb', min:4, max:35.9, pct:true}
      ],[
        {key:'atk', min:50, max:95},
        {key:'er', min:1.8, max:32.5, pct:true}
      ],[
        {key:'hp', min:430, max:4780}
      ],[
        {key:'def', min:4, max:115}
      ]
    ]
  };
  const [type, setType] = useState(props.type);
  const [stats, setStats] = useState(props.preset);
  
  useEffect(() => {
    console.log('props effect', props);
    setType(props.type);
    setStats(props.preset);
  }, [props.type, props.preset]);
  
  return statsConfig[type].map((d,i) => {
    return <Form.Group>
        {d.map(c => {
            let pctProps = c.pct
              ? {step:0.1, icon:'percent', className:"percent"}
              : {className:"normal"};
            return (c.key==="pdmg" && (!stats.bonus || stats.bonus==="physical")) 
                || (c.key==="edmg" && (stats.bonus && stats.bonus!=="physical")) 
                || (c.key!=="pdmg" && c.key!=="edmg") 
              ? <Form.Field inline className="artifactStat" key={"artifactEditField"+c.key}>
                  <Popup trigger={<label>{statsDetails[c.key].name}</label>}><Header>{statsDetails[c.key].name}</Header><p>{statsDetails[c.key].description}</p></Popup>
                  {c.key==="bonus"
                  ? <Dropdown 
                      placeholder='Select Damage Type'
                      label='Damage Type'
                      selection
                      options={[
                        ...c.choices.map(a => {return {
                          key:a.toLowerCase(), 
                          value:a.toLowerCase(), 
                          text:a
                        }})
                      ]}
                      value={stats&&stats[c.key] ? stats[c.key] : 'physical'}
                      onChange={(e,d) => {
                        let dmgswitch={};
                        if(stats.bonus==="physical" && d.value!=="physical") {
                          dmgswitch = {
                            pdmg:0,
                            edmg:Math.max(Math.min(stats.pdmg,.466), .052)
                          };
                        }
                        if(stats.bonus!=="physical" && d.value==="physical") {
                          dmgswitch = {
                            pdmg:Math.max(Math.min(stats.edmg,.583), .066),
                            edmg:0
                          };
                        }
                        setStats(s => {
                          console.log('onChange', type, s);
                          let st = {...s, [c.key]: d.value, ...dmgswitch};
                          props.onChange(type, st);
                          return st;
                        });
                      }}
                    />
                  : <Input type='number' id={type+'_'+c.key} name={type+'_'+c.key}
                        min={c.min} max={c.max} value={stats&&stats[c.key] ? (c.pct ? (stats[c.key]*100).toFixed(1) : stats[c.key]) : 0} 
                        onChange={(e,d) => {
                          setStats(s => {
                            console.log('onChange', type, s);
                            let st = {...s, [c.key]: (c.pct ? Math.round(d.value*10)/1000 : parseInt(d.value))};
                            props.onChange(type, st);
                            return st;
                          });
                        }} 
                        {...pctProps}
                      />
                  }
                </Form.Field>
              : '';
          }
        )}
      </Form.Group>;
  });
};

export default ArtifactEdit;