import Tools from '../../Tools.js';
import Scaling from '../../Scaling.js';
import BaseCharacter from '../base.js';

//TODO: implement character
var Zhongli = (props) => {
  
  let scaleTable = {
    ascension0: {
      min: {level:1, maxHP:1144, baseAtk:20, defense:57, attr:0},
      max: {level:20, maxHP:2967, baseAtk:51, defense:149, attr:0}
    },
    ascension1: {
      min: {level:20, maxHP:3948, baseAtk:67, defense:198, attr:0},
      max: {level:40, maxHP:5908, baseAtk:101, defense:297, attr:0}
    },
    ascension2: {
      min: {level:40, maxHP:6605, baseAtk:113, defense:332, attr:0.072},
      max: {level:50, maxHP:7599, baseAtk:130, defense:382, attr:0.072}
    },
    ascension3: {
      min: {level:50, maxHP:8528, baseAtk:146, defense:428, attr:0.144},
      max: {level:60, maxHP:9533, baseAtk:163, defense:479, attr:0.144}
    },
    ascension4: {
      min: {level:60, maxHP:10230, baseAtk:175, defense:514, attr:0.144},
      max: {level:70, maxHP:11243, baseAtk:192, defense:564, attr:0.144}
    },
    ascension5: {
      min: {level:70, maxHP:11940, baseAtk:204, defense:599, attr:0.216},
      max: {level:80, maxHP:12965, baseAtk:222, defense:651, attr:0.216}
    },
    ascension6: {
      min: {level:80, maxHP:13662, baseAtk:233, defense:686, attr:0.288},
      max: {level:90, maxHP:14695, baseAtk:251, defense:738, attr:0.288}
    }
  };
  return BaseCharacter({
    ...props,
    name: 'Zhongli',
    element: 'geo',
    equip: 'polearm',
    scaleTable: scaleTable,
    scaleAttr: 'damageBonus.geo',
    attackTalent: {
      description: 'Rain of Stone',
      level: props.attack,
      type: "NormalAtk",
      defaultImpl: {
        talentProperties: {
          comboTable: [
            {dmg:0.308, anim:0.5}, 
            {dmg:0.312, anim:0.33}, 
            {dmg:0.386, anim:0.33}, 
            {dmg:0.429, anim:0.66}, 
            {dmg:0.108, anim:0.5, extra:[
              {dmg:0.108, anim:0.1},
              {dmg:0.108, anim:0.1},
              {dmg:0.108, anim:0.1}
            ]},
            {dmg:0.545, anim:1}
          ]
        },
        duration: 1,
        Resolve(result) {
          result = Scaling.ScaleAttackCombo(result);
          return result;
        }
      },
      chargedImpl: {
        description: 'Rain of Stone - Charged',
        type: "ChargeAtk",
        duration: 0.83,
        Finalize(result) {
          let slash = Tools.CreateTalentSideEffect(result, 'default');
          slash.precedingResult = result.precedingResult;
          result.talentEffects.push(slash);
          
          result.effects.push(Tools.CreateTalentSideEffect(result, 'fallenSpear', {
            relativeTime: -0.66
          }))
          return result;
        }
      },
      fallenSpearEffect: {
        description: 'Rain of Stone - Charged - Fallen Spear',
        entity: 'Zhongli\'s Rain of Stone',
        duration: 0.1,
        talentProperties: {
          spearNumber: 1
        },
        Resolve(result) {
          result = Scaling.ScaleTalent(1.11, result);
          return result;
        },
        Finalize(result) {
          if(result.talentProperties.spearNumber<4) {
            result.effects.push(Tools.CreateTalentSideEffect(result, 'fallenSpear', {
              talentProperties: {
                spearNumber: result.talentProperties.spearNumber+1
              }
            }));
          }
          return result;
        }
      }
    },
    skillTalent: {
      description:'Dominus Lapidis',
      level: props.skill,
      type: 'Skill',
      track: {cooldown: 4},
      defaultImpl: {
        duration: 1.33,
        Prepare(result) {
          let delay = result.time - result.talent.track.lastUsed;
          if(delay<result.talent.track.cooldown) {
            result = Tools.CreateCooldownEffect(result, {cooldown: result.talent.track.cooldown});
          }
          result.talent.track.lastUsed = result.time;
          result.talent.track.cooldown = 4;
          result.attributes.element = 'geo';
          return result;
        },
        Resolve(result) {
          result.effects.push(Tools.CreateTalentSideEffect(result, 'stoneStele'));
          return result;
        }
      },
      holdImpl: {
        description: 'Dominus Lapidis - Hold',
        duration: 1.5,
        Prepare(result) {
          let delay = result.time - result.talent.track.lastUsed;
          if(delay<result.talent.track.cooldown) {
            result = Tools.CreateCooldownEffect(result, {cooldown: result.talent.track.cooldown});
          }
          result.talent.track.lastUsed = result.time;
          result.talent.track.cooldown = 12;
          result.attributes.element = 'geo';
          return result;
        },
        Resolve(result) {
          result = Scaling.ScaleTalent(.80, result);
          result.effects.push(Tools.CreateTalentSideEffect(result, 'shield'));
          return result;
        }
      },
      stoneSteleEffect: {
        description: 'Dominus Lapidis - Stone Stele',
        entity: 'Zhongli\'s Dominus Lapidis',
        duration: 30,
        relativeTime: -0.33,
        Prepare(result) {
          result.attributes.element = 'geo';
          return result;
        },
        Resolve(result) {
          result = Scaling.ScaleTalent(.16, result);
          result.effects.push(Tools.CreateTalentSideEffect(result, 'resonance', {
            relativeTime: (result) => {
              return -result.precedingResult.duration+2;
            },
            talentProperties: {
              effectSource: result
            }
          }))
          return result;
        }
      },
      resonanceEffect: {
        description: 'Dominus Lapidis - Stone Stele - Resonance',
        entity: 'Zhongli\'s Dominus Lapidis',
        duration: 2,
        Prepare(result) {
          result.attributes.element = 'geo';
          return result;
        },
        Resolve(result) {
          result = Scaling.ScaleTalent(.32, result);
          return result;
        },
        Finalize(result) {
          let src = result.talentProperties.effectSource;
          if(src.time+src.duration > result.time+result.duration) {
            result.effects.push(Tools.CreateTalentSideEffect(result, 'resonance', {
              talentProperties: {
                effectSource: src
              }
            }));
          }
          return result;
        }
      },
      shieldEffect: {
        description: 'Dominus Lapidis - Shield',
        entity: 'Zhongli\'s Dominus Lapidis',
        duration: 20,
        Prepare(result) {
          result.attributes.element = 'geo';
          return result;
        }
      }
    },
    burstTalent: {
      description: 'Planet Befall',
      level: props.burst,
      type: 'Burst',
      defaultImpl: {
        duration: 3,
        cooldown: 12,
        Prepare(result) {
          result.attributes.element = 'geo';
          return result;
        },
        Resolve(result) {
          result = Scaling.ScaleTalent(4.01, result, 'higher');
          result.effects.push(Tools.CreateTalentSideEffect(result, 'petrify'));
          return result;
        }
      },
      petrifyEffect: {
        description: 'Planet Befall - Petrification',
        entity: 'Zhongli\' Planet Befall',
        duration: 3.1,
        relativeTime: -1,
        Prepare(result) {
          result.attributes.element = 'geo';
          return result;
        },
        Resolve(result) {
          result.setDuration(3.1 + Math.min(result.talentProperties.level-1, 9)/10);
          return result;
        }
      }
    },
    passiveAsc1: {
      description: 'Resonant Waves',
      PersistentResolve(result, context) {
        return result;
      }
    },
    passiveAsc4: {
      description: 'Dominance of Earth',
      PersistentResolve(result, context) {
        return result;
      }
    },
    passiveOther: [{
      description: 'Arcanum of Crystal',
      PersistentResolve(result, context) {
        return result;
      }
    }],
    constellations: [
      ...BaseCharacter.Constellation53,
    ]
  });
};

export default Zhongli;