import Tools from '../../Tools.js';
import Scaling from '../../Scaling.js';
import BaseCharacter from '../base.js';


var TravelerAnemo = (props) => {
  
  let elementalDmgEffect = {
    duration: 0.1,
    relativeTime: (r => -r.precedingResult.duration),
    talentProperties: {
      element: ''
    },
    Prepare(result) {
      if(result.talentProperties.element) {
        result.attributes.element = result.talentProperties.element;
        result.forceStatus = true;
      }
      return result;
    },
    Resolve(result) {
      if(result.talentProperties.element) {
        let sourceScaling = result.talentProperties.sourceScaling, 
            scaling = result.talentProperties.scaling;
        result = Scaling.ScaleTalent(sourceScaling * scaling, result);
        result.logs.push('Additionnal Elemental Damage deals '+(Math.round(scaling*10000)/100)+'%');
      }
      return result;
    }
  };
  
  
  
  let scaleTable = {
    ascension0: {
      min: {level:1, maxHP:912, baseAtk:18, defense:57, attr:0},
      max: {level:20, maxHP:2342, baseAtk:46, defense:147, attr:0}
    },
    ascension1: {
      min: {level:20, maxHP:3024, baseAtk:60, defense:190, attr:0},
      max: {level:40, maxHP:4529, baseAtk:88, defense:284, attr:0}
    },
    ascension2: {
      min: {level:40, maxHP:5013, baseAtk:98, defense:315, attr:0.06},
      max: {level:50, maxHP:5766, baseAtk:112, defense:362, attr:0.06}
    },
    ascension3: {
      min: {level:50, maxHP:6411, baseAtk:126, defense:405, attr:0.12},
      max: {level:60, maxHP:7164, baseAtk:140, defense:450, attr:0.12}
    },
    ascension4: {
      min: {level:60, maxHP:7648, baseAtk:149, defense:480, attr:0.12},
      max: {level:70, maxHP:8401, baseAtk:164, defense:527, attr:0.12}
    },
    ascension5: {
      min: {level:70, maxHP:8885, baseAtk:174, defense:558, attr:0.18},
      max: {level:80, maxHP:9638, baseAtk:188, defense:605, attr:0.18}
    },
    ascension6: {
      min: {level:80, maxHP:10122, baseAtk:198, defense:635, attr:0.24},
      max: {level:90, maxHP:10875, baseAtk:213, defense:682, attr:0.24}
    }
  };
  return BaseCharacter({
    ...props,
    name: props.name || 'Traveler - Anemo',
    element: props.element || 'anemo',
    equip: 'sword',
    scaleTable: scaleTable,
    scaleAttr: 'atkPct',
    attackTalent: {
      description: 'Foreign Ironwind',
      level: props.attack,
      type: "NormalAtk",
      defaultImpl: {
        talentProperties: {
          comboTable: [
            {dmg:0.445, anim:0.33}, 
            {dmg:0.434, anim:0.5}, 
            {dmg:0.53, anim:0.33}, 
            {dmg:0.583, anim:0.66}, 
            {dmg:0.708, anim:1}
          ]
        },
        duration: 1,
        Resolve(result) {
          result = Scaling.ScaleAttackCombo(result);
          result.attributes.element='physical';
          return result;
        }
      },
      chargedImpl: {
        description: 'Foreign Ironwind - Charged A-Hit',  
        type: "ChargeAtk",
        duration: 1,
        Resolve(result) {
          let slash = Tools.CreateTalentSideEffect(result, 'default');
          slash.precedingResult = result.precedingResult;
          result.talentEffects.push(slash);
          
          result.talentEffects.push(Tools.CreateTalentSideEffect(result, 'chargedSecond'));
          result = Scaling.ScaleTalent(0.559, result);
          return result;
        }
      },
      chargedSecondEffect: {
        description: 'Foreign Ironwind - Charged B-Hit',
        type: "ChargeAtk",
        duration: 0.3,
        Resolve(result) {
          result = Scaling.ScaleTalent(props.secondChargeDmg || 0.722, result);
          return result;
        }
      }
    },
    skillTalent: props.skillTalent || {
      description:'Palm Vortex',
      level: props.skill,
      type: 'Skill',
      defaultImpl: {
        Prepare(result) {
          result.attributes.element = 'anemo';
          return result;
        },
        Finalize(result) {
          result.talentEffects.push(Tools.CreateTalentSideEffect(result, 'lowExplosion'));
          return result;
        }
      },
      holdImpl: {
        description: 'Palm Vortex - Hold',
        duration: 0.2,
        Prepare(result) {
          result.attributes.element = 'anemo';
          return result;
        },
        Finalize(result) {
          result.talentEffects.push(Tools.CreateTalentSideEffect(result, 'initialCutting'));
          return result;
        }
      },
      initialCuttingEffect: {
        description: 'Palm Vortex - Initial Cutting',
        duration: 0.15,
        talentProperties: {
          cuttingNumber: 1,
          element: ''
        },
        Prepare(result) {
          result = Tools.AbsorbElement(result);
          result.attributes.element = 'anemo';
          return result;
        },
        Resolve(result) {
          result = Scaling.ScaleTalent(0.12, result);
          return result;
        },
        Finalize(result) {
          if(result.talentProperties.element && !result.talentProperties.absorbed) {
            result.effects.push(Tools.CreateTalentSideEffect(result, 'elementalDmg', {
              talentProperties: {
                element: result.talentProperties.element, 
                sourceScaling: 0.12
              }
            }));
          }
          if(result.talentProperties.cuttingNumber<2) {
            result.talentEffects.push(Tools.CreateTalentSideEffect(result, 'initialCutting', {
              talentProperties: {
                element: result.talentProperties.element,
                cuttingNumber: result.talentProperties.cuttingNumber+1
              }
            }));
          } else {
            result.talentEffects.push(Tools.CreateTalentSideEffect(result, 'maxCutting', {
              talentProperties: {
                element: result.talentProperties.element,
                cuttingNumber: result.talentProperties.cuttingNumber+1
              }
            }));
          }
          return result;
        }
      },
      maxCuttingEffect: {
        description: 'Palm Vortex - Cutting',
        duration: 0.35,
        talentProperties: {
          cuttingNumber: 1,
          element: ''
        },
        Prepare(result) {
          result = Tools.AbsorbElement(result);
          result.attributes.element = 'anemo';
          return result;
        },
        Resolve(result) {
          result = Scaling.ScaleTalent(0.168, result);
          return result;
        },
        Finalize(result) {
          if(result.talentProperties.element && !result.talentProperties.absorbed) {
            result.effects.push(Tools.CreateTalentSideEffect(result, 'elementalDmg', {
              talentProperties: {
                element: result.talentProperties.element, 
                sourceScaling: 0.168
              }
            }));
          }
          if(result.talentProperties.cuttingNumber<6) {
            result.talentEffects.push(Tools.CreateTalentSideEffect(result, 'maxCutting', {
              duration: result.talentProperties.cuttingNumber%2===0?0.15:0.35,
              talentProperties: {
                element: result.talentProperties.element,
                cuttingNumber: result.talentProperties.cuttingNumber+1
              }
            }));
          } else {
            result.talentEffects.push(Tools.CreateTalentSideEffect(result, 'maxExplosion', {
              talentProperties: {
                element: result.talentProperties.element
              }
            }));
          }
          return result;
        }
      },
      lowExplosionEffect: {
        description: 'Palm Vortex - Low Explosion',
        duration: 0.1,
        Prepare(result) {
          result = Tools.AbsorbElement(result);
          result.attributes.element = 'anemo';
          return result;
        },
        Resolve(result) {
          result = Scaling.ScaleTalent(1.76, result);
          return result;
        },
        Finalize(result) {
          if(result.talentProperties.element && !result.talentProperties.absorbed) {
            result.effects.push(Tools.CreateTalentSideEffect(result, 'elementalDmg', {
              talentProperties: {
                element: result.talentProperties.element, 
                sourceScaling: 1.76
              }
            }));
          }
          return result;
        }
      },
      maxExplosionEffect: {
        description: 'Palm Vortex - Max Explosion',
        duration: 0.1,
        Prepare(result) {
          result = Tools.AbsorbElement(result);
          result.attributes.element = 'anemo';
          return result;
        },
        Resolve(result) {
          result = Scaling.ScaleTalent(1.92, result);
          return result;
        },
        Finalize(result) {
          if(result.talentProperties.element && !result.talentProperties.absorbed) {
            result.effects.push(Tools.CreateTalentSideEffect(result, 'elementalDmg', {
              talentProperties: {
                element: result.talentProperties.element, 
                sourceScaling: 1.92
              }
            }));
          }
          return result;
        }
      },
      elementalDmgEffect: {
        ...elementalDmgEffect,
        description: 'Palm Vortex - Additional Elemental Damage',
        entity: (props.name || 'Lumine')+'\'s Palm Vortex',
        talentProperties: {
          ...elementalDmgEffect.talentProperties,
          scaling: .23
        }
      }
    },
    burstTalent: props.burstTalent || {
      description: 'Gust Surge',
      level: props.burst,
      type: 'Burst',
      defaultImpl: {
        cooldown: 15,
        duration: 1.8,
        Prepare(result) {
          result.attributes.element = 'anemo';
          return result;
        },
        Resolve(result) {
          return result;
        },
        Finalize(result) {
          result.effects.push(Tools.CreateTalentSideEffect(result, 'tornado', {
            relativeTime: -0.5
          }));
          return result;
        }
      },
      tornadoEffect: {
        description: 'Gust Surge - Tornado',
        entity: (props.name || 'Lumine')+'\'s Gust Surge',
        duration: 0.5,
        talentProperties: {
          tornadoNumber: 1,
          element: ''
        },
        Prepare(result) {
          result = Tools.AbsorbElement(result);
          result.attributes.element = 'anemo';
          return result;
        },
        Resolve(result) {
          result = Scaling.ScaleTalent(.808, result);
          return result;
        },
        Finalize(result) {
          if(result.talentProperties.element && !result.talentProperties.absorbed) {
            result.effects.push(Tools.CreateTalentSideEffect(result, 'elementalDmg', {
              talentProperties: {
                element: result.talentProperties.element, 
                sourceScaling: 0.808
              }
            }))
          }
          if(result.talentProperties.tornadoNumber<9) {
            result.effects.push(Tools.CreateTalentSideEffect(result, 'tornado', {
              talentProperties: {
                element: result.talentProperties.element,
                tornadoNumber: result.talentProperties.tornadoNumber+1
              }
            }));
          }
          return result;
        }
      },
      elementalDmgEffect: {
        ...elementalDmgEffect,
        description: 'Gust Surge - Additional Elemental Damage',
        entity: (props.name || 'Lumine')+'\'s Gust Surge',
        talentProperties: {
          ...elementalDmgEffect.talentProperties,
          scaling: .23//.307
        }
      }
    },
    passiveAsc1: {
      description: 'Slitting Wind',
      PersistentResolve(result, context) {
        return result;
      }
    },
    passiveAsc4: {
      description: 'Second Wind',
      PersistentResolve(result, context) {
        return result;
      }
    },
    passiveOther: [],
    constellations: [
      ...BaseCharacter.Constellation53,
      {
        description: 'Intertwined Winds',
        level: 6,
        PersistentFinalize(result, context) {
          if(context.IsSelf(result) && result.type==='Burst' && (result.description==='Gust Surge' || result.description==='Gust Surge - Tornado')) {
            result.effects.push(Tools.CreateSideEffect(result, {
              description: 'Intertwined Winds',
              entity: (props.name || 'Lumine')+'\'s Constellations',
              duration: 8,
              talentProperties: {
                element: result.talentProperties.element
              },
              Prepare(result) {
                result.attributes.element = 'anemo';
                return result;
              },
              PersistentResolve(result, context) {
                if(result.attributes.element==='anemo') {
                  result.attributes.resistanceReduction['anemo'] += 0.2;
                  result.logs.push('Traveler\'s Intertwined Winds reduces Anemo resistance by 20%');
                }
                if(context.talentProperties.element && result.attributes.element===context.talentProperties.element) {
                  result.attributes.resistanceReduction[result.attributes.element] += 0.2;
                  result.logs.push('Traveler\'s Intertwined Winds reduces '+result.attributes.element+' resistance by 20%');
                }
                return result;
              }
            }));
          }
          return result;
        }
      }
    ]
  });
};

export default TravelerAnemo;