import React, {useState, useEffect, Fragment} from 'react';
import {Auth} from 'aws-amplify';
import { Button, Label } from 'semantic-ui-react';

var Logout = (props) => {
  
  const [user, setUser] = useState();
  
  useEffect(() => {
    Auth.currentUserPoolUser().then((user) => {
      let sess = Auth.userSession(user);
      setUser(user);
    });
  }, []);
  
  async function logout() {
    console.log(`Logging out`);
    try {
      await Auth.signOut();
      console.log("Logged out"); 
    } catch (error) {
      console.log(`Error logging in: ${error}`);
    }
  }
  
  return user ? <Fragment>
    <Label color="black">{user.attributes.email}</Label>
    <Button onClick={() => logout()}>Logout</Button>
  </Fragment> : '';
}

export default Logout;