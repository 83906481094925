import React, {Fragment} from 'react';
import { Header, List, Modal, Image } from 'semantic-ui-react';

export default {
  title: "Things worth noting",
  content: <Fragment>
    <Image src="/static/user-guide/dummy.png" aria-label='Target Dummy' floated="right" />
    <Header as='h1'>Things worth noting</Header>
    <p>This isn't a reimplementation of the original game, this is a simulator 
      and thus, there are a few things you need to know to understand and make 
      the most out of it</p>
    <Header as='h2'>Simulator's context</Header>
    <div>
      <p>This is a target-dummy-kind of simulator. Here are a few facts to 
        explain this context</p>
      <List bulleted>
        <List.Item>The opponent will never strike back, effects that trigger on 
          damage taken will never trigger</List.Item>
        <List.Item>You'll also never loose life, so effects that trigger 
          on your HP being lower than 100% will never trigger</List.Item>
        <List.Item>The opponent does not have life, effects that trigger on 
          your opponent being lower than 100% HP will also never 
          trigger</List.Item>
        <List.Item>There is no movement, effects that depend on this will not 
          trigger. It will also make the composition slightly unrealistic as
          you would usualy waste time moving around and dodging opponent's 
          attacks</List.Item>
      </List>
    </div>
    <Header as='h2'>Mechanics that are not yet implemented</Header>
    <List bulleted>
      <List.Item><p><b>Energy</b> </p>
        You can, however unrealistic as it may sound, use Burst abilities 
        on cooldown. This also means that effects that trigger on receiving
        energy particles and orbs will not trigger for the moment</List.Item>
      <List.Item><p><b>Healing</b> </p>
        Even though healing is very useful and will most likely be included
        at some point to play test with healing compositions and get the best 
        healing build for your team, the game in its current state focuses
        much more on dishing out damage and dodging hits than to sustain
        a fight for a long period of time relying on healing and 
        survivability</List.Item>
    </List>
    <Header as='h2'>Adaptations from game to simulator</Header>
    <List bulleted>
      <List.Item><p style={{margin:"0"}}><b>Critical hits</b></p>
        Crits have been normalized in order to compute a stable DPS value.<br/>
        It can be summarized like this : 
        <pre>[average damage] = [normal damage] * (1 + [crit rate] * [crit dmg])</pre>
      </List.Item>
      <List.Item><p><b>Trigger chances</b></p>
        Trigger chances on the other hand cannot be averaged. They have been 
        implemented so that they each trigger at the same moment while also 
        accounting for their number of trigger per 100 chance
      </List.Item>
    </List>
  </Fragment>
};