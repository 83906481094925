import Scaling from '../../Scaling.js';
import BaseWeapon from '../base.js';

const RecurveBow = (props) => {
  
  return BaseWeapon({
    ...props,
    type: 'bow',
    name: 'Recurve Bow',
    lvl1Atk: 38,
    lvl1Sub: 0.102,
    subType: 'hpPct',
    PersistentResolve(result, context) {
      /**
       * effect: 'Defeating an opponent restores [8%] HP.'
       */
      if(this.IsSelf(result)) {
      }
      return result;
    }
  });
};

export default RecurveBow;