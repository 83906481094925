import {BaseSet} from './base.js';
import Tools from '../Tools.js';

const GlacierAndSnowfield = (props) => {
  
  return BaseSet({
    ...props,
    name: 'Glacier and Snowfield',
    two: {
      attributes: {
        damageBonus: {
          cryo: 0.15
        }
      },
      PersistentResolve(result, context) {
        return result;
      }
    },
    four: {
      PersistentResolve(result, context) {
        if(!this.IsSelf(result)) return result;
        if(result.type==="Reaction") {
          result.attributes.reactionBonus.superconduct += 0.5;
          result.attributes.reactionBonus.melt += 0.15;
          result.logs.push(this.name+" 4pc increases superconduct by 50% and melt by 15%");
        }
        return result;
      },
      PersistentFinalize(result) {
        if(!this.IsSelf(result)) return result;
        if(result.type==="Burst") {
          result.effects.push(Tools.CreateSideEffect(result, {
            description: this.name+' 4pc',
            entity: 'Buffs',
            duration: 10,
            relativeTime: 0,
            type: 'Buff',
            PersistentResolve(result) {
              result.attributes.damageBonus.cryo += 0.25;
              result.logs.push(this.name+" 4pc increases Cryo DMG by 25%")
              return result;
            }
          }));
        }
        return result;
      }
    }
  });
};

export default GlacierAndSnowfield;