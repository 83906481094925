import Scaling from '../../Scaling.js';
import BaseWeapon from '../base.js';

const MappaMare = (props) => {
  
  return BaseWeapon({
    ...props,
    type: 'catalyst',
    name: 'Mappa Mare',
    lvl1Atk: 44,
    lvl1Sub: 24,
    subType: 'elemMastery',
    PersistentResolve(result, context) {
      /**
       * effect: 'Triggering an Elemental reaction grants a [8%] Elemental DMG 
       * Bonus for 10s. Max 2 stacks.'
       */
      if(this.IsSelf(result)) {
      }
      return result;
    }
  });
};

export default MappaMare;