import {BaseSet} from './base.js';
import Tools from '../Tools.js';

const ArchaicPetra = (props) => {
  
  return BaseSet({
    ...props,
    name: 'Archaic Petra',
    two: {
      attributes: {
        damageBonus: {
          geo: 0.15
        }
      },
      PersistentResolve(result, context) {
        return result;
      }
    },
    four: {
      PersistentResolve(result, context) {
        if(!this.IsSelf(result)) return result;
        if(result.type==="Reaction" && result.description==="Crystallize Shield") {
          result.effects.push(Tools.CreateSideEffect(result, {
            description: 'Archaic Petra 4pc',
            entity: 'Buffs',
            duration: 10,
            relativeTime: (r => -r.precedingResult.duration),
            type: 'Buff',
            talentProperties: {
              element: result.attributes.element
            },
            PersistentPrepare(result, context) {
              if(result!==context && result.type==='Buff' && result.description==='Archaic Petra 4pc') {
                context.logs.push('Archaic Petra 4pc buff interrupted by another Archaic Petra 4pc buff');
                context.setDuration(result.time - context.time);
              }
              return result;
            },
            PersistentResolve(result, context) {
              if(result.attributes.skillPct>0 && result.attributes.element===context.talentProperties.element) {
                result.logs.push('Archaic Petra 4pc increases '+context.talentProperties.element+' DMG by 35%');
                result.attributes.damageBonus[context.talentProperties.element] += 0.35;
              }
              return result;
            }
          }))
        }
        return result;
      }
    }
  });
};

export default ArchaicPetra;