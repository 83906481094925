import Scaling from '../../Scaling.js';
import BaseWeapon from '../base.js';

const SolarPearl = (props) => {
  
  return BaseWeapon({
    ...props,
    type: 'catalyst',
    name: 'Solar Pearl',
    lvl1Atk: 42,
    lvl1Sub: 0.06,
    subType: 'critRate',
    PersistentResolve(result, context) {
      /**
       * effect: 'Normal Attack hits increase Elemental Skill and 
       * Elemental Burst DMG by [20%] for 6s. Likewise, Elemental Skill or 
       * Elemental Burst hits increase Normal Attack DMG by [20%] for 6s.'
       */
      if(this.IsSelf(result)) {
      }
      return result;
    }
  });
};

export default SolarPearl;