import React, {useState, useEffect, Fragment} from 'react';
import { Hub, Auth} from 'aws-amplify';
import { Modal, Dimmer, Label, Icon, Header, Image, Segment, Popup, Dropdown } from 'semantic-ui-react';
import Login from './login.js';
import Logout from './logout.js';

var Authentication = (props) => {
  
   const [lastEvent, setLastEvent] = useState('');
   const [user, setUser] = useState();
  
  const listener = (data) => {
    switch (data.payload.event) {
      case 'signIn':
        setLastEvent('user signed in');
        setUser(data.payload.data);
        break;
      case 'signUp':
        setLastEvent('user signed up');
        setUser(data.payload.data);
        break;
      case 'signOut':
        setLastEvent('user signed out');
        setUser(null);
        break;
      case 'signIn_failure':
        setLastEvent('user sign in failed');
        break;
      case 'tokenRefresh':
        setLastEvent('token refresh succeeded');
        break;
      case 'tokenRefresh_failure':
        setLastEvent('token refresh failed');
        break;
      case 'configured':
        setLastEvent('the Auth module is configured');
    }
  }

  useEffect(() => {
    Hub.listen('auth', listener);
    Auth.currentUserPoolUser().then((user) => {
      let sess = Auth.userSession(user);
      setUser(user);
    });
  }, []);
  
  return <Segment key={lastEvent} compact basic {...props} style={{padding:0, margin: "0.4em 0", ...props.style}}>
    {!user ? <Login /> : <Logout /> }
  </Segment>;
}

export default Authentication;