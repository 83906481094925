import React, {Fragment} from 'react';
import { Grid, Popup, Header, Card, Icon, Divider } from 'semantic-ui-react';

const CommunityPage = () => {
    return <Fragment>
    <Header as="h1">Community Resources</Header>
    <Divider horizontal >Official resources</Divider>
    <Card.Group centered>
      <Card href="https://genshin.mihoyo.com/en/home">
        <Card.Content>
          <Card.Header>Official Website <Icon name="external" /></Card.Header>
          <Card.Description>
            See also :{" "}
            <a href="https://twitter.com/genshinimpact"><Icon style={{color:"#1DA1F2"}} name="twitter" /></a>
            <a href="https://www.facebook.com/Genshinimpact/"><Icon style={{color:"#4267B2"}} name="facebook" /></a>
            <a href="https://www.youtube.com/c/GenshinImpact"><Icon style={{color:"#FF0000"}} name="youtube" /></a>
            <a href="https://www.reddit.com/r/Genshin_Impact"><Icon style={{color:"#FF5700"}} name="reddit" /></a>
            <a href="https://www.twitch.tv/directory/game/Genshin"><Icon style={{color:"#6441a5"}} name="twitch" /></a>
          </Card.Description>
        </Card.Content>
      </Card>
    </Card.Group>
    <Divider horizontal >Unofficial resources</Divider>
    <Card.Group centered>
      <Card href="https://genshin-impact.fandom.com/">
        <Card.Content>
          <Card.Header>Fandom <Icon name="external" /></Card.Header>
          <Card.Description>Unofficial Wiki</Card.Description>
        </Card.Content>
      </Card>
      <Card href="https://genshin-impact-map.appsample.com/">
        <Card.Content>
          <Card.Header>Interactive World Map <Icon name="external" /></Card.Header>
          <Card.Description></Card.Description>
        </Card.Content>
      </Card>
      <Card href="https://genshin-center.com/">
        <Card.Content>
          <Card.Header>Genshin Center <Icon name="external" /></Card.Header>
          <Card.Description></Card.Description>
        </Card.Content>
      </Card>
      {/*<Card href="https://docs.google.com/spreadsheets/d/1s0G2SDIOp9WB7NRn3ABoRgsS_Qjid46g1-BswFrbTFY/edit?usp=sharing">
        <Card.Content>
          <Card.Header>Team Building Resources</Card.Header>
          <Card.Description></Card.Description>
        </Card.Content>
      </Card>*/}
      <Card href="https://genshin.gg/">
        <Card.Content>
          <Card.Header>Genshin.GG <Icon name="external" /></Card.Header>
          <Card.Description></Card.Description>
        </Card.Content>
      </Card>
      <Card href="https://genshin.honeyhunterworld.com/">
        <Card.Content>
          <Card.Header>Honey Hunter World <Icon name="external" /></Card.Header>
          <Card.Description></Card.Description>
        </Card.Content>
      </Card>
      <Card href="https://www.gensh.in/">
        <Card.Content>
          <Card.Header>Gensh.IN <Icon name="external" /></Card.Header>
          <Card.Description></Card.Description>
        </Card.Content>
      </Card>
    </Card.Group>
  </Fragment>;
}

export default CommunityPage;