import React, {Fragment} from 'react';
import { Header, List, Modal, Image } from 'semantic-ui-react';

export default {
  title: "What is the Simulator?",
  content: <Fragment>
    <Header as='h1'>Welcome to the Genshin Impact Simulator</Header>
    <p>The Simulator is a web application for Genshin Impact players to put 
      their theorycraft into practice before investing in-game resources to 
      build their dream-team. It is also meant as an educational tool to
      familiarize yourself with the game's mechanics.</p>
    <p></p>
    <Header as='h2'>Simulator features</Header>
    <Modal trigger={<Image src="/static/user-guide/general-screen.png" aria-label='General Screen' size="large" />}>
      <Modal.Content>
        <Image src="/static/user-guide/general-screen.png" aria-label='General Screen' />
      </Modal.Content>
    </Modal>
    <Header as='h4'>Character features</Header>
    <List bulleted>
      <List.Item>Select an available character*; its level, ascension, and talent levels</List.Item>
      <List.Item>Select an available weapon*; its level and refinement</List.Item>
      <List.Item>Select artifact set bonuses and artifact stats; from a preset or individually to match a real character</List.Item>
      <List.Item>All abilities, stats and effects are then inferred from the above in the composition</List.Item>
    </List>
    <Header as='h4'>Monster features</Header>
    <List bulleted>
      <List.Item>Select a monster and its level</List.Item>
      <List.Item>Corresponding resistances are applied in the composition</List.Item>
    </List>
    <Header as='h4'>Composition features</Header>
    <List bulleted>
      <List.Item>Choose which ability to use on any of the character in the team in any order</List.Item>
      <List.Item>Calculate the damage values and effects</List.Item>
      <List.Item>Describes the effects being applied on each item of the composition</List.Item>
    </List>
    <Header as='h4'>History Tab features</Header>
    <List bulleted>
      <List.Item>Undo and Redo items from the history tab</List.Item>
      <List.Item>Reset the history, which allows to start a new composition without loosing progress</List.Item>
      <List.Item>Rewrite history by Undoing and doing something new</List.Item>
    </List>
  </Fragment>
};