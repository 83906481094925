import Scaling from '../../Scaling.js';
import BaseWeapon from '../base.js';

const LithicBlade = (props) => {
  
  return BaseWeapon({
    ...props,
    type: 'claymore',
    name: 'Lithic Blade',
    lvl1Atk: 41,
    lvl1Sub: 0.16,
    subType: 'critDmg',
    PersistentResolve(result, context) {
      /**
       * effect: 'For every character in the party who hails from Liyue, the 
       * character who equips this weapon gains [6+1%] ATK increase and 
       * [2+1%] CRIT Rate increase.'
       */
      if(this.IsSelf(result)) {
      }
      return result;
    }
  });
};

export default LithicBlade;