import Scaling from '../../Scaling.js';
import BaseWeapon from '../base.js';

const SharpshootersOath = (props) => {
  
  return BaseWeapon({
    ...props,
    type: 'bow',
    name: 'Sharpshooter\'s Oath',
    lvl1Atk: 39,
    lvl1Sub: 0.102,
    subType: 'critDmg',
    PersistentResolve(result, context) {
      /**
       * effect: 'Increases DMG against weak spots by [24%].'
       */
      if(this.IsSelf(result)) {
        //console.log('Sharpshootersoath resolving...');
        if(result.type==="ChargeAtk" || result.type==="ChargeAtkHold") {
          result.logs.push('Weak Spot Attacks with '+this.name+' increased by '+(Scaling.ScaleWeaponPassive(0.24, this.refinement)*100)+'%');
          result.attributes.damageBonus.all += Scaling.ScaleWeaponPassive(0.24, this.refinement);
        }
      }
      return result;
    }
  });
};

export default SharpshootersOath;