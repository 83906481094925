import React, {useState, useEffect} from 'react';
import { Grid, Segment } from 'semantic-ui-react'

const StatGrid = (props) => {
  const [character, setCharacter] = useState(props.character);
  const [attr, setAttr] = useState(props.attr);
  const [mode, setMode] = useState(props.mode);
  
  useEffect(() => {
    setAttr(props.attr);
    setMode(props.mode);
    setCharacter(props.character);
  }, [props])
  
  return ((mode==="total" && character.attributes && attr) || (mode!=="total" && attr))?
  <Segment.Group horizontal style={{border:'none', margin:0, boxShadow:'none'}}>
    <Segment basic style={{padding:0,width:"50%"}}>
      <Grid divided="vertically" columns={2} padded style={{margin:0}}>
        {
          [
            {header: 'Max HP',            content: mode==="total"?character.attributes.maxHP+" +"+Math.round(attr.maxHP*(1+attr.hpPct)-character.attributes.maxHP):"+"+attr.maxHP+" +"+(Math.round(attr.hpPct*10000)/100)+"%"},
            {header: 'ATK',               content: mode==="total"?attr.baseAtk+" +"+Math.round((attr.baseAtk*(1+attr.atkPct)+attr.flatAtk)-(attr.baseAtk)):"+"+attr.flatAtk+" +"+(Math.round(attr.atkPct*10000)/100)+"%"},
            {header: 'DEF',               content: mode==="total"?character.attributes.defense+" +"+Math.round(attr.defense*(1+attr.defensePct)-character.attributes.defense):"+"+attr.defense+" +"+(Math.round(attr.defensePct*10000)/100)+"%"},
            {header: 'Elemental Mastery', content: mode==="total"?attr.elemMastery:'+'+attr.elemMastery},
            {header: 'Vaporize & Melt',   content: '+'+(Math.round(((0.189266831 * attr.elemMastery * Math.exp(-0.000505 * attr.elemMastery)) / 100)*10000)/100)+'%'},
            {header: 'Overloaded, Superconduct, Electro-Charged, Shattered & Swirl', content:'+'+(Math.round(((0.453633528 * attr.elemMastery * Math.exp(-0.000505 * attr.elemMastery)) / 100)*10000)/100)+'%'}
          ].map(c => {
            if(c.header) {
              return <Grid.Row style={{padding:0}}>
                <Grid.Column width={9} style={{margin:0,paddingRight:0}}>{c.header}</Grid.Column>
                <Grid.Column width={7} style={{margin:0,paddingLeft:0}}>{c.content}</Grid.Column>
              </Grid.Row>;
            } else {
              return <Grid.Row style={{padding:0}}>
                <Grid.Column width={16} style={{margin:0}}>{c.content}</Grid.Column>
              </Grid.Row>;
            }
          })
        }
      </Grid>
    </Segment>
    <Segment basic style={{padding:0,width:"50%"}}>
      <Grid divided="vertically" columns={2} padded style={{margin:0}}>
        {
          [
            {header: 'CRIT Rate', content: (mode!=="total"?"+":"")+(Math.round(attr.critRate*10000)/100)+'%'},
            {header: 'CRIT DMG', content: (mode!=="total"?"+":"")+(Math.round(attr.critDmg*10000)/100)+'%'},
            {header: 'Energy Recharge', content: mode==="total"?(Math.round((1+attr.energyRecharge)*10000)/100)+'%':"+"+(Math.round((attr.energyRecharge)*10000)/100)+'%'},
            {header: 'Pyro DMG Bonus', content: (mode!=="total"?"+":"")+(Math.round(attr.damageBonus.pyro*10000)/100)+'%'},
            {header: 'Hydro DMG Bonus', content: (mode!=="total"?"+":"")+(Math.round(attr.damageBonus.hydro*10000)/100)+'%'},
            {header: 'Dendro DMG Bonus', content: (mode!=="total"?"+":"")+(Math.round(attr.damageBonus.dendro*10000)/100)+'%'},
            {header: 'Electro DMG Bonus', content: (mode!=="total"?"+":"")+(Math.round(attr.damageBonus.electro*10000)/100)+'%'},
            {header: 'Anemo DMG Bonus', content: (mode!=="total"?"+":"")+(Math.round(attr.damageBonus.anemo*10000)/100)+'%'},
            {header: 'Cryo DMG Bonus', content: (mode!=="total"?"+":"")+(Math.round(attr.damageBonus.cryo*10000)/100)+'%'},
            {header: 'Geo DMG Bonus', content: (mode!=="total"?"+":"")+(Math.round(attr.damageBonus.geo*10000)/100)+'%'},
            {header: 'Physical DMG Bonus', content: (mode!=="total"?"+":"")+(Math.round(attr.damageBonus.physical*10000)/100)+'%'},
          ].map(c => {
            if(c.header) {
              return <Grid.Row style={{padding:0}}>
                <Grid.Column width={9} style={{margin:0,paddingRight:0}}>{c.header}</Grid.Column>
                <Grid.Column width={7} style={{margin:0,paddingLeft:0}}>{c.content}</Grid.Column>
              </Grid.Row>;
            } else {
              return <Grid.Row style={{padding:0}}>
                <Grid.Column width={16} style={{margin:0}}>{c.content}</Grid.Column>
              </Grid.Row>;
            }
          })
        }
      </Grid>
    </Segment>
  </Segment.Group>:'';
};

export default StatGrid;