import Scaling from '../../Scaling.js';
import BaseWeapon from '../base.js';

const TwinNephrite = (props) => {
  
  return BaseWeapon({
    ...props,
    type: 'catalyst',
    name: 'Twin Nephrite',
    lvl1Atk: 40,
    lvl1Sub: 0.034,
    subType: 'critRate',
    PersistentResolve(result, context) {
      /**
       * effect: 'Defeating an enemy increases Movement SPD and ATK by [12+2%] 
       * for 15s.'
       */
      if(this.IsSelf(result)) {
      }
      return result;
    }
  });
};

export default TwinNephrite;