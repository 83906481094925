
import * as Genshin from './';
import Simulator from './Simulator.js';
import Tools from './Tools.js';

const API = {
  /**
   * @cfg =  {
      name, 
      level, 
      constellation, 
      ascension, 
      talents: {
        attack,
        skill
        burst
      }
      weapon:{
        name, 
        level, 
        ascension, 
        refinement
      }, 
      artifacts:{
        set:[{
          name, 
          pcs
        }], 
        stats:[{
          hp, 
          hppct, 
          atk, 
          atkpct, 
          def, 
          defpct, 
          cr, 
          cd, 
          em, 
          er, 
          pdmg, 
          edmg,
          hb
        }]
      }
    }
   * @returns = {
     attributes
    }
   */
  SelectCharacter(props) {
    props = JSON.parse(props);
    return JSON.stringify(Tools.BuildCharacter(props));
  },
  /**
   * @cfg = {
      team:[
        {
          name, 
          level, 
          constellation, 
          ascension, 
          talentLevel: {
            attack,
            skill
            burst
          }
          weapon:{
            name, 
            level, 
            ascension, 
            refinement
          }, 
          artifacts:{
            set:[{
              name, 
              pcs
            }], 
            stats:[{
              hp, 
              hppct, 
              atk, 
              atkpct, 
              def, 
              defpct, 
              cr, 
              cd, 
              em, 
              er, 
              pdmg, 
              edmg,
              hb
            }]
          }
        }
      ],
      monster:[{
        name,
        level
      }],
      history:[
        {
          char,
          talent
        }
      ]
    }
    * @return = [{
      
    }]
  **/
  RunSimulator(cfg) {
    cfg = JSON.parse(cfg);
    let team = [];
    let characters = {};
    cfg.team.forEach(c => {
      let char = Tools.BuildCharacter(c);
      if(char) {
        characters[c.name] = char;
        team.push(characters[c.name]);
      }
    });
    let sim = Simulator({
      team: team,
      monsters: cfg.monsters
        .map(m => Genshin.Monsters[m.name.replaceAll(/[^a-z0-9]/gi, '')]({level: m.level})),
      history: cfg.history.entries
        .filter(e => characters[e.entity] ? true : false)
        .map(e => Genshin.HistoryEntry(characters[e.entity]["Use"+e.talent](e.impl)))
    });
    sim.Run();
    
    return JSON.stringify({
      talents:sim.timetrack.talentEffects.filter(t => t.type!=="start").map(t => t.serialize()),
      effects:sim.timetrack.effects.map(t => t.serialize()),
      statuses:sim.timetrack.statuses.map(t => t.serialize())
    });
  }
};

export default API;