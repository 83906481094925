import Scaling from '../../Scaling.js';
import BaseWeapon from '../base.js';

const Halberd = (props) => {
  
  return BaseWeapon({
    ...props,
    type: 'polearm',
    name: 'Halberd',
    lvl1Atk: 40,
    lvl1Sub: 0.051,
    subType: 'atkPct',
    PersistentResolve(result, context) {
      /**
       * effect: 'Normal Attacks deal an additional [160%] DMG. 
       * Can only occur once every 10s.'
       */
      if(this.IsSelf(result)) {
      }
      return result;
    }
  });
};

export default Halberd;