import {ZeroAttributes} from '../Attributes.js';

const BaseSet = (props) => {
  let attributes = ZeroAttributes();
  if(props.pcs>=2 && props.two.attributes) {
    attributes.Merge(props.two.attributes);
  }
  if(props.pcs>=4 && props.four.attributes) {
    attributes.Merge(props.four.attributes);
  }
  return {
    name: props.name,
    setPieces: props.pcs,
    attributes: attributes,
    twoPersistentPrepare: props.pcs>=2 && props.two.PersistentPrepare ? props.two.PersistentPrepare : (result => result),
    fourPersistentPrepare: props.pcs>=4 && props.four.PersistentPrepare ? props.four.PersistentPrepare : (result => result),
    twoPersistentResolve: props.pcs>=2 && props.two.PersistentResolve ? props.two.PersistentResolve : (result => result),
    fourPersistentResolve: props.pcs>=4 && props.four.PersistentResolve ? props.four.PersistentResolve : (result => result),
    twoPersistentFinalize: props.pcs>=2 && props.two.PersistentFinalize ? props.two.PersistentFinalize : (result => result),
    fourPersistentFinalize: props.pcs>=4 && props.four.PersistentFinalize ? props.four.PersistentFinalize : (result => result),
    PersistentPrepare(result, context) {
      result = this.twoPersistentPrepare(result, context);
      result = this.fourPersistentPrepare(result, context);
      return result;
    },
    PersistentResolve(result, context) {
      result = this.twoPersistentResolve(result);
      result = this.fourPersistentResolve(result, context);
      return result;
    },
    PersistentFinalize(result, context) {
      result = this.twoPersistentFinalize(result, context);
      result = this.fourPersistentFinalize(result, context);
      return result;
    },
    IsSelf(result) {
      return result.initiator.name === this.wielder;
    }
  };
};

export { BaseSet };