import Scaling from '../../Scaling.js';
import BaseWeapon from '../base.js';
import Tools from '../../Tools.js';

const TheWidsith = (props) => {
  
  return BaseWeapon({
    ...props,
    type: 'catalyst',
    name: 'The Widsith',
    lvl1Atk: 42,
    lvl1Sub: 0.12,
    subType: 'critDmg',
    track: {lastTrigger:-999, nextToTrigger:1},
    PersistentResolve(result, context) {
      /**
       * effect: 'When a character takes the field, they will gain a random 
       * theme song for 10s. This can only occur once every 30s. 
       * Recitative: ATK is increased by [60%]. 
       * Aria: Increases all Elemental DMG by [48%]. 
       * Interlude: Elemental Mastery is increased by [240].'
       */
      if(this.IsSelf(result) && result.type==="Swap") {
        let possibleEffects = [
          (result) => {
            result.effects.push(Tools.CreateSideEffect(result, {
              description: 'The Widsith - Recitative',
              entity: 'Buffs',
              type: 'Buff',
              duration: 10,
              relativeTime: (r => -r.precedingResult.duration),
              talentProperties: {
                atkPct: Scaling.ScaleWeaponPassive(0.60, this.refinement)
              },
              PersistentResolve(result, context) {
                if(context.IsSelf(result) && result.attributes.skillPct>0 && result.type!=='Reaction') {
                  result.logs.push('The Widsith - Recitative increases ATK% by '+Math.round(context.talentProperties.atkPct*10000)/100+'%');
                  result.attributes.atkPct += context.talentProperties.atkPct;
                }
                return result;
              }
            }));
            return result;
          },
          (result) => {
            result.effects.push(Tools.CreateSideEffect(result, {
              description: 'The Widsith - Aria',
              entity: 'Buffs',
              type: 'Buff',
              duration: 10,
              relativeTime: (r => -r.precedingResult.duration),
              talentProperties: {
                dmgBonus: Scaling.ScaleWeaponPassive(0.48, this.refinement)
              },
              PersistentResolve(result, context) {
                if(context.IsSelf(result) && result.attributes.skillPct>0 && result.type!=='Reaction') {
                  result.logs.push('The Widsith - Aria increases elemental damage by '+Math.round(context.talentProperties.dmgBonus*10000)/100+'%');
                  result.attributes.damageBonus.all += context.talentProperties.dmgBonus;
                }
                return result;
              }
            }));
            return result;
          },
          (result) => {
            result.effects.push(Tools.CreateSideEffect(result, {
              description: 'The Widsith - Interlude',
              entity: 'Buffs',
              type: 'Buff',
              duration: 10,
              relativeTime: (r => -r.precedingResult.duration),
              talentProperties: {
                elemMastery: Scaling.ScaleWeaponPassive(240, this.refinement)
              },
              PersistentResolve(result, context) {
                if(context.IsSelf(result) && result.attributes.skillPct>0) {
                  result.logs.push('The Widsith - Interlude increases elemental mastery by '+context.talentProperties.elemMastery);
                  result.attributes.elemMastery += context.talentProperties.elemMastery;
                }
                return result;
              }
            }));
            return result;
          }
        ];
        
        if(result.time - this.track.lastTrigger > 30) {
          result = possibleEffects[this.track.nextToTrigger-1](result);
          this.track.lastTrigger = result.time;
          if(this.track.nextToTrigger>=3) {
            this.track.nextToTrigger=1;
          } else {
            this.track.nextToTrigger++;
          }
        }
        return result;
      }
      return result;
    }
  });
};

export default TheWidsith;