import React, {Fragment, useState, useEffect} from 'react';
import ReactGA from 'react-ga';
import { Confirm, Divider, Label, Grid, Icon, Header, Menu, Image, Segment, Popup, Modal, Dropdown, Rating, Input, Button, Form, Tab } from 'semantic-ui-react';
import CharacterSelect from './character-select.js';
import WeaponSelect from './weapon-select.js';
import ArtifactEdit from './artifact-edit.js';
import ArtifactStatsPreset from './preset/artifact-stats.js';
import StatGrid from './stat-grid.js';
import Artifacts from './db/artifacts.js';
import Weapons from './db/weapons.js';
import './character-edit.css';

const CharacterEdit = (props) => {
  const [open, setOpen] = useState(false);
  const [trigger] = useState(props.children);
  
  const [builds, setBuilds] = useState(props.builds);
  const [buildName, setBuildName] = useState(props.character.build || 'default');
  const [showConfirmOverwrite, setShowConfirmOverwrite] = useState(false);
  
  const [defaultCharacter, setDefaultCharacter] = useState(props.character);
  const [character, setCharacter] = useState(props.character.name.replaceAll(/[^0-9a-z]/gi, ''));
  const [equip, setEquip] = useState(props.character.equip);
  const [ascension, setAscension] = useState(props.character.ascension || 0);
  const [level, setLevel] = useState(props.character.level || 1);
  const [constellation, setConstellation] = useState(props.character.constellation || 0);
  const [attackLevel, setAttackLevel] = useState(props.character.attack.level || 1);
  const [skillLevel, setSkillLevel] = useState(props.character.skill.level || 1);
  const [burstLevel, setBurstLevel] = useState(props.character.burst.level || 1);
  
  const [artifactTimePresetSelected, setArtifactTimePresetSelected] = useState();
  const [artifactGobletPresetSelected, setArtifactGobletPresetSelected] = useState();
  const [artifactHeadPresetSelected, setArtifactHeadPresetSelected] = useState();
  const [artifactSubPresetSelected, setArtifactSubPresetSelected] = useState();
  const [artifactStatsApplied, setArtifactStatsApplied] = useState(props.character.artifacts.stats || {flower:{}, feather:{}, time:{}, goblet:{}, head:{}});
  const [artifactAttr, setArtifactAttr] = useState({});
  
  const [weapon, setWeapon] = useState({
    name: props.character.weapon.name || '',
    level: props.character.weapon.level || 1,
    ascension: props.character.weapon.ascension || 0,
    refinement: props.character.weapon.refinement || 1
  });
  const [artifactBonus1, setArtifactBonus1] = useState(props.character.artifacts.sets[0]?props.character.artifacts.sets[0].name:'No Set');
  const [artifactBonus2, setArtifactBonus2] = useState(props.character.artifacts.sets[1]?props.character.artifacts.sets[1].name:'No other set');
  
  
  useEffect(() => {
    setDefaultCharacter(props.character);
    setBuildName(props.character.build);
    setBuilds(props.builds);
    setCharacter(props.character.name.replaceAll(/[^0-9a-z]/gi, ''));
    setEquip(props.character.equip);
    setAscension(props.character.ascension || 0);
    setLevel(props.character.level || 1);
    setConstellation(props.character.constellation || 0);
    setAttackLevel(props.character.attack.level || 1);
    setSkillLevel(props.character.skill.level || 1);
    setBurstLevel(props.character.burst.level || 1);
    
    if(props.character.artifacts) {
      setArtifactBonus1(props.character.artifacts.sets[0]?props.character.artifacts.sets[0].name:'No Set');
      setArtifactBonus2(props.character.artifacts.sets[1]?props.character.artifacts.sets[1].name:'No other set');
      setArtifactStatsApplied(props.character.artifacts.stats);
      setArtifactAttr(ArtifactConfigToAttributes(props.character.artifacts.stats));
    }
    
    setWeapon({
      name: props.character.weapon.name || 'None',
      level: props.character.weapon.level || 1,
      ascension: props.character.weapon.ascension || 0,
      refinement: props.character.weapon.refinement || 1
    });
  }, [props]);
  
  useEffect(() => {
      setArtifactAttr(ArtifactConfigToAttributes(artifactStatsApplied));
  }, [artifactStatsApplied]);
  
  /*useEffect(() => {
    let stats = {
      flatAtk: 0,
      atkPct: 0,
      critRate: 0,
      critDmg: 0,
      damageBonus: {
        physical:0,
        hydro:0,
        electro:0,
        pyro:0,
        cryo:0,
        anemo:0,
        geo:0,
        dendro:0
      },
      elemMastery: 0,
      defense: 0,
      defensePct: 0,
      energyRecharge: 0,
      maxHP: 0,
      hpPct: 0
    };
    ['flower','feather','time','goblet','head'].forEach(t => {
      [
        {dest:'flatAtk',src:'atk'},
        {dest:'atkPct',src:'atkpct'},
        {dest:'critRate',src:'cr'},
        {dest:'critDmg',src:'cd'},
        {dest:'damageBonus.physical',src:'pdmg'},
        {dest:'damageBonus.hydro',src:'edmg'},
        {dest:'damageBonus.electro',src:'edmg'},
        {dest:'damageBonus.pyro',src:'edmg'},
        {dest:'damageBonus.cryo',src:'edmg'},
        {dest:'damageBonus.anemo',src:'edmg'},
        {dest:'damageBonus.geo',src:'edmg'},
        {dest:'damageBonus.dendro',src:'edmg'},
        {dest:'damageBonus.elemMastery',src:'em'},
        {dest:'damageBonus.defense',src:'def'},
        {dest:'damageBonus.defensePct',src:'defpct'},
        {dest:'damageBonus.energyRecharge',src:'er'},
        {dest:'damageBonus.maxHP',src:'hp'},
        {dest:'damageBonus.hpPct',src:'hppct'}
      ].forEach(s => {
        let dest = s.dest.split('.');
        if(dest.length===2) {
          stats[dest[0]][dest[1]] += artifactAttr[t][s.src]?artifactAttr[t][s.src]:0;
        } else {
          stats[dest[0]] += artifactAttr[t][s.src]?artifactAttr[t][s.src]:0;
        }
      });
    });
    setArtifacts(a => {
      return {...a, attributes:stats};
    });
  }, [artifactAttr]);*/
  
  function ArtifactConfigToAttributes(cfg) {
    let stats = {
      flatAtk: 0,
      atkPct: 0,
      critRate: 0,
      critDmg: 0,
      damageBonus: {
        physical:0,
        hydro:0,
        electro:0,
        pyro:0,
        cryo:0,
        anemo:0,
        geo:0,
        dendro:0
      },
      elemMastery: 0,
      defense: 0,
      defensePct: 0,
      energyRecharge: 0,
      maxHP: 0,
      hpPct: 0
    };
    ['flower','feather','time','goblet','head'].forEach(t => {
      [
        {dest:'flatAtk',src:'atk'},
        {dest:'atkPct',src:'atkpct'},
        {dest:'critRate',src:'cr'},
        {dest:'critDmg',src:'cd'},
        {dest:'damageBonus.physical',src:'pdmg'},
        {dest:'damageBonus.hydro',src:'edmg'},
        {dest:'damageBonus.electro',src:'edmg'},
        {dest:'damageBonus.pyro',src:'edmg'},
        {dest:'damageBonus.cryo',src:'edmg'},
        {dest:'damageBonus.anemo',src:'edmg'},
        {dest:'damageBonus.geo',src:'edmg'},
        {dest:'damageBonus.dendro',src:'edmg'},
        {dest:'elemMastery',src:'em'},
        {dest:'defense',src:'def'},
        {dest:'defensePct',src:'defpct'},
        {dest:'energyRecharge',src:'er'},
        {dest:'maxHP',src:'hp'},
        {dest:'hpPct',src:'hppct'}
      ].forEach(s => {
        let dest = s.dest.split('.');
        if(!cfg[t]['bonus'] || (s.src!=="edmg" && s.src!=="pdmg") || dest[1]===cfg[t]['bonus'].toLowerCase()) {
          if(dest.length===2) {
            stats[dest[0]][dest[1]] += cfg[t][s.src]?cfg[t][s.src]:0;
          } else {
            stats[dest[0]] += cfg[t][s.src]?cfg[t][s.src]:0;
          }
        }
      });
    });
    return stats;
  }
  
  function RestrictLevelToAscension(level, ascension) {
    switch(ascension) {
      case 0:
        if(level>20) return 20;
        break;
      case 1:
        if(level<20) return 20;
        if(level>40) return 40;
        break;
      case 2:
        if(level<40) return 40;
        if(level>50) return 50;
        break;
      case 3:
        if(level<50) return 50;
        if(level>60) return 60;
        break;
      case 4:
        if(level<60) return 60;
        if(level>70) return 70;
        break;
      case 5:
        if(level<70) return 70;
        if(level>80) return 80;
        break;
      case 6:
        if(level<80) return 80;
        if(level>90) return 90;
        break;
      default:
        return null;
    }
    return level;
  }
  function RestrictAscensionToLevel(ascension, level) {
    if(level<20) 
      return 0;
      
    if(level===20)
      if(ascension>1) return 1;
      else if(ascension<0)  return 0;
    if(level>20 && level<40)
      if(ascension<1 || ascension>1) return 1;
      
    if(level===40)
      if(ascension>2) return 2;
      else if(ascension<1) return 1;
    if(level>40 && level<50)
      if(ascension<2 || ascension>2) return 2;
      
    if(level===50)
      if(ascension>3) return 3;
      else if(ascension<2) return 2;
    if(level>50 && level<60)
      if(ascension<3 || ascension>3) return 3;
      
    if(level===60)
      if(ascension>4) return 4;
      else if(ascension<3) return 3;
    if(level>60 && level<70)
      if(ascension<4 || ascension>4) return 4;
      
    if(level===70)
      if(ascension>5) return 5;
      else if(ascension<4) return 4;
    if(level>70 && level<80)
      if(ascension<5 || ascension>5) return 5;
      
    if(level===80)
      if(ascension>6) return 6;
      else if(ascension<5) return 5;
    if(level>80 && level<=90)
      if(ascension<6 || ascension>6) return 6;
    
    return ascension;
    
  }
  function RestrictAscensionToTalent(ascension, level) {
    if(level>2) 
      if(ascension<3)
        return 3;
    if(level>4) 
      if(ascension<4)
        return 4;
    if(level>6) 
      if(ascension<5)
        return 5;
    if(level>8) 
      if(ascension<6)
        return 6;
    
    return ascension;
    
  }
  function RestrictTalentToAscension(ascension) {
    if(ascension<3)
        return 2;
    if(ascension<4)
        return 4;
    if(ascension<5)
        return 6;
    if(ascension<6)
        return 8;
    if(ascension>=6)
        return 10;
  }
  
  function AdjustWeaponEffect(text) {
    const data = [...text.matchAll(/(?<before>[^[\]]*)\[(?<value>[0-9.]+)(?<sign>[-+]?)(?<step>[0-9.]*)(?<pct>[%]?)\](?<after>[^[\]]*)/gi)].map(d => {return {matched: d[0], ...d.groups};});
    text = [];
    data.forEach((m,i) => {
      if(i===0) {
        text.push(m.before);
      }
      let v = parseFloat(m.value);
      if(m.step) {
        let step = parseFloat(m.step);
        v += (m.sign==="-"?-1:1) * (weapon.refinement-1) * step;
      } else if(m.sign==="-") {
        v /= 1 + (0.25 * (weapon.refinement-1));
      } else {
        v *= 1 + (0.25 * (weapon.refinement-1));
      }
      v = Math.round(v*10)/10;
      text.push(<b>{v}{m.pct}</b>);
      text.push(m.after);
    });
    return text;
  }
  
  function ChangeCharacterLevel(lvl) {
    let asc = RestrictAscensionToLevel(ascension, lvl);
    if(asc !== ascension) setAscension(asc);
    let tlt = RestrictTalentToAscension(asc);
    if(tlt<attackLevel) setAttackLevel(tlt);
    if(tlt<skillLevel) setSkillLevel(tlt);
    if(tlt<burstLevel) setBurstLevel(tlt);
    setLevel(lvl);
  }
  
  function ChangeCharacterAscension(asc) {
    let lvl = RestrictLevelToAscension(level, asc);
    if(level !== lvl) setLevel(lvl);
    let tlt = RestrictTalentToAscension(asc);
    if(tlt<attackLevel) setAttackLevel(tlt);
    if(tlt<skillLevel) setSkillLevel(tlt);
    if(tlt<burstLevel) setBurstLevel(tlt);
    setAscension(asc);
  }
  
  function ChangeCharacterTalentLevel(alvl, slvl, blvl) {
    let asc = RestrictAscensionToTalent(ascension, Math.max(alvl, slvl, blvl));
    if(asc !== ascension) setAscension(asc);
    let lvl = RestrictLevelToAscension(level, asc);
    if(lvl !== level) setLevel(lvl);
    if(alvl !== attackLevel) setAttackLevel(alvl);
    if(slvl !== skillLevel) setSkillLevel(slvl);
    if(blvl !== burstLevel) setBurstLevel(blvl);
  }
  
  const artifactItemRenderer = (name, rarity, image) => {
    return <Header>
      {
        image===null
        ? <img alt="None" src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=' aria-label='None' className="ui image" />
        : <Image src={image} aria-label={name} />
      }
      <Header.Content>
        {name}
        <Header.Subheader className='rarity'>{[...Array(rarity)].map(i => <Icon name='star' />)}</Header.Subheader>
      </Header.Content>
    </Header>;
  };

  function Save() {
    let charConfig = {
      name:character,
      level:level,
      ascension:ascension,
      constellation:constellation,
      attack: {level:attackLevel},
      skill: {level:skillLevel},
      burst: {level:burstLevel},
      equip: equip,
      weapon: {
        name:weapon.name,
        level:weapon.level,
        ascension:weapon.ascension,
        refinement:weapon.refinement
      },
      artifacts: {
        sets:(() => {
          let sets = [];
          
          if(artifactBonus1 && artifactBonus1!=="No Set") {
            if(artifactBonus2 && (artifactBonus2==="No other set" || artifactBonus1===artifactBonus2)) {
              sets = [{name:Artifacts.find(a => a.name===artifactBonus1).name, setPieces:4}];
            } else {
              sets.push({name:Artifacts.find(a => a.name===artifactBonus1).name, setPieces:2});
              if(artifactBonus2 && artifactBonus2!=="No 4 pieces bonus") {
                sets.push({name:Artifacts.find(a => a.name===artifactBonus2).name, setPieces:2});
              }
            }
          }
          return sets;
        })(),
        stats: artifactStatsApplied
      }
    };
    props.onSave(charConfig, buildName);
    setOpen(false);
  }

  return <Fragment>
  <Modal
    onClose={() => setOpen(false)}
    onOpen={() => {
      ReactGA.modalview(window.location.pathname+'/edit/'+character);
      setOpen(true);
    }}
    open={open}
    trigger={trigger}>
    <Modal.Header>Character setup</Modal.Header>
    <Modal.Content>
      <Form>
        <Segment basic style={{padding:0,margin:0}}>
          <Grid columns={2}>
            <Grid.Column>
              <Form.Group>
                <Form.Field width={16}>
                  <label>Character</label>
                  <CharacterSelect onChange={(e,d) => {
                    setCharacter(d.value); 
                    let newEquip = d.options.find(c => c.value===d.value).equip;
                    if(equip !== newEquip) {
                      setEquip(newEquip);
                      setWeapon(w => {
                        return {
                          ...w, 
                          name:'None'
                        };
                      });
                    }
                  }} value={character} default={defaultCharacter.name} />
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field width={3}>
                  <label>Level</label>
                  <Input type="number" id="level" name="level" min="1" max="90" value={level} onChange={(e,d) => {
                    ChangeCharacterLevel(parseInt(d.value));
                  }}/>
                </Form.Field>
                <Form.Field width={6}>
                  <label>Ascension</label>
                  <Rating label='Ascension' maxRating={6} clearable name="ascension" size="huge" rating={ascension} onRate={(e,d) => {
                    ChangeCharacterAscension(d.rating);
                  }}/>
                </Form.Field>
                <Form.Field width={7}>
                  <label>Constellation</label>
                  <Rating label='Constellation' maxRating={6} clearable name="constellation" size="huge" rating={constellation} onRate={(e,d) => {
                    setConstellation(d.rating);
                  }} />
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field width={6}>
                  <label>Attack Level <Label>{attackLevel}</Label></label>
                  <Input type="range" min={1} max={10} name="attackLevel" value={attackLevel} onChange={(e,d) => {
                    ChangeCharacterTalentLevel(parseInt(d.value), skillLevel, burstLevel);
                  }} />
                </Form.Field>
                <Form.Field width={6}>
                  <label>Skill Level <Label>{skillLevel}</Label></label>
                  <Input type="range" min={1} max={10} name="skillLevel" value={skillLevel} onChange={(e,d) => {
                    ChangeCharacterTalentLevel(attackLevel, parseInt(d.value), burstLevel);
                  }} />
                </Form.Field>
                <Form.Field width={6}>
                  <label>Burst Level <Label>{burstLevel}</Label></label>
                  <Input type="range" min={1} max={10} name="burstLevel" value={burstLevel} onChange={(e,d) => {
                    ChangeCharacterTalentLevel(attackLevel, skillLevel, parseInt(d.value));
                  }} />
                </Form.Field>
              </Form.Group>
            </Grid.Column>
            <Grid.Column>
              <Form.Group>
                <Form.Field width={16}>
                  <label>Weapon</label>
                  <WeaponSelect onChange={(e,d) => setWeapon(w => {
                    return {
                      ...w, 
                      name:d.value
                    };
                  })} value={weapon.name} equip={equip} />
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field width={3}>
                  <label>Level</label>
                  <Input type="number" id="weaponLevel" name="weaponLevel" min="1" max="90" value={weapon.level} onChange={(e,d) => {
                    let asc = RestrictAscensionToLevel(weapon.ascension, parseInt(d.value));
                    setWeapon(w => {return {...w, level:parseInt(d.value), ascension:asc}});
                  }}/>
                </Form.Field>
                <Form.Field width={6}>
                  <label>Ascension</label>
                  <Rating label='Ascension' maxRating={6} clearable name="weaponAscension" size="huge" rating={weapon.ascension} onRate={(e,d) => {
                    let lvl = RestrictLevelToAscension(weapon.level, d.rating);
                    setWeapon(w => {return {...w, level:lvl, ascension:d.rating}});
                  }} />
                </Form.Field>
                <Form.Field width={7}>
                  <label>Refinement</label>
                  <Rating label='Refinement' maxRating={5} name="refinement" size="huge" rating={weapon.refinement} onRate={(e,d) => setWeapon(w => {return {...w, refinement:d.rating}})} />
                </Form.Field>
              </Form.Group>
              <div style={{margin:"0 2em"}}>{(() => {
                if(Weapons[equip]) {
                  let w = Weapons[equip].find(w => w.name===weapon.name);
                  if(w) {
                    return <Fragment>
                      <b>{w.passive}</b> :{" "}
                      {AdjustWeaponEffect(w.effect)}
                    </Fragment>
                  }
                }
              })()}</div>
            </Grid.Column>
          </Grid>
  
          <Divider vertical fitted />
        </Segment>
        <Grid columns={5} width="equal">
          <Grid.Column width={16} style={{height:"450px"}}>
            <Popup trigger={<Icon name="list" style={{position:"absolute", top:"35px", right:"25px"}} />} position="bottom left" wide="very" hoverable>
              <Header size='tiny'>Overall Stats added from artifacts</Header>
              <StatGrid key="characterEditStats" attr={artifactAttr} />
            </Popup>
            <Tab className='artifactEdit' panes={[
              
              { menuItem: <Menu.Item key='overview' style={{fontWeight:700}}>
                  Overview
                </Menu.Item>, render: () => <Tab.Pane>
                  <Grid>
                    <Grid.Column width={16}>
                      <Form.Field width={16}>
                        <label>Quick Artifact Stats Preset 
                          <Popup hoverable trigger={
                              <Icon name="info" />
                            }>{"Based on "}
                            <a href="https://www.reddit.com/r/Genshin_Impact/comments/j0wf9w/genshin_impact_team_building_resources_updated_c0/">
                              u/___robin___
                            </a>{"'s work on "}
                            <a href="https://docs.google.com/spreadsheets/d/1pYDZpKgnF-8aRdfKfD_pYRnKKAabgboO1WuxjPmcZO8/edit">
                              Genshin Impact Resources 3.0
                            </a>
                          </Popup>
                        </label>
                        <Image src='/static/artifacts/artifact-time.png' aria-label='Artifact Time' inline circular size='mini' />
                        <Dropdown
                          placeholder='Select Time Artifact Stat'
                          label='Time Artifact Stat'
                          className="primaryStatPreset"
                          selection
                          options={[
                            ...ArtifactStatsPreset.ArtifactTimeStatsPreset.builds.map(a => {return {
                              key:a.name.replaceAll(/[^a-z0-9]/gi, '').replace('%', 'pct').toLowerCase(), 
                              value:a.name, 
                              text:a.name
                            }})
                          ]}
                          value={artifactTimePresetSelected ? artifactTimePresetSelected : 'None'}
                          onChange={(e,d) => {
                            setArtifactTimePresetSelected(d.value);
                          }}
                          />
                        <Image src='/static/artifacts/artifact-goblet.png' aria-label='Artifact Goblet' inline circular size='mini' />
                        <Dropdown
                          placeholder='Select Goblet Artifact Stat'
                          label='Goblet Artifact Stat'
                          className="primaryStatPreset"
                          selection
                          options={[
                            ...ArtifactStatsPreset.ArtifactGobletStatsPreset.builds.map(a => {return {
                              key:a.name.replaceAll(/[^a-z0-9]/gi, '').replace('%', 'pct').toLowerCase(), 
                              value:a.name, 
                              text:a.name
                            }})
                          ]}
                          value={artifactGobletPresetSelected ? artifactGobletPresetSelected : 'None'}
                          onChange={(e,d) => {
                            setArtifactGobletPresetSelected(d.value);
                          }}
                          />
                        <Image src='/static/artifacts/artifact-head.png' aria-label='Artifact Head' inline circular size='mini' />
                        <Dropdown
                          placeholder='Select Head Artifact Stat'
                          label='Head Artifact Stat'
                          className="primaryStatPreset"
                          style={{marginRight:"0"}}
                          selection
                          options={[
                            ...ArtifactStatsPreset.ArtifactCircletStatsPreset.builds.map(a => {return {
                              key:a.name.replaceAll(/[^a-z0-9]/gi, '').replace('%', 'pct').toLowerCase(), 
                              value:a.name, 
                              text:a.name
                            }})
                          ]}
                          value={artifactHeadPresetSelected ? artifactHeadPresetSelected : 'None'}
                          onChange={(e,d) => {
                            setArtifactHeadPresetSelected(d.value);
                          }}
                          />
                        {" | "}
                        <Dropdown
                          placeholder='Select Artifact Sub Stats'
                          label='Artifact Sub Stats'
                          selection
                          className="subStatPreset"
                          options={[
                            ...ArtifactStatsPreset.ArtifactSubStatsPreset.builds.map(a => {return {
                              key:a.name.replaceAll(/[^a-z0-9]/gi, '').replace('%', 'pct').toLowerCase(), 
                              value:a.name, 
                              text:a.name
                            }})
                          ]}
                          value={artifactSubPresetSelected?artifactSubPresetSelected:'None'}
                          onChange={(e,d) => {
                            setArtifactSubPresetSelected(d.value);
                          }}
                          />
                        <Button onClick={(e) => {
                          let artifactStats = ArtifactStatsPreset.GetPreset(artifactTimePresetSelected, artifactGobletPresetSelected, artifactHeadPresetSelected, artifactSubPresetSelected);
                          setArtifactStatsApplied(artifactStats);
                          setArtifactAttr(ArtifactConfigToAttributes(artifactStats));
                        }}>Apply</Button>
                        {
                          artifactStatsApplied !== defaultCharacter.artifacts.stats
                          ? <Button icon='undo' aria-label='Undo' onClick={e => {
                              setArtifactStatsApplied(defaultCharacter.artifacts.stats);
                              setArtifactAttr(ArtifactConfigToAttributes(defaultCharacter.artifacts.stats));
                            }} />
                          :''
                        }
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column width={16}>
                      <Divider style={{margin:"0"}}/>
                    </Grid.Column>
                    <Grid.Column width={7}>
                      <Form.Field>
                        <label>Artifact Bonus</label>
                        <Dropdown
                          placeholder='Select Artifact'
                          label='Artifact'
                          className='artifact'
                          selection
                          options={[
                            {key: 'none', value: 'No Set', text: artifactItemRenderer('No Set', 0, null), bonus: {two: '',four: ''}},
                            ...Artifacts.map(a => {
                              let key = a.name.replaceAll(/[^a-z0-9]+/gi, '').toLowerCase();
                              return {
                                key: key,
                                value: a.name,
                                text: artifactItemRenderer(a.name, a.rarity, '/static/artifacts/'+key+'.png'),
                                bonus: {
                                  two: a.two,
                                  four: a.four
                                }
                              };
                            })
                          ]} 
                          value={artifactBonus1} 
                          onChange={(e,d) => {
                            setArtifactBonus1(d.value);
                            setArtifactBonus2('No other set');
                          }}
                          />
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column width={7}>
                      <Header>Set Bonus</Header>
                      {(() => {
                        if(artifactBonus1==="No Set") {
                          return 'No Set Bonus';
                        } else if(artifactBonus1) {
                          let artifact = Artifacts.find(a => a.name===artifactBonus1);
                          return artifact ? <Fragment><Header size='tiny'>{artifact.name}</Header>2-Piece Set: {artifact.two}</Fragment> : '';
                        } else  {
                          return '';
                        }
                      })()}
                    </Grid.Column>
                    <Grid.Column width={2}></Grid.Column>
                    <Grid.Column width={7}>
                      <Form.Field>
                        <label>Artifact Bonus</label>
                        <Dropdown
                          placeholder='Select Artifact'
                          label='Artifact'
                          className='artifact'
                          selection
                          disabled={artifactBonus1==='No Set'}
                          options={[
                            {key:'noother', value:'No other set', text: artifactItemRenderer('No other set', 0, null),bonus:{two:'',four:''}},
                            {key:'no4bonus', value:'No 4 pieces bonus', text: artifactItemRenderer('No 4 pieces bonus', 0, null),bonus:{two:'',four:''}},
                            ...Artifacts.map(a => {
                              let key = a.name.replaceAll(/[^a-z0-9]+/gi, '').toLowerCase();
                              return {
                                key: key,
                                value: a.name,
                                text: artifactItemRenderer(a.name, a.rarity, '/static/artifacts/'+key+'.png'),
                                bonus: {
                                  two:a.two,
                                  four:a.four
                                }
                              };
                            })
                          ]} 
                          value={artifactBonus2} 
                          onChange={(e,d) => {
                            setArtifactBonus2(d.value);
                          }}
                          />
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column width={7}>
                      {(() => {
                        let artifact;
                        if(artifactBonus1) {
                          if(artifactBonus2==="No other set" || artifactBonus2===artifactBonus1) {
                            artifact = Artifacts.find(a => a.name===artifactBonus1);
                            return artifact ? <Fragment>4-Piece Set: {artifact.four}</Fragment> : '';
                          } else if (artifactBonus2==="No 4 pieces bonus") {
                            return '';
                          } else {
                            artifact = Artifacts.find(a => a.name===artifactBonus2);
                            return artifact ? <Fragment><Header size='tiny'>{artifact.name}</Header>2-Piece Set: {artifact.two}</Fragment> : '';
                          }
                        } else  {
                          return '';
                        }
                      })()}
                    </Grid.Column>
                    <Grid.Column width={2}></Grid.Column>
                  </Grid>
                </Tab.Pane> },
              { menuItem: <Menu.Item key='menu-flower'>
                  <Image src='/static/artifacts/artifact-flower.png' aria-label='Artifact Flower' circular size='mini' />
                </Menu.Item>, 
                render: () => <Tab.Pane>
                  <ArtifactEdit key="flower" type="flower" 
                    preset={artifactStatsApplied['flower']} 
                    onChange={(type, stats) => {
                      setArtifactStatsApplied(a => {
                        return {...a, [type]:stats};
                      });
                    }}/>
                </Tab.Pane> },
              { menuItem: <Menu.Item key='menu-feather'>
                  <Image src='/static/artifacts/artifact-feather.png' aria-label='Artifact Feather' circular size='mini' />
                </Menu.Item>, 
                render: () => <Tab.Pane>
                  <ArtifactEdit key="feather" type="feather" 
                    preset={artifactStatsApplied['feather']} 
                    onChange={(type, stats) => {
                      setArtifactStatsApplied(a => {
                        return {...a, [type]:stats};
                      });
                    }}/>
                </Tab.Pane> },
              { menuItem: <Menu.Item key='menu-time'>
                  <Image src='/static/artifacts/artifact-time.png' aria-label='Artifact Time' circular size='mini' />
                </Menu.Item>, 
                render: () => <Tab.Pane>
                  <ArtifactEdit key="time" type="time" 
                    preset={artifactStatsApplied['time']} 
                    onChange={(type, stats) => {
                      setArtifactStatsApplied(a => {
                        return {...a, [type]:stats};
                      });
                    }}/>
                </Tab.Pane> },
              { menuItem: <Menu.Item key='menu-goblet'>
                  <Image src='/static/artifacts/artifact-goblet.png' aria-label='Artifact Goblet' circular size='mini' />
                </Menu.Item>, 
                render: () => <Tab.Pane>
                  <ArtifactEdit key="goblet" type="goblet"
                    preset={artifactStatsApplied['goblet']} 
                    onChange={(type, stats) => {
                      setArtifactStatsApplied(a => {
                        return {...a, [type]:stats};
                      });
                    }}/>
                </Tab.Pane> },
              { menuItem: <Menu.Item key='menu-head'>
                  <Image src='/static/artifacts/artifact-head.png' aria-label='Artifact Head' circular size='mini' />
                </Menu.Item>, 
                render: () => <Tab.Pane>
                  <ArtifactEdit key="head" type="head" 
                    preset={artifactStatsApplied['head']} 
                    onChange={(type, stats) => {
                      setArtifactStatsApplied(a => {
                        return {...a, [type]:stats};
                      });
                    }}/>
                </Tab.Pane> },
            ]} />
          </Grid.Column>
        </Grid>
      </Form>
    </Modal.Content>
    <Modal.Actions>
      <Button floated='left' color='black' onClick={() => setOpen(false)}>
        Cancel
      </Button>
      <div style={{display:"inline-block", marginRight:"1em"}}>
        <label style={{marginRight:"0.5em"}}>Save as</label>
        <Dropdown
          placeholder='Select build'
          selection
          value={buildName}
          search
          allowAdditions
          onAddItem={(e, { value }) => {
            setBuilds(b => {
              return {
                [value]: {added:true},
                ...b
              }
            });
          }}
          onChange={(e,{value}) => {
            setBuildName(value);
          }}
          options={Object.keys(builds).map(b => {
            return {
              key: b,
              text: b,
              value: b,
              added: builds[b].added
            }
          })}
        />
      </div>
      <Button
        content="Save"
        onClick={() => {
          if(props.character.build !== buildName && !builds[buildName].added) {
            setShowConfirmOverwrite(true);
          } else {
            Save();
          }
        }}
        positive
      />
    </Modal.Actions>
  </Modal>
  <Confirm
    open={showConfirmOverwrite}
    header={<div className="header"><Icon name='warning sign' /> Overwriting build</div>}
    content={<div className="content">Are you sure you want to permanently overwrite build '<b>{buildName}</b>'</div>}
    onCancel={() => setShowConfirmOverwrite(false)}
    onConfirm={() => {
      Save();
      setShowConfirmOverwrite(false);
    }}
    />
  </Fragment>;
};

export default CharacterEdit;