import * as Genshin from './';
import Attributes, {BaseCharacterAttributes} from './Attributes.js';
import Result from './Result.js';

const Tools = {
  
  BuildCharacter(props) {
    let cls = props.name.replaceAll(/[^a-z0-9]/gi, '');
    let c = null;
    if(Genshin.Characters[cls]) {
      c = Genshin.Characters[cls]({
        level: props.level, 
        ascension: props.ascension,
        constellation: props.constellation,
        weapon: Genshin.Weapons[props.weapon.name.replace(/(^|\s)([a-z])/g, m => m.toUpperCase()).replace(/[^a-z0-9]/gi, '')]
          ? Genshin.Weapons[props.weapon.name.replace(/(^|\s)([a-z])/g, m => m.toUpperCase()).replace(/[^a-z0-9]/gi, '')]({
              level:props.weapon.level,
              ascension:props.weapon.ascension,
              refinement:props.weapon.refinement
            })
          : null,
        artifacts: {
          sets:props.artifacts.sets.map(a => {
            let key = a.name.replace(/(^|\s)([a-z])/g, m => m.toUpperCase()).replace(/[^a-z0-9]/gi, '');
            let artifact;
            if(Genshin.Artifacts[key]) {
              artifact = Genshin.Artifacts[key]({pcs:a.setPieces});
            }
            return artifact;
          }),
          stats: props.artifacts.stats,
          attributes: props.artifacts.stats
            ? Tools.ArtifactConfigToAttributes(props.artifacts.stats)
            : props.artifacts.attributes/*{
            baseAtk:0,
            flatAtk:0,
            atkPct:0,
            critRate:0,
            critDmg:0,
            damageBonus: {
              all:0,
              physical:0,
              hydro:0,
              electro:0,
              pyro:0,
              cryo:0,
              anemo:0,
              geo:0,
              dendro:0
            },
            resistanceReduction: {
              all:0,
              physical:0,
              hydro:0,
              electro:0,
              pyro:0,
              cryo:0,
              anemo:0,
              geo:0,
              dendro:0
            },
            elemMastery:0,
            resistance: {
              all:0,
              physical:0,
              hydro:0,
              electro:0,
              pyro:0,
              cryo:0,
              anemo:0,
              geo:0,
              dendro:0
            },
            defense: 0,
            defenseReduction: 0,
            energyRecharge: 0,
            maxHP: 0,
            dmgPct: 0
          }*/
        },
        attack: props.attack.level,
        skill: props.skill.level,
        burst: props.burst.level
      });
      c.fullAttributes = Tools.GetAttributes(c);
    }
    return c;
  },
  
  ArtifactConfigToAttributes(cfg) {
    let stats = {
      flatAtk: 0,
      atkPct: 0,
      critRate: 0,
      critDmg: 0,
      damageBonus: {
        physical:0,
        hydro:0,
        electro:0,
        pyro:0,
        cryo:0,
        anemo:0,
        geo:0,
        dendro:0
      },
      elemMastery: 0,
      defense: 0,
      defensePct: 0,
      energyRecharge: 0,
      maxHP: 0,
      hpPct: 0
    };
    ['flower','feather','time','goblet','head'].forEach(t => {
      [
        {dest:'flatAtk',src:'atk'},
        {dest:'atkPct',src:'atkpct',pct:true},
        {dest:'critRate',src:'cr',pct:true},
        {dest:'critDmg',src:'cd',pct:true},
        {dest:'damageBonus.physical',src:'pdmg',pct:true},
        {dest:'damageBonus.hydro',src:'edmg',pct:true},
        {dest:'damageBonus.electro',src:'edmg',pct:true},
        {dest:'damageBonus.pyro',src:'edmg',pct:true},
        {dest:'damageBonus.cryo',src:'edmg',pct:true},
        {dest:'damageBonus.anemo',src:'edmg',pct:true},
        {dest:'damageBonus.geo',src:'edmg',pct:true},
        {dest:'damageBonus.dendro',src:'edmg',pct:true},
        {dest:'elemMastery',src:'em'},
        {dest:'defense',src:'def'},
        {dest:'defensePct',src:'defpct',pct:true},
        {dest:'energyRecharge',src:'er',pct:true},
        {dest:'maxHP',src:'hp'},
        {dest:'hpPct',src:'hppct',pct:true}
      ].forEach(s => {
        let dest = s.dest.split('.');
        if(!cfg[t]['bonus'] || (s.src!=="edmg" && s.src!=="pdmg") || dest[1]===cfg[t]['bonus'].toLowerCase()) {
          if(cfg[t][s.src]) {
            if(s.pct) {
              cfg[t][s.src] = Math.round(cfg[t][s.src]*100)/100;
            }
          } else {
            cfg[t][s.src] = 0;
          }
          if(dest.length===2) {
            stats[dest[0]][dest[1]] += cfg[t][s.src];
          } else {
            stats[dest[0]] += cfg[t][s.src];
          }
        }
      });
    });
    return stats;
  },
  
  GetAttributes(character) {
    let attributes = BaseCharacterAttributes()
      .Merge(character.weapon.attributes)
      .Merge(character.attributes);
    if(character.artifacts.stats) {
      attributes.Merge(Tools.ArtifactConfigToAttributes(character.artifacts.stats));
    } else {
      attributes.Merge(character.artifacts.attributes);
    }
    character.artifacts.sets.forEach(s => attributes.Merge(s.attributes ? s.attributes : {}));
    attributes.postProcesses.forEach(f => f(attributes));
    //console.log('Attributes', attributes);
    return attributes;
  },
  
  statusDuration:{
    fire: 12,
    wet: 9.5,
    shock: 9.5,
    cold: 9.5,
    frozen: 4,
    swirl: 0.2,
    crystallize: 0.2
  },
  
  HistoryEntryToResult(historyEntry, precedingResult, target) {
    return Result({
      description: historyEntry.description,
      duration: historyEntry.duration,
      enforceCooldown: historyEntry.enforceCooldown,
      cooldown: historyEntry.cooldown,
      entity: historyEntry.entity,
      initiator: historyEntry.initiator,
      logs: [],
      precedingResult: precedingResult,
      relativeTime: historyEntry.relativeTime,
      talent: historyEntry.talent,
      talentImpl: historyEntry.talentImpl,
      talentProperties: historyEntry.talentProperties,
      target: target,
      type: historyEntry.type,
      Prepare: historyEntry.Prepare,
      Resolve: historyEntry.Resolve,
      Finalize: historyEntry.Finalize,
      PersistentPrepare: historyEntry.PersistentPrepare,
      PersistentResolve: historyEntry.PersistentResolve,
      PersistentFinalize: historyEntry.PersistentFinalize,
    });
  },
  
  GetComboStep(result) {
    let comboTable = result.talentProperties.comboTable;
    let precedingComboStep = null;
    let nextComboStep = null;
    
    if(result.type.startsWith("NormalAtk")) {
      if(result.precedingResult  && result.precedingResult.description === result.description) {
        let comboStep = result.precedingResult.type.match(/NormalAtk-([0-9])/);
        if(comboStep && comboStep[1]) {
          precedingComboStep = parseInt(comboStep[1], 10);
        }
      }
      nextComboStep = 
        precedingComboStep===null || precedingComboStep>=comboTable.length 
        ? 1 
        : precedingComboStep+1;
    }
    return {current:precedingComboStep, next:nextComboStep};
  },
  
  ProcChance(pct, track) {
    if(!track.proc) {
      track.proc = 0;
    }
    track.proc += pct;
    if(track.proc>=1) {
      track.proced = false;
    }
    if(!track.proced && track.proc>0.5) {
      track.proced = true;
      if(track.proc>=1) {
        track.proc %= 1;
      }
      return true;
    } else {
      return false;
    }
  },
  
  CreateTalentSideEffect(effect, talent, override) {
    let talentRes = (effect.talent[talent+"Effect"]
        ? effect.talent[talent+"Effect"]
        : (effect.talent[talent+"Impl"]
          ? effect.talent[talent+"Impl"]
          : {}
        )
      );
    let talentObj = Tools.CreateSideEffect(effect, {
      entity: effect.entity,
      //initiator: effect.initiator,
      logs: ["Talent Effect of "+effect.description, ...(talent.logs?talent.logs:[])],
      precedingResult: effect,
      talent: effect.talent,
      talentImpl: effect.talentImpl,
      target: effect.target,
      enforceCooldown: false, 
      ...effect.talent, 
      Prepare: (result => result),
      Resolve: (result => result),
      Finalize: (result => result),
      PersistentPrepare: (result => result),
      PersistentResolve: (result => result),
      PersistentFinalize: (result => result),
      ...(talentRes), 
      ...(override),
      talentProperties: {
        level: effect.talent.level,
        ...effect.talent.talentProperties, 
        ...talentRes.talentProperties,
        ...(override?override.talentProperties:{})
      }
    });
    return talentObj;
  },
  
  CreateSideEffect(effect, override) {
    //console.log('Create Side Effect', {time:effect.time, duration:effect.duration, description:effect.description, override:{...override}});
    return Result({
      duration: 0.2,
      entity: effect.entity,
      enforceCooldown: false,
      initiator: effect.initiator,
      logs: ["Side effect of "+effect.initiator.name+"'s "+effect.description],
      precedingResult: effect,
      relativeTime: 0,
      stacks: 1,
      comboStep: null,
      talent: effect.talent,
      talentImpl: "default",
      target: effect.target,
      ...override,
      effectExtension: override.effectExtension===true
        ? (
          effect.effectExtension
          ? effect.effectExtension
          : effect
          )
        : (
          override.effectExtension
          ? override.effectExtension
          : null
          ),
      talentProperties: {
        level: effect.talent.level,
        ...override.talentProperties
      }
    });
  },
  
  CreateCooldownEffect(result, override={}) {
    let cooldown = override.cooldown || result.cooldown;
    let coolingDown = Tools.CreateSideEffect(result.precedingResult, {
      description: "Waiting for cooldown",
      type: "Cooldown",
      entity: result.entity,
      duration: (typeof override.lastUsed === "number" ? override.lastUsed : result.talent.track.lastUsed)+cooldown-result.time
    });
    result.setTime((typeof override.lastUsed === "number" ? override.lastUsed : result.talent.track.lastUsed)+cooldown);
    result.precedingResult = coolingDown;
    result.talentEffects.push(coolingDown);
    return result;
  },
  
  CreateStartEffect(initiator, monster) {
    return Result({
      description: 'Start',
      type: 'start',
      duration: 0,
      entity: 'start',
      initiator: initiator,
      target: monster
    });
  },
  
  CreateElectroStatusEffect(source) {
    let shockStatus = Tools.CreateSideEffect(source, {
      description: 'Shock',
      entity: 'Status Effect',
      duration: Tools.statusDuration.shock,
      relativeTime: (r => -r.precedingResult.duration),
      type: 'Status',
      Prepare: function(result) {
        result.attributes.element = 'electro';
        return result;
      },
      logs: ["Shock applied by "+source.initiator.name+"'s "+source.description]
    });
    source.ongoing.status.forEach(s => {
      if(s.type==='Status')
      switch(s.attributes.element) {
        case 'electro': {
          s.setDuration(source.time - s.time);
          s.logs.push("Shock refreshed by "+source.initiator.name+"'s "+source.description);
          } break;
        case 'pyro': {
          s.logs.push("Interrupted by Overloaded Reaction");
          s.setDuration(source.time - s.time + 0.1);
          shockStatus.setDuration(0.1);
          shockStatus.logs.push("Trigger Overloaded Reaction by "+source.initiator.name+"'s "+source.description);
          shockStatus = [shockStatus, Tools.CreateOverloadedReaction(source)];
          } break;
        case 'hydro': {
          s.logs.push("Interrupted by Electro-Charged Reaction");
          s.setDuration(source.time - s.time + 0.5);
          shockStatus.setDuration(0.5);
          shockStatus.logs.push("Trigger Electro-Charged Reaction by "+source.initiator.name+"'s "+source.description);
          shockStatus = [shockStatus, ...Tools.CreateElectrochargedReaction(source)];
          } break;
        case 'cryo': {
          s.logs.push("Interrupted by Superconduct Reaction");
          s.setDuration(source.time - s.time + 0.2);
          shockStatus.setDuration(0.2);
          shockStatus.logs.push("Trigger Superconduct Reaction by "+source.initiator.name+"'s "+source.description);
          shockStatus = [shockStatus, Tools.CreateSuperconductReaction(source)];
          } break;
        default:
          break;
      }
    });
    return shockStatus;
  },
  
  CreatePyroStatusEffect(source) {
    let fireStatus = Tools.CreateSideEffect(source, {
      description: 'Fire',
      entity: 'Status Effect',
      duration: Tools.statusDuration.fire,
      relativeTime: (r => -r.precedingResult.duration),
      type: 'Status',
      Prepare: function(result) {
        result.attributes.element = 'pyro';
        return result;
      },
      logs: ["Fire applied by "+source.description+" from "+source.initiator.name]
    });
    source.ongoing.status.forEach(s => {
      if(s.type==='Status')
      switch(s.attributes.element) {
        case 'pyro': {
          s.setDuration(source.time - s.time);
          s.logs.push("Fire refreshed by "+source.initiator.name+"'s "+source.description);
          } break;
        case 'electro': {
          s.logs.push("Interrupted by Overloaded Reaction");
          s.setDuration(source.time - s.time + 0.1);
          fireStatus.setDuration(0.1);
          fireStatus.logs.push("Trigger Overloaded Reaction by "+source.initiator.name+"'s "+source.description);
          source.statusEffects.push(Tools.CreateOverloadedReaction(source));
          } break;
        case 'hydro': {
          s.logs.push("Interrupted by Vaporize Reaction");
          s.setDuration(source.time - s.time + 0.2);
          fireStatus.setDuration(0.2);
          fireStatus.logs.push("Trigger Vaporize Reaction by "+source.initiator.name+"'s "+source.description);
          source.reactionMultiplier.vaporize += 1.5;
          source.logs.push("Vaporize triggered, applying 1.5x modifier");
          } break;
        case 'cryo': {
          s.logs.push("Interrupted by Melt Reaction");
          s.setDuration(source.time - s.time + 0.2);
          fireStatus.setDuration(0.2);
          fireStatus.logs.push("Trigger Melt Reaction by "+source.initiator.name+"'s "+source.description);
          source.reactionMultiplier.melt += 2;
          source.logs.push("Vaporize triggered, applying 2x modifier");
          } break;
        case 'dendro': {
          console.log('Trigger Burning, but not yet implemented...');
          } break;
        default:
          break;
      }
    });
    
    return fireStatus;
  },
  
  CreateHydroStatusEffect(source) {
    let wetStatus = Tools.CreateSideEffect(source, {
      description: 'Wet',
      entity: 'Status Effect',
      duration: Tools.statusDuration.wet,
      relativeTime: (r => -r.precedingResult.duration),
      type: 'Status',
      Prepare: function(result) {
        result.attributes.element = 'hydro';
        return result;
      },
      logs: ["Wet applied by "+source.description+" from "+source.initiator.name]
    });
    source.ongoing.status.forEach(s => {
      if(s.type==='Status')
      switch(s.attributes.element) {
        case 'hydro': {
          s.setDuration(source.time - s.time);
          s.logs.push("Wet refreshed by "+source.initiator.name+"'s "+source.description);
          } break;
        case 'pyro': {
          s.logs.push("Interrupted by Vaporize Reaction");
          s.setDuration(source.time - s.time + 0.2);
          wetStatus.setDuration(0.2);
          wetStatus.logs.push("Trigger Vaporize Reaction by "+source.initiator.name+"'s "+source.description);
          source.reactionMultiplier.vaporize += 2;
          source.logs.push("Vaporize triggered, applying 2x modifier");
          } break;
        case 'electro': {
          s.logs.push("Interrupted by Electro-Charged Reaction");
          s.setDuration(source.time - s.time + 0.5);
          wetStatus.setDuration(0.5);
          wetStatus.logs.push("Trigger Electro-Charged Reaction by "+source.initiator.name+"'s "+source.description);
          wetStatus = [
            wetStatus,
            ...Tools.CreateElectrochargedReaction(source)
          ];
          } break;
        case 'cryo': {
          let frozenReaction = Tools.CreateFrozenReaction(source, s);
          s.logs.push("Interrupted by Frozen Reaction");
          s.setDuration(source.time - s.time + 0.2);
          wetStatus.setDuration(0.2);
          wetStatus.logs.push("Trigger Frozen Reaction by "+source.initiator.name+"'s "+source.description);
          wetStatus = [
            wetStatus,
            frozenReaction
          ];
          } break;
        default:
          break;
      }
    });
    return wetStatus;
  },
  
  CreateCryoStatusEffect(source) {
    
    let coldStatus = Tools.CreateSideEffect(source, {
      description: 'Cold',
      entity: 'Status Effect',
      duration: Tools.statusDuration.cold,
      relativeTime: (r => -r.precedingResult.duration),
      type: 'Status',
      Prepare: function(result) {
        result.attributes.element = 'cryo';
        return result;
      },
      logs: ["Cold applied by "+source.description+" from "+source.initiator.name]
    });
    source.ongoing.status.forEach(s => {
      if(s.type==='Status')
      switch(s.attributes.element) {
        case 'cryo': {
          s.setDuration(source.time - s.time);
          s.logs.push("Cold refreshed by "+source.initiator.name+"'s "+source.description);
          } break;
        case 'pyro': {
          s.logs.push("Interrupted by Melt Reaction");
          s.setDuration(source.time - s.time + 0.2);
          coldStatus.setDuration(0.2);
          coldStatus.logs.push("Trigger Melt Reaction by "+source.initiator.name+"'s "+source.description);
          source.reactionMultiplier.melt += 1.5;
          source.logs.push("Vaporize triggered, applying 1.5x modifier");
          } break;
        case 'electro': {
          s.logs.push("Interrupted by Superconduct Reaction");
          s.setDuration(source.time - s.time + 0.2);
          coldStatus.setDuration(0.2);
          coldStatus.logs.push("Trigger Superconduct Reaction by "+source.initiator.name+"'s "+source.description);
          coldStatus = [coldStatus, Tools.CreateSuperconductReaction(source)];
          } break;
        case 'hydro': {
          let frozenReaction = Tools.CreateFrozenReaction(source, s);
          s.logs.push("Interrupted by Frozen Reaction");
          s.setDuration(source.time - s.time + 0.2);
          coldStatus.setDuration(0.2);
          coldStatus.logs.push("Trigger Frozen Reaction by "+source.initiator.name+"'s "+source.description);
          coldStatus = [
            coldStatus,
            frozenReaction
          ];
          } break;
        default:
          break;
      }
    });
    return coldStatus;
  },
  
  CreateAnemoStatusEffect(source) {
    let anemoStatus = Tools.CreateSideEffect(source, {
      description: 'Swirl',
      entity: 'Status Effect',
      duration: Tools.statusDuration.swirl,
      relativeTime: (r => -r.precedingResult.duration),
      type: 'Status',
      Prepare: function(result) {
        result.attributes.element = 'anemo';
        return result;
      },
      logs: ["Anemo applied by "+source.description+" from "+source.initiator.name]
    });
    source.ongoing.status.forEach(s => {
      if(s.type==='Status')
      switch(s.attributes.element) {
        case 'cryo':
        case 'pyro': 
        case 'electro':
        case 'hydro': {
          s.setDuration(source.time - s.time + 0.2);
          s.logs.push(s.description+" refreshed by "+source.initiator.name+"'s "+source.description);
          source.statusEffects.push(Tools.CreateSwirlReaction(source, s));
          } break;
        default:
          break;
      }
    });
    return anemoStatus;
  },
  
  CreateGeoStatusEffect(source) {
    let geoStatus = Tools.CreateSideEffect(source, {
      description: 'Crystallize',
      entity: 'Status Effect',
      duration: Tools.statusDuration.crystallize,
      relativeTime: (r => -r.precedingResult.duration),
      type: 'Status',
      Prepare: function(result) {
        result.attributes.element = 'geo';
        return result;
      },
      logs: ["Geo applied by "+source.description+" from "+source.initiator.name]
    });
    source.ongoing.status.forEach(s => {
      if(s.type==='Status')
      switch(s.attributes.element) {
        case 'cryo': 
        case 'pyro': 
        case 'electro':
        case 'hydro': {
          s.setDuration(source.time - s.time + 0.2);
          s.logs.push(s.description+" crystallized by "+source.initiator.name+"'s "+source.description);
          source.statusEffects.push(Tools.CreateCrystallizeReaction(source, s));
          } break;
        default:
          break;
      }
    });
    return geoStatus;
  },
  
  CreateStatusEffect(element, source) {
    let status = null;
    if(source.type!=="Reaction" || source.description==="Swirl")
    switch(element) {
      case 'electro':
        status = this.CreateElectroStatusEffect(source);
        break;
      case 'pyro':
        status = this.CreatePyroStatusEffect(source);
        break;
      case 'hydro':
        status = this.CreateHydroStatusEffect(source);
        break;
      case 'cryo':
        status = this.CreateCryoStatusEffect(source);
        break;
      case 'anemo':
        if(source.ongoing.status.find(s => 
          s.description==="Fire" 
          || s.description==="Cold" 
          || s.description==="Wet" 
          || s.description==="Shock")) {
          status = this.CreateAnemoStatusEffect(source);
        }
        break;
      case 'geo':
        if(source.ongoing.status.find(s => 
          s.description==="Fire" 
          || s.description==="Cold" 
          || s.description==="Wet" 
          || s.description==="Shock")) {
          status = this.CreateGeoStatusEffect(source);
        }
        break;
      default:
        status = null;
    }
    return status;
  },
  
  CreateOverloadedReaction(source) {
    return Tools.CreateSideEffect(source, {
      description: 'Overloaded',
      entity: 'Reaction Effect',
      duration: 0.1,
      relativeTime: (r => -r.precedingResult.duration),
      type: 'Reaction',
      Resolve(result) {
        result.attributes.element='pyro';
        result.attributes.critRate = 0;
        result.attributes.critDmg = 0;
        result.attributes.skillPct = 0;
        return result;
      },
      logs: ["Triggered by "+source.precedingResult.initiator.name+"'s "+source.precedingResult.description]
    });
  },
  
  CreateSuperconductReaction(source) {
    return Tools.CreateSideEffect(source, {
      description: 'Superconduct',
      entity: 'Reaction Effect',
      duration: 9,
      relativeTime: (r => -r.precedingResult.duration),
      type: 'Reaction',
      Resolve(result) {
        result.attributes.element = 'cryo';
        return result;
      },
      PersistentResolve(result) {
        result.logs.push('Superconduct reduces defense by 40%');
        result.attributes.resistanceReduction.physical += 0.4;
        return result;
      },
      logs: ["Triggered by "+source.precedingResult.initiator.name+"'s "+source.precedingResult.description]
    });
  },
  
  CreateElectrochargedReaction(source) {
    return [
      Tools.CreateSideEffect(source, {
        description: 'Electro-Charged',
        entity: 'Reaction Effect',
        duration: 1,
        relativeTime: (r => -r.precedingResult.duration),
        type: 'Reaction',
        Resolve(result) {
          result.attributes.element = 'electro';
          result.attributes.critRate = 0;
          result.attributes.critDmg = 0;
          result.attributes.skillPct = 0;
          return result;
        },
        logs: ["Triggered by "+source.precedingResult.initiator.name+"'s "+source.precedingResult.description]
      }),
      Tools.CreateSideEffect(source, {
        description: 'Electro-Charged',
        entity: 'Reaction Effect',
        duration: 0.2,
        relativeTime: (r => -r.precedingResult.duration+1),
        type: 'Reaction',
        Resolve(result) {
          result.attributes.element = 'electro';
          result.attributes.critRate = 0;
          result.attributes.critDmg = 0;
          result.attributes.skillPct = 0;
          return result;
        },
        logs: ["Triggered by "+source.precedingResult.initiator+"'s "+source.precedingResult.description]
      })
    ];
  },
  
  CreateFrozenReaction(source, context) {
    let duration = context.time+context.duration-source.time;
    
    return Tools.CreateSideEffect(source, {
      description: 'Frozen',
      entity: 'Reaction Effect',
      duration: duration,
      relativeTime: (r => -r.precedingResult.duration),
      type: 'Reaction',
      Resolve(result) {
        result.attributes.element = 'cryo';
        return result;
      },
      PersistentResolve(result, context) {
        if(["NormalAtk", "ChargeAtk", "ChargeAtkHold"].find(f=>result.type.startsWith(f))
            && result.initiator.weapon.type==="claymore") {
          context.setDuration(result.time - context.time);
          context.logs.push("Frozen shattered by "+result.initiator.name+"'s "+result.description);
          result.statusEffects.push(Tools.CreateShatteredReaction(result));
        } else if(result.attributes.element==="geo" 
            && result.attributes.skillPct>0) {
          context.setDuration(result.time - context.time);
          context.logs.push("Frozen shattered by "+result.initiator.name+"'s "+result.description);
          result.statusEffects.push(Tools.CreateShatteredReaction(result));
        } else if((result.type==="Reaction" && result.description!=="Frozen")
          || result.reactionMultiplier.melt
          || result.reactionMultiplier.vaporize
          || result.reactionMultiplier.shattered) {
          context.setDuration(result.time - context.time);
          context.logs.push("Frozen interrupted by "+result.initiator.name+"'s "+result.description);
        }
        return result;
      },
      logs: ["Triggered by "+source.initiator.name+"'s "+source.description]
    });
  },
  
  CreateShatteredReaction(source) {
    return Tools.CreateSideEffect(source, {
      description: 'Shattered',
      entity: 'Reaction Effect',
      duration: 0.1,
      relativeTime: (r => -r.precedingResult.duration),
      type: 'Reaction',
      Resolve(result) {
        result.attributes.element = 'physical';
        result.attributes.critRate = 0;
        result.attributes.critDmg = 0;
        result.attributes.skillPct = 0;
        return result;
      },
      logs: ["Shattered triggered by "+source.initiator.name+"'s "+source.description]
    });
  },
  
  CreateSwirlReaction(source, context) {
    return Tools.CreateSideEffect(source, {
      description: 'Swirl',
      entity: 'Reaction Effect',
      duration: 0.2,
      relativeTime: (r => -r.precedingResult.duration),
      type: 'Reaction',
      Prepare(result) {
        result.attributes.element=context.attributes.element;
        //this can only happen if there are other targets
        /*let status = Tools.CreateStatusEffect(result.attributes.element, result);
        status.relativeTime = 0;
        if(status) {
          result.statusEffects.push(status);
        }*/
        //result.ApplyStatus(result, true);
        return result;
      },
      Resolve(result) {
        result.attributes.critRate = 0;
        result.attributes.critDmg = 0;
        result.attributes.skillPct = 0;
        return result;
      },
      logs: ["Triggered by "+source.precedingResult.initiator.name+"'s "+source.precedingResult.description]
    });
  },
  
  CreateCrystallizeReaction(source, context) {
    return Tools.CreateSideEffect(source, {
      description: 'Crystallize Shield',
      entity: 'Reaction Effect',
      duration: 15,
      relativeTime: (r => 0.2),
      type: 'Reaction',
      Prepare(result) {
        result.attributes.element=context.attributes.element;
        return result;
      },
      Resolve(result) {
        return result;
      },
      PersistentPrepare(result, context) {
        if(result !== context) {
          if(result.type==="Reaction" && result.description==='Crystallize Shield') {
            context.logs.push('Crystallize Shield replaced by another Crystallize Shield');
            context.setDuration(result.time - context.time);
          }
        }
        return result;
      },
      logs: ["Triggered by "+source.precedingResult.initiator.name+"'s "+source.precedingResult.description]
    });
  },
  
  AbsorbElement(result) {
  if(!result.talentProperties.element && result.attributes.element==='anemo') {
    let status = result.ongoing.status.filter(f => f.type==="Status" && ["pyro", "cryo", "electro", "hydro"].find(e => f.attributes.element===e));
    if(status.length>0) {
      result.talentProperties.element = status[0].attributes.element;
      result.talentProperties.absorbed = true;
      status[0].setDuration(result.time - status[0].time + result.duration);
      status[0].logs.push('This element has been absorbed.')
      result.logs.push('Absorbed the '+result.talentProperties.element+' element');
    } else {
      result.logs.push('No elements to absorb');
    }
  } else {
    result.logs.push('Maintain the absorbed '+result.talentProperties.element+' element');
  }
  return result;
}
};

export default Tools;