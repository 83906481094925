import {BaseSet} from './base.js';

const Adventurer = (props) => {
  
  return BaseSet({
    ...props,
    name: 'Adventurer',
    two: {
      attributes: {
        maxHp: 1000
      },
      PersistentResolve(result, context) {
        return result;
      }
    },
    four: {
      PersistentResolve(result, context) {
        return result;
      }
    }
  });
};

export default Adventurer;