/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getComposition = /* GraphQL */ `
  query GetComposition($id: ID!) {
    getComposition(id: $id) {
      id
      team {
        name
        build
        level
        ascension
        constellation
        attack {
          level
        }
        skill {
          level
        }
        burst {
          level
        }
        equip
        weapon {
          name
          level
          ascension
          refinement
        }
        artifacts {
          sets {
            name
            setPieces
          }
          stats {
            flower {
              edmg
              pdmg
              bonus
              atk
              atkpct
              cr
              cd
              er
              em
              def
              defpct
              hp
              hppct
            }
            feather {
              edmg
              pdmg
              bonus
              atk
              atkpct
              cr
              cd
              er
              em
              def
              defpct
              hp
              hppct
            }
            time {
              edmg
              pdmg
              bonus
              atk
              atkpct
              cr
              cd
              er
              em
              def
              defpct
              hp
              hppct
            }
            goblet {
              edmg
              pdmg
              bonus
              atk
              atkpct
              cr
              cd
              er
              em
              def
              defpct
              hp
              hppct
            }
            head {
              edmg
              pdmg
              bonus
              atk
              atkpct
              cr
              cd
              er
              em
              def
              defpct
              hp
              hppct
            }
          }
        }
      }
      history {
        entity
        talent
        impl
        description
      }
      monsters {
        name
        level
      }
      password
      sticky
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listCompositions = /* GraphQL */ `
  query ListCompositions(
    $filter: ModelCompositionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompositions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        team {
          name
          build
          level
          ascension
          constellation
          attack {
            level
          }
          skill {
            level
          }
          burst {
            level
          }
          equip
          weapon {
            name
            level
            ascension
            refinement
          }
          artifacts {
            sets {
              name
              setPieces
            }
            stats {
              flower {
                edmg
                pdmg
                bonus
                atk
                atkpct
                cr
                cd
                er
                em
                def
                defpct
                hp
                hppct
              }
              feather {
                edmg
                pdmg
                bonus
                atk
                atkpct
                cr
                cd
                er
                em
                def
                defpct
                hp
                hppct
              }
              time {
                edmg
                pdmg
                bonus
                atk
                atkpct
                cr
                cd
                er
                em
                def
                defpct
                hp
                hppct
              }
              goblet {
                edmg
                pdmg
                bonus
                atk
                atkpct
                cr
                cd
                er
                em
                def
                defpct
                hp
                hppct
              }
              head {
                edmg
                pdmg
                bonus
                atk
                atkpct
                cr
                cd
                er
                em
                def
                defpct
                hp
                hppct
              }
            }
          }
        }
        history {
          entity
          talent
          impl
          description
        }
        monsters {
          name
          level
        }
        password
        sticky
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
