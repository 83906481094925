import Scaling from '../../Scaling.js';
import BaseWeapon from '../base.js';

const CoolSteel = (props) => {
  
  return BaseWeapon({
    ...props,
    type: 'sword',
    name: 'Cool Steel',
    lvl1Atk: 39,
    lvl1Sub: 0.077,
    subType: 'atkPct',
    PersistentResolve(result, context) {
      /**
       * effect: 'Increases DMG against enemies affected by Hydro or Cryo 
       * by [12%].'
       */
      if(this.IsSelf(result)) {
      }
      return result;
    }
  });
};

export default CoolSteel;