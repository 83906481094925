import {BaseSet} from './base.js';

const Thundersoother = (props) => {
  
  return BaseSet({
    ...props,
    name: 'Thundersoother',
    two: {
      attributes: {
        resistance: {
          electro: 0.4
        }
      },
      PersistentResolve(result, context) {
        return result;
      }
    },
    four: {
      PersistentResolve(result, context) {
        if(!this.IsSelf(result)) return result;
        if(result.ongoing.status.filter(e => e.type==="Status" && e.description==="Shock").length>0) {
          result.damageBonus.all += 0.35;
          result.logs.push("Thundersoother Increase DMG against enemies affected by Electro by 35%.");
        }
        return result;
      }
    }
  });
};

export default Thundersoother;