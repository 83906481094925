import Scaling from '../../Scaling.js';
import BaseWeapon from '../base.js';

const PrototypeGrudge = (props) => {
  
  return BaseWeapon({
    ...props,
    type: 'polearm',
    name: 'Prototype Grudge',
    lvl1Atk: 42,
    lvl1Sub: 0.10,
    subType: 'energyRecharge',
    PersistentResolve(result, context) {
      /**
       * effect: 'After using an Elemental Skill, increases Normal and 
       * Charged Attack DMG by [8%] for 12s. Max 2 stacks.'
       */
      if(this.IsSelf(result)) {
      }
      return result;
    }
  });
};

export default PrototypeGrudge;