import React, {Fragment} from 'react';
import { Link } from 'react-router-dom';
import { List, Header } from 'semantic-ui-react';

const TermsOfUsePage = () => {
  return <div style={{textAlign:"left"}}>
  <Header as='h1'>Our Terms of Use</Header>
  
  <Header as='h2'>Terms of Use</Header>
  
  <p>PLEASE READ AND REVIEW THESE TERMS OF USE CAREFULLY BEFORE USING THIS WEBSITE.</p>

  <p>Date of Last Revision: December 16, 2020</p>

  <Header as='h4'>WHO WE ARE AND HOW TO CONTACT US</Header>
  <p>These Terms of Use apply to our websites and related 
    online and offline offerings (collectively, the “Services”). The Services 
    include websites at gamehoned.com. The Services are operated by fans</p>

  <p>These Terms of Use tell you the rules for using our Services.</p>

  <p>To contact us, please email support@gamehoned.com.</p>

  <Header as='h4'>BY USING OUR SERVICES YOU ACCEPT THESE TERMS OF USE</Header>
  <p>By accessing or using the Services, you acknowledge that you have read, 
    understand and agree to be bound by these Terms of Use (“Terms of Use” or 
    “Agreement“). If you do not agree to these Terms of Use, then you must not 
    use our Services. We recommend that you print a copy of these Terms for 
    future reference.</p>

  <p>Note that as set forth in our Privacy Policy, in the case of residents in 
    the European Economic Area (“EEA”), we do rely on consent as the legal 
    basis for processing personal data collected through Advertising or 
    Targeting Related Cookies (as described in our 
    <Link to="/legal/privacy-policy">Privacy Policy</Link>) and in that case 
    (only), we require explicit, opt-in consent. Your agreement to this Terms 
    of Use does not equate to a consent to the processing of such personal 
    data collected through Marketing Cookies – that is a separate consent 
    that EEA residents must give as described in our Privacy Policy.</p>

  <Header as='h4'>WE MAY MAKE CHANGES TO OUR SERVICES</Header>
  <p>We may update and change our Services from time to time to reflect 
    changes to our products, our users’ needs and our business priorities. 
    We will try to give you reasonable notice of any major changes.</p>

  <Header as='h4'>WE MAY MAKE CHANGES TO THIS AGREEMENT</Header>
  <p>We reserve the right, at our sole discretion, to change, modify, add, or 
    delete portions of this Agreement at any time and without further notice. 
    Please check this page regularly to determine if there have been changes 
    to these Terms of Use and to review such changes. Your continued use of 
    the Services after the effective date of the revised Terms of Use 
    constitutes your acceptance of the terms.</p>

  <p>PLEASE READ THESE TERMS OF USE CAREFULLY AS THEY CONTAIN IMPORTANT 
    INFORMATION REGARDING YOUR LEGAL RIGHTS, REMEDIES AND OBLIGATIONS, 
    INCLUDING BUT NOT LIMITED TO LIMITATIONS AND EXCLUSIONS</p>

  <Header as='h4'>WHEN THESE TERMS OF USE APPLY</Header>
  <p>These Terms of Use apply to all users alike. In order to use the Services, 
    you must accept these Terms of Use. You may do so by using any of the 
    Services. You confirm that you are of legal age to form a binding contract 
    with the Services Operator, or an emancipated minor, or have parental or 
    legal guardian consent and are fully able and competent to enter into, 
    and comply with, these Terms of Use. In any case, you confirm that you 
    are 16 or older (applicable to users located in California and in the 
    European Economic Area (“EEA”) excluding the U.K.) or 13 or older 
    (applicable to users located in the rest of the world including the U.K.), 
    and acknowledge that the Services are not intended for children under such 
    ages, as applicable. You may not use the Services and may not accept the 
    Terms of Use if you by law are barred from using the Services or accepting 
    the Terms of Use.</p>

  <Header as='h4'>TRADEMARKS</Header>
  <p>GameHoned, the GameHoned logos, and any other product or service name, 
    logo, or slogan used by GameHoned, and the look and feel of the Services, 
    including all page headers, custom graphics, button icons and scripts, are 
    trademarks or trade dress of GameHoned, and may not be used in whole or in 
    part in connection with any product or service that is not GameHoned’s, in 
    any manner that is likely to cause confusion among customers, or in any 
    manner that disparages or discredits GameHoned, without our prior written 
    permission. Any use of these trademarks must be in accordance with any 
    guidelines that GameHoned may provide you from time to time.</p>

  <p>All other trademarks referenced in the Services are the property of their 
    respective owners. Reference on the Services to any products, services, 
    processes or other information, by trade name, trademark, manufacturer, 
    supplier, or otherwise does not constitute or imply endorsement, 
    sponsorship, or recommendation thereof by us or any other affiliation.</p>

  <Header as='h4'>COMPANY ORIGINAL CONTENT</Header>
  <p>Articles and content that are created by the Company or one of its 
    licensors (for example, Company-created content that appears on 
    gamehoned.com) (collectively, the “Company Original Content”) is the 
    exclusive property of the Company and its licensors and is protected by 
    copyright and intellectual property laws and treaties, and all rights are 
    reserved. Such Company Original Content includes visual content such as 
    videos, articles, photographs, music, graphics, and logos.</p>

  <p>Except as explicitly provided herein, nothing in this Agreement shall be 
    deemed to create a license to the Company Original Content, and you agree 
    not to sell, license, rent, modify, distribute, copy, reproduce, transmit, 
    publicly display, publicly perform, publish, adapt, edit or create 
    derivative works from the Company Original Content. Subject to the terms 
    and conditions of this Agreement, Company provides you with a license to 
    use the Company Original Content for your personal, noncommercial use only. 
    Company may terminate this license at any time for any reason or no reason. 
    Use of Company Original Content for any purpose not expressly permitted by 
    this Agreement is strictly prohibited.</p>

  <p>We may provide opportunities to third parties (including community 
    members) to submit an application to provide original content, which 
    Company may approve or deny in its sole discretion. If such original 
    content is accepted by Company, it shall be subject to the rules for 
    Company Original Content that are set forth above and that a contributor 
    may agree to as part of the application process.</p>

  <p>The Company may also provide links to articles on third party websites, 
    as set forth below under Disclaimers and Limitation of Liability. Because 
    the Company has no control over such third party websites, the Company is 
    not responsible for the availability of such websites and is not 
    responsible for any content on such websites.</p>

  <Header as='h4'>PROMOTIONS</Header>
  <p>Users may promote, administer, or conduct a promotion (a contest or 
    sweepstakes) on, through or utilizing the Services (a “Promotion”). If you 
    choose to promote, administer or conduct a Promotion, you must adhere to 
    the following rules: (i) You may carry out Promotions to the extent 
    permitted by applicable law and you are solely responsible for ensuring 
    that any Promotions comply with any and all applicable laws, obligations, 
    and restrictions. (ii) You will be classified as the promoter of your 
    Promotion in the applicable jurisdiction(s) and you will be solely 
    responsible for all aspects of and expenses related to your Promotion, 
    including, without limitation, the execution, administration, and 
    operation of the Promotion; drafting and posting any official rules; 
    selecting winners; issuing prizes; and obtaining all necessary third-party 
    permissions and approvals, including, without limitation, filing any and 
    all necessary registrations and bonds. GameHoned has the right to remove 
    your Promotion from the Services if GameHoned reasonably believes that 
    your Promotion does not comply with the Terms of Use or applicable law. 
    (iii) GameHoned is not responsible for and does not endorse or support any 
    such Promotions. You may not indicate that GameHoned is a sponsor or 
    co-sponsor of the Promotion. (iv) You will display or read out the 
    following when a Promotion is connection with your Promotion: “This is a 
    promotion by [Your Name]. GameHoned does not sponsor or endorse 
    [Your Name]’s promotion and is not responsible for this promotion”.</p>

  <Header as='h4'>USER CONDUCT</Header>
  <p>You agree not to:</p>
  <List bulleted>
    <List.Item>Remove, circumvent, disable, damage or otherwise interfere with 
      security-related features of the Services or content, features that 
      prevent or restrict use or copying of any content accessible through 
      the Services, or delete the copyright or other proprietary rights 
      notices on the Services or user content</List.Item>
    <List.Item>Except as expressly permitted by the Company, 
      you may not modify, publish, transmit, reproduce, scrape, create 
      derivative works from, distribute, perform, adapt, aggregate, sell, 
      transfer or in any way exploit any of the content, in whole or 
      in part,</List.Item>
    <List.Item>Use any robot, spider, site search and/or retrieval application, 
      or other device to scrape, extract, retrieve or index any portion of the 
      content;</List.Item>
    <List.Item>use any Media Player for any purpose other than non-commercial, 
      personal consumption and viewing of applicable content;</List.Item>
  </List>
  <p>You further agree:</p>
  <List bulleted>
    <List.Item>To only use the Services in a manner consistent with these 
      Terms of Use and in compliance with applicable laws and 
      regulations;</List.Item>
    <List.Item>Not to intentionally block, remove, or otherwise obstruct the 
      proper functioning and view of advertisements, and/or user interface 
      and functionality;</List.Item>
    <List.Item>Not to upload, post, email, transmit or otherwise make 
      available any material that contains software viruses or any other 
      computer code, files or programs designed to interrupt, destroy, 
      limit the functionality of, or enable unauthorized access to any 
      computer software or hardware or telecommunications 
      equipment;</List.Item>
    <List.Item>Not to upload, post, email, transmit or otherwise make 
      available any content that infringes any patent, trademark, trade secret, 
      copyright or other proprietary rights of any party</List.Item>
    <List.Item>not use any robot, spider, scraper or other automated means 
      to access the Services for any purpose without our express written 
      permission;</List.Item>
    <List.Item>Not to forge headers, or manipulate source identifiers or 
      otherwise attempt to disguise the origin of any content made available 
      via the Services;</List.Item>
  </List>
  <p>You will not:</p>
  <List bulleted>
    <List.Item>take any action that imposes, or may impose in our sole 
      discretion an unreasonable or disproportionately large load on 
      our infrastructure;</List.Item>
    <List.Item>interfere or attempt to interfere with the proper working of 
      the Services or any activities conducted on a Service; or</List.Item>
    <List.Item>bypass any measures we may use to prevent or restrict access 
    to the Services, including robot exclusion headers.</List.Item>
  </List>
  <Header as='h4'>RESPECTING COPYRIGHT AND CLAIMS OF COPYRIGHT INFRINGEMENT</Header>
  <p>GameHoned respects the intellectual property of others and follows the 
    requirements set forth in the Digital Millennium Copyright Act (“DMCA”) 
    and other applicable laws. If you are a copyright owner or agent thereof 
    and believe that content posted on the Services infringes upon your 
    copyright, please contact our designated agent under the DMCA:</p>

    <p>dmca@gamehoned.com</p>
    <p>Please include “Claim of copyright infringement” in the subject of 
      your email. For clarity, only DMCA notices should go to the 
      Copyright Agent.</p>

  <Header as='h4'>IDEA SUBMISSION</Header>
  <p>By submitting ideas, suggestions, documents, and/or proposals 
    (“Submissions”) to GameHoned or its employees, you acknowledge and agree 
    that GameHoned shall be entitled to use or disclose such Submissions 
    for any purpose in any way without providing compensation or credit 
    to you.</p>
    
  <Header as='h4'>TERMINATION</Header>
  <p>To the fullest extent permitted by applicable law, GameHoned reserves 
    the right, without notice and in our sole discretion, to terminate your 
    license to use the Services, and to block or prevent your future access to 
    and use of the Services, including where we reasonably consider that: 
    (a) your use of the Services violates these Terms of Use or applicable 
    law; (b) you fraudulently use or misuse the Services; or (c) we are 
    unable to continue providing the Services to you due to technical or 
    legitimate business reasons. This includes the ability to terminate or to
    suspend your access to any purchased products or services, including any 
    subscriptions. To the fullest extent permitted by applicable law, your 
    only remedy with respect to any dissatisfaction with (i) the Services, 
    (ii) any term of these Terms of Use, (iii) any policy or practice of 
    GameHoned in operating the Services, or (iv) any content or information 
    transmitted through the Services, is to terminate your account and to 
    discontinue use of any and all parts of the Services.</p>
  <Header as='h4'>DISCLAIMERS; NO WARRANTIES</Header>
  <p>GameHoned does not guarantee that any unauthorized copying, use or 
    distribution of content submitted by you by third parties will not take 
    place. In addition, GameHoned is not responsible for the content or 
    conduct, whether online or offline, of any user of the Services or other 
    site or services that may be referenced, or linked to, in the 
    Services.</p>
  <p>Because Company has no control over such sites and services, you 
    acknowledge and agree that Company is not responsible for the availability 
    of such external sites or services, and does not endorse and is not 
    responsible or liable for any content, advertising, products or other 
    materials on or available from such sites or services. We encourage you to 
    be aware of when you leave the Services, and to read the terms and 
    conditions and privacy policy of any third-party website or service 
    that you visit.</p>
  <p>You understand and acknowledge that by using the Services you may be 
    exposed to content that you may find offensive, indecent or objectionable 
    and that, in this respect, you use the Services at your 
    own risk.</p>
  <p>TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW: 
    (A) THE SERVICES AND THE CONTENT AND MATERIALS CONTAINED THEREIN ARE 
    PROVIDED ON AN “AS IS” BASIS WITHOUT WARRANTIES OF ANY KIND, EITHER 
    EXPRESS OR IMPLIED, EXCEPT AS EXPRESSLY PROVIDED TO THE CONTRARY IN A 
    WRITING BY GAMEHONED; (B) GAMEHONED, AND ITS OFFICERS, DIRECTORS, 
    EMPLOYEES, SHAREHOLDERS, AGENTS, LICENSORS, SUBCONTRACTORS, AFFILIATES, 
    PARTNERS, AND SUPPLIERS (“GAMEHONED PARTIES”) DISCLAIM ALL OTHER 
    WARRANTIES, STATUTORY, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, 
    IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, 
    TITLE AND NON-INFRINGEMENT AS TO THE SERVICES, INCLUDING ANY INFORMATION, 
    CONTENT OR MATERIALS CONTAINED THEREIN; (C) NO GAMEHONED PARTY REPRESENTS 
    OR WARRANTS THAT CONTENT OR MATERIALS ON THE SERVICES ARE ACCURATE, 
    COMPLETE, RELIABLE, CURRENT OR ERROR-FREE; AND (D) WHILE GAMEHONED 
    ATTEMPTS TO MAKE YOUR ACCESS AND USE OF THE SERVICES SAFE, NO GAMEHONED 
    PARTY CAN AND DOES NOT REPRESENT OR WARRANT THAT THE SERVICES OR OUR 
    SERVER(S) ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. NO ADVICE OR 
    INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM GAMEHONED OR 
    THROUGH THE SERVICES WILL CREATE ANY WARRANTY NOT EXPRESSLY STATED HEREIN. 
    GAMEHONED DOES NOT AND CANNOT GUARANTEE ANY SPECIFIC RESULTS FROM USE OF 
    THE SERVICES.</p>
  <Header as='h4'>LIMITATION ON LIABILITY</Header>
  <p>TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW: 
    (A) IN NO EVENT SHALL GAMEHONED OR ANY GAMEHONED PARTY BE LIABLE FOR ANY 
    DIRECT, SPECIAL, INDIRECT OR CONSEQUENTIAL DAMAGES, OR ANY OTHER DAMAGES 
    OF ANY KIND, INCLUDING BUT NOT LIMITED TO LOSS OF USE, LOSS OF PROFITS OR 
    LOSS OF DATA, WHETHER IN AN ACTION IN CONTRACT, TORT (INCLUDING BUT NOT 
    LIMITED TO NEGLIGENCE) OR OTHERWISE, ARISING OUT OF OR IN ANY WAY 
    CONNECTED WITH THE USE OF OR INABILITY TO USE THE SERVICES, THE CONTENT OR 
    THE MATERIALS, INCLUDING WITHOUT LIMITATION ANY DAMAGES CAUSED BY OR 
    RESULTING FROM RELIANCE BY USER ON ANY INFORMATION OBTAINED FROM GAMEHONED, 
    OR THAT RESULT FROM MISTAKES, OMISSIONS, INTERRUPTIONS, DELETION OF FILES 
    OR EMAIL, ERRORS, DEFECTS, VIRUSES, DELAYS IN OPERATION OR TRANSMISSION 
    OR ANY FAILURE OF PERFORMANCE, WHETHER OR NOT RESULTING FROM ACTS OF GOD, 
    COMMUNICATIONS FAILURE, THEFT, DESTRUCTION OR UNAUTHORIZED ACCESS TO 
    GAMEHONED’S RECORDS, PROGRAMS OR SERVICES; AND (B) IN NO EVENT SHALL THE 
    AGGREGATE LIABILITY OF GAMEHONED, WHETHER IN CONTRACT, WARRANTY, TORT 
    (INCLUDING NEGLIGENCE, WHETHER ACTIVE, PASSIVE OR IMPUTED), PRODUCT 
    LIABILITY, STRICT LIABILITY OR OTHER THEORY, ARISING OUT OF OR RELATING 
    TO THE USE OF OR INABILITY TO USE THE SERVICES EXCEED THE AMOUNT PAID BY 
    YOU, IF ANY, FOR ACCESSING THE SERVICES DURING THE TWELVE (12) MONTHS 
    IMMEDIATELY PRECEDING THE DATE OF THE CLAIM OR ONE HUNDRED DOLLARS, 
    WHICHEVER IS GREATER.</p>
  <p>We do not exclude or limit in any way our liability to you where it would 
    be unlawful to do so.</p>
  <p>YOU ACKNOWLEDGE AND AGREE THAT GAMEHONED HAS OFFERED THE SERVICES, 
    MATERIALS AND OTHER CONTENT AND INFORMATION, SET ITS PRICES, AND ENTERED 
    INTO THESE TERMS OF USE IN RELIANCE UPON THE WARRANTY DISCLAIMERS AND 
    THE LIMITATIONS OF LIABILITY SET FORTH HEREIN, THAT THE WARRANTY 
    DISCLAIMERS AND THE LIMITATIONS OF LIABILITY SET FORTH HEREIN REFLECT A 
    REASONABLE AND FAIR ALLOCATION OF RISK BETWEEN YOU AND GAMEHONED, AND THAT 
    THE WARRANTY DISCLAIMERS AND THE LIMITATIONS OF LIABILITY SET FORTH HEREIN 
    FORM AN ESSENTIAL BASIS OF THE BARGAIN BETWEEN YOU AND GAMEHONED. GAMEHONED 
    WOULD NOT BE ABLE TO PROVIDE THE SERVICES TO YOU ON AN ECONOMICALLY 
    REASONABLE BASIS WITHOUT THESE LIMITATIONS.</p>
  <p>YOU AND GAMEHONED AGREE THAT ANY CAUSE OF ACTION ARISING OUT OF OR RELATED 
    TO THE SERVICES MUST COMMENCE WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION 
    ACCRUES. OTHERWISE, SUCH CAUSE OF ACTION IS PERMANENTLY BARRED.</p>
  <Header as='h4'>INDEMNITY</Header>
  <p>You agree to indemnify, defend, and hold harmless GameHoned, its 
    affiliated companies, contractors, employees, agents and its third-party 
    suppliers, licensors, and partners from any claims, losses, damages, 
    liabilities, including legal fees and expenses, arising out of your use 
    or misuse of the Services, any user content you post, store or otherwise 
    transmit in or through the Services, your violation of the rights of any 
    third party, any violation by you of these Terms of Use, or any breach of 
    the representations, warranties, and covenants made by you herein. 
    GameHoned reserves the right, at your expense, to assume the exclusive 
    defense and control of any matter for which you are required to indemnify 
    GameHoned, and you agree to cooperate with GameHoned’s defense of these 
    claims. GameHoned will use reasonable efforts to notify you of any such 
    claim, action, or proceeding upon becoming aware of it. The foregoing 
    indemnity includes, without limitation, any claims, losses, damages, 
    liabilities, including legal fees and expenses, arising out of your 
    Promotions or user content.</p>
  <Header as='h4'>GOVERNING LAW, VENUE, AND JURISDICTION</Header>
  <p>To the fullest extent permitted by applicable law, the laws of the 
    Province of Quebec, without regard to principles of conflict of laws, 
    govern these Terms of Use and any dispute of any sort that might arise 
    between you and the Company or any of our affiliates. To the fullest 
    extent permitted by applicable law, you agree not to commence or prosecute 
    any action in connection with your use of the Services other than in the 
    state and federal courts of Quebec, and you hereby consent to and 
    waive all defenses of lack of personal jurisdiction and forum non 
    conveniens with respect to venue and jurisdiction in the state and 
    federal courts of Quebec.</p>
  <Header as='h4'>OTHER TERMS AND CONDITIONS</Header>
  <p>To the extent that a translation of these Terms of Use differs from the 
    English language version, the English language version controls.</p>
  <p>If the Company does not exercise or enforce any right, remedy, or 
    provision of these Terms of Use, this shall not constitute a waiver of 
    such right or provision in that or any other instance.</p>
  <p>If any provision of these Terms of Use is held to be unlawful, void, or 
    for any reason unenforceable, then that provision will be limited or 
    eliminated from these Terms of Use to the minimum extent necessary and 
    will not affect the validity and enforceability of any remaining 
    provisions.</p>
  <p>These Terms of Use, and any rights and licenses granted hereunder, may 
    not be transferred or assigned by you, but may be assigned by GameHoned 
    without restriction. Any assignment attempted to be made in violation of 
    these Terms of Use shall be void.</p>
  <p>The Terms of Use, together with the Privacy Policy, is the entire 
    agreement between you and GameHoned relating to the subject matter 
    herein and will not be modified except in writing, signed or otherwise 
    agreed to by both parties, or by a change to these Terms of Use or 
    Guidelines made by GameHoned as set forth in Section 6 above.</p>
  
</div>;
};

export default TermsOfUsePage;