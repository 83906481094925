import Scaling from '../../Scaling.js';
import BaseWeapon from '../base.js';

const FavoniusLance = (props) => {
  
  return BaseWeapon({
    ...props,
    type: 'polearm',
    name: 'Favonius Lance',
    lvl1Atk: 44,
    lvl1Sub: 0.067,
    subType: 'energyRecharge',
    PersistentResolve(result, context) {
      /**
       * effect: 'CRIT Hits have a [60+10%] chance to generate a small amount 
       * of Elemental Particles, which will regenerate 6 Energy for the 
       * character. Can only occur once every [12-1.5s]'
       */
      if(this.IsSelf(result)) {
      }
      return result;
    }
  });
};

export default FavoniusLance;