import Scaling from '../../Scaling.js';
import BaseWeapon from '../base.js';

const SkyriderSword = (props) => {
  
  return BaseWeapon({
    ...props,
    type: 'sword',
    name: 'Skyrider Sword',
    lvl1Atk: 38,
    lvl1Sub: 0.113,
    subType: 'energyRecharge',
    PersistentResolve(result, context) {
      /**
       * effect: 'Using an Elemental Burst grants a [12%] increase in ATK and 
       * Movement SPD for 15s.'
       */
      if(this.IsSelf(result)) {
      }
      return result;
    }
  });
};

export default SkyriderSword;