import {BaseSet} from './base.js';

const LuckyDog = (props) => {
  
  return BaseSet({
    ...props,
    name: 'Lucky Dog',
    two: {
      attributes: {
        defense: 100
      },
      PersistentResolve(result, context) {
        return result;
      }
    },
    four: {
      PersistentResolve(result, context) {
        return result;
      }
    }
  });
};

export default LuckyDog;