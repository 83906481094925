import Scaling from '../../Scaling.js';
import BaseWeapon from '../base.js';

const BlackcliffPole = (props) => {
  
  return BaseWeapon({
    ...props,
    type: 'polearm',
    name: 'Blackcliff Pole',
    lvl1Atk: 42,
    lvl1Sub: 0.12,
    subType: 'critDmg',
    PersistentResolve(result, context) {
      /**
       * effect: 'After defeating an enemy, ATK is increased by [12%] for 30s. 
       * This effect has a maximum of 3 stacks, and the duration of each 
       * stack is independent of the others.'
       */
      if(this.IsSelf(result)) {
      }
      return result;
    }
  });
};

export default BlackcliffPole;