import {BaseSet} from './base.js';

const Gambler = (props) => {
  
  return BaseSet({
    ...props,
    name: 'Gambler',
    two: {
      PersistentResolve(result, context) {
        if(!this.IsSelf(result)) return result;
        if(result.type==="Skill") {
          result.attributes.damageBonus.all += 0.20;
          result.logs.push(this.name + ' increases Elemental Skill Damage by 20%');
        }
        return result;
      }
    },
    four: {
      PersistentResolve(result, context) {
        return result;
      }
    }
  });
};

export default Gambler;