import Tools from '../../Tools.js';
import Scaling from '../../Scaling.js';
import BaseCharacter from '../base.js';

var Jean = (props) => {
  
  let scaleTable = {
    ascension0: {
      min: {level:1, maxHP:1144, baseAtk:19, defense:60, attr:0},
      max: {level:20, maxHP:2967, baseAtk:49, defense:155, attr:0}
    },
    ascension1: {
      min: {level:20, maxHP:3948, baseAtk:65, defense:206, attr:0},
      max: {level:40, maxHP:5908, baseAtk:96, defense:309, attr:0}
    },
    ascension2: {
      min: {level:40, maxHP:6605, baseAtk:108, defense:345, attr:0.055},
      max: {level:50, maxHP:7599, baseAtk:124, defense:397, attr:0.055}
    },
    ascension3: {
      min: {level:50, maxHP:8528, baseAtk:139, defense:446, attr:0.111},
      max: {level:60, maxHP:9533, baseAtk:155, defense:499, attr:0.111}
    },
    ascension4: {
      min: {level:60, maxHP:10230, baseAtk:166, defense:535, attr:0.111},
      max: {level:70, maxHP:11243, baseAtk:183, defense:588, attr:0.111}
    },
    ascension5: {
      min: {level:70, maxHP:11940, baseAtk:195, defense:624, attr:0.166},
      max: {level:80, maxHP:12965, baseAtk:211, defense:678, attr:0.166}
    },
    ascension6: {
      min: {level:80, maxHP:13662, baseAtk:222, defense:715, attr:0.222},
      max: {level:90, maxHP:14695, baseAtk:239, defense:769, attr:0.222}
    }
  };
  return BaseCharacter({
    ...props,
    name: 'Jean',
    element: 'anemo',
    equip: 'sword',
    scaleTable: scaleTable,
    scaleAttr: 'healingPct',
    attackTalent: {
      description: 'Favonius Bladework',
      level: props.attack,
      type: "NormalAtk",
      defaultImpl: {
        talentProperties: {
          comboTable: [
            {dmg:0.483, anim:0.23}, 
            {dmg:0.456, anim:0.23}, 
            {dmg:0.603, anim:0.5}, 
            {dmg:0.659, anim:0.8}, 
            {dmg:0.792, anim:0.6}
          ]
        },
        duration: 1,
        Resolve(result) {
          result = Scaling.ScaleAttackCombo(result);
          return result;
        }
      },
      chargedImpl: {
        description: 'Favonius Bladework - Charged',
        level: props.attack,
        type: "ChargeAtk",
        duration: 0.87,
        Resolve(result) {
          let slash = Tools.CreateTalentSideEffect(result, 'default', {
            description: 'Favonius Bladework - Charged'
          });
          slash.precedingResult = result.precedingResult;
          result.talentEffects.push(slash);
          
          result = Scaling.ScaleAttackTalent(1.62, result);
          return result;
        }
      }
    },
    skillTalent: {
      description:'Gale Blade',
      level: props.skill,
      type: 'Skill',
      defaultImpl: {
        duration:0.66,
        Prepare(result) {
          result.attributes.element = 'anemo';
          return result;
        },
        Resolve(result) {
          result = Scaling.ScaleTalent(2.92, result);
          return result;
        }
      },
      holdImpl: {
        description: 'Gale Blade - Hold',
        duration:1,
        Prepare(result) {
          result.attributes.element = 'anemo';
          return result;
        },
        Resolve(result) {
          result = Scaling.ScaleTalent(2.92, result);
          return result;
        }
      }
    },
    burstTalent: {
      description: 'Dandelion Breeze',
      level: props.burst,
      type: 'Burst',
      defaultImpl: {
        duration: 1.77,
        Prepare(result) {
          result.attributes.element = 'anemo';
          return result;
        },
        Resolve(result) {
          result.effects.push(Tools.CreateTalentSideEffect(result, 'field'));
          result = Scaling.ScaleTalent(4.25, result);
          return result;
        }
      },
      fieldEffect: {
        description: 'Dandelion Field',
        entity: 'Jean\'s Dandelion Breeze',
        duration: 10,
        relativeTime: (r => -r.precedingResult.duration),
        Resolve(result) {
          return result;
        }
      }
      
    },
    passiveAsc1: {
      description: 'Wind Companion',
      PersistentResolve(result, context) {
        return result;
      }
    },
    passiveAsc4: {
      description: 'Let the Wind Lead',
      PersistentResolve(result, context) {
        return result;
      }
    },
    passiveOther: [],
    constellations: [
      ...BaseCharacter.Constellation35
    ]
  });
};

export default Jean;