import Scaling from '../../Scaling.js';
import BaseWeapon from '../base.js';

const PrototypeCrescent = (props) => {
  
  return BaseWeapon({
    ...props,
    type: 'bow',
    name: 'Prototype Crescent',
    lvl1Atk: 42,
    lvl1Sub: 0.09,
    subType: 'atkPct',
    PersistentResolve(result, context) {
      /**
       * effect: 'Aimed Shot hits on weak points increase Movement SPD by 10% 
       * and ATK by 36% for 10s.'
       */
      if(this.IsSelf(result)) {
      }
      return result;
    }
  });
};

export default PrototypeCrescent;