import React, {useState, useEffect, Fragment} from 'react';
import { Button, Image, Popup, Icon, Header } from 'semantic-ui-react';
import ReactGA from 'react-ga';
//import Characters from './db/characters.js';

const TalentButton = (props) => {
  const [character, setCharacter] = useState(props.character);
  const [talent, setTalent] = useState({
    talent: props.talent, 
    data: props.data,
    impl: props.data.impl.name, 
    name: props.data.talent.name,
    description: props.data.talent.name+(props.data.impl.description?" - "+props.data.impl.description:"")
  });
  const [style, setStyle] = useState({
    borderColor: "white", 
    borderWidth: "thin",
    borderStyle: "groove",
    boxShadow: "1px 1px 10px "+"lightgray"
  });
  
  useEffect(() => {
    let talentDesc = props.data.talent.name+(props.data.impl.description?" - "+props.data.impl.description:"");
    setCharacter(props.character);
    setTalent({
      talent: props.talent, 
      impl: props.data.impl, 
      name: props.data.talent.name, 
      description: talentDesc
    });
    setStyle({
      borderColor: (props.data.impl.border || "white"), 
      borderWidth: "thin",
      borderStyle: "groove",
      boxShadow: "1px 1px 10px "+(props.data.impl.color || "lightgray")
    });
  }, [props])
  
  return <Popup position={props.popPosition || "top center"} pinned hoverable trigger={
    <Button onBlur={(e) => props.onBlur ? props.onBlur(e) : null}
      onFocus={(e) => props.onFocus ? props.onFocus(e) : null}
      onMouseEnter={(e) => props.onMouseEnter ? props.onMouseEnter(e) : null}
      onMouseLeave={(e) => props.onMouseLeave ? props.onMouseLeave(e) : null}
      onClick={(e) => {
        if(props.onClick) {
          props.onClick(e)
        }
        ReactGA.event({
          category: 'Character Ability',
          action: character.name,
          label: talent.description
        });
        /*if(talent.impl.devStage!=="NotImpl")*/ {
          props.onTalent(character, talent);
        }
      }} 
      circular 
      size={talent.impl.name==='default'?'big':'mini' }
      compact 
      style={{padding: "0", backgroundColor: "transparent", margin: 0}}
      aria-label={character.name + ' ' + talent.name}>
        <Image 
          src={
            '/static/talents/'
            + character.name.toLowerCase().replaceAll(/[^a-z0-9]/gi, "")
            + '-'
            + talent.name.toLowerCase().replaceAll(/[^a-z0-9]/gi, "")
            + '.png'
          } 
          aria-label={character.name + ' ' + talent.name}
          avatar 
          disabled={false/*talent.impl.devStage==="NotImpl"*/}
          style={style} />
      </Button>
    } >
      <Header style={{whiteSpace:"nowrap"}}>
        {talent.description}
        {(()=>{
          let icon;
          switch(talent.impl.devStage) {
            case "Testing":
              icon = <Header.Subheader><Icon name="warning sign" style={{color:"orange"}} />In testing</Header.Subheader>;
              break;
            case "NotImpl":
              icon = <Header.Subheader><Icon name="times circle" style={{color:"red"}} />Not yet implemented</Header.Subheader>;
              break;
          }
          return icon;
        })()}
      </Header>
    </Popup>;
};

export default TalentButton;