import Scaling from '../../Scaling.js';
import BaseWeapon from '../base.js';

const TheFlute = (props) => {
  
  return BaseWeapon({
    ...props,
    type: 'sword',
    name: 'The Flute',
    lvl1Atk: 42,
    lvl1Sub: 0.09,
    subType: 'atkPct',
    PersistentResolve(result, context) {
      /**
       * effect: 'Normal or Charged Attacks grant a Harmonic on hits. 
       * Gaining 5 Harmonics triggers the power of music and deals 
       * [100%] ATK DMG to surrounding enemies. Harmonics last up to 30s, 
       * and a maximum of 1 can be gained every 0.5s.'
       */
      if(this.IsSelf(result)) {
      }
      return result;
    }
  });
};

export default TheFlute;