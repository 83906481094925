import Scaling from '../../Scaling.js';
import BaseWeapon from '../base.js';

const SacrificialGreatsword = (props) => {
  
  return BaseWeapon({
    ...props,
    type: 'claymore',
    name: 'Sacrificial Greatsword',
    lvl1Atk: 44,
    lvl1Sub: 0.067,
    subType: 'energyRecharge',
    PersistentResolve(result, context) {
      /**
       * effect: 'After damaging an opponent with an Elemental Skill, the skill 
       * has a [40%] chance to end its own CD. 
       * Can only occur once every [30-4,4,4,3,3s].'
       */
      if(this.IsSelf(result)) {
      }
      return result;
    }
  });
};

export default SacrificialGreatsword;