import Scaling from '../../Scaling.js';
import BaseWeapon from '../base.js';

const DebateClub = (props) => {
  
  return BaseWeapon({
    ...props,
    type: 'claymore',
    name: 'Debate Club',
    lvl1Atk: 39,
    lvl1Sub: 0.077,
    subType: 'atkPct',
    PersistentResolve(result, context) {
      /**
       * effect: 'After using an Elemental Skill, Normal or Charged Attacks, 
       * on hit, deal an additional [60%] ATK DMG in a small area. 
       * Effect lasts 15s. DMG can only occur once every 3s.'
       */
      if(this.IsSelf(result)) {
      }
      return result;
    }
  });
};

export default DebateClub;