import Scaling from '../../Scaling.js';
import BaseWeapon from '../base.js';

const DragonspineSpear = (props) => {
  
  return BaseWeapon({
    ...props,
    type: 'polearm',
    name: 'Dragonspine Spear',
    lvl1Atk: 41,
    lvl1Sub: 0.15,
    subType: 'damageBonus.physical',
    PersistentResolve(result, context) {
      /**
       * effect: 'Hitting an opponent with Normal and Charged Attacks has a 
       * [60%] chance of forming and dropping an Everfrost Icicle above them, 
       * dealing [80%] AoE ATK DMG. Opponents affected by Cryo are dealt 
       * [200%] ATK DMG. Can only occur once every 10s.'
       */
      if(this.IsSelf(result)) {
      }
      return result;
    }
  });
};

export default DragonspineSpear;