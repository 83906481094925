import {BaseSet} from './base.js';

const MaidenBeloved = (props) => {
  
  return BaseSet({
    ...props,
    name: 'Maiden Beloved',
    two: {
      attributes: {
        //TODO: implement healing
      },
      PersistentResolve(result, context) {
        return result;
      }
    },
    four: {
      PersistentResolve(result, context) {
        if(!this.IsSelf(result)) return result;
        //TODO: implement healing
        return result;
      }
    }
  });
};

export default MaidenBeloved;