import Tools from '../../Tools.js';
import Scaling from '../../Scaling.js';
import BaseCharacter from '../base.js';

var Diluc = (props) => {
  
  let scaleTable = {
    ascension0: {
      min: {level:1, maxHP:1011, baseAtk:26, defense:61, attr:0},
      max: {level:20, maxHP:2621, baseAtk:68, defense:158, attr:0}
    },
    ascension1: {
      min: {level:20, maxHP:3488, baseAtk:90, defense:211, attr:0},
      max: {level:40, maxHP:5219, baseAtk:135, defense:315, attr:0}
    },
    ascension2: {
      min: {level:40, maxHP:5834, baseAtk:151, defense:352, attr:0.048},
      max: {level:50, maxHP:6712, baseAtk:173, defense:405, attr:0.048}
    },
    ascension3: {
      min: {level:50, maxHP:7533, baseAtk:195, defense:455, attr:0.096},
      max: {level:60, maxHP:8421, baseAtk:217, defense:509, attr:0.096}
    },
    ascension4: {
      min: {level:60, maxHP:9036, baseAtk:233, defense:546, attr:0.096},
      max: {level:70, maxHP:9932, baseAtk:256, defense:600, attr:0.096}
    },
    ascension5: {
      min: {level:70, maxHP:10547, baseAtk:272, defense:637, attr:0.144},
      max: {level:80, maxHP:11453, baseAtk:296, defense:692, attr:0.144}
    },
    ascension6: {
      min: {level:80, maxHP:12068, baseAtk:312, defense:729, attr:0.192},
      max: {level:90, maxHP:12981, baseAtk:335, defense:784, attr:0.192}
    }
  };
  return BaseCharacter({
    ...props,
    name: 'Diluc',
    element: 'pyro',
    equip: 'claymore',
    scaleTable: scaleTable,
    scaleAttr: 'critRate',
    attackTalent: {
      description: 'Tempered Sword',
      level: props.attack,
      type: "NormalAtk",
      defaultImpl: {
        talentProperties: {
          comboTable: [
            {dmg:0.897, anim:0.375}, 
            {dmg:0.8763, anim:0.8}, 
            {dmg:0.9881, anim:0.5}, 
            {dmg:1.3399, anim:1}
          ]
        },
        duration: 1,
        Resolve(result) {
          result = Scaling.ScaleAttackCombo(result);
          return result;
        }
      },
      chargedImpl: {
        description: "Tempered Sword - Charged",
        type: "ChargeAtk",
        duration: 1.5,
        Resolve(result) {
          result = Scaling.ScaleTalent(1.247, result);
          return result;
        }
      },
      spinningImpl: {
        description: "Tempered Sword - Windup",
        type: "ChargeAtkHold",
        duration: 0.5,
        Prepare(result) {
          let precedingResult = result;
          let spinCount = 10 + (result.initiator.ascension>=1?3:0);
          [...Array(spinCount)].forEach((e,i) => {
            if(i<spinCount-1) {
              precedingResult = Tools.CreateTalentSideEffect(precedingResult, "spinRotation", {
                description: `Tempered Sword - Spin Attack (${i+1} of ${spinCount-1})`
              });
            } else {
              precedingResult = Tools.CreateTalentSideEffect(precedingResult, "charged", {
                description: 'Tempered Sword - Final Blow'
              });
            }
            result.talentEffects.push(precedingResult);
          });
          return result;
        },
        Resolve(result) {
          //account for windup animation
          result.setDuration(0.5);
          return result;
        }
      },
      spinRotationEffect: {
        description: 'Tempered Sword - Spin Attack',
        entity: 'Diluc',
        duration: 0.5,
        effectExtension: true,
        type: 'ChargeAtkHold',
        Resolve(result) {
          result = Scaling.ScaleTalent(0.688, result);
          return result;
        }
      }
    },
    skillTalent: {
      description:'Searing Onslaught',
      level: props.skill,
      type: 'Skill',
      track: {lastUsed:-999, comboStep:0},
      defaultImpl: {
        duration: 0.625,
        Prepare(result) {
          let delay = result.time - this.track.lastUsed;
          if((delay >= 5 && delay < 15)
            || (delay < 5 && this.track.comboStep>=3)) {
              this.track.comboStep = 0;
              result = Tools.CreateCooldownEffect(result, {cooldown:10});
          }
          if(delay>5) {
            this.track.comboStep = 0;
          }
          result.attributes.element = 'pyro';
          return result;
        },
        Resolve(result) {
          let comboDmg = [.944, .976, 1.29]
          result = Scaling.ScaleTalent(comboDmg[this.track.comboStep], result);
          this.track.lastUsed = result.time;
          this.track.comboStep++;
          return result;
        }
      }
    },
    burstTalent: {
      description: 'Dawn',
      level: props.burst,
      type: 'Burst',
      cooldown: 12,
      defaultImpl: {
        duration: 2.5,
        Prepare(result) {
          result.attributes.element = 'pyro';
          result.effects.push(Tools.CreateTalentSideEffect(result, 'infusion'));
          let prec = Tools.CreateTalentSideEffect(result, 'slash');
          result.effects.push(prec);
          [...Array(8)].forEach((v,i) => {
            prec = Tools.CreateTalentSideEffect(prec, 'dot', {
              description: `Dawn - DoT & Knockback (${i+1} of 8)`
            });
            result.effects.push(prec);
          });
          result.effects.push(Tools.CreateTalentSideEffect(prec.precedingResult, 'explosion'));
          return result;
        },
        Resolve(result) {
          return result;
        }
      },
      slashEffect: {
        description: 'Dawn - Slashing',
        entity: 'Diluc\'s Dawn',
        duration: 0.3,
        relativeTime: -0.8,
        Prepare(result) {
          result.attributes.element = 'pyro';
          return result;
        },
        Resolve(result) {
          result = Scaling.ScaleTalent(2.04, result);
          return result;
        }
      },
      dotEffect: {
        description: 'Dawn - DoT & Knockback',
        entity: 'Diluc\'s Dawn',
        duration: 0.2,
        Prepare(result) {
          result.attributes.element = 'pyro';
          return result;
        },
        Resolve(result) {
          result = Scaling.ScaleTalent(0.6, result);
          return result;
        }
      },
      explosionEffect: {
        description: 'Dawn - Explosion',
        entity: 'Diluc\'s Dawn',
        duration: 0.2,
        Prepare(result) {
          result.attributes.element = 'pyro';
          return result;
        },
        Resolve(result) {
          result = Scaling.ScaleTalent(2.04, result);
          return result;
        }
      },
      infusionEffect: {
        description: 'Dawn - Infusion',
        entity: 'Diluc\'s Dawn',
        duration: 8,
        relativeTime: (r => -r.precedingResult.duration),
        PersistentResolve(result, context) {
          if(context.initiator===result.initiator && ["ChargeAtk", "NormalAtk"].find(t => result.type.startsWith(t))) {
            result.logs.push('Dawn infuses Diluc\'s weapon with pyro');
            result.attributes.element = 'pyro';
          }
          return result;
        }
      }
    },
    passiveAsc1: {
      description: 'Relentless',
      PersistentResolve(result, context) {
        return result;
      }
    },
    passiveAsc4: {
      description: 'Blessing of Phoenix',
      PersistentResolve(result, context) {
        return result;
      }
    },
    passiveOther: [{
      description: 'Tradition of the Dawn Knight',
      PersistentResolve(result, context) {
        return result;
      }
    }],
    constellations: [
      ...BaseCharacter.Constellation35
    ]
  });
};

export default Diluc;