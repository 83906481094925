import Tools from '../../Tools.js';
import Scaling from '../../Scaling.js';
import BaseCharacter from '../base.js';

//TODO: implement character
var Ningguang = (props) => {
  
  let scaleTable = {
    ascension0: {
      min: {level:1, maxHP:821, baseAtk:18, defense:48, attr:0},
      max: {level:20, maxHP:2108, baseAtk:46, defense:123, attr:0}
    },
    ascension1: {
      min: {level:20, maxHP:2721, baseAtk:59, defense:159, attr:0},
      max: {level:40, maxHP:4076, baseAtk:89, defense:239, attr:0}
    },
    ascension2: {
      min: {level:40, maxHP:4512, baseAtk:98, defense:264, attr:0.06},
      max: {level:50, maxHP:5189, baseAtk:112, defense:304, attr:0.06}
    },
    ascension3: {
      min: {level:50, maxHP:5770, baseAtk:125, defense:338, attr:0.12},
      max: {level:60, maxHP:6448, baseAtk:140, defense:378, attr:0.12}
    },
    ascension4: {
      min: {level:60, maxHP:6884, baseAtk:150, defense:403, attr:0.12},
      max: {level:70, maxHP:7561, baseAtk:164, defense:443, attr:0.12}
    },
    ascension5: {
      min: {level:70, maxHP:7996, baseAtk:173, defense:468, attr:0.18},
      max: {level:80, maxHP:8674, baseAtk:188, defense:508, attr:0.18}
    },
    ascension6: {
      min: {level:80, maxHP:9110, baseAtk:198, defense:533, attr:0.24},
      max: {level:90, maxHP:9787, baseAtk:212, defense:573, attr:0.24}
    }
  };
  return BaseCharacter({
    ...props,
    name: 'Ningguang',
    element: 'geo',
    equip: 'catalyst',
    scaleTable: scaleTable,
    scaleAttr: 'damageBonus.geo',
    attackTalent: {
      description: 'Sparkling Scatter',
      level: props.attack,
      type: "NormalAtk",
      defaultImpl: {
        talentProperties: {
          comboTable: [
            {dmg:0.711, anim:0.5}, 
            {dmg:0.709, anim:0.6}, 
            {dmg:0.883, anim:1}, 
            {dmg:0.865, anim:0.75}, 
            {dmg:1.12, anim:1}
          ]
        },
        duration: 1,
        Resolve(result) {
          result = Scaling.ScaleAttackCombo(result);
          result.attributes.element='physical';
          return result;
        }
      },
      chargedImpl: {
        type: "ChargeAtk",
        duration: 1,
        Resolve(result) {
          result = Scaling.ScaleTalent(1.02, result);
          result.attributes.element='physical';
          return result;
        }
      }
    },
    skillTalent: {
      description:'Jade Screen',
      level: props.skill,
      type: 'Skill',
      defaultImpl: {
        Prepare(result) {
          return result;
        },
        Resolve(result) {
          return result;
        }
      }
    },
    burstTalent: {
      description: 'Starshatter',
      level: props.burst,
      type: 'Burst',
      defaultImpl: {
        Prepare(result) {
          return result;
        },
        Resolve(result) {
          return result;
        }
      }
    },
    passiveAsc1: {
      description: 'Backup Plan',
      PersistentResolve(result, context) {
        return result;
      }
    },
    passiveAsc4: {
      description: 'Strategic Reserve',
      PersistentResolve(result, context) {
        return result;
      }
    },
    passiveOther: [{
      description: 'Trove of Marvelous Treasures',
      PersistentResolve(result, context) {
        return result;
      }
    }],
    constellations: [
      ...BaseCharacter.Constellation53,
    ]
  });
};

export default Ningguang;