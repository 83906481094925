import API from '../domain/API.js';

const APIClient = {
  /**
   * @cfg =  {
      name, 
      level, 
      constellation, 
      ascension, 
      talents: {
        attack,
        skill
        burst
      }
      weapon:{
        name, 
        level, 
        ascension, 
        refinement
      }, 
      artifacts:{
        set:[{
          name, 
          pcs
        }], 
        stats:[{
          hp, 
          hppct, 
          atk, 
          atkpct, 
          def, 
          defpct, 
          cr, 
          cd, 
          em, 
          er, 
          pdmg, 
          edmg,
          hb
        }]
      }
    }
   * @returns = {
     attributes
    }
   */
  SelectCharacter(cfg) {
    return JSON.parse(API.SelectCharacter(JSON.stringify(cfg)));
  },
  /**
   * @cfg = {
      team:[
        {
          name, 
          level, 
          constellation, 
          ascension, 
          talentLevel: {
            attack,
            skill
            burst
          }
          weapon:{
            name, 
            level, 
            ascension, 
            refinement
          }, 
          artifacts:{
            set:[{
              name, 
              pcs
            }], 
            stats:[{
              hp, 
              hppct, 
              atk, 
              atkpct, 
              def, 
              defpct, 
              cr, 
              cd, 
              em, 
              er, 
              pdmg, 
              edmg,
              hb
            }]
          }
        }
      ],
      monster:[{
        name,
        level
      }],
      history:[
        {
          char,
          talent
        }
      ]
    }
    * @return = [{
      
    }]
  **/
  RunSimulator(cfg) {
    let r = JSON.parse(API.RunSimulator(JSON.stringify(cfg)));
    return r;
  }
};

export default APIClient;