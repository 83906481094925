import Scaling from '../../Scaling.js';
import BaseWeapon from '../base.js';

const EbonyBow = (props) => {
  
  const mechanoid = ['Ruin Guard', 'Ruin Hunter'];
  
  return BaseWeapon({
    ...props,
    type: 'bow',
    name: 'Ebony Bow',
    lvl1Atk: 40,
    lvl1Sub: 0.063,
    subType: 'atkPct',
    PersistentResolve(result, context) {
      /**
       * effect: 'Increases DMG against mechanoid Ruin enemies by [40%].'
       */
      if(this.IsSelf(result)) {
        if(mechanoid.find(m => m===result.target.name)) {
          result.logs.push('Ebony Bow attacks against Mechanoid Ruin enemies increased by '+(Scaling.ScaleWeaponPassive(0.40, this.refinement)*100)+'%');
          result.attributes.damageBonus.all += Scaling.ScaleWeaponPassive(0.40, this.refinement);
        }
      }
      return result;
    }
  });
};

export default EbonyBow;