import Tools from './Tools.js';
import Explainer from './Explainer.js';

const Result = (props) => {
  //console.log("Result", props.description, props);
  return {
    /**
     * Status, 
     * EffectOverTime, 
     * NormalAtk-1, NormalAtk-2, NormalAtk-3, NormalAtk-4, NormalAtk-5, ...
     * ChargeAtk, ChargeAtkHold
     * Skill, SkillHold
     * Burst
     * Switch
     * Idle
     **/
    type: props.type,
    effectExtension: props.effectExtension || null,
    description: props.description,
    initiator: props.initiator,
    talent: props.talent || {track: {lastStatusApplied:-999, lastUsed:-999}},
    talentImpl: props.talentImpl,
    talentProperties: props.talentProperties || {},
    comboStep: props.comboStep || null,
    enforceCooldown: props.enforceCooldown || false,
    cooldown: props.cooldown || 0,
    forceStatus: false,
    target: props.target,
    entity: props.entity || props.initiator.name,
    relativeTime: props.relativeTime || 0,
    stacks: props.stacks || 1,
    stackable: props.stackable || false,
    maxStacks: props.maxStacks || 1,
    scalingStat: props.scalingStat || 'atk',
    time: props.time || null,
    setTime(t) {
      if(t !== this.time) {
        let ot = this.time;
        this.time = t;
        this.onTimeChange(this, ot, t);
      }
    },
    onTimeChange(obj, before, after) {
      if(props.onTimeChange) {
        props.onTimeChange(obj, before, after);
      }
    },
    duration: props.duration || 0.2,
    setDuration(d) {
      if(d!==this.duration) {
        let od = this.duration;
        this.duration = d;
        this.onDurationChange(this, od, d);
      }
    },
    onDurationChange(obj, before, after) {
      if(props.onDurationChange) {
        props.onDurationChange(obj, before, after);
      }
    },
    attributes: props.initiator ? {...Tools.GetAttributes(props.initiator)} : {},
    targetAttributes: props.target ? {...props.target.attributes} : null,
    precedingResult: props.precedingResult || null,
    followingResults: {
      effects:[],
      statuses:[],
      talents:[]
    },
    ongoing: {
      effects: [],
      status: []
    },
    effects: [],
    talentEffects: [],
    statusEffects: [],
    reactionMultiplier: {
      melt:0,
      vaporize:0,
      shattered:0
    },
    values: {
      normalDamage:0,
      critDamage:0,
      averageDamage:0,
      aoeDamage:0,
      aoeCritDamage:0,
      averageAoeDamage:0,
      healing:0
    },
    explaining: false,
    explainer: Explainer(),
    
    GetRelativeTime() {
      let relativeValue = 0;
      if(typeof this.relativeTime === "function") {
        relativeValue = this.relativeTime(this);
      } else {
        relativeValue = this.relativeTime;
      }
      return relativeValue;
    },
    ValueCalc() {
      this.explaining = false;
      this.values.normalDamage = Math.round(this.CalcDamage("no"));
      this.values.critDamage = Math.round(this.CalcDamage("yes"));
      this.explaining = true;
      this.values.averageDamage = Math.round(this.CalcDamage("average"));
    },
    AttackPower() {
      let attackPower = 0;
      if(this.scalingStat==='defense') {
        attackPower = (
            this.attributes.defense 
            * (1 + this.attributes.defensePct)
          );
      } else {
        attackPower = (
            this.attributes.baseAtk 
            * (1 + this.attributes.baseAtkPct)
            * (1 + this.attributes.atkPct)
          ) 
          + this.attributes.flatAtk;
        
      }
      this.ExplainAttackPower();
      return attackPower;
    },
    ExplainAttackPower() {
      if(!this.explaining) return;
      
      if(this.scalingStat==='defense') {
        this.explainer.CreateGroup("power")
        .Add("var", this.attributes.defense, "defense")
        .Add("plus")
        .GreateGroup()
          .Add('rad', 1, undefined, "percent")
          .Add('plus')
          .Add('var', this.attributes.defensePct, 'defense percent', "percent");
      
      } else {
        let b = this.explainer.CreateGroup("power");
        let g = b.CreateGroup()
          .Add("var", this.attributes.baseAtk, "base attack");
        if(this.attributes.baseAtkPct) {
          g.Add("mult").CreateGroup()
            .Add('rad', 1, undefined, "percent")
            .Add('plus')
            .Add('var', this.attributes.baseAtkPct, "base attack percent", "percent");
        }
        g.Add("mult").CreateGroup()
          .Add('rad', 1, undefined, "percent")
          .Add('plus')
          .Add('var', this.attributes.atkPct, 'attack percent', "percent");
        b.Add('plus')
          .Add('var', this.attributes.flatAtk, 'flat attack');
      }
    },
    
    DamageBonusMultiplier() {
      this.ExplainDamageBonusMultiplier();
      return (
          1
          + this.attributes.damageBonus.all
          + this.attributes.damageBonus[this.attributes.element]
        );
    },
    ExplainDamageBonusMultiplier() {
      if(!this.explaining) return;
      
      let g = this.explainer.Add('mult').CreateGroup("damage bonus")
        .Add('rad', 1, undefined, "percent")
      if(this.attributes.damageBonus.all)
        g.Add('plus').Add("var", this.attributes.damageBonus.all, "damage bonus", "percent");
      if(this.attributes.damageBonus[this.attributes.element])
        g.Add('plus').Add("var", this.attributes.damageBonus[this.attributes.element], this.attributes.element+' damage bonus', "percent");
    },
    
    SkillMultiplier() {
      this.ExplainSkillMultiplier();
      return this.attributes.skillPct;
    },
    ExplainSkillMultiplier() {
      if(!this.explaining) return;
    
      this.explainer.Add('mult').CreateGroup("skill")
        .Add("var", this.attributes.skillPct, 'skill percent', "percent");
    },
    
    LevelMultiplier() {
      this.ExplainLevelMultiplier();
      return (
        (this.attributes.level + 100)
         / 
        (
          (this.attributes.level + 100) 
          + 
          (
            (this.targetAttributes.level + 100) 
            * 
            (1-this.attributes.defenseReduction)
          )
        )
      );
    },
    ExplainLevelMultiplier() {
      if(!this.explaining) return;
    
      let b = this.explainer.Add('mult').CreateGroup("level & defense");
      b.CreateGroup()
        .Add("var", this.attributes.level, 'character level')
        .Add("plus")
        .Add("rad", 100);
      b = b.Add("div")
        .CreateGroup();
      b.CreateGroup()
        .Add('var', this.attributes.level, 'character level')
        .Add('plus')
        .Add('rad', 100);
      b = b.Add('plus')
        .CreateGroup();
      b.CreateGroup()
        .Add('var', this.targetAttributes.level, 'target level')
        .Add('plus')
        .Add('rad', 100);
      b.Add('mult')
        .CreateGroup()
          .Add('rad', 1, undefined, "percent")
          .Add('minus')
          .Add('var', this.attributes.defenseReduction, 'defense reduction', "percent");
    },
    
    ResistanceMultiplier() {
      let resistMultiplier=1;
      let resistance = (
          this.targetAttributes.resistance.all
          + this.targetAttributes.resistance[this.attributes.element]
        ) - (
          this.attributes.resistanceReduction.all
          + this.attributes.resistanceReduction[this.attributes.element]
        );
      if(resistance<0) {
        resistMultiplier -= resistance/2;
      } else if(resistance<0.75) {
        resistMultiplier -= resistance;
      } else {
        resistMultiplier /= (4 * resistance + 1);
      }
      this.ExplainResistanceMultiplier(resistance);
      return resistMultiplier;
    },
    ExplainResistanceMultiplier(resistance) {
      if(!this.explaining) return;
    
      let b = this.explainer.Add('mult').CreateGroup(
        resistance<0
        ? "negative resistance"
        : resistance<0.75
          ? "resistance"
          : "high resistance"
        );
      b.Add('rad', 1, undefined, "percent");
      if(resistance<0) {
        b = b.Add('minus');
        this.ExplainResistance(b);
        b.Add('div')
          .Add('rad', 2);
      } else if(resistance<0.75) {
        this.ExplainResistance(b.Add('minus'));
      } else {
        b = b.Add('div')
          .CreateGroup()
          .Add('rad', 4)
          .Add('mult');
        this.ExplainResistance(b);
        b.Add('plus')
          .Add('rad', 1);
      }
    },
    ExplainResistance(node) {
      let g = node.CreateGroup();
      g.CreateGroup()
        .Add("var", this.targetAttributes.resistance.all, 'resistance', "percent")
        .Add("plus")
        .Add("var", this.targetAttributes.resistance[this.attributes.element], this.attributes.element+' resistance', "percent");
      g.Add('minus')
        .CreateGroup()
        .Add('var', this.attributes.resistanceReduction.all, 'resistance reduction', "percent")
        .Add('plus')
        .Add('var', this.attributes.resistanceReduction[this.attributes.element], this.attributes.element+' resistance reduction', "percent");
      return g;
    },
    
    CritMultiplier(crit="average") {
      let critMultiplier = 1;
      switch (crit) {
        case 'yes':
          critMultiplier = (1 + this.attributes.critDmg);
          break;
        
        case 'average':
          critMultiplier = (1 + (this.attributes.critRate>1?1:this.attributes.critRate) * this.attributes.critDmg);
          break;
          
        default:
          critMultiplier = 1;
          break;
      }
      this.ExplainCritMultiplier();
      return critMultiplier;
    },
    ExplainCritMultiplier() {
      if(!this.explaining) return;
      
      let b = this.explainer.Add('mult').CreateGroup("crit average");
      b.Add('rad', 1, undefined, "percent")
        .Add('plus')
        .Add('var', this.attributes.critDmg, 'critical damage', "percent")
        .Add('mult')
        .Add('var', this.attributes.critRate>1?1:this.attributes.critRate, 'critical rate', "percent");
    },
    
    AmplifyingReactionMultiplier() {
      let reactionMultiplier = 
        this.reactionMultiplier.melt * (1 + this.attributes.reactionBonus.melt)
        + this.reactionMultiplier.vaporize * (1 + this.attributes.reactionBonus.vaporize);
      let data = {
        emPct: reactionMultiplier
          ? reactionMultiplier * (
            1 + (25/9 * this.attributes.elemMastery / (1401 + this.attributes.elemMastery))
          )
          : 1
      };
      if(reactionMultiplier) {
        this.ExplainAmplifyingReactionMultiplier();
      }
      return data.emPct;
    },
    ExplainAmplifyingReactionMultiplier() {
      if(!this.explaining) return;
      
      let b = this.explainer.Add('mult').CreateGroup('amplifying reaction');
      let g = b.CreateGroup();
      if(this.reactionMultiplier.melt) {
        g.Add('var', this.reactionMultiplier.melt, 'melt multiplier', "percent");
        if(this.attributes.reactionBonus.melt)
          g.Add('mult')
          .CreateGroup()
            .Add('rad', 1, undefined, "percent")
            .Add('plus')
            .Add('var', this.attributes.reactionBonus.melt, 'melt bonus', "percent");
      }
      if(this.reactionMultiplier.vaporize) {
        if(this.reactionMultiplier.melt) {
          g.Add('plus');
        }
        g.Add('var', this.reactionMultiplier.vaporize, 'vaporize multiplier', "percent");
        if(this.attributes.reactionBonus.vaporize)
          g.Add('mult')
          .CreateGroup()
            .Add('rad', 1, undefined, "percent")
            .Add('plus')
            .Add('var', this.attributes.reactionBonus.vaporize, 'vaporize bonus', "percent");
      }
      if(this.reactionMultiplier.shattered) {
        if(this.reactionMultiplier.melt || this.reactionMultiplier.vaporize) {
          g.Add('plus');
        }
        g.Add('var', this.reactionMultiplier.shattered, 'shattered multiplier', "percent");
        if(this.attributes.reactionBonus.shattered)
          g.Add('mult')
          .CreateGroup()
            .Add('rad', 1, undefined, "percent")
            .Add('plus')
            .Add('var', this.attributes.reactionBonus.shattered, 'shattered bonus', "percent");
      }
      g = b.Add('mult')
        .CreateGroup()
          .Add('rad', 1, undefined, "percent")
          .Add('plus');
      g.CreateGroup()
            .Add('rad', 25)
            .Add('div')
            .Add('rad', 9)
            .Add('mult')
            .Add('var', this.attributes.elemMastery, 'elemental mastery')
            .Add('div')
            .CreateGroup()
              .Add('rad', 1401)
              .Add('plus')
              .Add('var', this.attributes.elemMastery, 'elemental mastery');

    },
    
    TransformativeReactionMultiplier(reactionType, reactionBonus) {
      this.ExplainTransformativeReactionMultiplier(reactionType, reactionBonus);
      return 1 + reactionBonus + (60/9 * this.attributes.elemMastery / (1401 + this.attributes.elemMastery));
    },
    ExplainTransformativeReactionMultiplier(reactionType, reactionBonus) {
      if(!this.explaining) return;
      
      this.explainer
        .Add('mult')
        .CreateGroup('transformative reaction')
        .Add('rad', 1, undefined, "percent")
        .Add('plus')
        .Add('var', reactionBonus, reactionType+' reaction bonus', "percent")
        .Add('plus')
        .CreateGroup()
        .Add('rad', 60, undefined, "int")
        .Add('div')
        .Add('rad', 9, undefined, "int")
        .Add('mult')
        .Add('var', this.attributes.elemMastery, 'elemental mastery')
        .Add('div')
        .CreateGroup()
        .Add('rad', 1401, undefined, "int")
        .Add('plus')
        .Add('var', this.attributes.elemMastery, 'elemental mastery');
    },
    
    OverloadedPower() {
      this.ExplainReactionPower(
          0.0000027646, 
          0.0005189440, 
          0.0314790536, 
          0.9268181504, 
          4.3991155718, 
          37.4371542286);
      return (
          - 0.0000027646 * Math.pow(this.attributes.level, 5)
          + 0.0005189440 * Math.pow(this.attributes.level, 4)
          - 0.0314790536 * Math.pow(this.attributes.level, 3)
          + 0.9268181504 * Math.pow(this.attributes.level, 2)
          - 4.3991155718 * this.attributes.level
          + 37.4371542286
        ) * 1.1111;
    },
    SuperconductPower() {
      this.ExplainReactionPower(
          0.0000006038,
          0.0001110078,
          0.0064237710,
          0.1836799174,
          0.4750909512,
          7.4972486411);
      return (
          - 0.0000006038 * Math.pow(this.attributes.level, 5)
          + 0.0001110078 * Math.pow(this.attributes.level, 4)
          - 0.0064237710 * Math.pow(this.attributes.level, 3)
          + 0.1836799174 * Math.pow(this.attributes.level, 2)
          - 0.4750909512 * this.attributes.level
          + 7.4972486411
        ) * 1.1111;
    },
    ElectroChargedPower() {
      this.ExplainReactionPower(
          0.0000014798,
          0.0002746679,
          0.0162160738,
          0.4742385201,
          1.6987232790,
          20.8340255487);
      return (
          - 0.0000014798 * Math.pow(this.attributes.level, 5)
          + 0.0002746679 * Math.pow(this.attributes.level, 4)
          - 0.0162160738 * Math.pow(this.attributes.level, 3)
          + 0.4742385201 * Math.pow(this.attributes.level, 2)
          - 1.6987232790 * this.attributes.level
          + 20.8340255487
        ) * 1.1111;
    },
    ShatteredPower() {
      this.ExplainReactionPower(
          0.0000020555,
          0.0003895953,
          0.0239673351,
          0.7174530144,
          3.7397755267,
          31.2160750111);
      return (
          - 0.0000020555 * Math.pow(this.attributes.level, 5)
          + 0.0003895953 * Math.pow(this.attributes.level, 4)
          - 0.0239673351 * Math.pow(this.attributes.level, 3)
          + 0.7174530144 * Math.pow(this.attributes.level, 2)
          - 3.7397755267 * this.attributes.level
          + 31.2160750111
        ) * 1.1111;
    },
    SwirlPower() {
      this.ExplainReactionPower(
          0.0000008854,
          0.0001679502,
          0.0103922088,
          0.3097567417,
          1.7733381829,
          13.5157684329);
      return (
          - 0.0000008854 * Math.pow(this.attributes.level, 5)
          + 0.0001679502 * Math.pow(this.attributes.level, 4)
          - 0.0103922088 * Math.pow(this.attributes.level, 3)
          + 0.3097567417 * Math.pow(this.attributes.level, 2)
          - 1.7733381829 * this.attributes.level
          + 13.5157684329
        ) * 1.1111;
    },
    ExplainReactionPower(v1, v2, v3, v4, v5, v6) {
      if(!this.explaining) return;
      
      this.explainer
        .Add('rad', 1.1111, 'counter "reaction power" formula\'s innate 10% resistance bias', 'percent')
        .Add('mult')
        .CreateGroup('Reaction Power')
        .Add('minus')
        .Add('rad', v1, undefined, "float")
        .Add('mult')
        .Add('var', this.attributes.level, 'character level')
        .Add('exp')
        .Add('rad', 5)
        
        .Add('plus')
        .Add('rad', v2, undefined, "float")
        .Add('mult')
        .Add('var', this.attributes.level, 'character level')
        .Add('exp')
        .Add('rad', 4)
        
        .Add('minus')
        .Add('rad', v3, undefined, "float")
        .Add('mult')
        .Add('var', this.attributes.level, 'character level')
        .Add('exp')
        .Add('rad', 3)
        
        .Add('plus')
        .Add('rad', v4, undefined, "float")
        .Add('mult')
        .Add('var', this.attributes.level, 'character level')
        .Add('exp')
        .Add('rad', 2)
        
        .Add('minus')
        .Add('rad', v5, undefined, "float")
        .Add('mult')
        .Add('var', this.attributes.level, 'character level')
        
        .Add('plus')
        .Add('rad', v6, undefined, "float");
      
    },
    
    CalcDamage(crit="average"/*no,yes*/) {
      let damage = 0;
      let data = [];
      if(this.type==="Reaction") {
        switch(this.description) {
          case "Overloaded": {
            data.push(this.OverloadedPower());
            data.push(this.TransformativeReactionMultiplier(
              "overloaded", 
              this.attributes.reactionBonus.overloaded
            ));
          } break;
            
          case "Superconduct": {
            data.push(this.SuperconductPower());
            data.push(this.TransformativeReactionMultiplier(
              "superconduct", 
              this.attributes.reactionBonus.superconduct
            ));
          } break;
            
          case "Electro-Charged": {
            data.push(this.ElectroChargedPower());
            data.push(this.TransformativeReactionMultiplier(
              "electro-charged", 
              this.attributes.reactionBonus.electroCharged
            ));
          } break;
            
          case "Shattered": {
            data.push(this.ShatteredPower());
            data.push(this.TransformativeReactionMultiplier(
              "shattered", 
              this.attributes.reactionBonus.shattered
            ));
          } break;
            
          case "Swirl": {
            data.push(this.SwirlPower());
            data.push(this.TransformativeReactionMultiplier(
              "swirl", 
              this.attributes.reactionBonus.swirl
            ));
          } break;
            
          default:
            break;
        }
        data.push(this.ResistanceMultiplier());
      } else {
        //console.log('CalcDamage', this.attributes, this.targetAttributes);
        /**
         * (Base ATK * (1 + ATK%) + FLAT ATK)
         * * (1 + Corresponding Dmg Bonus%)
         * * (Skill Multiplier)
         * * [
         *    (100+Character Level)
         *    / (
         *      (100+Character Level) 
         *      + (100+Enemy Level) * Defence drop
         *    )
         *  ]
         *  * (1 - Corresponding Enemy RES%)
         **/
        data = [
          this.AttackPower(),
          this.DamageBonusMultiplier(),
          this.SkillMultiplier(),
          this.CritMultiplier(crit),
          this.LevelMultiplier(),
          this.ResistanceMultiplier(),
          this.AmplifyingReactionMultiplier()
        ];
      }
      damage = data.reduce((a,c) => a*c, 1);
      //console.log('Damage calculated', {crit:crit, damage:damage, description:this.description, type:this.time});
      this.ExplainCalcDamage(damage);
      return damage;
    },
    ExplainCalcDamage(damage) {
      if(!this.explaining) return;
      
      this.explainer
        .Add('equals')
        .Add('var', damage, "damage", "int");
    },
    
    
    ApplyStatus: function(result, ignoreCD=false) {
      if(result.talent.track.lastStatusApplied+1.5 <= result.time || ignoreCD) {
        //console.log(result.type+"."+result.description+" applies a "+result.attributes.element+" status");
        let status = Tools.CreateStatusEffect(result.attributes.element, result);
        if(status) {
          result.talent.track.lastStatusApplied = result.time;
          if(status.constructor===Array) {
            result.statusEffects.push(...status);
          } else {
            result.statusEffects.push(status);
          }
        }
      }
      return result;
    },
    StartCooldown: function(result) {
      return result;
    },
    Prepare: function(result) {
      if(props.Prepare) {
        result = props.Prepare(result, this);
      }
      
      return result;
    },
    Resolve: function(result) {
      if(props.Resolve) {
        result = props.Resolve(result, this);
      }
      
      return result;
    },
    Finalize: function(result) {
      if(props.Finalize) {
        result = props.Finalize(result, this);
      }
      
      return result;
    },
    PersistentPrepare: function(result) {
      if(props.PersistentPrepare) {
        result = props.PersistentPrepare(result, this);
      }
      
      return result;
    },
    PersistentResolve: function(result) {
      if(props.PersistentResolve) {
        result = props.PersistentResolve(result, this);
      }
      
      return result;
    },
    PersistentFinalize: function(result) {
      if(props.PersistentFinalize) {
        result = props.PersistentFinalize(result, this);
      }
      
      return result;
    },
    IsSelf: function(result) {
      return result.initiator === this.initiator;
    },
    logs: props.logs || [],
    
    serialize() {
      return {
        type: this.type,
        effectExtension: this.effectExtension?this.effectExtension.serialize():null,
        description: this.description,
        initiator: {
          name: this.initiator.name
        },
        target: {
          name: this.target.name,
        },
        attributes: this.attributes,
        targetAttributes: this.targetAttributes,
        reactionMultiplier: this.reactionMultiplier,
        entity: this.entity,
        comboStep: this.comboStep,
        stacks: this.stacks,
        duration: this.duration,
        time: this.time,
        values: this.values,
        logs: this.logs,
        explainer: this.explainer
      };
    }
  };
};

export default Result;