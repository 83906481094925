import {BaseSet} from './base.js';

const ResolutionOfSojourner = (props) => {
  
  return BaseSet({
    ...props,
    name: 'Resolution of Sojourner',
    two: {
      attributes: {
        atkPct: 0.18
      },
      PersistentResolve(result, context) {
        return result;
      }
    },
    four: {
      PersistentResolve(result, context) {
        if(!this.IsSelf(result)) return result;
        if(result.type==="ChargeAtk" || result.type==="ChargeAtkHold") {
          result.attributes.critRate += 0.30;
          result.logs.push(this.name + ' increases CRIT Rate by 30% on Charged Attacks');
        }
        return result;
      }
    }
  });
};

export default ResolutionOfSojourner;