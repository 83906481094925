const Weapons = {
    bow:[
      { name: 'None',         
                          rarity: 0, 
                          passive: '', 
                          effect: '' },
      { name: 'Amos\' Bow', devStage:'Testing',   
                          rarity: 5, 
                          passive: 'Strong-Willed', 
                          effect: 'Increases Normal Attack and Aimed Shot DMG by [12%]. Increases DMG from arrows shot by a further [8%] for every 0.1s that the arrow is in flight, up to 0.5s.' },
      { name: 'Skyward Harp', devStage:'Testing', 
                          rarity: 5, 
                          passive: 'Echoing Ballad', 
                          effect: 'Increases CRIT DMG by [20%]. Hits have a [60+10%] chance to inflict a small AoE attack, dealing 125% Physical ATK DMG. Can only occur once every [4-]s.' },
      
      { name: 'Alley Hunter', devStage:'Testing',        
                          rarity: 4, 
                          passive: 'Urban Guerrilla', 
                          effect: 'Every 4s a character is on the field, their ATK increases by [4%] and their CRIT DMG increases by [4%]. This effect has a maximum of 5 stacks and will not be reset if the character leaves the field, but will be cleared when the character takes DMG.' },
      { name: 'Blackcliff Warbow', 
                          rarity: 4, 
                          passive: 'Press the Advantage', 
                          effect: 'After defeating an enemy, ATK is increased by [12%] for 30s. This effect has a maximum of 3 stacks, and the duration of each stack is independent of the others.' },
      { name: 'Compound Bow', devStage:'Testing',         
                          rarity: 4, 
                          passive: 'Infusion Arrow', 
                          effect: 'Normal Attack and Aimed Shot hits increase ATK by [4%] and Normal ATK SPD by [1.2%] for 6s. Max 4 stacks. Can only occur once every 0.3s.' },
      { name: 'Favonius Warbow', 
                          rarity: 4, 
                          passive: 'Windfall', 
                          effect: 'CRIT hits have a [60+10%] chance to generate 1 Elemental Orb, which will regenerate 6 Energy for the character. Can only occur once every [12-]s.' },
      { name: 'Prototype Crescent', devStage:'NotImpl',   
                          rarity: 4, 
                          passive: 'Unreturning', 
                          effect: 'Aimed Shot hits on weak points increase Movement SPD by 10% and ATK by 36% for 10s.' },
      { name: 'Royal Bow', devStage:'NotImpl',            
                          rarity: 4, 
                          passive: 'Focus', 
                          effect: 'Upon damaging an opponent, increases CRIT Rate by 8%. Max 5 stacks. A CRIT hit removes all stacks.' },
      { name: 'Rust',                 
                          rarity: 4, 
                          passive: 'Rapid Firing', 
                          effect: 'Increases Normal Attack DMG by 40% but decreases Aimed Shot DMG by 10%.' },
      { name: 'Sacrificial Bow', devStage:'NotImpl',      
                          rarity: 4, 
                          passive: 'Composed', 
                          effect: 'After dealing damage to an enemy with an Elemental Skill, the skill has a 40% chance to end its own CD. Can only occur once every 30s.' },
      { name: 'The Stringless', devStage:'NotImpl',       
                          rarity: 4, 
                          passive: 'Arrowless Song', 
                          effect: 'Increases Elemental Skill and Elemental Burst DMG by 24%.' },
      { name: 'The Viridescent Hunt', devStage:'NotImpl', 
                          rarity: 4, 
                          passive: 'Verdant Wind', 
                          effect: 'Upon hit, Normal and Aimed Shot Attacks have a 50% chance to generate a Cyclone, which will continuously attract surrounding enemies, dealing [40%] of ATK as DMG to these enemies every 0.5s for 4s. This effect can only occur once every [14-1]s.' },
        
      { name: 'Ebony Bow', devStage:'Testing',            
                          rarity: 3, 
                          passive: 'Decimate', 
                          effect: 'Increases DMG against mechanoid Ruin enemies by [40%].' },
      { name: 'Messenger', devStage:'NotImpl',            
                          rarity: 3, 
                          passive: 'Archer\'s Message', 
                          effect: 'Aimed Shot hits on weak spots deal an additional [100%] ATK DMG as CRIT DMG. Can only occur once every 10s.' },
      { name: 'Raven Bow', devStage:'NotImpl',            
                          rarity: 3, 
                          passive: 'Bane of Flame and Water', 
                          effect: 'Increases DMG against enemies affected by Hydro or Pyro by [12%].' },
      { name: 'Recurve Bow', 
                          rarity: 3, 
                          passive: 'Cull the Weak', 
                          effect: 'Defeating an opponent restores [8%] HP.' },
      { name: 'Sharpshooter\'s Oath', 
                          rarity: 3, 
                          passive: 'Precise', 
                          effect: 'Increases DMG against weak spots by [24%].' },
      { name: 'Slingshot', devStage:'NotImpl',            
                          rarity: 3, 
                          passive: 'Slingshot', 
                          effect: 'If a Normal Attack or Aimed Shot hits a target within 0.3s of being fired, increases DMG by [36+6%]. Otherwise, it decreases DMG by 10%.' },
    ],
    catalyst: [
      { name: 'None',                 
                          rarity: 0, 
                          passive: '', 
                          effect: '' },
      { name: 'Lost Prayer to the Sacred Winds', devStage:'NotImpl', 
                          rarity: 5, 
                          passive: 'Boundless Blessing', 
                          effect: 'Increases Movement SPD by 10%. When in battle, gain an [8%] Elemental DMG Bonus every 4s. Max 4 stacks. Lasts until the character falls or leaves combat.' },
      { name: 'Memory of Dust', devStage:'NotImpl',    
                          rarity: 5, 
                          passive: 'Golden Majesty', 
                          effect: 'Increases Shield Strength by [20%]. Scoring hits on opponents increases ATK by [4%] for 8s. Max 5 stacks. Can only occur once every 0.3s. While protected by a shield, this ATK increase effect is increased by 100%.' },
      { name: 'Skyward Atlas', devStage:'NotImpl',     
                          rarity: 5, 
                          passive: 'Wandering Clouds', 
                          effect: 'Increases Elemental DMG Bonus by [12%]. Normal Attack hits have a 50% chance to earn the favor of the clouds. which actively seek out nearby enemies to attack for 15s, dealing [160%] ATK DMG. Can only occur once every 30s.' },
      
      { name: 'Blackcliff Amulet', 
                          rarity: 4, 
                          passive: 'Press the Advantage', 
                          effect: 'After defeating an enemy, ATK is increased by [12%] for 30s. This effect has a maximum of 3 stacks, and the duration of each stack is independent of the others.' },
      { name: 'Eye of Perception', devStage:'NotImpl', 
                          rarity: 4, 
                          passive: 'Echo', 
                          effect: 'Normal and Charged Attacks have a 50% chance to fire a Bolt of Perception, dealing [240%] ATK as DMG. This bolt can bounce between enemies a maximum of 4 times. This effect can occur once every [12-1]s.' },
      { name: 'Favonius Codex', devStage:'NotImpl',    
                          rarity: 4, 
                          passive: 'Critical Charge', 
                          effect: 'CRIT hits have a [60%] chance to generate a small amount of Elemental Particles, which will regenerate 6 Energy for the character. Can only occur once every [12-1.5]s.' },
      { name: 'Frostbearer', devStage:'NotImpl',       
                          rarity: 4, 
                          passive: 'Frost Burial', 
                          effect: 'Hitting an opponent with Normal and Charged Attacks has a [60+10%] chance of forming and dropping an Everfrost Icicle above them, dealing [80+15]% AoE ATK DMG. Opponents affected by Cryo are dealt [200+40%] ATK DMG. Can only occur once every 10s.' },
      { name: 'Mappa Mare', devStage:'NotImpl',        
                          rarity: 4, 
                          passive: 'Infusion Scroll', 
                          effect: 'Triggering an Elemental reaction grants a [8%] Elemental DMG Bonus for 10s. Max 2 stacks.' },
      { name: 'Prototype Malice', devStage:'NotImpl',  
                          rarity: 4, 
                          passive: 'Gilding', 
                          effect: 'Using an Elemental Burst regenerates [4+0.5] Energy every 2s for 6s. Additionally, all party members will regenerate [4+0.5%] HP every 2s for this duration.' },
      { name: 'Royal Grimoire', devStage:'NotImpl',    
                          rarity: 4, 
                          passive: 'Focus', 
                          effect: 'Upon damaging an opponent, increases CRIT Rate by [8%]. Max 5 stacks. A CRIT hit removes all stacks.' },
      { name: 'Sacrificial Fragments', devStage:'NotImpl', 
                          rarity: 4, 
                          passive: 'Composed', 
                          effect: 'After dealing damage to an enemy with an Elemental Skill, the skill has a [40%] chance to end its own CD. Can only occur once every [30-4s].' },
      { name: 'Solar Pearl', devStage:'NotImpl',       
                          rarity: 4, 
                          passive: 'Solar Shine', 
                          effect: 'Normal Attack hits increase Elemental Skill and Elemental Burst DMG by [20%] for 6s. Likewise, Elemental Skill or Elemental Burst hits increase Normal Attack DMG by [20%] for 6s.' },
      { name: 'The Widsith', devStage:'NotImpl',       
                          rarity: 4, 
                          passive: 'Debut', 
                          effect: 'When a character takes the field, they will gain a random theme song for 10s. This can only occur once every 30s. Recitative: ATK is increased by [60%]. Aria: Increases all Elemental DMG by [48%]. Interlude: Elemental Mastery is increased by [240].' },
      { name: 'Wine and Song', devStage:'NotImpl',     
                          rarity: 4, 
                          passive: 'Wind in the Square', 
                          effect: 'For every character in the party who hails from Mondstadt, the character who equips this weapon gains [8%] ATK increase and [3+0.5%] Movement SPD increase.' },
      
      { name: 'Amber Catalyst', devStage:'NotImpl',    
                          rarity: 3, 
                          passive: 'Elemental Mastery', 
                          effect: 'Normal Attack hits increase all Elemental DMG by [6%] for 6s. Max 2 stacks.' },
      { name: 'Emerald Orb', devStage:'NotImpl',       
                          rarity: 3, 
                          passive: 'Rapids', 
                          effect: 'Upon causing a Vaporize, Electro-Charged, Frozen, or a Hydro-infused Swirl reaction, increases ATK by [20%] for 12s.' },
      { name: 'Magic Guide', devStage:'NotImpl',       
                          rarity: 3, 
                          passive: 'Bane of Storm and Tide', 
                          effect: 'Increases DMG against enemies affected by Hydro or Electro by [12%].' },
      { name: 'Otherworldly Story', 
                          rarity: 3, 
                          passive: 'Energy Shower', 
                          effect: 'Each Elemental Orb or Particle collected restores [1%] HP.' },
      { name: 'Thrilling Tales of Dragon Slayers', devStage:'NotImpl', 
                          rarity: 3, 
                          passive: 'Legacy', 
                          effect: 'When switching characters, the new character taking the field has their ATK increased by [24%] for 10s. This effect can only occur once every 20s.' },
      { name: 'Twin Nephrite', 
                          rarity: 3, 
                          passive: 'Guerilla Tactic', 
                          effect: 'Defeating an enemy increases Movement SPD and ATK by [12+2%] for 15s.' },
    ],
    claymore: [
      { name: 'None',                 
                          rarity: 0, 
                          passive: '', 
                          effect: '' },
      { name: 'The Unforged', devStage:'NotImpl',     
                          rarity: 5, 
                          passive: 'Golden Majesty', 
                          effect: 'Increases Shield Strength by [20%], scoring hits on opponents increases ATK by [4%] for 8s. Max 5 stacks. Can only occur once every 0.3s. While protected by a shield this ATK increase effect is increased by 100%' },
      { name: 'Wolf\'s Gravestone', devStage:'NotImpl',     
                          rarity: 5, 
                          passive: 'Wolfish Tracker', 
                          effect: 'Increases ATK by [20%]. On hit, attacks against enemies with less than 30% HP increase all party members\' ATK by [40%] for 12s. Can only occur once every 30s.' },
      { name: 'Skyward Pride', devStage:'NotImpl',     
                          rarity: 5, 
                          passive: 'Sky-ripping Dragon Spine', 
                          effect: 'Increases all DMG by [8%]. After using an Elemental Burst, Normal or Charged Attack, on hit, creates a vacuum blade that does [80%] of ATK as DMG to enemies along its path. Lasts for 20s or 8 vacuum blades.' },
      
      { name: 'Snow-Tombed Starsilver', devStage:'NotImpl',     
                          rarity: 4, 
                          passive: 'Frost Burial', 
                          effect: 'Hitting an opponent with Normal and Charged Attacks has a [60%] chance of forming and dropping an Everfrost Icicle above them, dealing [80%] AoE ATK DMG. Opponents affected by Cryo are dealt [200%] ATK DMG. Can only occur once every 10s.' },
      { name: 'Whiteblind', devStage:'NotImpl',     
                          rarity: 4, 
                          passive: 'Infusion Blade', 
                          effect: 'On hit, Normal or Charged Attacks increase ATK and DEF by [6%] for 6s. Max 4 stacks ([24%] total). Can only occur once every 0.5s.' },
      { name: 'Sacrificial Greatsword', devStage:'NotImpl',     
                          rarity: 4, 
                          passive: 'Composed', 
                          effect: 'After damaging an opponent with an Elemental Skill, the skill has a [40%] chance to end its own CD. Can only occur once every [30-4,4,4,3,3s].' },
      { name: 'Lithic Blade', devStage:'NotImpl',     
                          rarity: 4, 
                          passive: 'Lithic Axiom - Unity', 
                          effect: 'For every character in the party who hails from Liyue, the character who equips this weapon gains [6+1%] ATK increase and [2+1%] CRIT Rate increase.' },
      { name: 'Blackcliff Slasher', 
                          rarity: 4, 
                          passive: 'Press the Advantage', 
                          effect: 'After defeating an enemy, ATK is increased by [12%] for 30s. This effect has a maximum of 3 stacks, and the duration of each stack is independent of the others.' },
      { name: 'Serpent Spine', devStage:'NotImpl',     
                          rarity: 4, 
                          passive: 'Wavesplitter', 
                          effect: 'Every 4s a character is on the field, they will deal [6+1%] more DMG and take [3-0.3%] more DMG. This effect has a maximum of 5 stacks and will not be reset if the character leaves the field, but will be reduced by 1 stack when the character takes DMG.' },
      { name: 'The Bell', 
                          rarity: 4, 
                          passive: 'Rebellious Guardian', 
                          effect: 'Taking DMG generates a shield which absorbs DMG up to [20+3%] of max HP. This shield lasts for 10s or until broken, and can only be triggered once every 45s. While protected by the shield, the character gains [12%] increased DMG.' },
      { name: 'Royal Greatsword', devStage:'NotImpl',     
                          rarity: 4, 
                          passive: 'Focus', 
                          effect: 'Upon damaging an enemy, increases CRIT Rate by [8%]. Max 5 stacks. A CRIT hit removes all stacks.' },
      { name: 'Rainslasher', devStage:'NotImpl',     
                          rarity: 4, 
                          passive: 'Bane of Storm and Tide', 
                          effect: 'Increases DMG against enemies affected by Hydro or Electro by [20+4%].' },
      { name: 'Prototype Aminus', devStage:'NotImpl',     
                          rarity: 4, 
                          passive: 'Crush', 
                          effect: 'On hit, Normal or Charged Attacks have a 50% chance to deal an additional [240%] ATK DMG to enemies within a small AoE. Can only occur once every 15s.' },
      { name: 'Favonius Greatsword', devStage:'NotImpl',     
                          rarity: 4, 
                          passive: 'Windfall', 
                          effect: 'CRIT Hits have a [60+10%] chance to generate a small amount of Elemental Particles, which will regenerate 6 Energy for the character. Can only occur once every [12-1.5s].' },
      
      { name: 'White Iron Greatsword', 
                          rarity: 3, 
                          passive: 'Cull the Weak', 
                          effect: 'Defeating an opponent restores [8%] HP.' },
      { name: 'Skyrider Greatsword', devStage:'NotImpl',     
                          rarity: 3, 
                          passive: 'Courage', 
                          effect: 'On hit, Normal or Charged Attacks increase ATK by [6+1%] for 6s. Max 4 stacks. Can only occur once every 0.5s.' },
      { name: 'Quartz', devStage:'NotImpl',     
                          rarity: 3, 
                          passive: 'Residual Heat', 
                          effect: 'Upon causing an Overloaded, Melt, Burning, Vaporize, or a Pyro-infused Swirl reaction, increases Base ATK by [20%] for 12s.' },
      { name: 'Ferrous Shadow', devStage:'NotImpl',     
                          rarity: 3, 
                          passive: 'Unbending', 
                          effect: 'When HP falls below [70+5%], increases Charged Attack DMG by [30+5%], and Charged Attacks become much harder to interrupt.' },
      { name: 'Debate Club', devStage:'NotImpl',     
                          rarity: 3, 
                          passive: 'Blunt Conclusion', 
                          effect: 'After using an Elemental Skill, Normal or Charged Attacks, on hit, deal an additional [60%] ATK DMG in a small area. Effect lasts 15s. DMG can only occur once every 3s.' },
      { name: 'Bloodtainted Greatsword', devStage:'NotImpl',     
                          rarity: 3, 
                          passive: 'Bane of Fire and Thunder', 
                          effect: 'Increases DMG against enemies affected by Pyro or Electro by [12%].' },
    ],
    polearm: [
      { name: 'None',                 
                          rarity: 0, 
                          passive: '', 
                          effect: '' },
      { name: 'Vortex Vanquisher', devStage:'NotImpl',     
                          rarity: 5, 
                          passive: 'Golden Majesty', 
                          effect: 'Increases Shield Strength by [20%]. Scoring hits on opponents increases ATK by [4%] for 8s. Max 5 stacks. Can only occur once every 0.3s. While protected by a shield, this ATK increase effect is increased by 100%.' },
      { name: 'Skyward Spine', devStage:'NotImpl',     
                          rarity: 5, 
                          passive: 'Blackwing', 
                          effect: 'Increases CRIT Rate by [8%] and increases Normal ATK SPD by 12%. Additionally, Normal and Charged Attacks hits on enemies have a 50% chance to trigger a vaccuum blade that deals [40+15%] of ATK as DMG in a small AoE. This effect can occur no more than once every 2s.' },
      { name: 'Primordial Jade Winged-Spear', devStage:'NotImpl',     
                          rarity: 5, 
                          passive: 'Eagle Spear of Justice', 
                          effect: 'On hit, increases ATK by [3.2+0.7%] for 6s. Max 7 stacks. This effect can only occur once every 0.3s. While in possession of the maximum possible stacks, DMG dealt is increased by [12%].' },
      
      { name: 'Crescent Pike',   
                          rarity: 4, 
                          passive: 'Infusion Needle', 
                          effect: 'After picking up an Elemental Orb/Particle, Normal and Charged Attacks deal an additional [20%] ATK as DMG for 5s.' },
      { name: 'Deathmatch', devStage:'NotImpl',     
                          rarity: 4, 
                          passive: 'Gladiator', 
                          effect: 'If there are at least 2 enemies nearby, ATK is increased by [16%] and DEF is increased by [16%]. If there are less than 2 enemies nearby, ATK is increased by [24%].' },
      { name: 'Royal Spear', devStage:'NotImpl',     
                          rarity: 4, 
                          passive: 'Focus', 
                          effect: 'Upon damaging an enemy, increases CRIT Rate by [8%]. Max 5 stacks. A CRIT hit removes all stacks.' },
      { name: 'Favonius Lance', devStage:'NotImpl',     
                          rarity: 4, 
                          passive: 'Windfall', 
                          effect: 'CRIT Hits have a [60+10%] chance to generate a small amount of Elemental Particles, which will regenerate 6 Energy for the character. Can only occur once every [12-1.5s]' },
      { name: 'Lithic Spear', devStage:'NotImpl',     
                          rarity: 4, 
                          passive: 'Lithic Axiom - Subjugating Evil', 
                          effect: 'Normal Attack hits have a [20%] chance of causing the next Charged Attack performed in the following 10s to deal [40%] increased DMG.' },
      { name: 'Dragonspine Spear', devStage:'NotImpl',     
                          rarity: 4, 
                          passive: 'Frost Burial', 
                          effect: 'Hitting an opponent with Normal and Charged Attacks has a [60%] chance of forming and dropping an Everfrost Icicle above them, dealing [80%] AoE ATK DMG. Opponents affected by Cryo are dealt [200%] ATK DMG. Can only occur once every 10s.' },
      { name: 'Prototype Grudge', devStage:'NotImpl',     
                          rarity: 4, 
                          passive: 'Magic Affinity', 
                          effect: 'After using an Elemental Skill, increases Normal and Charged Attack DMG by [8%] for 12s. Max 2 stacks.' },
      { name: 'Dragon\'s Bane', devStage:'NotImpl',     
                          rarity: 4, 
                          passive: 'Bane of Flame and Water', 
                          effect: 'Increases DMG against enemies affected by Hydro or Pyro by [20+4%].' },
      { name: 'Blackcliff Pole', 
                          rarity: 4, 
                          passive: 'Press the Advantage', 
                          effect: 'After defeating an enemy, ATK is increased by [12%] for 30s. This effect has a maximum of 3 stacks, and the duration of each stack is independent of the others.' },
      
      { name: 'Halberd', devStage:'NotImpl',
                          rarity: 3, 
                          passive: 'Heavy',
                          effect: 'Normal Attacks deal an additional [160%] DMG. Can only occur once every 10s.' },
      { name: 'Black Tassel', devStage:'NotImpl',     
                          rarity: 3, 
                          passive: 'Bane of the Soft', 
                          effect: 'Increases DMG against slimes by [40%].' },
      { name: 'White Tassel', devStage:'NotImpl',     
                          rarity: 3, 
                          passive: 'Sharp', 
                          effect: 'Increases Normal Attack DMG by [24%].' },
    ],
    sword: [
      { name: 'None',                 
                          rarity: 0, 
                          passive: '', 
                          effect: '' },
      { name: 'Summit Shaper', devStage:'NotImpl',     
                          rarity: 5, 
                          passive: 'Golden Majesty', 
                          effect: 'Increases Shield Strength by [20%]. Scoring hits on opponents increases ATK by [4%] for 8s. Max 5 stacks. Can only occur once every 0.3s. While protected by a shield, this ATK increase effect is increased by 100%.' },
      { name: 'Aquila Favonia', devStage:'NotImpl',     
                          rarity: 5, 
                          passive: 'Falcon\'s Defiance', 
                          effect: 'ATK is increased by [20%]. Triggers on taking DMG: the soul of the Falcon of the West awakens, holding the banner of the resistance aloft, regenerating HP equal to [100+15%] of ATK and dealing [200+30%] of ATK as DMG to surrounding enemies. This effect can only occur once every 15s.' },
      { name: 'Skyward Blade', devStage:'NotImpl',     
                          rarity: 5, 
                          passive: 'Sky-Piercing Fang', 
                          effect: 'Crit Rate increased by [4%]. Gains Skypiercing Might upon using Elemental Burst: Increases Movement SPD by 10%, increases ATK SPD by 10%, and increases the DMG of Normal and Charged Attack hits by [20%] for 12s.' },
      
      { name: 'Blackcliff Longsword',    
                          rarity: 4, 
                          passive: 'Press the Advantage', 
                          effect: 'After defeating an enemy, ATK is increased by [12%] for 30s. This effect has a maximum of 3 stacks, and the duration of each stack is independent of the others.' },
      { name: 'Sacrificial Sword', devStage:'NotImpl',     
                          rarity: 4, 
                          passive: 'Composed', 
                          effect: 'After damaging an opponent with an Elemental Skill, the skill has a [40%] chance to end its own CD. Can only occur once every [30-4s].' },
      { name: 'Sword of Descension', devStage:'NotImpl',     
                          rarity: 4, 
                          passive: 'Descension', 
                          effect: 'Hitting enemies with Normal or Charged Attacks grants a 50% chance to deal 200% ATK as DMG in a small AoE. This effect can only occur once every 10s. Additionally, if the Traveler equips the Sword of Descension, their ATK is increased by 66.' },
      { name: 'The Alley Flash',    
                          rarity: 4, 
                          passive: 'Itinerant Hero', 
                          effect: 'Continuosly sprinting for at least 1s increases ATK by 28% for 6s. This effect cannot stack.' },
      { name: 'Favonius Sword',    
                          rarity: 4, 
                          passive: 'Windfall', 
                          effect: 'CRIT hits have a [60+10%] chance to generate 1 Elemental Orb, which will regenerate 6 Energy for the character. Can only occur once every [12-1.5s].' },
      { name: 'The Black Sword', devStage:'Testing',     
                          rarity: 4, 
                          passive: 'Justice', 
                          effect: 'Increases DMG dealt by Normal and Charged Attacks by [20%]. Additionally, regenerates [60+10%] of ATK as HP when Normal and Charged Attacks score a CRIT Hit. This effect can occur once every 5s.' },
      { name: 'The Flute', devStage:'NotImpl',     
                          rarity: 4, 
                          passive: 'Chord', 
                          effect: 'Normal or Charged Attacks grant a Harmonic on hits. Gaining 5 Harmonics triggers the power of music and deals [100%] ATK DMG to surrounding enemies. Harmonics last up to 30s, and a maximum of 1 can be gained every 0.5s.' },
      { name: 'Royal Longsword', devStage:'NotImpl',     
                          rarity: 4, 
                          passive: 'Focus', 
                          effect: 'Upon dealing damage to an opponent, increases CRIT Rate by [8%]. Max 5 stacks. A CRIT hit removes all existing stacks.' },
      { name: 'Prototype Rancour', devStage:'NotImpl',     
                          rarity: 4, 
                          passive: 'Smashed Stone', 
                          effect: 'On hit, Normal or Charged Attacks increase ATK and DEF by [4%] for 6s. Max 4 stacks. Can only occur once every 0.3s.' },
      { name: 'Lion\'s Roar', devStage:'NotImpl',     
                          rarity: 4, 
                          passive: 'Bane of Fire and Thunder', 
                          effect: 'Increases DMG against enemies affected by Pyro or Electro by [20+4%].' },
      { name: 'Iron Sting', devStage:'NotImpl',     
                          rarity: 4, 
                          passive: 'Infusion Stinger', 
                          effect: 'Dealing Elemental DMG increases all DMG by [6%] for 6s. Max 2 stacks. Can only occur once every 1s.' },
      { name: 'Festering Desire', devStage:'NotImpl',     
                          rarity: 4, 
                          passive: 'Undying Admiration', 
                          effect: 'Increases Elemental Skill DMG by [16%] and Elemental Skill CRIT Rate by [6%].' },
      
      { name: 'Skyrider Sword', devStage:'NotImpl',     
                          rarity: 3, 
                          passive: 'Determination', 
                          effect: 'Using an Elemental Burst grants a [12%] increase in ATK and Movement SPD for 15s.' },
      { name: 'Fillet Blade', devStage:'NotImpl',     
                          rarity: 3, 
                          passive: 'Gash', 
                          effect: 'On hit, has 50% chance to deal [240+40%] ATK DMG to a single enemy. Can only occur once every [15-1s].' },
      { name: 'Traveler\'s Handy Sword', 
                          rarity: 3, 
                          passive: 'Journey', 
                          effect: 'Each Elemental Orb or Particle collected restores [1%] HP.' },
      { name: 'Harbinger of Dawn', devStage:'NotImpl',     
                          rarity: 3, 
                          passive: 'Vigorous', 
                          effect: 'When HP is above 90%, increases CRIT Rate by [14%].' },
      { name: 'Dark Iron Sword', devStage:'NotImpl',     
                          rarity: 3, 
                          passive: 'Overloaded', 
                          effect: 'Upon causing an Overloaded, Superconduct, Electro-Charged, or an Electro-infused Swirl reaction, ATK is increased by [20%] for 12s.' },
      { name: 'Cool Steel', devStage:'NotImpl',     
                          rarity: 3, 
                          passive: 'Bane of Water and Ice', 
                          effect: 'Increases DMG against enemies affected by Hydro or Cryo by [12%].' },
    ]
  };

export default Weapons;