import Tools from '../../Tools.js';
import Scaling from '../../Scaling.js';
import BaseCharacter from '../base.js';

var Qiqi = (props) => {
  
  let scaleTable = {
    ascension0: {
      min: {level:1, maxHP:963, baseAtk:23, defense:72, attr:0},
      max: {level:20, maxHP:2498, baseAtk:58, defense:186, attr:0}
    },
    ascension1: {
      min: {level:20, maxHP:3323, baseAtk:77, defense:248, attr:0},
      max: {level:40, maxHP:4973, baseAtk:115, defense:371, attr:0}
    },
    ascension2: {
      min: {level:40, maxHP:5559, baseAtk:129, defense:415, attr:0.055},
      max: {level:50, maxHP:6396, baseAtk:149, defense:477, attr:0.055}
    },
    ascension3: {
      min: {level:50, maxHP:7178, baseAtk:167, defense:535, attr:0.111},
      max: {level:60, maxHP:8023, baseAtk:186, defense:598, attr:0.111}
    },
    ascension4: {
      min: {level:60, maxHP:8610, baseAtk:200, defense:642, attr:0.111},
      max: {level:70, maxHP:9463, baseAtk:219, defense:706, attr:0.111}
    },
    ascension5: {
      min: {level:70, maxHP:10050, baseAtk:233, defense:749, attr:0.166},
      max: {level:80, maxHP:10912, baseAtk:253, defense:814, attr:0.166}
    },
    ascension6: {
      min: {level:80, maxHP:11499, baseAtk:266, defense:857, attr:0.222},
      max: {level:90, maxHP:12362, baseAtk:286, defense:922, attr:0.222}
    }
  };
  return BaseCharacter({
    ...props,
    name: 'Qiqi',
    element: 'cryo',
    equip: 'sword',
    scaleTable: scaleTable,
    scaleAttr: 'damageBonus_electro',
    attackTalent: {
      description: 'Ancient Sword Art',
      level: props.attack,
      type: "NormalAtk",
      defaultImpl: {
        talentProperties: {
          comboTable: [
            {dmg:0.378, anim:0.4}, 
            {dmg:0.389, anim:0.35}, 
            {dmg:0.242, anim:0.2, extra:[
              {dmg:0.242, anim:0.35}
            ]},
            {dmg:0.247, anim:0.2, extra:[
              {dmg:0.247, anim:0.3},
              {dmg:0.247, anim:0.1}
            ]}, 
            {dmg:0.63, anim:1}
          ]
        },
        duration: 1,
        Resolve(result) {
          result = Scaling.ScaleAttackCombo(result);
          
          return result;
        }
      },
      chargedImpl: {
        description: 'Ancient Sword Art - Charge A-Hit',
        type: "ChargeAtk",
        duration: 0.65,
        Resolve(result) {
          let slash = Tools.CreateTalentSideEffect(result, 'default');
          slash.precedingResult = result.precedingResult;
          result.talentEffects.push(slash);
          
          result.talentEffects.push(Tools.CreateTalentSideEffect(result, 'chargedSecond'));
          result = Scaling.ScaleAttackTalent(0.643, result);
          return result;
        }
      },
      chargedSecondEffect: {
        description: 'Ancient Sword Art - Charged B-Hit',
        type: "ChargeAtk",
        duration: 0.1,
        Resolve(result) {
          result = Scaling.ScaleAttackTalent(0.643, result);
          return result;
        }
      }
    },
    skillTalent: {
      description:'Adeptus Art: Herald of Frost',
      level: props.skill,
      type: 'Skill',
      defaultImpl: {
        duration: 2,
        cooldown: 30,
        Prepare(result) {
          result.attributes.element = 'cryo';
          return result;
        },
        Resolve(result) {
          result = Scaling.ScaleTalent(0.96, result);
          result.effects.push(Tools.CreateTalentSideEffect(result, 'herald'));
          return result;
        }
      },
      heraldEffect: {
        description: 'Herald of Frost',
        entity: 'Qiqi\'s Herald of Frost',
        duration: 1,
        talentProperties: {
          spinNumber:1,
          spinCount:9,
          durations: [1, 2.1, 1, 2.4, 1.1, 2, 1, 2.1, 2.3]
        },
        Prepare(result) {
          result.attributes.element = 'cryo';
          return result;
        },
        Resolve(result) {
          result = Scaling.ScaleTalent(0.36, result);
          return result;
        },
        Finalize(result) {
          if(result.talentProperties.spinNumber<result.talentProperties.spinCount) {
            let nextSpin = result.talentProperties.spinNumber+1;
            result.effects.push(Tools.CreateTalentSideEffect(result, 'herald', {
              talentProperties: {spinNumber:nextSpin},
              duration: result.talentProperties.durations[nextSpin-1]
            }))
          }
          return result;
        }
      }
    },
    burstTalent: {
      description: 'Adeptus Art: Preserver of Fortune',
      level: props.burst,
      type: 'Burst',
      defaultImpl: {
        duration:2,
        Prepare(result) {
          result.effects.push(Tools.CreateTalentSideEffect(result, 'talisman'));
          result.attributes.element = 'cryo';
          return result;
        },
        Resolve(result) {
          result = Scaling.ScaleTalent(2.85, result);
          return result;
        }
      },
      talismanEffect: {
        description: 'Fortune-Preserving Talisman',
        entity: 'Qiqi\'s Adeptus Art: Preserver of Fortune',
        type: 'Buff',
        duration: 15,
        relativeTime: (r => -r.precedingResult.duration)
      }
    },
    passiveAsc1: {
      description: 'Life-Prolonging Methods',
      PersistentResolve(result, context) {
        return result;
      }
    },
    passiveAsc4: {
      description: 'A Glimpse into Arcanum',
      PersistentResolve(result, context) {
        return result;
      }
    },
    passiveOther: [{
      description: 'Former Life Memories',
      PersistentResolve(result, context) {
        return result;
      }
    }],
    constellations: [
      ...BaseCharacter.Constellation53,
    ]
  });
};

export default Qiqi;