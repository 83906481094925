import Scaling from '../Scaling.js';
import BaseWeapon from './base.js';

const None = (props) => {
  
  return BaseWeapon({
    ...props,
    type: 'none',
    name: 'None',
    lvl1Atk: 0,
    lvl1Sub: 0,
    subType: 'atkPct',
    PersistentResolve(result, context) {
      return result;
    }
  });
};

export default None;