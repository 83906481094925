import Tools from '../../Tools.js';
import Scaling from '../../Scaling.js';
import BaseCharacter from '../base.js';

var TravelerGeo = (props) => {
  
  let scaleTable = {
    ascension0: {
      min: {level:1, maxHP:912, baseAtk:18, defense:57, attr:0},
      max: {level:20, maxHP:2342, baseAtk:46, defense:147, attr:0}
    },
    ascension1: {
      min: {level:20, maxHP:3024, baseAtk:60, defense:190, attr:0},
      max: {level:40, maxHP:4529, baseAtk:88, defense:284, attr:0}
    },
    ascension2: {
      min: {level:40, maxHP:5013, baseAtk:98, defense:315, attr:0.06},
      max: {level:50, maxHP:5766, baseAtk:112, defense:362, attr:0.06}
    },
    ascension3: {
      min: {level:50, maxHP:6411, baseAtk:126, defense:405, attr:0.12},
      max: {level:60, maxHP:7164, baseAtk:140, defense:450, attr:0.12}
    },
    ascension4: {
      min: {level:60, maxHP:7648, baseAtk:149, defense:480, attr:0.12},
      max: {level:70, maxHP:8401, baseAtk:164, defense:527, attr:0.12}
    },
    ascension5: {
      min: {level:70, maxHP:8885, baseAtk:174, defense:558, attr:0.18},
      max: {level:80, maxHP:9638, baseAtk:188, defense:605, attr:0.18}
    },
    ascension6: {
      min: {level:80, maxHP:10122, baseAtk:198, defense:635, attr:0.24},
      max: {level:90, maxHP:10875, baseAtk:213, defense:682, attr:0.24}
    }
  };
  return BaseCharacter({
    ...props,
    name: props.name || 'Traveler - Geo',
    element: props.element || 'geo',
    equip: 'sword',
    scaleTable: scaleTable,
    scaleAttr: 'atkPct',
    attackTalent: {
      description: 'Foreign Rockblade',
      level: props.attack,
      type: "NormalAtk",
      defaultImpl: {
        talentProperties: {
          comboTable: [
            {dmg:0.445, anim:0.33}, 
            {dmg:0.434, anim:0.5}, 
            {dmg:0.53, anim:0.33}, 
            {dmg:0.583, anim:0.66}, 
            {dmg:0.708, anim:1}
          ]
        },
        duration: 1,
        Resolve(result) {
          result = Scaling.ScaleAttackCombo(result);
          result.attributes.element='physical';
          return result;
        }
      },
      chargedImpl: {
        description: 'Foreign Rockblade - Charged A-Hit',
        type: "ChargeAtk",
        duration: 1,
        Resolve(result) {
          let slash = Tools.CreateTalentSideEffect(result, 'default');
          slash.precedingResult = result.precedingResult;
          result.talentEffects.push(slash);
          
          result.talentEffects.push(Tools.CreateTalentSideEffect(result, 'chargedSecond'));
          result = Scaling.ScaleTalent(0.559, result);
          return result;
        }
      },
      chargedSecondEffect: {
        description: 'Foreign Rockblade - Charged B-Hit',
        type: "ChargeAtk",
        duration: 0.3,
        Resolve(result) {
          result = Scaling.ScaleTalent(props.secondChargeDmg || 0.722, result);
          return result;
        }
      }
    },
    skillTalent: {
      description:'Starfell Sword',
      level: props.skill,
      type: 'Skill',
      defaultImpl: {
        duration: 1,//TODO: check this value
        cooldown: 8,
        Prepare(result) {
          result.attributes.element = 'geo';
          return result;
        },
        Finalize(result) {
          result.effects.push(Tools.CreateTalentSideEffect(result, 'meteorite'));
          return result;
        }
      },
      holdImpl: {
        description: 'Starfell Sword - Hold',
        cooldown: 8,
        duration: 2,
        Prepare(result) {
          result.attributes.element = 'geo';
          return result;
        },
        Finalize(result) {
          result.effects.push(Tools.CreateTalentSideEffect(result, 'meteorite'));
          return result;
        }
      },
      meteoriteEffect: {
        description: 'Starfell Sword - Meteorite',
        entity: 'Traveler\'s Starfell Sword',
        duration: 30,
        relativeTime: -0.1,
        Prepare(result) {
          result.attributes.element = 'geo';
          return result;
        },
        Resolve(result) {
          result = Scaling.ScaleTalent(2.48, result);
          return result;
        }
      }
    },
    burstTalent: {
      description: 'Wake of Earth',
      level: props.burst,
      type: 'Burst',
      defaultImpl: {
        cooldown: 15,
        duration: 2,
        Prepare(result) {
          result.attributes.element = 'geo';
          return result;
        },
        Finalize(result) {
          result.effects.push(Tools.CreateTalentSideEffect(result, 'shockwave'));
          return result;
        }
      },
      shockwaveEffect: {
        description: 'Wake of Earth - Shockwave',
        entity: 'Traveler\'s Wake of Earth',
        duration: 1,
        relativeTime: -0.2,
        Prepare(result) {
          result.attributes.element = 'geo';
          return result;
        },
        Resolve(result) {
          result = Scaling.ScaleTalent(1.48, result);
          return result;
        },
        Finalize(result) {
          result.effects.push(Tools.CreateTalentSideEffect(result, 'stonewall'));
          return result;
        }
      },
      stonewallEffect: {
        description: 'Wake of Earth - Stonewall',
        entity: 'Traveler\'s Wake of Earth',
        duration: 15,
        Prepare(result) {
          result.attributes.element = 'geo';
          return result;
        }
      }
    },
    passiveAsc1: {
      description: 'Shattered Darkrock',
      PersistentResolve(result, context) {
        return result;
      }
    },
    passiveAsc4: {
      description: 'Frenzied Rockslide',
      PersistentResolve(result, context) {
        return result;
      }
    },
    passiveOther: [],
    constellations: [
      ...BaseCharacter.Constellation53,
    ]
  });
};

export default TravelerGeo;