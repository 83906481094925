
function nvl(value, def) {
  return (value===null || typeof value === "undefined") ? def : value;
}

const Attributes = (props) => {
  
  return {
    level: props.level,
    /**
     * physical, hydro, cryo, pyro, geo, electro, anemo, dendro
     **/
    element: nvl(props.element, 'physical'),
    baseAtk: nvl(props.baseAtk, 0),
    baseAtkPct: nvl(props.baseAtkPct, 0),
    flatAtk: nvl(props.flatAtk, 0),
    atkPct: nvl(props.atkPct, 0),
    atkSpd: nvl(props.atkSpd, 0),
    critRate: nvl(props.critRate, 0.0),
    critDmg: nvl(props.critDmg, 0.0),
    elemMastery: nvl(props.elemMastery, 0),
    defense: nvl(props.defense, 0),
    defensePct: nvl(props.defensePct, 0),
    defenseReduction: nvl(props.defenseReduction, 0),
    energyRecharge: nvl(props.energyRecharge, 0),
    maxHP: nvl(props.maxHP, 0),
    hpPct: nvl(props.hpPct, 0),
    reactionBonus: {
      all:0,
      overloaded:0,
      electroCharged:0,
      shattered:0,
      melt:0,
      superconduct:0,
      swirl:0,
      vaporize:0,
      burning:0,
      ...props.reactionBonus
    },
    damageBonus: {
      all:0,
      physical:0,
      hydro:0,
      electro:0,
      pyro:0,
      cryo:0,
      anemo:0,
      geo:0,
      dendro:0,
      ...props.damageBonus
    },
    resistanceReduction: {
      all:0,
      physical:0,
      hydro:0,
      electro:0,
      pyro:0,
      cryo:0,
      anemo:0,
      geo:0,
      dendro:0,
      ...props.resistanceReduction
    },
    resistance: {
      all:0,
      physical:0,
      hydro:0,
      electro:0,
      pyro:0,
      cryo:0,
      anemo:0,
      geo:0,
      dendro:0,
      ...props.resistance
    },
    skill: 0,
    
    postProcesses: props.postProcesses ? props.postProcesses : [],
    
    Merge(attr) {
      //absolute attributes
      ['level', 'element']
        .forEach(a => this[a] = (attr[a] ? attr[a] : this[a]));
      //additive attributes
      ['baseAtk', 'baseAtkPct', 'flatAtk', 
       'atkPct', 'atkSpd',
       'critRate', 'critDmg', 'elemMastery', 
       'defense', 'defensePct', 'defenseReduction', 
       'energyRecharge', 'maxHP', 'hpPct']
        .forEach(a => this[a] += (attr[a] ? attr[a] : 0));
      ['all', 'overloaded', 'electroCharged',
       'shattered', 'melt', 'superconduct',
       'swirl', 'vaporize', 'burning']
        .forEach(a => this.reactionBonus[a] += (attr.reactionBonus && attr.reactionBonus[a] ? attr.reactionBonus[a] : 0));
      ['all', 'physical', 'hydro', 
       'electro', 'pyro', 'cryo', 
       'anemo', 'geo', 'dendro']
        .forEach(a => {
          this.damageBonus[a] += (attr.damageBonus && attr.damageBonus[a] ? attr.damageBonus[a] : 0);
          this.resistanceReduction[a] += (attr.resistanceReduction && attr.resistanceReduction[a] ? attr.resistanceReduction[a] : 0);
          this.resistance[a] += (attr.resistance && attr.resistance[a] ? attr.resistance[a] : 0);
        });
      if(attr.postProcesses && attr.postProcesses.length>0) {
        this.postProcesses.push(...attr.postProcesses);
      }
      return this;
    },
    Flatten() {
      return {
        level: this.level,
        element: this.element,
        attackPower: this.baseAtk 
          * (1 + this.baseAtkPct) 
          * (1 + this.atkPct) 
          + this.flatAtk,
        critRage: this.critRate,
        critDmg: this.critDmg,
        skill: this.skill,
        damageBonus: {
          physical: this.damageBonus.all+this.damageBonus.physical,
          hydro: this.damageBonus.all+this.damageBonus.hydro,
          electro: this.damageBonus.all+this.damageBonus.electro,
          pyro: this.damageBonus.all+this.damageBonus.pyro,
          cryo: this.damageBonus.all+this.damageBonus.cryo,
          anemo: this.damageBonus.all+this.damageBonus.anemo,
          geo: this.damageBonus.all+this.damageBonus.geo,
          dendro: this.damageBonus.all+this.damageBonus.dendro
        },
        reactionBonus: {
          overloaded: 1 
            + (0.453633528 * this.attributes.elemMastery * Math.exp(-0.000505 * this.attributes.elemMastery)) / 100 
            + this.reactionBonus.all
            + this.reactionBonus.overloaded,
          electroCharged: 1 
            + (0.453633528 * this.attributes.elemMastery * Math.exp(-0.000505 * this.attributes.elemMastery)) / 100 
            + this.reactionBonus.all
            + this.reactionBonus.electroCharged,
          shattered: 1 
            + (0.453633528 * this.attributes.elemMastery * Math.exp(-0.000505 * this.attributes.elemMastery)) / 100 
            + this.reactionBonus.all
            + this.reactionBonus.shattered,
          melt: 1 
            + (0.189266831 * this.attributes.elemMastery * Math.exp(-0.000505 * this.attributes.elemMastery)) / 100
            + this.reactionBonus.all
            + this.reactionBonus.melt,
          superconduct: 1 
            + (0.453633528 * this.attributes.elemMastery * Math.exp(-0.000505 * this.attributes.elemMastery)) / 100 
            + this.reactionBonus.all
            + this.reactionBonus.superconduct,
          swirl: 1 
            + (0.189266831 * this.attributes.elemMastery * Math.exp(-0.000505 * this.attributes.elemMastery)) / 100 
            + this.reactionBonus.all
            + this.reactionBonus.swirl,
          vaporize: 1 
            + (0.189266831 * this.attributes.elemMastery * Math.exp(-0.000505 * this.attributes.elemMastery)) / 100
            + this.reactionBonus.all
            + this.reactionBonus.vaporize,
          burning: this.reactionBonus.all+this.reactionBonus.burning
        },
        resistanceReduction: {
          physical: this.resistanceReduction.all+this.resistanceReduction.physical,
          hydro: this.resistanceReduction.all+this.resistanceReduction.hydro,
          electro: this.resistanceReduction.all+this.resistanceReduction.electro,
          pyro: this.resistanceReduction.all+this.resistanceReduction.pyro,
          cryo: this.resistanceReduction.all+this.resistanceReduction.cryo,
          anemo: this.resistanceReduction.all+this.resistanceReduction.anemo,
          geo: this.resistanceReduction.all+this.resistanceReduction.geo,
          dendro: this.resistanceReduction.all+this.resistanceReduction.dendro
        },
        resistance: {
          physical: this.resistance.all+this.resistance.physical,
          hydro: this.resistance.all+this.resistance.hydro,
          electro: this.resistance.all+this.resistance.electro,
          pyro: this.resistance.all+this.resistance.pyro,
          cryo: this.resistance.all+this.resistance.cryo,
          anemo: this.resistance.all+this.resistance.anemo,
          geo: this.resistance.all+this.resistance.geo,
          dendro: this.resistance.all+this.resistance.dendro
        },
        defense: this.defense * (1 + this.defensePct),
        defenseReduction: this.defenseReduction,
        energyRecharge: 1 + this.energyRecharge,
        maxHP: this.maxHP * (1 + this.hpPct)
      };
    }
  };
};

const ZeroAttributes = () => Attributes({
    level: null,
    baseAtk: 0,
    baseAtkPct: 0,
    flatAtk: 0,
    atkPct: 0,
    atkSpd: 0,
    critRate: 0,
    critDmg: 0,
    damageBonus: {
      all:0,
      physical:0,
      hydro:0,
      electro:0,
      pyro:0,
      cryo:0,
      anemo:0,
      geo:0,
      dendro:0
    },
    reactionBonus: {
      all:0,
      overloaded:0,
      electroCharged:0,
      shattered:0,
      melt:0,
      superconduct:0,
      swirl:0,
      vaporize:0,
      burning:0
    },
    skill: 0,
    resistanceReduction: {
      all:0,
      physical:0,
      hydro:0,
      electro:0,
      pyro:0,
      cryo:0,
      anemo:0,
      geo:0,
      dendro:0
    },
    elemMastery: 0,
    /**
     * physical, hydro, cryo, pyro, geo, electro, anemo, dendro
     **/
    element: null,
    resistance: {
      all:0,
      physical:0,
      hydro:0,
      electro:0,
      pyro:0,
      cryo:0,
      anemo:0,
      geo:0,
      dendro:0
    },
    defense: 0,
    defensePct: 0,
    defenseReduction: 0,
    energyRecharge: 0,
    maxHP: 0,
    hpPct: 0,
});
const BaseCharacterAttributes = () => ZeroAttributes().Merge({
  critRate: 0.05,
  critDmg: 0.5
});

export default Attributes;
export {ZeroAttributes, BaseCharacterAttributes};