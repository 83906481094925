
const HistoryEntry = (props) => {
  
  return {
    description: props.description,
    initiator: props.initiator,
    talent: props.talent, 
    talentImpl: props.talentImpl,
    talentProperties: props.talentProperties,
    enforceCooldown: props.enforceCooldown || false,
    cooldown: props.cooldown || 0,
    entity: props.entity || props.initiator.name,
    duration: props.duration || 0.2,
    relativeTime: props.relativeTime || 0,
    /**
     * Electro, Pyro, Hydro, Dendro, Cryo, Anemo, 
     * EffectOverTime, 
     * NormalAtk-1, NormalAtk-2, NormalAtk-3, NormalAtk-4, NormalAtk-5, ...
     * ChargeAtk, ChargeAtkHold
     * Skill, SkillHold
     * Burst
     * Switch
     * Idle
     **/
    type: props.type,
    Prepare: function(result, context) {
      return props.Prepare ? props.Prepare(result, context) : result;
    },
    Resolve: function(result, context) {
      return props.Resolve ? props.Resolve(result, context) : result;
    },
    Finalize: function(result, context) {
      return props.Finalize ? props.Finalize(result, context) : result;
    },
    PersistentResolve: function(result, context) {
      return props.PersistentResolve ? props.PersistentResolve(result, context) : result;
    }
  }
};

export default HistoryEntry;