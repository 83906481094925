import Scaling from '../../Scaling.js';
import BaseWeapon from '../base.js';

const SkywardAtlas = (props) => {
  
  return BaseWeapon({
    ...props,
    type: 'catalyst',
    name: 'Skyward Atlas',
    lvl1Atk: 48,
    lvl1Sub: 0.072,
    subType: 'atkPct',
    PersistentResolve(result, context) {
      /**
       * effect: 'Increases Elemental DMG Bonus by [12%]. Normal Attack hits 
       * have a 50% chance to earn the favor of the clouds. which actively seek 
       * out nearby enemies to attack for 15s, dealing [160%] ATK DMG. Can only 
       * occur once every 30s.'
       */
      if(this.IsSelf(result)) {
        let effectValues = {
          elementalDmg: Scaling.ScaleWeaponPassive(0.12, this.refinement),
          atkDmg: Scaling.ScaleWeaponPassive(1.6, this.refinement)
        }
      }
      return result;
    }
  });
};

export default SkywardAtlas;