import {BaseSet} from './base.js';
import Tools from '../Tools.js';

const MartialArtist = (props) => {
  
  return BaseSet({
    ...props,
    name: 'Martial Artist',
    two: {
      PersistentResolve(result, context) {
        if(!this.IsSelf(result)) return result;
        if(result.type.startsWith("NormalAtk") || result.type==="ChargeAtk" || result.type==="ChargeAtkHold") {
          result.attributes.damageBonus.all += 0.15;
          result.logs.push(this.name + ' increases Damage Bonus by 35% on Normal and Charged Attacks');
        }
        return result;
      }
    },
    four: {
      PersistentFinalize(result){
        if(!this.IsSelf(result)) return result;
        if(result.type==='Skill') {
          result.effects.push(Tools.CreateSideEffect(result, {
            description: 'Martial Artist 4pc',
            entity: 'Buffs',
            duration: 8,
            relativeTime: (r => -r.precedingResult.duration),//-result.duration,
            type: 'Buff',
            PersistentResolve(result) {
              if(result.initiator.name===this.initiator.name) {
                if(result.type.startsWith("NormalAtk") 
                   || result.type==="ChargeAtk" 
                   || result.type==="ChargeAtkHold") {
                  result.attributes.damageBonus.all += 0.25;
                  result.logs.push("Martial Artist 4pc increases Normal and Charged Attack DMG by 25% for 8s");
                }
              }
              return result;
            }
          }))
        }
        return result;
      }
    }
  });
};

export default MartialArtist;