import Scaling from '../../Scaling.js';
import BaseWeapon from '../base.js';

const TheViridescentHunt = (props) => {
  
  return BaseWeapon({
    ...props,
    type: 'bow',
    name: 'The Viridescent Hunt',
    lvl1Atk: 42,
    lvl1Sub: 0.06,
    subType: 'critRate',
    PersistentResolve(result, context) {
      /**
       * effect: 'Upon hit, Normal and Aimed Shot Attacks have a 50% chance to 
       * generate a Cyclone, which will continuously attract surrounding 
       * enemies, dealing [40%] of ATK as DMG to these enemies every 0.5s for 
       * 4s. This effect can only occur once every [14-1]s.'
       */
      if(this.IsSelf(result)) {
      }
      return result;
    }
  });
};

export default TheViridescentHunt;