import React, { PureComponent, useState } from 'react';
import './App.css';
import 'semantic-ui-css/semantic.min.css';
import Amplify from "aws-amplify";
import awsExports from "./aws-exports";
import FixedMenuLayout from './routes';
import ReactGA from 'react-ga';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import IndexPage from './routes/IndexPage.js'; 
import * as Genshin from './routes/genshin/';
import PrivacyPolicyPage from './routes/PrivacyPolicyPage.js';
import TermsOfUsePage from './routes/TermsOfUsePage.js';
import {withTracker} from './analyticsTracker.js';

Amplify.configure(awsExports);

const DEFAULT_CONFIG = [{
  trackingId: 'UA-185687494-1',
  debug: true
}];

function App() {
  const [reactGAInitialised, setReactGAInitialised] = useState(false);
  const [reactGAConfig] = useState(DEFAULT_CONFIG);
  
  function initReactGA() {
    ReactGA.initialize(reactGAConfig);
    // Send initial test view
    ReactGA.pageview(window.location.pathname + window.location.search);
    setReactGAInitialised(true);
  }
  
  if (!reactGAInitialised) {
    initReactGA();
  }
  
  return (
    <div className="App">
      <FixedMenuLayout>
        <Switch>
          <Route path="/" exact component={IndexPage}/>
          <Route path="/genshin" exact component={Genshin.IndexPage}/>
          <Route path="/genshin/simulator" exact render={props => {
            /*global URLSearchParams*/
            let shared = new URLSearchParams(props.location.search).get('shared');
            window.history.replaceState({}, '', '/genshin/simulator');
            return <Genshin.SimulatorPage shared={shared}/>;
          }}/>
          <Route path="/genshin/simulator/user-guide" exact component={Genshin.UserGuidePage}/>
          <Route path="/genshin/guides" exact component={Genshin.GuidesPage}/>
          <Route path="/genshin/community" exact component={Genshin.CommunityPage}/>
          <Route path="/legal/privacy-policy" exact component={PrivacyPolicyPage}/>
          <Route path="/legal/terms-of-use" exact component={TermsOfUsePage}/>
        </Switch>
      </FixedMenuLayout>
    </div>
  );
}

export default withTracker(App);
