import React, {useState, useEffect, Fragment} from 'react';
import { Icon, Label, Header, Segment } from 'semantic-ui-react';

const CharacterCard = (props) => {
  const [monster, setMonster] = useState(props.monster);
  
  useEffect(() => {
    setMonster(props.monster);
  }, [props.monster]);
  
  return <Segment style={{height:"226px"}}>
      <Label attached="top left" style={{padding: "0.550781em 6.5em 0.550781em 1em", width: "100%"}}>
        <Header textAlign="left" style={{overflow: "hidden", height: "1.28571429em", whiteSpace: "nowrap", textOverflow: "ellipsis"}}>{monster.name}</Header>
      </Label>
      <Label as="a" attached="top right" style={{borderLeft: "1px gray groove", borderBottomLeftRadius: 0}}><Icon name="edit" />Edit</Label>
      <Fragment>
        <div style={{marginBottom:"4px"}}>
          <div style={{display: "inline-block"}}>
          </div>
          <img className="portrait" src={"/static/monsters/"+monster.name.replaceAll(/\s/gi, "-").toLowerCase()+".png"} style={{marginTop:"28px"}} alt={monster.name} />
          <Header style={{marginTop: "0"}}>Level {monster.level}</Header>
        </div>
      </Fragment>
    </Segment>;
};

export default CharacterCard;