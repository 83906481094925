import React, {Fragment, useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { Card, Button, Image, Header, Item, Icon, Advertisement } from 'semantic-ui-react';

const news = [
    {
      id:7297, 
      img:'20201212/2020121202360216151.png', 
      title: "Field Report: A Mountain, a Lost Civilization, and a Dragon's Remains",
      description: "Greetings, Travelers! This report comes to you from Dragonspine, where we've been surveying the scene in advance of your arrival.",
      date: "Dec 12, 2020"
    },{
      id: 7276,
      img: '20201211/2020121119455718088.png',
      title: "Version 1.2 \"The Chalk Prince and the Dragon\" Trailer | Genshin Impact",
      description: "This is Dragonspine, an enormous mountain that holds the remains of the venomous dragon, Durin, who fell here after the battle with Dvalin.",
      date: "Dec 11, 2020"
    },{
      id: 7244,
      img: '20201210/2020121016364255698.jpg',
      title: "While It's Warm - Event Details",
      description: "Fulfill food delivery orders to win Mora, Character Ascension Materials, Adventurer's Experience, Mystic Enhancement Ore, and Primogems!",
      date: "Dec 11, 2020"
    },{
      id: 7219,
      img: '20201208/2020120819130578831.png',
      title: "\"While It's Warm\" - Complete Orders to Earn Primogems!",
      description: "Good Hunter, Wangshu Inn, and Wanmin Restaurant are currently facing a high volume of orders. They don't have enough delivery staff to cover them all and are looking for help.",
      date: "Dec 09, 2020"
    },{
      id: 7170,
      img: '20201204/2020120410163194896.png',
      title: "Developers Discussion - 12/04",
      description: "Developers Discussion - 12/04",
      date: "Dec 04, 2020"
    }
  ]

const IndexPage = (props) => {
  return <Fragment>
    <Advertisement unit="top banner" test="Ad Unit" style={{margin:"0 auto 2em auto"}}/>
    <Header>Genshin Impact Resources</Header>
    <Card.Group centered>
      <Card href="/genshin/simulator">
        <Image src='/static/genshin/logo.png' wrapped ui={false} style={{backgroundColor:"rgba(17,17,17,0.75)"}}/>
        <Card.Content>
          <Card.Header>Simulator</Card.Header>
          <Card.Description>
            Try out team compositions, ability rotations, artifact values and 
            improve your game
          </Card.Description>
        </Card.Content>
        <Card.Content extra>
          <Button as={Link} to="/genshin/simulator">
            <Icon name="sliders" />Simulator
          </Button>
          <Button as={Link} to="/genshin/simulator/helpcenter">
            <Icon name="help circle" />Help Center
          </Button>
        </Card.Content>
      </Card>
      {/*<Card href="/genshin/guides">
        <Image src='/static/genshin/logo.png' wrapped ui={false} 
          style={{backgroundColor:"rgba(17,17,17,0.75)"}}/>
        <Card.Content>
          <Card.Header>Guides</Card.Header>
          <Card.Description>
            Read up on Genshin's mechanics, farming routes, and various 
            knowledge
          </Card.Description>
        </Card.Content>
        <Card.Content extra>
          <Button as={Link} to="/genshin/guides">Guides</Button>
        </Card.Content>
      </Card>
      <Card href="/genshin/community">
        <Image src='/static/genshin/logo.png' wrapped ui={false} 
          style={{backgroundColor:"rgba(17,17,17,0.75)"}}/>
        <Card.Content>
          <Card.Header>Community Resources</Card.Header>
          <Card.Description>
            Look up resources and discuss the game with other fans
          </Card.Description>
        </Card.Content>
        <Card.Content extra>
          <Button as={Link} to="/genshin/community">Community</Button>
        </Card.Content>
      </Card>*/}
    </Card.Group>
    {/*<Header as='h2'>News</Header>
    <Item.Group divided style={{textAlign:"left", maxWidth:"800px", margin:"0 auto"}}>
    {news.map(n => <Item key={"news"+n.id} href={"https://genshin.mihoyo.com/en/news/detail/"+n.id}>
        <Item.Image size='tiny' src={'https://uploadstatic-sea.mihoyo.com/contentweb/'+n.img} />
        <Item.Content>
          <Item.Header>{n.title}</Item.Header>
          <Item.Description>{n.description}</Item.Description>
          <Item.Extra>{n.date}</Item.Extra>
        </Item.Content>
      </Item>)}
    </Item.Group>*/}
  </Fragment>;
}

export default IndexPage;