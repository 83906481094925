import Tools from '../../Tools.js';
import Scaling from '../../Scaling.js';
import BaseCharacter from '../base.js';

//TODO: implement character
var Razor = (props) => {
  
  let scaleTable = {
    ascension0: {
      min: {level:1, maxHP:1003, baseAtk:20, defense:63, attr:0},
      max: {level:20, maxHP:2577, baseAtk:51, defense:162, attr:0}
    },
    ascension1: {
      min: {level:20, maxHP:3326, baseAtk:65, defense:209, attr:0},
      max: {level:40, maxHP:4982, baseAtk:97, defense:313, attr:0}
    },
    ascension2: {
      min: {level:40, maxHP:5514, baseAtk:108, defense:346, attr:0.075},
      max: {level:50, maxHP:6343, baseAtk:124, defense:398, attr:0.075}
    },
    ascension3: {
      min: {level:50, maxHP:7052, baseAtk:138, defense:443, attr:0.15},
      max: {level:60, maxHP:7881, baseAtk:154, defense:495, attr:0.15}
    },
    ascension4: {
      min: {level:60, maxHP:8413, baseAtk:164, defense:528, attr:0.15},
      max: {level:70, maxHP:9241, baseAtk:180, defense:580, attr:0.15}
    },
    ascension5: {
      min: {level:70, maxHP:9773, baseAtk:191, defense:613, attr:0.225},
      max: {level:80, maxHP:10602, baseAtk:207, defense:665, attr:0.225}
    },
    ascension6: {
      min: {level:80, maxHP:11134, baseAtk:217, defense:699, attr:0.30},
      max: {level:90, maxHP:11962, baseAtk:234, defense:751, attr:0.30}
    }
  };
  return BaseCharacter({
    ...props,
    name: 'Razor',
    element: 'electro',
    equip: 'claymore',
    scaleTable: scaleTable,
    scaleAttr: 'damageBonus.physical',
    attackTalent: {
      description: 'Steel Fang',
      level: props.attack,
      type: "NormalAtk",
      defaultImpl: {
        talentProperties: {
          comboTable: [
            {dmg:0.711, anim:0.5}, 
            {dmg:0.709, anim:0.6}, 
            {dmg:0.883, anim:1}, 
            {dmg:0.865, anim:0.75}, 
            {dmg:1.12, anim:1}
          ]
        },
        duration: 1,
        Resolve(result) {
          result = Scaling.ScaleAttackCombo(result);
          result.attributes.element='physical';
          return result;
        }
      },
      chargedImpl: {
        type: "ChargeAtk",
        duration: 1,
        Resolve(result) {
          result = Scaling.ScaleTalent(1.02, result);
          result.attributes.element='physical';
          return result;
        }
      },
      spinningImpl: {
        type: "ChargeAtkHold",
        duration: 1,
        Resolve(result) {
          //account for windup animation
          result.setDuration(1);
          let spinAnimation = [0.3, 0.4, 0.4, 0.5, 0.35, 0.5, 0.4, 0.4, 0.5, 0.9];
          [...Array(9)].forEach((e,i) => {
            result.talentEffects.push(Tools.CreateSideEffect(result, {
              description: 'Beidou Spin Attack',
              entity: 'Beidou',
              duration: spinAnimation[i],
              effectExtension: true,
              type: 'ChargeAtkHold',
              Resolve(result) {
                result = Scaling.ScaleTalent(0.562, result);
                result.attributes.element='physical';
                return result;
              }
            }));
          });
          result.talentEffects.push(Tools.CreateSideEffect(result, {
            ...result.initiator.chargedAttack,
            effectExtension: true
          }))
          return result;
        }
      }
    },
    skillTalent: {
      description:'Claw and Thunder',
      level: props.skill,
      type: 'Skill',
      defaultImpl: {
        Prepare(result) {
          return result;
        },
        Resolve(result) {
          return result;
        }
      },
      holdImpl: {
        Prepare(result) {
          return result;
        },
        Resolve(result) {
          return result;
        }
      }
    },
    burstTalent: {
      description: 'Lightning Fang',
      level: props.burst,
      type: 'Burst',
      defaultImpl: {
        Prepare(result) {
          return result;
        },
        Resolve(result) {
          return result;
        }
      }
    },
    passiveAsc1: {
      description: 'Awakening',
      PersistentResolve(result, context) {
        return result;
      }
    },
    passiveAsc4: {
      description: 'Hunger',
      PersistentResolve(result, context) {
        return result;
      }
    },
    passiveOther: [{
      description: 'Wolvensprint',
      PersistentResolve(result, context) {
        return result;
      }
    }],
    constellations: [
      ...BaseCharacter.Constellation53,
    ]
  });
};

export default Razor;