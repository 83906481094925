
const History = () => {
  return {
    entries: [],
    firstVersion: 0,
    currentVersion: 0,
    AddEntry(entry) {
      if(this.currentVersion<this.entries.length) {
        //console.log("Adding an entry and purging Undoed History");
        this.entries = [...this.entries.slice(0, this.currentVersion), entry];
      } else {
        //console.log("Adding an entry at the end of the History");
        this.entries = [...this.entries, entry];
      }
      if(entry.description === "Reset") {
        this.firstVersion = this.entries.length-1;
      }
      this.currentVersion = this.entries.length;
    },
    Undo() {
      if(this.currentVersion>0) {
        if(this.entries[this.currentVersion-1].description === "Reset") {
          let newFirstVersion=0;
          for(let i=this.currentVersion-2; i>0 && !newFirstVersion; i--) {
            if(this.entries[i].description === "Reset") {
              newFirstVersion = i;
            }
          }
          this.firstVersion = newFirstVersion;
        }
        this.currentVersion--;
        //console.log("Undo", this.firstVersion,  this.currentVersion);
      }
    },
    Redo() {
      if(this.currentVersion<this.entries.length) {
        this.currentVersion++;
        if(this.entries[this.currentVersion-1].description === "Reset") {
          this.firstVersion = this.currentVersion-1;
        }
        //console.log("Redo", this.firstVersion, this.currentVersion);
      }
    },
    GoTo(version) {
      if(this.currentVersion>0 && this.currentVersion<=this.entries.length) {
        this.currentVersion = version;
        let newFirstVersion=0;
        for(let i=this.currentVersion-1; i>0 && !newFirstVersion; i--) {
          if(this.entries[i].description === "Reset") {
            newFirstVersion = i;
          }
        }
        this.firstVersion = newFirstVersion;
        //console.log("GoTo", this.firstVersion, this.currentVersion);
      }
    },
    Reset() {
      this.AddEntry({description:"Reset"});
    },
    Trash() {
      this.entries = [];
    },
    GetEntries() {
      //console.log("GetEntries", this.firstVersion, this.currentVersion);
      return this.entries.slice(this.firstVersion, this.currentVersion).filter(e => e.description!=="Reset");
    }
  };
};

export default History;