import {BaseSet} from './base.js';

const TheExile = (props) => {
  
  return BaseSet({
    ...props,
    name: 'The Exile',
    two: {
      attributes: {
        energyRecharge: 0.20
      },
      PersistentResolve(result, context) {
        return result;
      }
    },
    four: {
      PersistentResolve(result, context) {
        //TODO: implement energy tracking?
        return result;
      }
    }
  });
};

export default TheExile;