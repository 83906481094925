import Scaling from '../../Scaling.js';
import BaseWeapon from '../base.js';

const AquilaFavonia = (props) => {
  
  return BaseWeapon({
    ...props,
    type: 'sword',
    name: 'Aquila Favonia',
    lvl1Atk: 48,
    lvl1Sub: 0.09,
    subType: 'damageBonus.physical',
    attributes: {
      atkPct: Scaling.ScaleWeaponPassive(0.20, props.refinement)
    },
    PersistentResolve(result, context) {
      /**
       * effect: 'ATK is increased by [20%]. Triggers on taking DMG: the soul 
       * of the Falcon of the West awakens, holding the banner of the 
       * resistance aloft, regenerating HP equal to [100+15%] of ATK and 
       * dealing [200+30%] of ATK as DMG to surrounding enemies. This effect 
       * can only occur once every 15s.'
       */
      if(this.IsSelf(result)) {
        let effectValues = {
          hpRegen: Scaling.ScaleWeaponPassive([1, 0.15], this.refinement),
          aoeDmg: Scaling.ScaleWeaponPassive([2, 0.3], this.refinement)
        };
      }
      return result;
    }
  });
};

export default AquilaFavonia;