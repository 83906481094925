import Tools from './Tools.js';

const TimeTrack = (props) => {
  return {
    inc:0,
    effects: [],
    statuses: [],
    talentEffects: [],
    //currentTime: 0,
    executeOrder: [],
    Reorder() {
      this.executeOrder.sort((a, b) => {
        let atime = a.obj.time, btime = b.obj.time;
        
        //consider any finalize as executing at the end of the event
        if(a.type==="Finalize") {
          atime += a.obj.duration;
        }
        if(b.type==="Finalize") {
          btime += b.obj.duration;
        }
        let diff = Math.round((atime-btime)*100)/100;
        //any finalize are always executed before anything that begins at the same time
        if(diff===0 && (a.type==="Finalize" || b.type==="Finalize")) {
          diff = Math.round((a.obj.time - b.obj.time)*100)/100;
        }
        //if there are still no differences (prepare, resolve and duration=0)
        if(diff===0) {
          if(a.type==="Prepare") {
            if(b.type==="Resolve") {
              diff=-1;
            } else if(b.type==="Finalize") {
              diff=-1;
            }
          }
          if(a.type==="Resolve") {
            if(b.type==="Prepare") {
              diff = 1;
            } else if(b.type==="Finalize") {
              diff = -1;
            }
          }
          if(a.type==="Finalize") {
            if(b.type==="Prepare") {
              diff = 1;
            } else if(b.type==="Resolve") {
              diff = 1;
            }
          }
        }
        //console.log(diff<0?"     A goes before B":diff>0?"     B goes before A":"     A ties with B");
        return diff;
      });
    },
    Queue(result) {
      this.executeOrder.push({
        type: 'Prepare',
        obj: result
      }, {
        type: 'Resolve',
        obj: result
      }, {
        type: 'Finalize',
        obj: result
      });
    },
    Next() {
      this.inc++;
      if(this.inc>1000) {
        console.log("processing over 1000 history action, problem ?");
        return undefined;
      }
      this.Reorder();
      /*console.log("executeOrder: ", this.executeOrder.map(e => {
        return {type:e.type, description:e.obj.description, time:e.obj.time, duration:e.obj.duration};
      }));*/
      let next = this.executeOrder.shift();
      return next;
    },
    AddEffect(result) {
      result.onTimeChange = (obj, b, a) => this.FixTimesOnChange(obj, b, a);
      result.onDurationChange = (obj, b, a) => this.FixTimesOnChange(obj, b, a);
      if(result.precedingResult) {
        result.precedingResult.followingResults.effects.push(result);
      }
      result.setTime(Math.round(((result.precedingResult ? result.precedingResult.time + result.precedingResult.duration : 0) + result.GetRelativeTime()) * 10000) / 10000);
      
      result = this.HandleStackable(result);
      
      //clear future entries from a same entity 
      //(ie, casting Nightrider followed by Midnight Phantasmagoria which places Oz's attack again)
      /*let removedEntries = this.effects.filter(s => (s.entity===result.entity && s.time>=result.time));
      if(removedEntries.length>0) {
        console.log("removing future effect entries from timetrack", removedEntries);
        this.effects = this.effects.filter(s => !(s.entity===result.entity && s.time>=result.time));
        this.executeOrder = this.executeOrder.filter(e => !removedEntries.find(r => r===e.obj));
      }*/
      
      this.effects.push(result);
      this.Queue(result);
      return result;
    },
    AddStatusEffect(result) {
      result.onTimeChange = (obj, b, a) => this.FixTimesOnChange(obj, b, a);
      result.onDurationChange = (obj, b, a) => this.FixTimesOnChange(obj, b, a);
      if(result.precedingResult) {
        result.precedingResult.followingResults.statuses.push(result);
      }
      result.setTime(Math.round(((result.precedingResult ? result.precedingResult.time + result.precedingResult.duration : 0) + result.GetRelativeTime()) * 10000) / 10000);
      result = this.HandleStackable(result);
      
      //clear future entries from a same entity 
      // (ie, casting Nightrider followed by Midnight Phantasmagoria which places Oz's attack again)
      /*let removedEntries = this.statuses.filter(s => (s.entity===result.entity && s.time>=result.time));
      if(removedEntries.length>0) {
        console.log("removing future status entries from timetrack", removedEntries);
        this.statuses = this.statuses.filter(s => !(s.entity===result.entity && s.time>=result.time));
        this.executeOrder = this.executeOrder.filter(e => !removedEntries.find(r => r===e.obj));
      }*/
      
      this.statuses.push(result);
      this.Queue(result);
      return result;
    },
    AddTalentEffect(result, time=this.currentTime) {
      result.onTimeChange = (obj, b, a) => this.FixTimesOnChange(obj, b, a);
      result.onDurationChange = (obj, b, a) => this.FixTimesOnChange(obj, b, a);
      if(result.precedingResult) {
        //if the preceding result already had a following talent result, 
        //insert this result in between
        if(result.precedingResult.followingResults.talents.length>0) {
          let t = result.precedingResult.followingResults.talents[0];
          result.followingResults.talents.push(t);
          t.precedingResult=result;
        }
        result.precedingResult.followingResults.talents.push(result);
      }
      result.setTime(Math.round(((result.precedingResult ? result.precedingResult.time + result.precedingResult.duration : 0) + result.GetRelativeTime()) * 10000) / 10000);
      //insert effect in chain
      let nextTalent = this.talentEffects.find(e => e.precedingResult===result.precedingResult && e!==result);
      if(nextTalent) {
        nextTalent.precedingResult = result;
      }
      result = this.HandleCombo(result);
      result = this.HandleStackable(result);
      
      this.talentEffects.push(result);
      this.Queue(result);
      if(nextTalent) {
        //this.CheckTime(nextTalent);
      }
      return result;
    },
    timeSpentFixTimesOnChange:0,
    FixTimesOnChange(obj, b, a) {
      let perf = !this.fixTimesOnChangeStart;
      if(perf) this.fixTimesOnChangeStart = window.performance.now();
      [
        ...obj.followingResults.effects,
        ...obj.followingResults.statuses,
        ...obj.followingResults.talents
      ].forEach((r) => {
        if(r.time !== Math.round(((r.precedingResult ? r.precedingResult.time + r.precedingResult.duration : 0) + r.GetRelativeTime()) * 10000) / 10000) {
          r.setTime(Math.round(((r.precedingResult ? r.precedingResult.time + r.precedingResult.duration : 0) + r.GetRelativeTime()) * 10000) / 10000);
        }
      });
      if(perf) {
        this.timeSpentFixTimesOnChange += window.performance.now() - this.fixTimesOnChangeStart;
        this.fixTimesOnChangeStart = undefined;
      }
    },
    HandleStackable(result) {
      if(result.stackable && !result.stacks) {
        result.stacks = 1;
      }
      let dups = [
        ...this.GetEffectsAt(result.time),
        ...this.GetStatusEffects(result.time)
      ].filter(e => e.description===result.description && e.entity===result.entity && e.type===result.type);
      if(dups.length>0) {
        //console.log('Found dups');
      }
      dups.forEach(d => {
        let newDuration = Math.round((result.time - d.time) * 10000) / 10000;
        if(d.duration !== newDuration) {
          d.setDuration(newDuration);
          //d.logs.push("Interupted by duplicate effect");
          if(result.stackable) {
            if(result.stacks+d.stacks <= result.maxStacks) {
              result.stacks += d.stacks;
            } else {
              result.stacks = result.maxStacks;
            }
            result.logs.push(result.description+" now has "+result.stacks+" stacks");
          }
        }
      });
      return result;
    },
    HandleCombo(result) {
      /*if(!result.comboStep) {
        let comboStep = Tools.GetComboStep(result);
        if(comboStep.next && result.talentProperties) {
          result.setDuration(result.talentProperties.comboTable[comboStep.next-1].anim);
          result.comboStep = comboStep.next;
          result.type = "NormalAtk-"+(comboStep.next);
        }
      }*/
      return result;
    },
    HandleAtkSpeed(result) {
      if(result.type.startsWith("NormalAtk")) {
        result.setDuration(Math.round((result.duration / (1 + result.attributes.atkSpd)) * 10000) / 10000);
      }
      return result;
    },
    GetEffects() {
      return [...this.effects, ...this.talentEffects].sort((a,b) => a.time-b.time);
    },
    GetEffectsAt(time) {
      let effects = this.effects.filter(element => time>=element.time && time<Math.round((element.time+element.duration) * 10000) / 10000);
      let talentEffects = this.talentEffects.filter(element => time>=element.time && time<Math.round((element.time+element.duration) * 10000) / 10000);
      let allEffects = [
        ...(effects || []),
        ...(talentEffects || [])
      ];
      return allEffects;
    },
    GetStatusEffects(time) {
      let statuses = this.statuses.filter(element => (time>=element.time && time<Math.round((element.time+element.duration) * 10000) / 10000));
      return [
        ...(statuses || [])
      ];
    },
    /*GetCurrentEffects() {
      return this.GetEffectsAt(this.currentTime);
    },*/
    ClearTimeTrack() {
      //console.log('ClearTimeTrack', this);
      this.inc=0;
      this.effects = [];
      this.statuses = [];
      this.talentEffects = [];
      //this.currentTime = 0;
    },
    GetStats() {
      let totalDamage=0;
      let lastTalentTime = this.talentEffects.reduce(
        (max, value) => (
          (Math.round((value.time+value.duration) * 10000) / 10000)>max
          ? Math.round((value.time+value.duration) * 10000) / 10000
          : max
        ), 0
      );
      [
        ...this.effects
        , ...this.talentEffects
      ].forEach(e => {if(e.time<=lastTalentTime) totalDamage += e.values.averageDamage});
      return {
        total: totalDamage,
        time: lastTalentTime,
        dps: Math.round(totalDamage / lastTalentTime * 100) / 100
      }
    }
  };
};

export default TimeTrack;