import React, {Fragment} from 'react';
import { Grid, Popup, Header, Advertisement, Item } from 'semantic-ui-react';

const GuidesPage = () => {
    return <Fragment>
    <Advertisement unit="top banner" test="Ad Unit" style={{margin:"0 auto 2em auto"}}/>
    <Header as="h1">Guides</Header>
    
    <Item.Group divided style={{textAlign:"left"}}>
      <Item href="#">
        <Item.Content>
          <Item.Header>Crystal Farming Route</Item.Header>
          <Item.Description>
            Effectively farm crystals every 3 days
          </Item.Description>
        </Item.Content>
      </Item>
  
      <Item href="#">
        <Item.Content>
          <Item.Header>Farm artifact fodder for free every day</Item.Header>
          <Item.Description>
            Learn this artifact fodder farming route and level your artifacts 
            without resin
          </Item.Description>
        </Item.Content>
      </Item>
  
      <Item href="#">
        <Item.Content>
          <Item.Header>Abyss Guide</Item.Header>
          <Item.Description>
            Learn the tricks to each encounters in the Abyss
          </Item.Description>
        </Item.Content>
      </Item>
    </Item.Group>
  </Fragment>;
}

export default GuidesPage;