import Tools from '../../Tools.js';
import Scaling from '../../Scaling.js';
import BaseCharacter from '../base.js';

//TODO: implement character
var Xiangling = (props) => {
  
  let scaleTable = {
    ascension0: {
      min: {level:1, maxHP:912, baseAtk:19, defense:56, attr:0},
      max: {level:20, maxHP:2342, baseAtk:49, defense:144, attr:0}
    },
    ascension1: {
      min: {level:20, maxHP:3024, baseAtk:63, defense:186, attr:0},
      max: {level:40, maxHP:4529, baseAtk:94, defense:279, attr:0}
    },
    ascension2: {
      min: {level:40, maxHP:5013, baseAtk:104, defense:308, attr:24},
      max: {level:50, maxHP:5766, baseAtk:119, defense:355, attr:24}
    },
    ascension3: {
      min: {level:50, maxHP:6411, baseAtk:133, defense:394, attr:48},
      max: {level:60, maxHP:7164, baseAtk:149, defense:441, attr:48}
    },
    ascension4: {
      min: {level:60, maxHP:7648, baseAtk:159, defense:470, attr:48},
      max: {level:70, maxHP:8401, baseAtk:174, defense:517, attr:48}
    },
    ascension5: {
      min: {level:70, maxHP:8885, baseAtk:184, defense:546, attr:72},
      max: {level:80, maxHP:9638, baseAtk:200, defense:593, attr:72}
    },
    ascension6: {
      min: {level:80, maxHP:10122, baseAtk:210, defense:623, attr:96},
      max: {level:90, maxHP:10886, baseAtk:226, defense:670, attr:96}
    }
  };
  return BaseCharacter({
    ...props,
    name: 'Xiangling',
    element: 'pyro',
    equip: 'polearm',
    scaleTable: scaleTable,
    scaleAttr: 'elemMastery',
    attackTalent: {
      description: 'Dough-Fu',
      level: props.attack,
      type: "NormalAtk",
      defaultImpl: {
        talentProperties: {
          comboTable: [
            {dmg:0.421, anim:0.5}, 
            {dmg:0.421, anim:0.6}, 
            {dmg:0.261, anim:1, extra: [
              {dmg:0.261, anim:1}
            ]}, 
            {dmg:0.141, anim:0.75, extra: [
              {dmg:0.141, anim:1},
              {dmg:0.141, anim:1},
              {dmg:0.141, anim:1}
            ]}, 
            {dmg:0.71, anim:1}
          ]
        },
        duration: 1,
        Resolve(result) {
          result = Scaling.ScaleAttackCombo(result);
          return result;
        }
      },
      chargedImpl: {
        type: "ChargeAtk",
        duration: 1,
        Resolve(result) {
          result = Scaling.ScaleTalent(1.22, result);
          return result;
        }
      }
    },
    skillTalent: {
      description:'Guoba Attack',
      level: props.skill,
      type: 'Skill',
      cooldown: 12,
      defaultImpl: {
        duration: 1,
        Prepare(result) {
          return result;
        },
        Resolve(result) {
          result.effects.push(Tools.CreateTalentSideEffect(result, 'guoba'));
          return result;
        }
      },
      guobaEffect: {
        description: 'Guoba Attack - Guoba the Panda',
        entity: 'Xiangling\'s Guoba Attack',
        duration: 2,
        Prepare(result) {
          result.attributes.element = 'pyro';
          return result;
        },
        Finalize(result) {
          result.effects.push(Tools.CreateTalentSideEffect(result, 'breath'));
          return result;
        }
      },
      breathEffect: {
        description: 'Guoba Attack - Guoba Fire Breath',
        entity: 'Xiangling\'s Guoba Attack',
        duration: 1.5,
        talentProperties: {
          number: 1
        },
        Prepare(result) {
          result.attributes.element = 'pyro';
          return result;
        },
        Resolve(result) {
          result = Scaling.ScaleTalent(1.11, result);
          return result;
        },
        Finalize(result) {
          if(result.talentProperties.number<4) {
            result.effects.push(Tools.CreateTalentSideEffect(result, 'breath', {
              talentProperties: {
                number: result.talentProperties.number + 1
              }
            }));
          }
          return result;
        }
      }
    },
    burstTalent: {
      description: 'Pyronado',
      level: props.burst,
      type: 'Burst',
      duration: 2,
      defaultImpl: {
        Prepare(result) {
          result.attributes.element = 'pyro';
          return result;
        },
        Resolve(result) {
          return result;
        },
        Finalize(result) {
          result.effects.push(Tools.CreateTalentSideEffect(result, 'swing', {
            relativeTime: -0.3
          }));
          return result;
        }
      },
      swingEffect: {
        description: 'Pyronado - Swing',
        entity: 'Xiangling\'s Pyronado',
        duration: 0.1,
        talentProperties: {
          number: 1,
          dmg: [.72, .88, 1.1]
        },
        Prepare(result) {
          result.attributes.element = 'pyro';
          return result;
        },
        Resolve(result) {
          result = Scaling.ScaleTalent(result.talentProperties.dmg[result.talentProperties.number-1], result);
          return result;
        },
        Finalize(result) {
          if(result.talentProperties.number<3) {
            result.effects.push(Tools.CreateTalentSideEffect(result, 'swing', {
              talentProperties: {
                number: result.talentProperties.number + 1,
                dmg: result.talentProperties.dmg
              }
            }));
          } else {
            result.effects.push(Tools.CreateTalentSideEffect(result, 'rotation'));
          }
          return result;
        }
      },
      rotationEffect: {
        description: 'Pyrodano - Rotation',
        entity: 'Xiangling\'s Pyronado',
        duration: 1.5,
        talentProperties: {
          number: 1,
          maxNumber: 7
        },
        Prepare(result) {
          result.attributes.element = 'pyro';
          return result;
        },
        Resolve(result) {
          result = Scaling.ScaleTalent(1.12, result);
          return result;
        },
        Finalize(result) {
          if(result.talentProperties.number<result.talentProperties.maxNumber) {
            result.effects.push(Tools.CreateTalentSideEffect(result, 'rotation', {
              talentProperties: {
                number: result.talentProperties.number + 1,
                maxNumber: result.talentProperties.maxNumber
              }
            }));
          }
          return result;
        }
      }
    },
    passiveAsc1: {
      description: 'Crossfire',
      PersistentResolve(result, context) {
        return result;
      }
    },
    passiveAsc4: {
      description: 'Beware, It\'s Super Hot!',
      PersistentResolve(result, context) {
        return result;
      }
    },
    passiveOther: [{
      description: 'Chef de Cuisine',
      PersistentResolve(result, context) {
        return result;
      }
    }],
    constellations: [
      ...BaseCharacter.Constellation53,
    ]
  });
};

export default Xiangling;