import Scaling from '../../Scaling.js';
import BaseWeapon from '../base.js';

const FavoniusGreatsword = (props) => {
  
  return BaseWeapon({
    ...props,
    type: 'claymore',
    name: 'Favonius Greatsword',
    lvl1Atk: 41,
    lvl1Sub: 0.133,
    subType: 'energyRecharge',
    PersistentResolve(result, context) {
      /**
       * effect: 'CRIT Hits have a [60+10%] chance to generate a small amount 
       * of Elemental Particles, which will regenerate 6 Energy for the 
       * character. Can only occur once every [12-1.5s].'
       */
      if(this.IsSelf(result)) {
      }
      return result;
    }
  });
};

export default FavoniusGreatsword;