import Tools from '../../Tools.js';
import Scaling from '../../Scaling.js';
import BaseCharacter from '../base.js';

var Amber = (props) => {
  
  let scaleTable = {
    ascension0:{
      min: {level:1,  maxHP:793,  baseAtk:19, defense:50, attr:0},
      max: {level:20, maxHP:2038, baseAtk:48, defense:129,attr:0}
    },
    ascension1:{
      min: {level:20, maxHP:2630, baseAtk:62, defense:167,attr:0},
      max: {level:40, maxHP:3940, baseAtk:93, defense:250,attr:0}
    },
    ascension2:{
      min: {level:40, maxHP:4361, baseAtk:103,defense:277,attr:0.06},
      max: {level:50, maxHP:5016, baseAtk:118,defense:318,attr:0.06}
    },
    ascension3:{
      min: {level:50, maxHP:5578, baseAtk:132,defense:354,attr:0.12},
      max: {level:60, maxHP:6233, baseAtk:147,defense:396,attr:0.12}
    },
    ascension4:{
      min: {level:60, maxHP:6654, baseAtk:157,defense:422,attr:0.12},
      max: {level:70, maxHP:7309, baseAtk:172,defense:464,attr:0.12}
    },
    ascension5:{
      min: {level:70, maxHP:7730, baseAtk:182,defense:491,attr:0.18},
      max: {level:80, maxHP:8385, baseAtk:198,defense:532,attr:0.18}
    },
    ascension6:{
      min: {level:80, maxHP:8806, baseAtk:208,defense:559,attr:0.24},
      max: {level:90, maxHP:9461, baseAtk:223, defense:601,attr:0.24}
    }
  };
  //TODO: check anim speed
  return BaseCharacter({
    ...props,
    name: 'Amber',
    element: 'pyro',
    equip: 'bow',
    scaleTable: scaleTable,
    scaleAttr: 'atkPct',
    attackTalent: {
      description: 'Sharpshooter',
      level: props.attack,
      type: 'NormalAtk',
      defaultImpl: {
        talentProperties: {
          comboTable: [
            {dmg:0.361, anim:0.5}, 
            {dmg:0.361, anim:0.6}, 
            {dmg:0.464, anim:1}, 
            {dmg:0.473, anim:0.75}, 
            {dmg:0.593, anim:1}
          ]
        },
        duration: 0.5,
        Resolve(result) {
          result = Scaling.ScaleAttackCombo(result);
          result.attributes.element='physical';
          return result;
        }
      },
      aimedImpl: {
        description: 'Sharpshooter - Aimed',
        type: 'ChargeAtk',
        duration: 0.625,
        Resolve(result) {
          result = Scaling.ScaleAttackTalent(0.439, result);
          result.attributes.element='physical';
          return result;
        }
      },
      fullyAimedImpl: {
        description: 'Sharpshooter - Fully-Aimed',
        type: 'ChargeAtkHold',
        duration: 1.5,
        Prepare(result) {
          result.attributes.element = 'pyro';
          return result;
        },
        Resolve(result) {
          result = Scaling.ScaleTalent(1.24, result);
          return result;
        }
      }
    },
    skillTalent: {
      description: 'Explosive Puppet',
      level: props.skill,
      type: 'Skill',
      defaultImpl: {
        duration: 0.2,
        cooldown: 15,
        Prepare(result) {
          result.attributes.element = 'physical';
          result.effects.push(Tools.CreateTalentSideEffect(result, "baronBunny"));
          return result;
        },
        Resolve(result) {
          result.logs.push("Spawning Baron Bunny on the field");
          return result;
        }
      },
      baronBunnyEffect: {
        description: 'Baron Bunny',
        entity: 'Amber\'s Baron Bunny',
        duration: 8,
        Finalize(result) {
          result.effects.push(Tools.CreateTalentSideEffect(result, "baronBunnyExplosion"));
          return result;
        }
      },
      baronBunnyExplosionEffect: {
        description: 'Baron Bunny - Explosion',
        entity: 'Amber\'s Baron Bunny',
        duration: 0.2,
        Prepare(result) {
          result.attributes.element = 'pyro';
          return result;
        },
        Resolve(result) {
          result = Scaling.ScaleTalent(1.23, result);
          return result;
        }
      }
    },
    burstTalent: {
      description: 'Fiery Rain',
      level: props.burst,
      type: 'Burst',
      defaultImpl: {
        duration: 0.2,
        cooldown: 12,
        Prepare(result) {
          result.attributes.element = 'physical';
          [...Array(18)].forEach((e,i) => {
            result.effects.push(Tools.CreateTalentSideEffect(result, 'fieryRainWave', {
              description: 'Fiery Rain Wave ('+(i+1)+' of 18)',
              relativeTime: (i*0.1)
            }));
          });
          return result;
        },
        Resolve(result) {
          return result;
        }
      },
      fieryRainWaveEffect: {
        description: 'Fiery Rain Wave',
        entity: "Amber's Fiery Rain",
        duration: 0.1,
        Prepare(result) {
          result.attributes.element = 'pyro';
          return result;
        },
        Resolve(result) {
          result = Scaling.ScaleTalent(0.281, result);
          return result;
        }
      }
    },
    passiveAsc1: {
      description: 'EveryArrowFindsItsTarget',
      PersistentResolve(result, context) {
        if(result.type==="Burst" && context.IsSelf(result)) {
          result.logs.push('Amber\'s Every Arrow Finds Its Target increases Fiery Rain\'s critical rate by 10%');
          result.attributes.critRate += 0.1;
        }
        return result;
      }
    },
    passiveAsc4: {
      description: 'PreciseShot',
      PersistentResolve(result, context) {
        if((result.type==="ChargeAtk" || result.type==="ChargeAtkHold") && context.IsSelf(result)) {
          //TODO:add a friendly effect for 10s which increase Atk by 15%
        }
        return result;
      }
    },
    passiveOther: [{
      description: 'GlidingChampion',
      PersistentResolve(result, context) {
        //this talent has no effect in combat
        return result;
      }
    }],
    constellations: [
      ...BaseCharacter.Constellation53,
      {
        description: 'One Arrow To Rule Them All',
        level: 1,
        PersistentResolve(result, context) {
          if((result.type==="ChargeAtk" || result.type==="ChargeAtkHold") && context.IsSelf(result)) {
            result.attributes.damageBonus.all += 0.2;
            result.logs.push("Amber's Constellation 1 increases weak spot hit by 20%");
          }
          return result;
        }
      }
    ]
  });
};

export default Amber;