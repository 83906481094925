import {BaseSet} from './base.js';
import Tools from '../Tools.js';

const NoblesseOblige = (props) => {
  
  return BaseSet({
    ...props,
    name: 'Noblesse Oblige',
    two: {
      PersistentResolve(result, context) {
        if(!this.IsSelf(result)) return result;
        if(result.type==="Burst") {
          result.attributes.damageBonus.all += 0.20;
          result.logs.push("Noblesse Oblige increases Burst DMG by 20%")
        }
        return result;
      }
    },
    four: {
      PersistentFinalize(result){
        if(!this.IsSelf(result)) return result;
        if(result.type==="Burst") {
          result.effects.push(Tools.CreateSideEffect(result, {
            description: this.name+' 4pc',
            entity: 'Buffs',
            duration: 12,
            relativeTime: 0,
            type: 'Buff',
            PersistentResolve(result) {
              result.attributes.atkPct += 0.20;
              result.logs.push("Noblesse Oblige 4pc increases all party members' ATK by 20%")
              return result;
            }
          }));
        }
        return result;
      }
    }
  });
};

export default NoblesseOblige;