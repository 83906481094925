import Scaling from '../../Scaling.js';
import BaseWeapon from '../base.js';

const TravelersHandySword = (props) => {
  
  return BaseWeapon({
    ...props,
    type: 'sword',
    name: 'Traveler\'s Handy Sword',
    lvl1Atk: 40,
    lvl1Sub: 0.064,
    subType: 'defensePct',
    PersistentResolve(result, context) {
      /**
       * effect: 'Each Elemental Orb or Particle collected restores [1%] HP.'
       */
      if(this.IsSelf(result)) {
      }
      return result;
    }
  });
};

export default TravelersHandySword;