import Scaling from '../../Scaling.js';
import BaseWeapon from '../base.js';

const Slingshot = (props) => {
  
  return BaseWeapon({
    ...props,
    type: 'bow',
    name: 'Slingshot',
    lvl1Atk: 38,
    lvl1Sub: 0.068,
    subType: 'critRate',
    PersistentResolve(result, context) {
      /**
       * effect: 'If a Normal Attack or Aimed Shot hits a target within 0.3s of 
       * being fired, increases DMG by [36+6%]. Otherwise, it decreases DMG by 
       * 10%.'
       */
      if(this.IsSelf(result)) {
      }
      return result;
    }
  });
};

export default Slingshot;