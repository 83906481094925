import Scaling from '../../Scaling.js';
import BaseWeapon from '../base.js';

const SkywardPride = (props) => {
  
  return BaseWeapon({
    ...props,
    type: 'claymore',
    name: 'SkywardPride',
    lvl1Atk: 48,
    lvl1Sub: 0.08,
    subType: 'energyRecharge',
    PersistentResolve(result, context) {
      /**
       * effect: 'Increases all DMG by [8%]. After using an Elemental Burst, 
       * Normal or Charged Attack, on hit, creates a vacuum blade that does 
       * [80%] of ATK as DMG to enemies along its path. Lasts for 20s or 8 
       * vacuum blades.'
       */
      if(this.IsSelf(result)) {
        let effectValues = {
          dmgIncrease: Scaling.ScaleWeaponPassive(0.08, this.refinement),
          vacuumBladeDmg: Scaling.ScaleWeaponPassive(0.80, this.refinement)
        }
      }
      return result;
    }
  });
};

export default SkywardPride;