import Tools from '../../Tools.js';
import Scaling from '../../Scaling.js';
import BaseCharacter from '../base.js';

var Ganyu = (props) => {
  
  let scaleTable = {
    ascension0: {
      min: {level:1, maxHP:763, baseAtk:26, defense:49, attr:0},
      max: {level:20, maxHP:1978, baseAtk:68, defense:127, attr:0}
    },
    ascension1: {
      min: {level:20, maxHP:2632, baseAtk:90, defense:169, attr:0},
      max: {level:40, maxHP:3939, baseAtk:135, defense:253, attr:0}
    },
    ascension2: {
      min: {level:40, maxHP:4403, baseAtk:151, defense:283, attr:0.096},
      max: {level:50, maxHP:5066, baseAtk:173, defense:326, attr:0.096}
    },
    ascension3: {
      min: {level:50, maxHP:5686, baseAtk:194, defense:366, attr:0.192},
      max: {level:60, maxHP:6355, baseAtk:217, defense:409, attr:0.192}
    },
    ascension4: {
      min: {level:60, maxHP:6820, baseAtk:233, defense:439, attr:0.192},
      max: {level:70, maxHP:7495, baseAtk:256, defense:482, attr:0.192}
    },
    ascension5: {
      min: {level:70, maxHP:7960, baseAtk:272, defense:512, attr:0.288},
      max: {level:80, maxHP:8643, baseAtk:295, defense:556, attr:0.288}
    },
    ascension6: {
      min: {level:80, maxHP:9108, baseAtk:311, defense:586, attr:0.384},
      max: {level:90, maxHP:9797, baseAtk:335, defense:630, attr:0.384}
    }
  };
  return BaseCharacter({
    ...props,
    name: 'Ganyu',
    element: 'cryo',
    equip: 'bow',
    scaleTable: scaleTable,
    scaleAttr: 'critDmg',
    attackTalent: {
      description: 'Liutian Archery',
      level: props.attack,
      type: "NormalAtk",
      defaultImpl: {
        talentProperties: {
          comboTable: [
            {dmg:0.317, anim:0.4}, 
            {dmg:0.356, anim:0.4}, 
            {dmg:0.455, anim:0.6}, 
            {dmg:0.455, anim:0.6}, 
            {dmg:0.482, anim:0.45}, 
            {dmg:0.576, anim:1}
          ]
        },
        duration: 1,
        Prepare(result) {
          result.attributes.element = 'physical';
          return result;
        },
        Resolve(result) {
          result = Scaling.ScaleAttackCombo(result);
          return result;
        }
      },
      aimedImpl: {
        description: 'Liutian Archery - Aimed',
        level: props.attack,
        type: "ChargeAtk",
        duration: 0.5,
        Prepare(result) {
          result.attributes.element = 'physical';
          return result;
        },
        Resolve(result) {
          result = Scaling.ScaleTalent(0.439, result);
          return result;
        }
      },
      fullyAimedImpl: {
        description: 'Liutian Archery - Charge Level 1',
        level: props.attack,
        type: "ChargeAtkHold",
        duration: 1,
        Prepare(result) {
          result.attributes.element = 'cryo';
          return result;
        },
        Resolve(result) {
          result = Scaling.ScaleTalent(1.24, result);
          return result;
        }
      },
      fullyAimed2Impl: {
        description: 'Liutian Archery - Frostflake Arrow',
        level: props.attack,
        type: "ChargeAtkHold",
        duration: 1.4,
        Prepare(result) {
          result.attributes.element = 'cryo';
          return result;
        },
        Resolve(result) {
          result = Scaling.ScaleTalent(1.28, result);
          return result;
        },
        Finalize(result) {
          result.effects.push(Tools.CreateTalentSideEffect(result, 'frostflake'));
          return result;
        }
      },
      frostflakeEffect: {
        description: 'Liutian Archery - Frostflake Bloom',
        entity: 'Ganyu\'s Liutian Archery',
        type: "ChargeAtkHold",
        duration: 0.15,
        relativeTime: 0.3,
        Prepare(result) {
          result.attributes.element = 'cryo';
          return result;
        },
        Resolve(result) {
          result = Scaling.ScaleTalent(2.18, result);
          return result;
        }
      }
    },
    skillTalent: {
      description:'Trail of the Qilin',
      level: props.skill,
      type: 'Skill',
      duration: 1,
      cooldown: 10,
      defaultImpl: {
        Prepare(result) {
          result.attributes.element = 'cryo';
          return result;
        },
        Resolve(result) {
          return result;
        },
        Finalize(result) {
          result.effects.push(Tools.CreateTalentSideEffect(result, 'icelotus'));
          return result;
        }
      },
      icelotusEffect: {
        description: 'Trail of the Qilin - Ice Lotus',
        entity: 'Ganyu\'s Trail of the Qilin',
        duration: 6,
        relativeTime: r => -r.precedingResult.duration,
        Prepare(result) {
          result.attributes.element = 'cryo';
          return result;
        },
        Resolve(result) {
          result = Scaling.ScaleTalent(1.32, result);
          return result;
        },
        Finalize(result) {
          result.effects.push(Tools.CreateTalentSideEffect(result, 'bloom'));
          return result;
        }
      },
      bloomEffect: {
        description: 'Trail of the Qilin - Ice Lotus Bloom',
        entity: 'Ganyu\'s Trail of the Qilin',
        duration: 0.1,
        Prepare(result) {
          result.attributes.element = 'cryo';
          return result;
        },
        Resolve(result) {
          result = Scaling.ScaleTalent(1.32, result);
          return result;
        }
      }
    },
    burstTalent: {
      description: 'Celestial Shower',
      level: props.burst,
      type: 'Burst',
      duration: 2.5,
      cooldown: 15,
      defaultImpl: {
        Prepare(result) {
          result.attributes.element = 'cryo';
          return result;
        },
        Resolve(result) {
          return result;
        },
        Finalize(result) {
          result.effects.push(Tools.CreateTalentSideEffect(result, 'iceshard', {
            relativeTime: 0.6
          }));
          return result;
        }
      },
      iceshardEffect: {
        description: 'Celestial Shower - Ice Shard',
        entity: 'Ganyu\'s Celestial Shower',
        duration: 1.5,
        talentProperties: {
          shardNumber: 1
        },
        Prepare(result) {
          result.attributes.element = 'cryo';
          return result;
        },
        Resolve(result) {
          result = Scaling.ScaleTalent(0.7, result);
          return result;
        },
        Finalize(result) {
          if(result.talentProperties.shardNumber<9) {
            result.effects.push(Tools.CreateTalentSideEffect(result, 'iceshard', {
              talentProperties: {
                shardNumber: result.talentProperties.shardNumber+1
              }
            }));
          }
          return result;
        }
      }
    },
    passiveAsc1: {
      description: 'Undivided Heart',
      PersistentFinalize(result, context) {
        if(result.description==='Liutian Archery - Frostflake Arrow') {
          result.effects.push(Tools.CreateSideEffect(result, {
            description: 'Undivided Heart',
            type: 'Passive',
            entity: 'Ganyu\'s Passives',
            duration: 5,
            PersistentResolve(result, context) {
              if(result.initiator===context.initiator 
                && result.description.startsWith('Liutian Archery - Frostflake')) {
                result.attributes.critRate += 0.2;
                result.logs.push('Undivided Heart increases Frostflake Arrows and Bloom effect\'s Critical Rate by 20%');
              }
              return result;
            }
          }));
        }
        return result;
      }
    },
    passiveAsc4: {
      description: 'Harmony between Heaven and Earth',
      PersistentResolve(result, context) {
        if(result.ongoing.effects.find(e => e.description.startsWith('Celestial Shower'))) {
          result.attributes.damageBonus.cryo += 0.2;
          result.logs.push('Ganyu\'s Harmony between Heaven and Earth grants a 20% Cryo DMG Bonus to active members in the AoE');
        }
        return result;
      }
    },
    passiveOther: [{
      description: 'Preserved for the Hunt',
      PersistentResolve(result, context) {
        return result;
      }
    }],
    constellations: [
      ...BaseCharacter.Constellation53,
    ]
  });
};

export default Ganyu;