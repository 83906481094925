import React, {useState, useEffect, Fragment} from 'react';
import { Segment, Popup, Header, Grid, Icon, Label, Modal, Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import Timeline, {TimelineHeaders, SidebarHeader, DateHeader} from 'react-calendar-timeline';
import moment from 'moment';
import Explainer from './explainer.js';
import Persistence from './persistence.js';

const TimeGrid = (props) => {
  //const [key, setKey] = useState(props.key);
  const [items, setItems] = useState(props.items);
  const [groups, setGroups] = useState(props.groups);
  const [explainTree, setExplainTree] = useState();
  const [explainModalOpen, setExplainModalOpen] = useState(false);
  
  const itemStyle = {
    Status: {
      selected: {
        Shock: {backgroundColor:"RGBA(231, 133, 239, 0.8)", borderColor:"RGBA(231, 133, 239, 1)"},
        Fire: {backgroundColor:"RGBA(220, 110, 37, 0.8)", borderColor:"RGBA(220, 110, 37, 1)"},
        Wet: {backgroundColor:"RGBA(36, 188, 237, 0.8)", borderColor:"RGBA(36, 188, 237, 1)"},
        Cold: {backgroundColor:"RGBA(158, 227, 231, 0.8)", borderColor:"RGBA(158, 227, 231, 1)"},
        Dendro: {backgroundColor:"RGBA(149, 197, 35, 0.8)", borderColor:"RGBA(149, 197, 35, 1)"},
        Swirl: {backgroundColor:"RGBA(94, 218, 176, 0.8)", borderColor:"RGBA(94, 218, 176, 1)"}
      },
      unselected: {
        Shock: {backgroundColor:"RGBA(231, 133, 239, 0.8)", borderColor:"RGBA(231, 133, 239, 1)"},
        Fire: {backgroundColor:"RGBA(220, 110, 37, 0.8)", borderColor:"RGBA(220, 110, 37, 1)"},
        Wet: {backgroundColor:"RGBA(36, 188, 237, 0.8)", borderColor:"RGBA(36, 188, 237, 1)"},
        Cold: {backgroundColor:"RGBA(158, 227, 231, 0.8)", borderColor:"RGBA(158, 227, 231, 1)"},
        Dendro: {backgroundColor:"RGBA(149, 197, 35, 0.8)", borderColor:"RGBA(149, 197, 35, 1)"},
        Swirl: {backgroundColor:"RGBA(94, 218, 176, 0.8)", borderColor:"RGBA(94, 218, 176, 1)"}
      }
    },
    Reaction: {
      selected: {
        'Electro-Charged': {backgroundColor:"RGBA(224, 142, 245, 0.8)", borderColor:"RGBA(224, 142, 245, 1)"},
        Overloaded: {backgroundColor:"RGBA(230, 111, 139, 0.8)", borderColor:"RGBA(230, 111, 139, 1)"},
        Superconduct: {backgroundColor:"RGBA(167, 159, 246, 0.8)", borderColor:"RGBA(167, 159, 246, 1)"},
        Frozen: {backgroundColor:"RGBA(154, 255, 255, 0.8)", borderColor:"RGBA(154, 255, 255, 1)"},
        Burning: {backgroundColor:"RGBA(240, 150, 14, 0.8)", borderColor:"RGBA(240, 150, 14, 1)"},
        Swirl: {backgroundColor:"RGBA(96, 246, 194, 0.8)", borderColor:"RGBA(96, 246, 194, 1)"},
        Shattered: {backgroundColor:"RGBA(253, 255, 253, 0.8)", borderColor:"RGBA(253, 255, 253, 1)"}
      },
      unselected: {
        'Electro-Charged': {backgroundColor:"RGBA(224, 142, 245, 0.8)", borderColor:"RGBA(224, 142, 245, 1)"},
        Overloaded: {backgroundColor:"RGBA(230, 111, 139, 0.8)", borderColor:"RGBA(230, 111, 139, 1)"},
        Superconduct: {backgroundColor:"RGBA(167, 159, 246, 0.8)", borderColor:"RGBA(167, 159, 246, 1)"},
        Frozen: {backgroundColor:"RGBA(154, 255, 255, 0.8)", borderColor:"RGBA(154, 255, 255, 1)"},
        Burning: {backgroundColor:"RGBA(240, 150, 14, 0.8)", borderColor:"RGBA(240, 150, 14, 1)"},
        Swirl: {backgroundColor:"RGBA(96, 246, 194, 0.8)", borderColor:"RGBA(96, 246, 194, 1)"},
        Shattered: {backgroundColor:"RGBA(253, 255, 253, 0.8)", borderColor:"RGBA(253, 255, 253, 1)"}
      }
    },
    Idle: {
      selected: {backgroundColor: "RGBA(170, 170, 170, 0.8)", borderColor:"RGBA(170, 170, 170, 1)"},
      unselected: {backgroundColor: "RGBA(170, 170, 170, 0.8)", borderColor:"RGBA(170, 170, 170, 1)"}
    },
    Swap: {
      selected: {backgroundColor: "RGBA(170, 170, 170, 0.8)", borderColor:"RGBA(170, 170, 170, 1)"},
      unselected: {backgroundColor: "RGBA(170, 170, 170, 0.8)", borderColor:"RGBA(170, 170, 170, 1)"}
    },
    Cooldown: {
      selected: {backgroundColor: "RGBA(170, 170, 255, 0.8)", borderColor:"RGBA(170, 170, 255, 1)"},
      unselected: {backgroundColor: "RGBA(170, 170, 255, 0.8)", borderColor:"RGBA(170, 170, 255, 1)"}
    },
    Element: {
      pyro: {backgroundColor:"RGBA(191, 40, 24, 0.8)", borderColor:"RGBA(191, 40, 24, 1)"},
      electro: {backgroundColor:"RGBA(147, 54, 176, 0.8)", borderColor:"RGBA(147, 54, 176, 1)"},
      geo: {backgroundColor:"RGBA(182, 118, 7, 0.8)", borderColor:"RGBA(182, 118, 7, 1)"},
      hydro: {backgroundColor:"RGBA(11, 77, 218, 0.8)", borderColor:"RGBA(11, 77, 218, 1)"},
      anemo: {backgroundColor:"RGBA(38, 147, 124, 0.8)", borderColor:"RGBA(38, 147, 124, 1)"},
      cryo: {backgroundColor:"RGBA(115, 145, 162, 0.8)", borderColor:"RGBA(115, 145, 162, 1)"},
      dendro: {backgroundColor:"RGBA(116, 155, 61, 0.8)", borderColor:"RGBA(116, 155, 61, 1)"},
      physical: {background:"repeating-linear-gradient(-55deg, #666, #666 2px, #777 2px, #777 4px)"},
    },
    Damaging: {
      //borderColor: "RGB(255, 67, 67)",
      MozBoxShadow:    "inset 0 0 5px RGB(255, 67, 67)",
      WebkitBoxShadow:    "inset 0 0 5px RGB(255, 67, 67)",
      BoxShadow:    "inset 0 0 5px RGB(255, 67, 67)"
    },
    NonDamaging: {
      
    },
    default: {
      selected: {},
      unselected: {}
    }
  }
  
  const [timelineProps, setTimelineProps] = useState({
    timeSteps:{
      second: 5,
      minute: 1,
      hour: 1,
      day: 1,
      month: 1,
      year: 1
    },
    headerUnit:"second",
    headerFormat:"m:ss"
  });
  const [visibleTime, setVisibleTime] = useState(Persistence.getVisibleTime());
  
  useEffect(() => {
    //setKey(props.key);
    setItems(props.items);
    setGroups(props.groups);
  }, [props]);
  
  useEffect(() => {
    Persistence.saveVisibleTime(visibleTime);
  }, [visibleTime]);
  
  function shouldHaveDamage(item) {
    return typeof item.data.attributes.skillPct !== 'undefined';
  }
  
  return <Fragment><Timeline
      //key={key}
      groups={groups}
      items={items}
      defaultTimeStart={moment(visibleTime.start)}
      defaultTimeEnd={moment(visibleTime.end)}
      visibleTimeStart={visibleTime.start===0?1:visibleTime.start}
      visibleTimeEnd={visibleTime.end}
      timeSteps={timelineProps.timeSteps}
      minZoom={1*1000}
      maxZoom={30*1000}
      canMove={false}
      canChangeGroup={false}
      canResize={false}
      traditionalZoom={true}
      lineHeight={30}
      itemHeightRatio={0.85}
      onTimeChange={
        (visibleTimeStart, visibleTimeEnd, updateScrollCanvas) => {
          let minTime=0, maxTime=120*1000;
          if (visibleTimeStart < minTime && visibleTimeEnd > maxTime) {
            updateScrollCanvas(minTime, maxTime);
            //console.log("saving timeline start/end", {start:minTime, end:maxTime});
            setVisibleTime({start:minTime, end:maxTime});
          } else if (visibleTimeStart < minTime) {
            updateScrollCanvas(minTime, minTime + (visibleTimeEnd - visibleTimeStart));
            //console.log("saving timeline start/end", {start:minTime, end:minTime + (visibleTimeEnd - visibleTimeStart)});
            setVisibleTime({start:minTime, end:minTime + (visibleTimeEnd - visibleTimeStart)});
          } else if (visibleTimeEnd > maxTime) {
            updateScrollCanvas(maxTime - (visibleTimeEnd - visibleTimeStart), maxTime);
            //console.log("saving timeline start/end", {start:maxTime - (visibleTimeEnd - visibleTimeStart), end:maxTime});
            setVisibleTime({start:maxTime - (visibleTimeEnd - visibleTimeStart), end:maxTime});
          } else {
            updateScrollCanvas(visibleTimeStart, visibleTimeEnd);
            //console.log("saving timeline start/end", {start:visibleTimeStart, end:visibleTimeEnd});
            setVisibleTime({start:visibleTimeStart, end:visibleTimeEnd});
          }
        }
      }
      stackItems={true}
      onZoom={
        (timelineContext) => {
          let {visibleTimeStart, visibleTimeEnd} = timelineContext;
          let visibleTime = visibleTimeEnd-visibleTimeStart;
          //console.log('onZoom', timelineContext);
          if (visibleTime>10000) {
            //console.log('zoomed more than 10s wide');
            setTimelineProps({
              timeSteps:{
                second: 5,
                minute: 1,
                hour: 1,
                day: 1,
                month: 1,
                year: 1
              },
              headerUnit:"second",
              headerFormat:"m:ss"
            });
          } else if(visibleTime>2000) {
            //console.log('zoomed more than 2s wide');
            setTimelineProps({
              timeSteps:{
                second: 1,
                minute: 1,
                hour: 1,
                day: 1,
                month: 1,
                year: 1
              },
              headerUnit:"second",
              headerFormat:"m:ss"
            });
          } else {
            //console.log('zoomed less than 2s wide');
            setTimelineProps({
              timeSteps:{
                millisecond:200,
                second: 1,
                minute: 1,
                hour: 1,
                day: 1,
                month: 1,
                year: 1
              },
              headerUnit:"millisecond",
              headerFormat:"m:ss.SSS"
            });
          }
          //console.log("saving timeline start/end", {start:visibleTimeStart, end:visibleTimeEnd});
          setVisibleTime({start:visibleTimeStart, end:visibleTimeEnd});
        }
      }
      horizontalLineClassNamesForGroup={(group) => group.talent ? ["row-talent"] : ["row-effect"]}
      itemRenderer={
        ({
          item,
          itemContext,
          getItemProps,
          getResizeProps
        }) => {
          const { left: leftResizeProps, right: rightResizeProps } = getResizeProps()
          let style = {
            borderWidth: "1px",
            ...item.data.type==="Idle" || item.data.type==="Swap" || item.data.type==="Cooldown"
              ? {} 
              : itemStyle.Element[item.data.attributes.element],
            ...item.data.type==="Status" || item.data.type==="Reaction"
              ? itemStyle[item.data.type][itemContext.selected?"selected":"unselected"][item.data.description]
              : itemStyle[item.data.type] 
                ? itemStyle[item.data.type][itemContext.selected?"selected":"unselected"]
                : itemStyle.default[itemContext.selected?"selected":"unselected"],
            ...shouldHaveDamage(item)
              ? itemStyle.Damaging
              : itemStyle.NonDamaging
          };
            
          let itemProps = getItemProps({style:style});
          return (
            <Popup hoverable position='bottom center' trigger={
              <div {...itemProps}>
                {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : ''}
          
                <div
                  className="rct-item-content"
                  style={{ maxHeight: `${itemContext.dimensions.height}` }}
                >
                  {
                    shouldHaveDamage(item)
                    ? <span style={{
                        color: "#ff4343", 
                        backgroundColor: "#000",
                        marginRight:5, 
                        paddingLeft:2, 
                        paddingRight:2, 
                        textShadow: "1px 0 0 currentColor"
                      }}>
                        {item.data.values.averageDamage.toLocaleString('en-US')}
                      </span>
                    : ''
                  }
                  {item.data.stacks>1 ? <span style={{
                        color:"#FFF", 
                        backgroundColor:"#000",
                        marginRight:5, 
                        paddingLeft:2, 
                        paddingRight:2
                      }}>{item.data.stacks}x</span> : ""}
                  {itemContext.title}
                </div>
          
                {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : ''}
              </div>
            } >
              <Header>
                {itemContext.title}{item.data.comboStep?" - "+item.data.comboStep+"-Hit":""}
                {!['Swap', 'Cooldown', 'Idle'].find(s => item.data.type===s) ?
                  <Header sub>{item.data.attributes.element}{(() => {
                    let reaction = Object.keys(item.data.reactionMultiplier).reduce((a,c) => {
                      return item.data.reactionMultiplier[c]>0?c:a;
                    }, '');
                    if(reaction) {
                      return " - "+{melt:"Melt", vaporize:"Vaporize", shattered:"Shattered"}[reaction];
                    }
                  })()}</Header>
                : ""}
                <Label style={{width:"100%",margin:"0.5rem 0 0 0",textAlign:"center"}}>
                  {Math.round((item.data.time) * 10000) / 10000} 
                  <Icon name="step backward" style={{margin:"0 0.75em"}} /> 
                  {Math.round((item.data.duration) * 10000) / 10000} 
                  <Icon name="step forward" style={{margin:"0 0.75em"}} /> 
                  {Math.round((item.data.time+item.data.duration) * 10000) / 10000}
                </Label>
              </Header>
              {
                shouldHaveDamage(item)
                ? <Grid columns={2} className="dmgsummary">
                    <Grid.Column width={9}>Non-crit damage</Grid.Column><Grid.Column width={7}>{item.data.values.normalDamage.toLocaleString('en-US')}</Grid.Column>
                    <Grid.Column width={9}>Crit damage</Grid.Column><Grid.Column width={7}>{item.data.values.critDamage.toLocaleString('en-US')} @{Math.round(item.data.attributes.critRate*10000)/100}%</Grid.Column>
                    <Grid.Column width={9}><b>Average damage</b></Grid.Column><Grid.Column width={7}><b>{item.data.values.averageDamage.toLocaleString('en-US')}</b>
                      <Button circular size="mini" icon="help" onClick={() => {
                        setExplainTree(item.data.explainer);
                        setExplainModalOpen(true);
                      }} aria-label='Explain Formula' />
                    </Grid.Column>
                  </Grid>
                : ''
              }
              {
                item.data.logs.length>0
                ? <Fragment>
                    <Header className="logs">Logs</Header>
                    <Grid className="logs">
                      {item.data.logs.map((l,i) => 
                        <Grid.Row key={itemContext.title+"-"+item.data.time+"-logs"+i} className={i%2?"even":"odd"}><p style={{font:"courrier new"}}><Icon name="caret right" />{l}</p></Grid.Row>
                      )}
                    </Grid>
                  </Fragment>
                : ''
              }
            </Popup>
          )
        }
      }
    >
      <TimelineHeaders>
        <DateHeader 
          unit={timelineProps.headerUnit} 
          labelFormat={timelineProps.headerFormat}
        />
      </TimelineHeaders>
    </Timeline>
    { items.length===0
      ? <Segment style={{width:"50%", margin:"3em auto"}}>
          <Header size='small'>Your timeline is empty</Header>
          Add characters to your team and use their abilities.<br/>
          Their outcome, including all damage, side effects and triggers, will be laid out on a timeline here.
        </Segment>
      : ''
    }
    <Modal open={explainModalOpen} onClose={() => {setExplainModalOpen(false)}} onOpen={() => {setExplainModalOpen(true)}} size="small">
      <Modal.Header>Formula explained <Button as="a" circular rel="noopener noreferrer" target={"_blank"} href="https://genshin-impact.fandom.com/wiki/Damage" ><Icon name="external" /> Wiki</Button> </Modal.Header>
      <Modal.Content><Explainer explainTree={explainTree} /></Modal.Content>
    </Modal>
    </Fragment>;
}

export default TimeGrid;