import Scaling from '../../Scaling.js';
import BaseWeapon from '../base.js';
import Tools from '../../Tools.js';

const CompoundBow = (props) => {
  
  return BaseWeapon({
    ...props,
    type: 'bow',
    name: 'Compound Bow',
    lvl1Atk: 41,
    lvl1Sub: 0.15,
    subType: 'damageBonus.physical',
    track: {
      lastTrigger: 0
    },
    PersistentFinalize(result, context) {
      /**
       * effect: 'Normal Attack and Aimed Shot hits increase ATK by [4%] and 
       * Normal ATK SPD by [1.2%] for 6s. Max 4 stacks. Can only occur once 
       * every 0.3s.''
       */
      if(this.IsSelf(result)) {
        //console.log('Rust resolving...');
        if(this.track.lastTrigger===0 || result.time>=this.track.lastTrigger+0.3) {
          if(result.type.startsWith("NormalAtk") 
          || result.type==="ChargeAtk" 
          || result.type==="ChargeAtkHold") {
            result.effects.push(Tools.CreateSideEffect(result, {
              description: "Infusion Arrow",
              entity: result.initiator.name+"'s Compound Warbow",
              duration: 6,
              type: 'Buff',
              relativeTime: (r => -r.precedingResult.duration),
              stackable: true,
              maxStacks: 4,
              PersistentResolve(result, context) {
                if( result.attributes.skillPct>0 && result.initiator===context.initiator) {
                  let atkPct = Scaling.ScaleWeaponPassive(0.04, result.initiator.weapon.refinement) * context.stacks;
                  let atkSpd = Scaling.ScaleWeaponPassive(0.012, result.initiator.weapon.refinement) * context.stacks;
                  result.attributes.atkPct += atkPct;
                  result.attributes.atkSpd += atkSpd;
                  result.logs.push("Compound Bow, Infusion Arrow buff increases ATK by "+(Math.round(atkPct*10000)/100)+"% and Normal ATK SPD by "+(Math.round(atkSpd*10000)/100)+"%")
                }
                return result;
              }
            }));
            this.track.lastTrigger=result.time;
          }
        }
      }
      return result;
    }
  });
};

export default CompoundBow;