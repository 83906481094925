import Attributes from '../Attributes.js';
import Scaling from '../Scaling.js';

var BaseCharacter = (props) => {
  
  
  let attributes = Attributes({
    ...Scaling.ScaleCharacterStats(props.scaleTable, props.level, props.ascension, props.scaleAttr)
  })
  .Merge({
    ...props.attributes
  });
  if(props.ascension>=1 && props.passiveAsc1.attributes) {
    attributes.Merge(props.passiveAsc1.attributes);
  }
  if(props.ascension>=4 && props.passiveAsc4.attributes) {
    attributes.Merge(props.passiveAsc4.attributes);
  }
  props.passiveOther.forEach(p => attributes.Merge(p.attributes ? p.attributes : {}));
  
  props.artifacts.sets.forEach(a => {
    a.wielder = props.name;
  });
  
  props.attackTalent.track = {
    lastUsed: -999,
    lastStatusApplied: -999,
    ...props.attackTalent.track
  };
  props.skillTalent.track = {
    lastUsed: -999,
    lastStatusApplied: -999,
    ...props.skillTalent.track
  };
  props.burstTalent.track = {
    lastUsed: -999,
    lastStatusApplied: -999,
    ...props.burstTalent.track
  };
  
  return {
    name: props.name,
    element: props.element,
    equip: props.equip,
    
    level: props.level,
    ascension: props.ascension,
    constellation: props.constellation,
    constellations: props.constellations,
    attributes: attributes,
    
    artifacts: props.artifacts,
    weapon: props.weapon,
    
    attack: props.attackTalent,
    chargedAttack: props.chargedAttackTalent,
    chargedAttackHold: props.chargedAttackHoldTalent,
    skill: props.skillTalent,
    burst: props.burstTalent,
    passiveAsc1: props.passiveAsc1,
    passiveAsc4: props.passiveAsc4,
    passiveOther: props.passiveOther,
    
    track: {
      lastSwap: -999
    },
    
    UseAttack(impl="default") {
      //console.log("Using Attack", this);
      if(!this.attack[impl+"Impl"]) {
        impl="default";
      }
      let talentObj = {
        ...this.attack, 
        initiator: this, 
        talent: this.attack, 
        talentImpl:impl, 
        enforceCooldown: true, 
        ...this.attack[impl+"Impl"], 
        talentProperties: {
          level: this.attack.level,
          ...this.attack.talentProperties, 
          ...this.attack[impl+"Impl"].talentProperties
        }
      };
      return talentObj;
    },
    UseSkill(impl="default") {
      //console.log("Using Skill", this);
      if(!this.skill[impl+"Impl"]) {
        impl="default";
      }
      let talentObj = {
        ...this.skill, 
        initiator: this, 
        talent: this.skill, 
        talentImpl:impl, 
        enforceCooldown: true, 
        ...this.skill[impl+"Impl"], 
        talentProperties: {
          level: this.skill.level,
          ...this.skill.talentProperties, 
          ...this.skill[impl+"Impl"].talentProperties
        }
      };
      return talentObj;
    },
    UseBurst(impl="default") {
      //console.log("Using Burst", this);
      if(!this.burst[impl+"Impl"]) {
        impl="default";
      }
      let talentObj = {
        ...this.burst, 
        initiator: this, 
        talent: this.burst, 
        talentImpl:impl, 
        enforceCooldown: true, 
        ...this.burst[impl+"Impl"], 
        talentProperties: {
          level: this.burst.level,
          ...this.burst.talentProperties, 
          ...this.burst[impl+"Impl"].talentProperties
        }
      };
      return talentObj;
    },
    idle: {
      description: 'Idle',
      level: 0,
      type: 'Idle',
      duration: 0.1,
      Resolve(result) {
        return result;
      }
    },
    UseIdle(impl="default") {
      let duration = parseFloat(impl);
      if((typeof duration !== "number" && !isNaN(duration)) || duration<0.6) {
        duration = 0.6;
      }
      let talentObj = {
        ...this.idle,
        initiator: this,
        talent: this.idle,
        talentImpl: impl,
        duration: duration,
        talentProperties: {
          level: 0,
          ...this.idle.talentProperties
        }
      };
      return talentObj;
    },
    PersistentPrepare(result, context) {
      result = this.weapon.PersistentPrepare(result, context);
      this.artifacts.sets.forEach(e => {
        if(e.PersistentPrepare) {
          result = e.PersistentPrepare(result, context);
        }
      });
      
      if(this.ascension>=1 && this.passiveAsc1.PersistentPrepare) {
        result = this.passiveAsc1.PersistentPrepare(result, context);
      }
      
      if(this.ascension>=4 && this.passiveAsc4.PersistentPrepare) {
        result = this.passiveAsc4.PersistentPrepare(result, context);
      }
      
      this.constellations.forEach(c => {
        if(this.constellation>=c.level && c.PersistentPrepare) {
          result = c.PersistentPrepare(result, context);
        }
      });
      return result;
    },
    PersistentResolve(result, context) {
      result = this.weapon.PersistentResolve(result, context);
      this.artifacts.sets.forEach(e => {
        if(e.PersistentResolve) {
          result = e.PersistentResolve(result, context);
        }
      });
      
      if(this.ascension>=1 && this.passiveAsc1.PersistentResolve) {
        result = this.passiveAsc1.PersistentResolve(result, context);
      }
      
      if(this.ascension>=4 && this.passiveAsc4.PersistentResolve) {
        result = this.passiveAsc4.PersistentResolve(result, context);
      }
      
      this.constellations.forEach(c => {
        if(this.constellation>=c.level && c.PersistentResolve) {
          result = c.PersistentResolve(result, context);
        }
      });
      return result;
    },
    PersistentFinalize(result, context) {
      result = this.weapon.PersistentFinalize(result, context);
      this.artifacts.sets.forEach(e => {
        if(e.PersistentFinalize) {
          result = e.PersistentFinalize(result, context);
        }
      });
      
      if(this.ascension>=1 && this.passiveAsc1.PersistentFinalize) {
        result = this.passiveAsc1.PersistentFinalize(result, context);
      }
      
      if(this.ascension>=4 && this.passiveAsc4.PersistentFinalize) {
        result = this.passiveAsc4.PersistentFinalize(result, context);
      }
      
      this.constellations.forEach(c => {
        if(this.constellation>=c.level && c.PersistentFinalize) {
          result = c.PersistentFinalize(result, context);
        }
      });
      return result;
    },
    IsSelf(result) {
      return result && result.initiator && result.initiator===this;
    },
    ResetCooldowns() {
      this.track.lastSwap = -999;
      this.attack.track.lastUsed = -999;
      this.attack.track.lastStatusApplied = -999;
      this.skill.track.lastUsed = -999;
      this.skill.track.lastStatusApplied = -999;
      this.burst.track.lastUsed = -999;
      this.burst.track.lastStatusApplied = -999;
    }
  }
};

BaseCharacter.Constellation53 = [{
  description: '',
  level: 3,
  PersistentPrepare(result, context) {
    if(context.IsSelf(result) && result.talent.type==='Burst') {
      result.talentProperties.level += 3;
    }
    return result;
  }
}, {
  description: '',
  level: 5,
  PersistentPrepare(result, context) {
    if(context.IsSelf(result) && result.talent.type==='Skill') {
      result.talentProperties.level += 3;
    }
    return result;
  }
}]
BaseCharacter.Constellation35 = [{
  description: '',
  level: 3,
  PersistentPrepare(result, context) {
    if(context.IsSelf(result) && result.talent.type==='Skill') {
      result.talentProperties.level += 3;
    }
    return result;
  }
}, {
  description: '',
  level: 5,
  PersistentPrepare(result, context) {
    if(context.IsSelf(result) && result.talent.type==='Burst') {
      result.talentProperties.level += 3;
    }
    return result;
  }
}]

export default BaseCharacter;