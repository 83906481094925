import Tools from '../../Tools.js';
import Scaling from '../../Scaling.js';
import BaseCharacter from '../base.js';

var Barbara = (props) => {
  
  let scaleTable = {
    ascension0:{
      min: {level:1, maxHP:821, baseAtk:14, defense:56, hpPct:0},
      max: {level:20, maxHP:2018, baseAtk:34, defense:144, hpPct:0}
    },
    ascension1:{
      min: {level:20, maxHP:2721, baseAtk:44, defense:186, hpPct:0},
      max: {level:40, maxHP:4076, baseAtk:67, defense:279, hpPct:0}
    },
    ascension2:{
      min: {level:40, maxHP:4512, baseAtk:74, defense:308, hpPct:0.06},
      max: {level:50, maxHP:5189, baseAtk:84, defense:355, hpPct:0.06}
    },
    ascension3:{
      min: {level:50, maxHP:5770, baseAtk:94, defense:394, hpPct:0.12},
      max: {level:60, maxHP:6448, baseAtk:105, defense:441, hpPct:0.12}
    },
    ascension4:{
      min: {level:60, maxHP:6884, baseAtk:105, defense:441, hpPct:0.12},
      max: {level:70, maxHP:7561, baseAtk:123, defense:517, hpPct:0.12}
    },
    ascension5:{
      min: {level:70, maxHP:7996, baseAtk:130, defense:546, hpPct:0.18},
      max: {level:80, maxHP:8674, baseAtk:141, defense:593, hpPct:0.18}
    },
    ascension6:{
      min: {level:80, maxHP:9110, baseAtk:149, defense:623, hpPct:0.24},
      max: {level:90, maxHP:9787, baseAtk:159, defense:669, hpPct:0.24}
    }
  };
  //TODO: check anim speed
  return BaseCharacter({
    ...props,
    name: 'Barbara',
    element: 'hydro',
    equip: 'catalyst',
    scaleTable: scaleTable,
    scaleAttr: 'hpPct',
    attackTalent: {
      description: 'Whisper of Water',
      level: props.attack,
      type: "NormalAtk",
      defaultImpl: {
        talentProperties: {
          comboTable: [
            //TODO: check anim speed
            {dmg:0.378, anim:0.66}, 
            {dmg:0.355, anim:0.3}, 
            {dmg:0.410, anim:0.4}, 
            {dmg:0.552, anim:1.2}
          ],
        },
        duration: 0.5,
        Prepare(result) {
          result.attributes.element = 'hydro';
          return result;
        },
        Resolve(result) {
          result = Scaling.ScaleAttackCombo(result);
          return result;
        }
      },
      chargedImpl: {
        type: "ChargeAtk",
        duration: 1.5,
        Prepare(result) {
          result.attributes.element = 'hydro';
          return result;
        },
        Resolve(result) {
          result = Scaling.ScaleTalent(1.66, result);
          return result;
        }
      }
    },
    skillTalent: {
      description:'Let the Show Begin',
      level: props.skill,
      type: 'Skill',
      defaultImpl: {
        duration: 1.5,
        cooldown: 32,
        Prepare(result) {
          result.attributes.element='hydro';
          return result;
        },
        Finalize(result) {
          result.effects.push(Tools.CreateTalentSideEffect(result, 'melody', {
            relativeTime: r => -r.precedingResult.duration/2
          }))
          return result;
        }
      },
      melodyEffect: {
        description: 'Let the Show Begin - Melody Loop',
        entity: 'Barbara\'s Let the Show Begin',
        duration: 4.75,
        talentProperties: {
          melodyNumber: 1,
          maxNumber: 3
        },
        Prepare(result) {
          result.attributes.element = 'hydro';
          result.forceStatus = true;
          return result;
        },
        Finalize(result) {
          if(result.talentProperties.melodyNumber<result.talentProperties.maxNumber) {
            result.effects.push(Tools.CreateTalentSideEffect(result, 'melody', {
              talentProperties: {
                melodyNumber: result.talentProperties.melodyNumber,
                maxNumber: result.talentProperties.maxNumber
              }
            }));
          }
          return result;
        }
      }
    },
    burstTalent: {
      description: 'Shining Miracle',
      level: props.burst,
      type: 'Burst',
      duration: 2.5,
      defaultImpl: {
        cooldown: 20,
        Prepare(result) {
          result.attributes.element='hydro';
          return result;
        },
        Resolve(result) {
          return result;
        }
      }
    },
    passiveAsc1: {
      description: 'Glorious Season',
      PersistentResolve(result, context) {
        return result;
      }
    },
    passiveAsc4: {
      description: 'Encore',
      PersistentResolve(result, context) {
        return result;
      }
    },
    passiveOther: [{
      description: 'With My Whole Heart',
      PersistentResolve(result, context) {
        return result;
      }
    }],
    constellations: [
      ...BaseCharacter.Constellation53,
    ]
  });
};

export default Barbara;