import Scaling from '../../Scaling.js';
import BaseWeapon from '../base.js';

const LostPrayerToTheSacredWinds = (props) => {
  
  return BaseWeapon({
    ...props,
    type: 'catalyst',
    name: 'Lost Prayer to the Sacred Winds',
    lvl1Atk: 46,
    lvl1Sub: 0.072,
    subType: 'critRate',
    PersistentResolve(result, context) {
      /**
       * effect: 'Increases Movement SPD by 10%. When in battle, gain an [8%] 
       * Elemental DMG Bonus every 4s. Max 4 stacks. Lasts until the character 
       * falls or leaves combat.'
       */
      if(this.IsSelf(result)) {
        let effectValues = {
          elementalDmg: Scaling.ScaleWeaponPassive(0.08, this.refinement)
        }
      }
      return result;
    }
  });
};

export default LostPrayerToTheSacredWinds;