import Tools from '../../Tools.js';
import Scaling from '../../Scaling.js';
import BaseCharacter from '../base.js';


const pullingEffects = ['Gale Blade', 'Palm Vortex', 'Gust Surge'];

var Klee = (props) => {
  
  let scaleTable = {
    ascension0: {
      min: {level:1, maxHP:801, baseAtk:24, defense:48, attr:0},
      max: {level:20, maxHP:2077, baseAtk:63, defense:124, attr:0}
    },
    ascension1: {
      min: {level:20, maxHP:2764, baseAtk:84, defense:165, attr:0},
      max: {level:40, maxHP:4136, baseAtk:125, defense:247, attr:0}
    },
    ascension2: {
      min: {level:40, maxHP:4623, baseAtk:140, defense:276, attr:0.072},
      max: {level:50, maxHP:5319, baseAtk:161, defense:318, attr:0.072}
    },
    ascension3: {
      min: {level:50, maxHP:5970, baseAtk:181, defense:357, attr:0.144},
      max: {level:60, maxHP:6673, baseAtk:202, defense:399, attr:0.144}
    },
    ascension4: {
      min: {level:60, maxHP:7161, baseAtk:217, defense:428, attr:0.144},
      max: {level:70, maxHP:7870, baseAtk:238, defense:470, attr:0.144}
    },
    ascension5: {
      min: {level:70, maxHP:8358, baseAtk:253, defense:500, attr:0.216},
      max: {level:80, maxHP:9076, baseAtk:274, defense:542, attr:0.216}
    },
    ascension6: {
      min: {level:80, maxHP:9563, baseAtk:289, defense:572, attr:0.288},
      max: {level:90, maxHP:10287, baseAtk:311, defense:615, attr:0.288}
    }
  };
  return BaseCharacter({
    ...props,
    name: 'Klee',
    element: 'pyro',
    equip: 'catalyst',
    scaleTable: scaleTable,
    scaleAttr: 'damageBonus.pyro',
    attackTalent: {
      description: 'Kaboom!',
      level: props.attack,
      type: "NormalAtk",
      defaultImpl: {
        talentProperties: {
          comboTable: [
            {dmg:0.722, anim:0.66}, 
            {dmg:0.624, anim:0.6}, 
            {dmg:0.899, anim:1}
          ]
        },
        duration: 1,
        Prepare(result) {
          result.attributes.element = 'pyro';
          return result;
        },
        Resolve(result) {
          result = Scaling.ScaleAttackCombo(result);
          return result;
        }
      },
      chargedImpl: {
        type: "ChargeAtk",
        duration: 1,
        Prepare(result) {
          result.attributes.element = 'pyro';
          return result;
        },
        Resolve(result) {
          result = Scaling.ScaleTalent(1.57, result);
          return result;
        }
      }
    },
    skillTalent: {
      description:'Jumpy Dumpty',
      level: props.skill,
      type: 'Skill',
      track: {charges:[{lastUsed:-999}, {lastUsed:-999}]},
      duration: 1.3,
      defaultImpl: {
        Prepare(result) {
          let oldestUsedCharge = this.track.charges.reduce((acc, cur) => !acc || cur.lastUsed < acc.lastUsed ? cur : acc);
          let delay = result.time - oldestUsedCharge.lastUsed;
          if(delay < 20) {
            result = Tools.CreateCooldownEffect(result, {cooldown: 20, lastUsed: oldestUsedCharge.lastUsed});
          }
          oldestUsedCharge.lastUsed = delay<20?oldestUsedCharge.lastUsed+20:result.time;
          result.attributes.element = 'pyro';
          result.effects.push(Tools.CreateTalentSideEffect(result, 'jump', {relativeTime:-0.26}));
          return result;
        },
        Resolve(result) {
          let ongoingJumpy = result.ongoing.effects.filter(r => ["Jumpy Dumpty - Bounce", "Jumpy Dumpty - Mines", "Jumpy Dumpty - Mine explosion"].find(f=>r.description.startsWith(f)))[0];
          if(ongoingJumpy) {
            ongoingJumpy.talentProperties.interrupted=true;
            ongoingJumpy.logs.push(`Using Jumpy Dumpty while another is still up interupts the former`);
            ongoingJumpy.setDuration(result.time - ongoingJumpy.time);
          }
          return result;
        }
      },
      jumpEffect: {
        description: 'Jumpy Dumpty - Bounce',
        entity: 'Klee\'s Jumpy Dumpty',
        duration: 0.57,
        talentProperties: {
          interrupted: false
        },
        talentProperties: {
          jumpNumber:1
        },
        Prepare(result) {
          return result;
        },
        Resolve(result) {
          result = Scaling.ScaleTalent(0.952, result);
          return result;
        },
        Finalize(result) {
          if(!result.talentProperties.interrupted) {
            if(result.talentProperties.jumpNumber<3) {
              result.effects.push(Tools.CreateTalentSideEffect(result, 'jump', {
                talentProperties: {
                  jumpNumber: result.talentProperties.jumpNumber+1
                }
              }));
            } else {
              result.effects.push(Tools.CreateTalentSideEffect(result, 'mines'));
            }
          }
          return result;
        }
      },
      minesEffect: {
        description: 'Jumpy Dumpty - Mines',
        entity: 'Klee\'s Jumpy Dumpty',
        duration: 15,
        relativeTime: 0.75,
        stacks: 8,
        stackable: false,
        maxStacks: 8,
        talentProperties: {
          isSpreadOut: true,
          interrupted: false
        },
        Prepare(result) {
          result.attributes.element = 'pyro';
          return result;
        },
        PersistentResolve(result, context) {
          if(pullingEffects.find(s => result.description.startsWith(s))) {
            context.talentProperties.isSpreadOut = false;
            context.setDuration(result.time - context.time);
          }
          return result;
        },
        Finalize(result) {
          if(!result.talentProperties.interrupted) {
            result.effects.push(Tools.CreateTalentSideEffect(result, 'mineExplosion', {
              talentProperties: {
                isSpreadOut: result.talentProperties.isSpreadOut
              }
            }));
          }
          return result;
        }
      },
      mineExplosionEffect: {
        description: 'Jumpy Dumpty - Mine explosion',
        entity: 'Klee\'s Jumpy Dumpty',
        duration:0.1,
        stacks: 8,
        stackable: false,
        maxStacks: 8,
        talentProperties: {
          isSpreadOut: true,
          interrupted: false
        },
        Prepare(result) {
          result.attributes.element = 'pyro';
          return result;
        },
        Resolve(result) {
          let procChance = result.talentProperties.isSpreadOut
            ? 0.3
            : 1;
          if(Tools.ProcChance(procChance, result.talent.track)) {
            result = Scaling.ScaleTalent(0.328, result);
          }
          
          if(!result.attributes.skillPct) {
            result.logs.push('Mine too far to hit target, did not cause damage');
          }
          return result;
        },
        PersistentResolve(result, context) {
          if(pullingEffects.find(s => result.description.startsWith(s))) {
            context.logs.push('Mines being pulled by '+result.description);
            context.talentProperties.isSpreadOut = false;
            context.setDuration(result.time - context.time);
          }
          return result;
        },
        Finalize(result) {
          if(result.stacks>1 && !result.talentProperties.interrupted) {
            result.effects.push(Tools.CreateTalentSideEffect(result, 'mineExplosion', {
              stacks: result.stacks - 1,
              talentProperties: {
                isSpreadOut: result.talentProperties.isSpreadOut
              }
            }));
          }
          return result;
        }
      }
    },
    burstTalent: {
      description: 'Sparks \'n\' Splash',
      level: props.burst,
      type: 'Burst',
      defaultImpl: {
        duration: 2,
        cooldown: 15,
        Prepare(result) {
          result.attributes.element = 'pyro';
          return result;
        },
        Resolve(result) {
          return result;
        },
        Finalize(result) {
          result.effects.push(Tools.CreateTalentSideEffect(result, 'sparks', {
            relativeTime: -0.5,
          }));
          return result;
        }
      },
      sparksEffect: {
        description: 'Sparks \'n\' Splash',
        duration: 1.5,
        entity: 'Klee\'s Sparks \'n\' Splash',
        talentProperties: {
          swaping: false,
          sparkWave: 1
        },
        Prepare(result) {
          result.attributes.element = 'pyro';
          return result;
        },
        PersistentResolve(result, context) {
          if(result.type === 'Swap') {
            context.talentProperties.swaping = true;
            context.logs.push('Swaping character interupts Klee\'s Sparks\'n\' Splash');
            context.setDuration(result.time - context.time);
          }
          return result;
        },
        Resolve(result) {
          return result;
        },
        Finalize(result) {
          if(!result.talentProperties.swaping) {
            result.effects.push(Tools.CreateTalentSideEffect(result, 'sparkDmg', {
              talentProperties: {
                sparkWave: result.talentProperties.sparkWave
              }
            }));
          }
          return result;
        }
      },
      sparkDmgEffect: {
        description: 'Sparks \'n\' Splash',
        entity: 'Klee\'s Sparks \'n\' Splash',
        duration: 0.125,
        talentProperties: {
          swaping: false,
          sparkNumber: 1,
          sparkWave: 1
        },
        Prepare(result) {
          result.attributes.element = 'pyro';
          return result;
        },
        PersistentResolve(result, context) {
          if(result.type === 'Swap') {
            context.talentProperties.swaping = true;
            context.logs.push('Swaping character interupts Klee\'s Sparks\'n\' Splash');
            context.setDuration(result.time - context.time);
          }
          return result;
        },
        Resolve(result) {
          result = Scaling.ScaleTalent(0.426, result);
          return result;
        },
        Finalize(result) {
          if(!result.talentProperties.swaping) {
            if(result.talentProperties.sparkNumber<4) {
              result.effects.push(Tools.CreateTalentSideEffect(result, 'sparkDmg', {
                talentProperties: {
                  sparkNumber: result.talentProperties.sparkNumber + 1,
                  sparkWave: result.talentProperties.sparkWave
                }
              }));
            } else if(result.talentProperties.sparkWave<5) {
              result.effects.push(Tools.CreateTalentSideEffect(result, 'sparks', {
                talentProperties: {
                  sparkWave: result.talentProperties.sparkWave + 1
                }
              }));
            }
          }
          return result;
        }
      }
    },
    passiveAsc1: {
      description: 'Pounding Surprise',
      PersistentResolve(result, context) {
        return result;
      }
    },
    passiveAsc4: {
      description: 'Sparkling Burst',
      PersistentResolve(result, context) {
        return result;
      }
    },
    passiveOther: [{
      description: 'All Of My Treasures!',
      PersistentResolve(result, context) {
        return result;
      }
    }],
    constellations: [
      {
        level: 2,
        description: '',
        PersistentResolve(result, context) {
          if(result.description === 'Jumpy Dumpty - Mine explosion') {
            result.effects.push(Tools.CreateSideEffect(result, {
              description: 'Explosive Frags',
              entity: 'Klee\'s Constellations',
              duration: 10,
              PersistentResolve(result, context) {
                result.logs.push('Klee\'s Explosive Frags reduces target defense by 23%');
                result.attributes.defenseReduction += 0.23;
                return result;
              }
            }));
          }
          return result;
        }
      },
      ...BaseCharacter.Constellation53,
    ]
  });
};

export default Klee;