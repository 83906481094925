import Scaling from '../../Scaling.js';
import BaseWeapon from '../base.js';

const FesteringDesire = (props) => {
  
  return BaseWeapon({
    ...props,
    type: 'sword',
    name: 'Festering Desire',
    lvl1Atk: 42,
    lvl1Sub: 0.10,
    subType: 'energyRecharge',
    PersistentResolve(result, context) {
      /**
       * effect: 'Increases Elemental Skill DMG by [16%] and 
       * Elemental Skill CRIT Rate by [6%].'
       */
      if(this.IsSelf(result)) {
      }
      return result;
    }
  });
};

export default FesteringDesire;