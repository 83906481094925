import React, {useState, useEffect, Fragment} from 'react';
import { Form, Input, Button, Modal, Dimmer, Label, Icon, Header, Image, Segment, Popup, Dropdown } from 'semantic-ui-react';
import StatGrid from './stat-grid.js';
import CharacterEdit from './character-edit.js';
import TalentButton from './talent-button.js';
import Characters from './db/characters.js';

const IdleButton = (props) => {
  return <Popup position="top center" pinned hoverable 
    trigger={<Button onClick={(e) => props.onClick(e)}
        circular 
        size='big'
        icon="street view"
        compact 
        style={{padding: "0.445em", margin: 0, border: "1px gray solid"}}
        aria-label={props.character.name + ' Idle'} />}
    >
    <Header style={{whiteSpace:"nowrap"}}>
      Add Idle Time
    </Header>
  </Popup>;
};

const CharacterCard = (props) => {
  const [character, setCharacter] = useState(props.character);
  const [builds, setBuilds] = useState(props.builds || {default:{}});
  const [currentBuild, setCurrentBuild] = useState(props.character.build || 'default');
  const [characterProps, setCharacterProps] = useState(Characters.find(c => c.name === props.character.name.replaceAll(/[^0-9a-z]/gi, '')));
  const [attr, setAttr] = useState(character.name!=="None" ? props.character.fullAttributes : null);
  const [dimmed, setDimmed] = useState(false);
  const [showingAttributes, setShowingAttributes] = useState(false);
  const [idleTime, setIdleTime] = useState(2);
  const [idleModalOpen, setIdleModalOpen] = useState(false);
  
  useEffect(() => {
    setCharacter(props.character);
    setBuilds(props.builds);
    setCurrentBuild(props.character.build);
    setCharacterProps(Characters.find(c => c.name === props.character.name.replaceAll(/[^0-9a-z]/gi, '')));
    setAttr(props.character.name!=="None" ? props.character.fullAttributes : null);
  }, [props]);
  
  return <Segment style={{height:"226px"}}>
    {
    character.name!=='None'
    ? <Fragment>
        <Label attached="top left" style={{padding: "0.550781em 6.5em 0.550781em 1em", width: "100%"}}>
          <Header textAlign="left" style={{overflow: "hidden", height: "1.28571429em", whiteSpace: "nowrap", textOverflow: "ellipsis"}}>{character.name}</Header>
        </Label>
        <CharacterEdit onSave={(e, b) => {
            setCharacter(e);
            props.onChange(e, b);
          }} 
          character={character}
          builds={builds}>
          <Label as="a" attached="top right" style={{borderLeft: "1px gray groove", borderBottomLeftRadius: 0}}><Icon name="edit" />Edit</Label>
        </CharacterEdit>
    
        <div style={{marginBottom:"0.3em", marginTop:"1.5em"}}>
          <Dimmer.Dimmable as='div' dimmed={dimmed} style={{display: "flex", position: "relative"}} onMouseEnter={() => setDimmed(attr?true:false)}>
            {/*<div style={{display: "flex", position: "relative"}} onMouseEnter={() => setDimmed(attr?true:false)}>*/}
              <div style={{display: "inline-block", width: "2em", height: "100px", marginRight: "0.1em"}}>
                <div style={{display: "inline-block", width: "2em", borderRadius: "500rem", backgroundColor: "rgb(71, 71, 71)", border: "1px solid white"}}>
                  <Image src={"/static/icons/"+character.element+".png"} aria-label={character.element} avatar />
                  <Image src={"/static/icons/"+character.equip+".png"} aria-label={character.equip} avatar style={{clipPath: "circle(0.82em at center)"}} />
                </div>
              </div>
              <img className="portrait" src={"/static/characters/"+character.name.replaceAll(/[^0-9a-z]/gi, "").toLowerCase()+".png"} alt={character.name} />
            {/*</div>*/}

            <Dimmer active={dimmed} onMouseLeave={() => setDimmed(false)} onClick={() => setShowingAttributes(true)} style={{cursor: 'pointer'}}>
              <Header size='small' icon inverted>
                <Icon name='search' />
                Show attributes
              </Header>
            </Dimmer>
          </Dimmer.Dimmable>
          <Modal
            open={showingAttributes}
            onClose={() => setShowingAttributes(false)}
            >
            <Modal.Header>{character.name}'s Attributes</Modal.Header>
            <Modal.Content>
              <StatGrid key="characterStats" mode="total" character={character} attr={attr} />
            </Modal.Content>
          </Modal>
          <div>
            <Dropdown
              className="builds"
              placeholder='Select build'
              selection
              compact
              style={{width:"calc(100% - 0.5em)", padding: "0.38em 2.1em 0.38em 0.5em"}}
              value={currentBuild}
              options={(Object.keys(builds) || []).map(b => {
                return {
                  key: b,
                  text: <span className="text" style={{
                    width:"100%", 
                    height:"1em", 
                    overflow:"hidden", 
                    textOverflow:"ellipsis",
                    whiteSpace: "nowrap"
                  }}>{b}</span>,
                  value: b
                };
              })}
              onChange={(e,d) => {
                props.onBuildChange(d.value);
              }}
            />
          </div>
        </div>
    
        <div style={{display:"inline-block", height:"36px", width:"39px"}}>
          {characterProps.attack.impl.length > 1
            ? <Popup position="bottom center" pinned hoverable flowing trigger={
                <TalentButton key={character.name+"-Attack-"+characterProps.attack.impl[0].name} character={character} talent="Attack" data={{talent:characterProps.attack, impl:characterProps.attack.impl[0]}} onTalent={props.onTalent} />
              }><Popup.Content>
              <div><Button.Group size='mini' compact>
                {characterProps.attack.impl.map((a,i) => i>0
                  ? <TalentButton key={character.name+"-Attack-"+a.name} character={character} talent="Attack" data={{talent:characterProps.attack, impl:a}} onTalent={props.onTalent} popPosition="bottom center"  />
                  : ''
                )}</Button.Group>
              </div>
              </Popup.Content>
              </Popup>
            : <TalentButton key={character.name+"-Attack-"+characterProps.attack.impl[0].name} character={character} talent="Attack" data={{talent:characterProps.attack, impl:characterProps.attack.impl[0]}} onTalent={props.onTalent} />
          }
        </div>
        <div style={{display:"inline-block", height:"36px", width:"39px", verticalAlign:"top"}}>
          {characterProps.skill.impl.length > 1
            ? <Popup position="bottom center" pinned hoverable flowing trigger={
                <TalentButton key={character.name+"-Skill-"+characterProps.skill.impl[0].name} character={character} talent="Skill" data={{talent:characterProps.skill, impl:characterProps.skill.impl[0]}} onTalent={props.onTalent} />
              }><Popup.Content>
              <div>
                {characterProps.skill.impl.map((a,i) => i>0
                  ? <TalentButton key={character.name+"-Skill-"+a.name} character={character} talent="Skill" data={{talent:characterProps.skill, impl:a}} onTalent={props.onTalent} popPosition="bottom center" />
                  : ''
                )}
              </div>
              </Popup.Content>
              </Popup>
            : <TalentButton key={character.name+"-Skill-"+characterProps.skill.impl[0].name} character={character} talent="Skill" data={{talent:characterProps.skill, impl:characterProps.skill.impl[0]}} onTalent={props.onTalent} />
          }
        </div>
        <div style={{display:"inline-block", height:"36px", width:"39px", verticalAlign:"top"}}>
          {characterProps.burst.impl.length > 1
            ? <Popup position="bottom center" pinned hoverable flowing trigger={
                <TalentButton key={character.name+"-Burst-"+characterProps.burst.impl[0].name} character={character} talent="Burst" data={{talent:characterProps.burst, impl:characterProps.burst.impl[0]}} onTalent={props.onTalent} />
              }><Popup.Content>
              <div>
                {characterProps.burst.impl.map((a,i) => i>0
                  ? <TalentButton key={character.name+"-Burst-"+a.name} character={character} talent="Burst" data={{talent:characterProps.burst, impl:a}} onTalent={props.onTalent} popPosition="bottom center"  />
                  : ''
                )}
              </div>
              </Popup.Content>
              </Popup>
            : <TalentButton key={character.name+"-Burst-"+characterProps.burst.impl[0].name} character={character} talent="Burst" data={{talent:characterProps.burst, impl:characterProps.burst.impl[0]}} onTalent={props.onTalent} />
          }
        </div>
        <div style={{display:"inline-block", height:"36px", width:"39px", verticalAlign:"top"}}>
          <Modal 
            onClose={() => {
              setIdleModalOpen(false);
            }}
            onOpen={() => setIdleModalOpen(true)} 
            open={idleModalOpen}
            size='mini'
            trigger={<IdleButton character={character} />}>
            <Modal.Header>Add Idle Time</Modal.Header>
            <Modal.Content>
              <Form>
                <Input type="range" min={0.6} max={10} step={0.1} value={idleTime}
                  onChange={(e,d) => {
                    setIdleTime(d.value);
                  }}/>
                <Label>{idleTime}s</Label>
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button color='black' onClick={() => setIdleModalOpen(false)}>Cancel</Button>
              <Button onClick={(e) => {
                props.onTalent(character, {
                  talent: 'Idle', 
                  impl: {name: idleTime}, 
                  description: 'Idle'
                });
                setIdleModalOpen(false);
              }}>Add</Button>
            </Modal.Actions>
          </Modal>
        </div>
      </Fragment>
    : <Fragment>
        <CharacterEdit onSave={(e, b) => {
            setCharacter(e);
            props.onChange(e, b);
          }} 
          character={character}
          builds={{}}>
          <div className="addcharacter">
            <div style={{position: "relative", width: "100%", margin:"auto"}}>
              <div style={{fontSize: "6em", lineHeight: "1.2em"}}>
                <Icon name="add circle" />
              </div>
              <Header style={{marginTop: "0"}}>Add a character</Header>
            </div>
          </div>
        </CharacterEdit>
      </Fragment>
    }
  </Segment>;
};

export default CharacterCard;