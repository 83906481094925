import Scaling from '../../Scaling.js';
import BaseWeapon from '../base.js';

const PrototypeAminus = (props) => {
  
  return BaseWeapon({
    ...props,
    type: 'claymore',
    name: 'PrototypeAminus',
    lvl1Atk: 44,
    lvl1Sub: 0.06,
    subType: 'atkPct',
    PersistentResolve(result, context) {
      /**
       * effect: 'On hit, Normal or Charged Attacks have a 50% chance to deal 
       * an additional [240%] ATK DMG to enemies within a small AoE. 
       * Can only occur once every 15s.'
       */
      if(this.IsSelf(result)) {
      }
      return result;
    }
  });
};

export default PrototypeAminus;