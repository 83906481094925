import Scaling from '../../Scaling.js';
import BaseWeapon from '../base.js';

const PrototypeRancour = (props) => {
  
  return BaseWeapon({
    ...props,
    type: 'sword',
    name: 'Prototype Rancour',
    lvl1Atk: 44,
    lvl1Sub: 0.075,
    subType: 'damageBonus.physical',
    PersistentResolve(result, context) {
      /**
       * effect: 'On hit, Normal or Charged Attacks increase ATK and DEF 
       * by [4%] for 6s. Max 4 stacks. Can only occur once every 0.3s.'
       */
      if(this.IsSelf(result)) {
      }
      return result;
    }
  });
};

export default PrototypeRancour;