import {BaseSet} from './base.js';

const WanderersTroupe = (props) => {
  
  return BaseSet({
    ...props,
    name: 'Wanderer\'s Troupe',
    two: {
      attributes: {
        elemMastery: 80
      },
      PersistentResolve(result, context) {
        return result;
      }
    },
    four: {
      PersistentResolve(result, context) {
        if(!this.IsSelf(result)) return result;
        if(result.initiator.weapon.type==='bow' || result.initiator.weapon.type==='catalyst') {
          if(result.type==="ChargeAtk" || result.type==="ChargeAtkHold") {
            result.attributes.damageBonus.all += 0.35;
            result.logs.push(this.name + ' increases Damage Bonus by 35% on Charge Attacks');
          }
        }
        return result;
      }
    }
  });
};

export default WanderersTroupe;