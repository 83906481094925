import Scaling from '../../Scaling.js';
import BaseWeapon from '../base.js';

const WolfsGravestone = (props) => {
  
  return BaseWeapon({
    ...props,
    type: 'claymore',
    name: 'Wolf\'s Gravestone',
    lvl1Atk: 46,
    lvl1Sub: 0.108,
    subType: 'atkPct',
    PersistentResolve(result, context) {
      /**
       * effect: 'Increases ATK by [20%]. On hit, attacks against enemies with 
       * less than 30% HP increase all party members\' ATK by [40%] for 12s. 
       * Can only occur once every 30s.'
       */
      if(this.IsSelf(result)) {
        let effectValues = {
          atkIncrease: Scaling.ScaleWeaponPassive(0.20, this.refinement),
          partyAtkIncrease: Scaling.ScaleWeaponPassive(0.40, this.refinement)
        }
      }
      return result;
    }
  });
};

export default WolfsGravestone;