import {BaseSet} from './base.js';

const BlizzardStrayer = (props) => {
  
  return BaseSet({
    ...props,
    name: 'Blizzard Strayer',
    two: {
      attributes: {
        damageBonus: {
          cryo: 0.15
        }
      },
      PersistentResolve(result, context) {
        return result;
      }
    },
    four: {
      PersistentResolve(result, context) {
        if(!this.IsSelf(result)) return result;
        if(result.ongoing.status.filter(e => e.type==="Reaction" && e.description==="Frozen").length>0) {
          result.attributes.critRate += 0.40;
          result.logs.push("Blizzard Strayer Increases Crit Rate against enemies affected by Frozen by 40%");
        } else if(result.ongoing.status.filter(e => e.type==="Status" && e.description==="Cold").length>0) {
          result.attributes.critRate += 0.20;
          result.logs.push("Blizzard Strayer Increases Crit Rate against enemies affected by Cryo by 20%");
        }
        return result;
      }
    }
  });
};

export default BlizzardStrayer;