
var HilichurlFighter = (props) => {
  let {resistance, ...attributes} = {resistance:{}, ...props.attributes};
  return {
    name: 'Hilichurl Fighter',
    level: props.level || 20,
    attributes: {
      level: props.level || 20,
      resistance: {
        all:      0.10,
        physical: 0,
        hydro:    0,
        electro:  0,
        pyro:     0,
        cryo:     0,
        anemo:    0,
        geo:      0,
        dendro:   0,
        ...resistance
      },
      ...attributes
    }
  };
};

export default HilichurlFighter;