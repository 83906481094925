import {BaseSet} from './base.js';
import Tools from '../Tools.js';

const HeartOfDepth = (props) => {
  
  return BaseSet({
    ...props,
    name: 'Heart of Depth',
    two: {
      attributes: {
        damageBonus: {
          hydro: 0.15
        }
      },
      PersistentResolve(result, context) {
        return result;
      }
    },
    four: {
      PersistentResolve(result, context) {
        if(!this.IsSelf(result)) return result;
        if(result.type==="Skill") {
          result.effects.push(Tools.CreateSideEffect(result, {
            description: this.name+' 4pc',
            entity: 'Buffs',
            duration: 15,
            relativeTime: 0,
            type: 'Buff',
            PersistentResolve(result, context) {
              if(result.initiator!==context.initiator) return result;
              if(["NormalAtk", "ChargeAtk", "ChargeAtkHold"].find(f => result.type.startsWith(f))) {
                result.attributes.atkPct += 0.30;
                result.logs.push("Heart of Depth increases Normal Attack and Charged Attack by 30%");
              }
              return result;
            }
          }));
        }
        return result;
      }
    }
  });
};

export default HeartOfDepth;