import Scaling from '../../Scaling.js';
import BaseWeapon from '../base.js';

const Rust = (props) => {
  
  return BaseWeapon({
    ...props,
    type: 'bow',
    name: 'Rust',
    lvl1Atk: 42,
    lvl1Sub: 0.09,
    subType: 'atkPct',
    PersistentResolve(result, context) {
      if(this.IsSelf(result)) {
        //console.log('Rust resolving...');
        if(result.type.startsWith("NormalAtk")) {
          result.logs.push('Normal attacks with Rust increased by '+(Scaling.ScaleWeaponPassive(0.40, this.refinement)*100)+'%');
          result.attributes.damageBonus.all += Scaling.ScaleWeaponPassive(0.40, this.refinement);
        }
        if(result.type==="ChargeAtk" || result.type==="ChargeAtkHold") {
          result.logs.push('Charge attacks with Rust reduced by 10%');
          result.attributes.damageBonus.all -= 0.10;
        }
      }
      return result;
    }
  });
};

export default Rust;