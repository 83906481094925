import Tools from '../../Tools.js';
import Scaling from '../../Scaling.js';
import BaseCharacter from '../base.js';

//TODO: implement character
var Venti = (props) => {
  
  let scaleTable = {
    ascension0: {
      min: {level:1, maxHP:820, baseAtk:21, defense:52, attr:0},
      max: {level:20, maxHP:2127, baseAtk:53, defense:135, attr:0}
    },
    ascension1: {
      min: {level:20, maxHP:2830, baseAtk:71, defense:180, attr:0},
      max: {level:40, maxHP:4234, baseAtk:106, defense:269, attr:0}
    },
    ascension2: {
      min: {level:40, maxHP:4734, baseAtk:118, defense:301, attr:0.08},
      max: {level:50, maxHP:5446, baseAtk:136, defense:346, attr:0.08}
    },
    ascension3: {
      min: {level:50, maxHP:6112, baseAtk:153, defense:388, attr:0.16},
      max: {level:60, maxHP:6832, baseAtk:170, defense:434, attr:0.16}
    },
    ascension4: {
      min: {level:60, maxHP:7331, baseAtk:183, defense:465, attr:0.16},
      max: {level:70, maxHP:8058, baseAtk:202, defense:512, attr:0.16}
    },
    ascension5: {
      min: {level:70, maxHP:8557, baseAtk:214, defense:543, attr:0.24},
      max: {level:80, maxHP:9291, baseAtk:233, defense:591, attr:0.24}
    },
    ascension6: {
      min: {level:80, maxHP:9790, baseAtk:245, defense:622, attr:0.32},
      max: {level:90, maxHP:10531, baseAtk:265, defense:670, attr:0.32}
    }
  };
  return BaseCharacter({
    ...props,
    name: 'Venti',
    element: 'anemo',
    equip: 'bow',
    scaleTable: scaleTable,
    scaleAttr: 'energyRecharge',
    attackTalent: {
      description: 'Divine Marksmanship',
      level: props.attack,
      type: "NormalAtk",
      defaultImpl: {
        talentProperties: {
          comboTable: [
            {dmg:0.204, anim:0.17, extra:[{dmg:0.204, anim:0.33}]}, 
            {dmg:0.444, anim:0.67}, 
            {dmg:0.524, anim:0.5}, 
            {dmg:0.261, anim:0.23, extra:[{dmg:0.261, anim:0.27}]}, 
            {dmg:0.507, anim:0.33},
            {dmg:0.710, anim:1.67}
          ]
        },
        duration: 1,
        Resolve(result) {
          result = Scaling.ScaleAttackCombo(result);
          return result;
        }
      },
      aimedImpl: {
        description: 'Divine Marksmanship - Aimed',
        type: "ChargeAtk",
        duration: 0.5,
        Resolve(result) {
          result = Scaling.ScaleTalent(0.439, result);
          return result;
        }
      },
      fullyAimedImpl: {
        description: 'Divine Marksmanship - Fully-Aimed',
        type: "ChargeAtkHold",
        duration: 1.5,
        Prepare(result) {
          result.attributes.element = 'anemo';
          return result;
        },
        Resolve(result) {
          result = Scaling.ScaleTalent(1.24, result);
          return result;
        }
      }
    },
    skillTalent: {
      description:'Skyward Sonnet',
      level: props.skill,
      duration: 1,
      type: 'Skill',
      defaultImpl: {
        Prepare(result) {
          result.attributes.element = 'anemo';
          return result;
        },
        Resolve(result) {
          result = Scaling.ScaleTalent(2.76, result);
          return result;
        }
      },
      holdImpl: {
        description: 'Skyward Sonnet - Hold',
        duration: 4,
        Prepare(result) {
          result.attributes.element = 'anemo';
          return result;
        },
        Resolve(result) {
          result = Scaling.ScaleTalent(3.80, result);
          result.effects.push(Tools.CreateTalentSideEffect(result, 'wind'));
          return result;
        }
      },
      windEffect: {
        description: 'Skyward Sonnet - Wind Domain',
        entity: 'Venti\'s Skyward Sonnet',
        duration: 15,
        relativeTime: -2.5,
        Prepare(result) {
          result.attributes.element = 'anemo';
          result.forceStatus=true;
          return result;
        }
      }
    },
    burstTalent: {
      description: 'Wind\'s Grand Ode',
      level: props.burst,
      duration: 2,
      type: 'Burst',
      defaultImpl: {
        Prepare(result) {
          result.attributes.element = 'anemo';
          return result;
        },
        Resolve(result) {
          result.effects.push(Tools.CreateTalentSideEffect(result, 'stormeye', {
            relativeTime: -0.33
          }));
          return result;
        }
      },
      stormeyeEffect: {
        description: 'Wind\'s Grand Ode - Stormeye',
        entity: 'Venti\'s Wind\'s Grand Ode',
        duration: 0.45,
        talentProperties: {
          stormeyeNumber: 1,
          element: ''
        },
        Prepare(result) {
          result = Tools.AbsorbElement(result);
          result.attributes.element = 'anemo';
          return result;
        },
        Resolve(result) {
          result = Scaling.ScaleTalent(.376, result);
          return result;
        },
        Finalize(result) {
          if(result.talentProperties.element && !result.talentProperties.absorbed) {
            result.effects.push(Tools.CreateTalentSideEffect(result, 'elementalDmg', {
              talentProperties: {
                element: result.talentProperties.element, 
                sourceScaling: 0.808
              }
            }))
          }
          if(result.talentProperties.stormeyeNumber<18) {
            result.effects.push(Tools.CreateTalentSideEffect(result, 'stormeye', {
              talentProperties: {
                element: result.talentProperties.element,
                stormeyeNumber: result.talentProperties.stormeyeNumber+1
              }
            }));
          }
          return result;
        }
      },
      elementalDmgEffect: {
        duration: 0.1,
        relativeTime: (r => -r.precedingResult.duration),
        description: 'Wind\'s Grand Ode - Additional Elemental Damage',
        entity: 'Venti\'s Wind\'s Grand Ode',
        talentProperties: {
          element: ''
        },
        Prepare(result) {
          if(result.talentProperties.element) {
            result.attributes.element = result.talentProperties.element;
            result.forceStatus = true;
          }
          return result;
        },
        Resolve(result) {
          if(result.talentProperties.element) {
            result = Scaling.ScaleTalent(.188, result);
            result.logs.push('Additionnal Elemental Damage deals '+(Math.round(result.attributes.skillPct*10000)/100)+'%')
          }
          return result;
        }
      }
    },
    passiveAsc1: {
      description: 'Embrace of Winds',
      PersistentResolve(result, context) {
        return result;
      }
    },
    passiveAsc4: {
      description: 'Stormeye',
      PersistentResolve(result, context) {
        return result;
      }
    },
    passiveOther: [{
      description: 'Windrider',
      PersistentResolve(result, context) {
        return result;
      }
    }],
    constellations: [
      ...BaseCharacter.Constellation53,
    ]
  });
};

export default Venti;