import ls from 'local-storage';

var Persistence = {
  
  defaultBuild(name) {
    return {default: {name: name}};
  },
  
  getTeamBuilds(team) {
    return team.map(c => {
      let charName = c.name.replaceAll(/[^0-9a-z]/gi, '');
      return {
        name: c.name, 
        builds: ls.get('genshinSimulatorBuilds.'+charName) || this.defaultBuild(c.name)
      };
    }).reduce(
      (arr,cur) => (arr[cur.name.replaceAll(/[^0-9a-z]/gi, '')] = cur.builds, arr)
      , {}
    );
  },
  
  getBuilds(charName) {
    charName = charName.replaceAll(/[^0-9a-z]/gi, '');
    return ls.get('genshinSimulatorBuilds.'+charName) || this.defaultBuild(charName);
  },
  
  saveBuild(buildName, character) {
    let charName = character.name.replaceAll(/[^0-9a-z]/gi, '');
    ls.set('genshinSimulatorBuilds.'+charName, {
      ...ls.get('genshinSimulatorBuilds.'+charName), 
      [buildName]: character
    });
  },
  
  getTeam() {
    return ls.get('genshinSimulatorTeam') || [];
  },
  
  saveTeam(team) {
    return ls.set('genshinSimulatorTeam', team.map(c => { 
      return {
        name: c.name.replaceAll(/[^0-9a-z]/gi, ''), 
        build: c.build || 'default'
      };
    }));
  },
  
  getMonsters() {
    return ls.get('genshinSimulatorMonsters') || [{name:'Hilichurl Fighter', level:77}];
  },
  
  saveMonsters(monsters) {
    
  },
  
  getHistory() {
    return ls.get('genshinSimulatorHistory') || {entries:[], version:0};
  },
  
  saveHistory(history) {
    ls.set('genshinSimulatorHistory', {
      version: history.currentVersion, 
      entries: history.entries
    });
  },
  
  getVisibleTime() {
    return ls.get('genshinSimulatorTimeGridZoom') || {
      start:0,
      end:15000
    };
  },
  
  saveVisibleTime(visibleTime) {
    ls.set('genshinSimulatorTimeGridZoom', visibleTime);
  }
  
};

export default Persistence;