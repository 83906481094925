import Tools from '../../Tools.js';
import Scaling from '../../Scaling.js';
import BaseCharacter from '../base.js';

//TODO: implement character
var Xinyan = (props) => {
  
  let scaleTable = {
    ascension0: {
      min: {level:1, maxHP:939, baseAtk:21, defense:67, attr:0},
      max: {level:20, maxHP:2413, baseAtk:54, defense:172, attr:0}
    },
    ascension1: {
      min: {level:20, maxHP:3114, baseAtk:69, defense:222, attr:0},
      max: {level:40, maxHP:4665, baseAtk:103, defense:333, attr:0}
    },
    ascension2: {
      min: {level:40, maxHP:5163, baseAtk:115, defense:368, attr:0.06},
      max: {level:50, maxHP:5939, baseAtk:132, defense:423, attr:0.06}
    },
    ascension3: {
      min: {level:50, maxHP:6604, baseAtk:147, defense:471, attr:0.12},
      max: {level:60, maxHP:7379, baseAtk:164, defense:526, attr:0.12}
    },
    ascension4: {
      min: {level:60, maxHP:7878, baseAtk:175, defense:562, attr:0.12},
      max: {level:70, maxHP:8653, baseAtk:192, defense:617, attr:0.12}
    },
    ascension5: {
      min: {level:70, maxHP:9151, baseAtk:203, defense:652, attr:0.18},
      max: {level:80, maxHP:9927, baseAtk:220, defense:708, attr:0.18}
    },
    ascension6: {
      min: {level:80, maxHP:10425, baseAtk:231, defense:743, attr:0.24},
      max: {level:90, maxHP:11201, baseAtk:249, defense:799, attr:0.24}
    }
  };
  return BaseCharacter({
    ...props,
    name: 'Xinyan',
    element: 'pyro',
    equip: 'claymore',
    scaleTable: scaleTable,
    scaleAttr: 'atkPct',
    attackTalent: {
      description: 'Dance on Fire',
      level: props.attack,
      type: "NormalAtk",
      defaultImpl: {
        talentProperties: {
          comboTable: [
            {dmg:0.711, anim:0.5}, 
            {dmg:0.709, anim:0.6}, 
            {dmg:0.883, anim:1}, 
            {dmg:0.865, anim:0.75}, 
            {dmg:1.12, anim:1}
          ]
        },
        duration: 1,
        Resolve(result) {
          result = Scaling.ScaleAttackCombo(result);
          result.attributes.element='physical';
          return result;
        }
      },
      chargedImpl: {
        type: "ChargeAtk",
        duration: 1,
        Resolve(result) {
          result = Scaling.ScaleTalent(1.02, result);
          result.attributes.element='physical';
          return result;
        }
      }
    },
    skillTalent: {
      description:'Sweeping Fervor',
      level: props.skill,
      type: 'Skill',
      defaultImpl: {
        Prepare(result) {
          return result;
        },
        Resolve(result) {
          return result;
        }
      }
    },
    burstTalent: {
      description: 'Riff Revolution',
      level: props.burst,
      type: 'Burst',
      defaultImpl: {
        Prepare(result) {
          return result;
        },
        Resolve(result) {
          return result;
        }
      }
    },
    passiveAsc1: {
      description: '"The Show Goes On, Even Without an Audience..."',
      PersistentResolve(result, context) {
        return result;
      }
    },
    passiveAsc4: {
      description: '"...Now That\'s Rock \'N\' Roll!"',
      PersistentResolve(result, context) {
        return result;
      }
    },
    passiveOther: [{
      description: 'A Rad Recipe',
      PersistentResolve(result, context) {
        return result;
      }
    }],
    constellations: [
      ...BaseCharacter.Constellation35
    ]
  });
};

export default Xinyan;