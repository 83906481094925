import Scaling from '../../Scaling.js';
import BaseWeapon from '../base.js';

const Whiteblind = (props) => {
  
  return BaseWeapon({
    ...props,
    type: 'claymore',
    name: 'Whiteblind',
    lvl1Atk: 42,
    lvl1Sub: 0.113,
    subType: 'defensePct',
    PersistentResolve(result, context) {
      /**
       * effect: 'On hit, Normal or Charged Attacks increase ATK and DEF by 
       * [6%] for 6s. Max 4 stacks ([24%] total). 
       * Can only occur once every 0.5s.'
       */
      if(this.IsSelf(result)) {
      }
      return result;
    }
  });
};

export default Whiteblind;