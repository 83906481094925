import Tools from '../../Tools.js';
import Scaling from '../../Scaling.js';
import BaseCharacter from '../base.js';

var Bennett = (props) => {
  
  let scaleTable = {
    ascension0: {
      min: {level:1, maxHP:1039, baseAtk:16, defense:65, attr:0},
      max: {level:20, maxHP:2670, baseAtk:41, defense:166, attr:0}
    },
    ascension1: {
      min: {level:20, maxHP:3447, baseAtk:53, defense:214, attr:0},
      max: {level:40, maxHP:5163, baseAtk:80, defense:321, attr:0}
    },
    ascension2: {
      min: {level:40, maxHP:5715, baseAtk:88, defense:356, attr:0.067},
      max: {level:50, maxHP:6573, baseAtk:102, defense:409, attr:0.067}
    },
    ascension3: {
      min: {level:50, maxHP:7309, baseAtk:113, defense:455, attr:0.133},
      max: {level:60, maxHP:8168, baseAtk:126, defense:508, attr:0.133}
    },
    ascension4: {
      min: {level:60, maxHP:8719, baseAtk:135, defense:542, attr:0.133},
      max: {level:70, maxHP:9577, baseAtk:148, defense:596, attr:0.133}
    },
    ascension5: {
      min: {level:70, maxHP:10129, baseAtk:156, defense:630, attr:0.2},
      max: {level:80, maxHP:10987, baseAtk:169, defense:684, attr:0.2}
    },
    ascension6: {
      min: {level:80, maxHP:11539, baseAtk:178, defense:718, attr:0.267},
      max: {level:90, maxHP:12397, baseAtk:191, defense:771, attr:0.267}
    }
  };
  return BaseCharacter({
    ...props,
    name: 'Bennett',
    element: 'pyro',
    equip: 'sword',
    scaleTable: scaleTable,
    scaleAttr: 'energyRecharge',
    attackTalent: {
      description: 'Strike of Fortune',
      level: props.attack,
      type: "NormalAtk",
      defaultImpl: {
        talentProperties: {
          comboTable: [
            {dmg:0.445, anim:0.33}, 
            {dmg:0.427, anim:0.33}, 
            {dmg:0.546, anim:0.5}, 
            {dmg:0.596, anim:0.75}, 
            {dmg:0.719, anim:1}
          ],
          duration: 1,
          Prepare(result) {
            result.attributes.element='physical';
            return result;
          },
          Resolve(result) {
            result = Scaling.ScaleAttackCombo(result);
            return result;
          }
        }
      },
      chargedImpl: {
        description: 'Strike of Fortune - Charged A-Hit',
        type: "ChargeAtk",
        duration: 0.3,
        Resolve(result) {
          let slash = Tools.CreateTalentSideEffect(result, 'default');
          slash.precedingResult = result.precedingResult;
          result.talentEffects.push(slash);
          
          result.talentEffects.push(Tools.CreateTalentSideEffect(result, 'chargedSecond'));
          result = Scaling.ScaleTalent(0.559, result);
          return result;
        }
      },
      chargedSecondEffect: {
        description: 'Strike of Fortune - Charged B-Hit',
        type: "ChargeAtk",
        duration: 0.3,
        Resolve(result) {
          result = Scaling.ScaleTalent(0.607, result);
          return result;
        }
      }
    },
    skillTalent: {
      description:'Passion Overload',
      level: props.skill,
      type: 'Skill',
      track: {cooldown:5},
      defaultImpl: {
        duration: 0.4,
        Prepare(result) {
          let delay = result.time - result.talent.track.lastUsed;
          if(delay<result.talent.track.cooldown) {
            result = Tools.CreateCooldownEffect(result, {cooldown: result.talent.track.cooldown});
          }
          result.talent.track.lastUsed = result.time;
          result.talent.track.cooldown = 5;
          result.attributes.element = 'pyro';
          return result;
        },
        Resolve(result) {
          result = Scaling.ScaleTalent(1.38, result);
          return result;
        }
      },
      hold1Impl: {
        description: 'Passion Overload - Charge Up',
        duration: 0.7,
        Prepare(result) {
          let delay = result.time - result.talent.track.lastUsed;
          if(delay<result.talent.track.cooldown) {
            result = Tools.CreateCooldownEffect(result, {cooldown: result.talent.track.cooldown});
          }
          result.talent.track.lastUsed = result.time;
          result.talent.track.cooldown = 7.5;
          result.attributes.element = 'pyro';
          return result;
        },
        Finalize(result) {
          result.talentEffects.push(Tools.CreateTalentSideEffect(result, 'firstStrike'));
          return result;
        }
      },
      firstStrikeEffect: {
        description: 'Passion Overload - First Strike',
        duration: 0.25,
        Prepare(result) {
          result.attributes.element = 'pyro';
          return result;
        },
        Resolve(result) {
          result = Scaling.ScaleTalent(0.84, result);
          return result;
        },
        Finalize(result) {
          result.talentEffects.push(Tools.CreateTalentSideEffect(result, 'secondStrike'));
          return result;
        }
      },
      secondStrikeEffect: {
        description: 'Passion Overload - Second Strike',
        duration: 0.66,
        Prepare(result) {
          result.attributes.element = 'pyro';
          return result;
        },
        Resolve(result) {
          result = Scaling.ScaleTalent(0.92, result);
          return result;
        }
      },
      hold2Impl: {
        description: 'Passion Overload - Charge Up',
        duration: 1.75,
        Prepare(result) {
          let delay = result.time - result.talent.track.lastUsed;
          if(delay<result.talent.track.cooldown) {
            result = Tools.CreateCooldownEffect(result, {cooldown: result.talent.track.cooldown});
          }
          result.talent.track.lastUsed = result.time;
          result.talent.track.cooldown = 10;
          result.attributes.element = 'pyro';
          return result;
        },
        Finalize(result) {
          result.talentEffects.push(Tools.CreateTalentSideEffect(result, 'firstStrike2'));
          return result;
        }
      },
      firstStrike2Effect: {
        description: 'Passion Overload - First Strike',
        duration: 0.25,
        Prepare(result) {
          result.attributes.element = 'pyro';
          return result;
        },
        Resolve(result) {
          result = Scaling.ScaleTalent(0.88, result);
          return result;
        },
        Finalize(result) {
          result.talentEffects.push(Tools.CreateTalentSideEffect(result, 'secondStrike2'));
          return result;
        }
      },
      secondStrike2Effect: {
        description: 'Passion Overload - Second Strike',
        duration: 0.66,
        Prepare(result) {
          result.attributes.element = 'pyro';
          return result;
        },
        Resolve(result) {
          result = Scaling.ScaleTalent(0.92, result);
          return result;
        },
        Finalize(result) {
          result.talentEffects.push(Tools.CreateTalentSideEffect(result, 'thirdStrike2'));
          return result;
        }
      },
      thirdStrike2Effect: {
        description: 'Passion Overload - Third Strike',
        duration: 0.33,
        talentProperties: {
          knockingback: true
        },
        Prepare(result) {
          result.attributes.element = 'pyro';
          return result;
        },
        Resolve(result) {
          result = Scaling.ScaleTalent(0.92, result);
          return result;
        },
        Finalize(result) {
          if(result.talentProperties.knockingback) {
            result.talentEffects.push(Tools.CreateTalentSideEffect(result, 'knockBack'));
          }
          return result;
        }
      },
      knockBackEffect: {
        description: 'Passion Overload - Knockback',
        duration: 1.5
      }
    },
    burstTalent: {
      description: 'Fantastic Voyage',
      level: props.burst,
      type: 'Burst',
      defaultImpl: {
        duration: 0.9,
        Prepare(result) {
          result.attributes.element = 'pyro';
          return result;
        },
        Resolve(result) {
          //TODO: implement Bennett's burst
          return result;
        },
        Finalize(result) {
          result.effects.push(Tools.CreateTalentSideEffect(result, 'inspiration'));
          return result;
        }
      },
      inspirationEffect: {
        description: 'Fantastic Voyage - Inspiration Field',
        entity: 'Bennett\'s Fantastic Voyage',
        cooldown: 15,
        duration: 12,
        Prepare(result) {
          result.attributes.element = 'pyro';
          return result;
        },
        Resolve(result) {
          result = Scaling.ScaleTalent(2.33, result);
          return result;
        },
        PersistentResolve(result, context) {
          let atkBonus = context.attributes.baseAtk * Scaling.ScaleValue(
            0.56, 
            Scaling.talentTable, 
            context.talentProperties.level || context.talent.level
          );
          result.logs.push('Fantastic Voyage - Inspiration Field increases atk by '+Math.round(atkBonus));
          result.attributes.flatAtk += atkBonus;
          return result;
        }
      }
    },
    passiveAsc1: {
      description: 'Rekindle',
      PersistentResolve(result, context) {
        if(result.description === 'Passion Overload' 
          || result.description === 'Passion Overload - Charge Up') {
          result.logs.push('Fearnaught reduces Passion Overload Cooldown by 20%');
          result.talent.track.cooldown *= 0.8;
        }
        return result;
      }
    },
    passiveAsc4: {
      description: 'Fearnaught',
      PersistentResolve(result, context) {
        if(result.ongoing.effects.find(e => e === 'Fantastic Voyage - Inspiration Field')) {
          if(result.description === 'Passion Overload' 
            || result.description === 'Passion Overload - Charge Up') {
            result.logs.push('Fearnaught reduces Passion Overload Cooldown by 50% when inside Fantastic Voyage');
            result.talent.track.cooldown *= 0.5;
          }
          if(result.description === 'Passion Overload - Third Strike') {
            result.logs.push('Fearnaught removes the knockback from Passion Overload');
            result.talentProperties.knockingback = false;
          }
        }
        return result;
      }
    },
    passiveOther: [{
      description: 'It Should Be Safe...',
      PersistentResolve(result, context) {
        return result;
      }
    }],
    constellations: [
      ...BaseCharacter.Constellation35
    ]
  });
};

export default Bennett;