import Scaling from '../../Scaling.js';
import BaseWeapon from '../base.js';

const RoyalSpear = (props) => {
  
  return BaseWeapon({
    ...props,
    type: 'polearm',
    name: 'Royal Spear',
    lvl1Atk: 44,
    lvl1Sub: 0.06,
    subType: 'atkPct',
    PersistentResolve(result, context) {
      /**
       * effect: 'Upon damaging an enemy, increases CRIT Rate by [8%]. 
       * Max 5 stacks. A CRIT hit removes all stacks.'
       */
      if(this.IsSelf(result)) {
      }
      return result;
    }
  });
};

export default RoyalSpear;