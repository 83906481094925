import {BaseSet} from './base.js';

const TravelingDoctor = (props) => {
  
  return BaseSet({
    ...props,
    name: 'Traveling Doctor',
    two: {
      PersistentResolve(result, context) {
        return result;
      }
    },
    four: {
      PersistentResolve(result, context) {
        return result;
      }
    }
  });
};

export default TravelingDoctor;