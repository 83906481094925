import React from 'react';
import { Link } from 'react-router-dom';
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Advertisement,
  Label,
} from 'semantic-ui-react';
import Authentication from '../components/authentication.js';
import CookieConsent from 'react-cookie-consent';
import './index.css';

const FixedMenuLayout = (props) => (
  <div className="pageWrap">
    <Menu fixed='top' inverted>
      <Container>
        <Menu.Item as={Link} to="/" header>
          <Image size='mini' src='/logo.png' aria-label='GameHoned Logo' style={{ marginRight: '1.5em' }} />
          Game Honed
        </Menu.Item>
        <Menu.Item as={Link} to="/">Home</Menu.Item>

        <Dropdown item simple text='Genshin Impact'>
          <Dropdown.Menu>
            <Dropdown.Item as={Link} to="/genshin">Home</Dropdown.Item>
            <Dropdown.Item as={Link} to="/genshin/simulator"><Label color="orange">Beta!</Label>Simulator</Dropdown.Item>
            <Dropdown.Item as={Link} to="/genshin/simulator/user-guide">User Guide</Dropdown.Item>
            {/*<Dropdown.Item as={Link} to="/genshin/guides">Guides</Dropdown.Item>
            <Dropdown.Item as={Link} to="/genshin/community">Community</Dropdown.Item>*/}
          </Dropdown.Menu>
        </Dropdown>
        
        <Authentication style={{marginLeft:"auto"}} />
      </Container>
    </Menu>


    <Container className="pageContainer">
      {props.children}
    </Container>

    <Segment inverted vertical style={{ margin: '5em 0em 0em', padding: '5em 0em' }}>
      <Container textAlign='center'>
        <Grid divided inverted stackable>
          <Grid.Column width={5}>
          </Grid.Column>
          <Grid.Column width={11}>
            {/*<Advertisement unit="leaderboard" test="Ad unit footer" style={{margin:"auto"}} />*/}
          </Grid.Column>
        </Grid>

        <Divider inverted section />
        <Image centered size='mini' src='/logo.png' aria-label='GameHoned Logo' />
        <List horizontal inverted divided link size='small'>
          <List.Item as={Link} to='/site-map'>
            Site Map
          </List.Item>
          <List.Item as={Link} to='/contact-us'>
            Contact Us
          </List.Item>
          <List.Item as={Link} to='/legal/terms-of-use'>
            Terms of Use
          </List.Item>
          <List.Item as={Link} to='/legal/privacy-policy'>
            Privacy Policy
          </List.Item>
        </List>
      </Container>
    </Segment>
    <CookieConsent>This website uses cookies to enhance the user experience. 
      For more information visit our <Link to="/legal/privacy-policy">Privacy 
      Policy</Link>
    </CookieConsent>
  </div>
);

export default FixedMenuLayout;