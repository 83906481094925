import Tools from '../../Tools.js';
import Scaling from '../../Scaling.js';
import BaseCharacter from '../base.js';

//TODO: implement character
var Kaeya = (props) => {
  
  let scaleTable = {
    ascension0: {
      min: {level:1, maxHP:976, baseAtk:19, defense:66, attr:0},
      max: {level:20, maxHP:2506, baseAtk:48, defense:171, attr:0}
    },
    ascension1: {
      min: {level:20, maxHP:3235, baseAtk:62, defense:220, attr:0},
      max: {level:40, maxHP:4846, baseAtk:93, defense:330, attr:0}
    },
    ascension2: {
      min: {level:40, maxHP:5364, baseAtk:103, defense:365, attr:0.067},
      max: {level:50, maxHP:6170, baseAtk:118, defense:420, attr:0.067}
    },
    ascension3: {
      min: {level:50, maxHP:6860, baseAtk:131, defense:467, attr:0.133},
      max: {level:60, maxHP:7666, baseAtk:147, defense:522, attr:0.133}
    },
    ascension4: {
      min: {level:60, maxHP:8184, baseAtk:156, defense:557, attr:0.133},
      max: {level:70, maxHP:8990, baseAtk:172, defense:612, attr:0.133}
    },
    ascension5: {
      min: {level:70, maxHP:9508, baseAtk:181, defense:647, attr:0.2},
      max: {level:80, maxHP:10312, baseAtk:196, defense:702, attr:0.2}
    },
    ascension6: {
      min: {level:80, maxHP:10830, baseAtk:205, defense:737, attr:0.267},
      max: {level:90, maxHP:11636, baseAtk:220, defense:792, attr:0.267}
    }
  };
  return BaseCharacter({
    ...props,
    name: 'Kaeya',
    element: 'cryo',
    equip: 'sword',
    scaleTable: scaleTable,
    scaleAttr: 'energyRecharge',
    attackTalent: {
      description: 'Ceremonial Bladework',
      level: props.attack,
      type: "NormalAtk",
      defaultImpl: {
        comboTable: [
          {dmg:0.538, anim:0.5}, 
          {dmg:0.517, anim:0.6}, 
          {dmg:0.653, anim:1}, 
          {dmg:0.709, anim:0.75}, 
          {dmg:0.882, anim:1}
        ],
        duration: 1,
        Resolve(result) {
          result = Scaling.ScaleAttackCombo(result);
          result.attributes.element='physical';
          return result;
        }
      },
      chargedImpl: {
        type: "ChargeAtk",
        duration: 1,
        Resolve(result) {
          result = Scaling.ScaleTalent(0.55, result);
          result.attributes.element='physical';
          return result;
        }
      }
    },
    skillTalent: {
      description:'Frostgnaw',
      level: props.skill,
      type: 'Skill',
      defaultImpl: {
        Prepare(result) {
          return result;
        },
        Resolve(result) {
          return result;
        }
      }
    },
    burstTalent: {
      description: 'Glacial Waltz',
      level: props.burst,
      type: 'Burst',
      defaultImpl: {
        Prepare(result) {
          return result;
        },
        Resolve(result) {
          return result;
        }
      }
    },
    passiveAsc1: {
      description: 'Cold-Blooded Strike',
      PersistentResolve(result, context) {
        return result;
      }
    },
    passiveAsc4: {
      description: 'Heart of the Abyss',
      PersistentResolve(result, context) {
        return result;
      }
    },
    passiveOther: [{
      description: 'Hidden Strength',
      PersistentResolve(result, context) {
        return result;
      }
    }],
    constellations: [
      ...BaseCharacter.Constellation53,
    ]
  });
};

export default Kaeya;