import Tools from '../../Tools.js';
import Scaling from '../../Scaling.js';
import BaseCharacter from '../base.js';

var Albedo = (props) => {
  
  let scaleTable = {
    ascension0: {
      min: {level:1, maxHP:1030, baseAtk:20, defense:68, attr:0},
      max: {level:20, maxHP:2671, baseAtk:51, defense:177, attr:0}
    },
    ascension1: {
      min: {level:20, maxHP:3556, baseAtk:68, defense:235, attr:0},
      max: {level:40, maxHP:5317, baseAtk:101, defense:352, attr:0}
    },
    ascension2: {
      min: {level:40, maxHP:5944, baseAtk:113, defense:394, attr:0.072},
      max: {level:50, maxHP:6839, baseAtk:130, defense:453, attr:0.072}
    },
    ascension3: {
      min: {level:50, maxHP:7675, baseAtk:146, defense:508, attr:0.144},
      max: {level:60, maxHP:8579, baseAtk:163, defense:568, attr:0.144}
    },
    ascension4: {
      min: {level:60, maxHP:9207, baseAtk:175, defense:610, attr:0.144},
      max: {level:70, maxHP:10119, baseAtk:192, defense:670, attr:0.144}
    },
    ascension5: {
      min: {level:70, maxHP:10746, baseAtk:204, defense:712, attr:0.216},
      max: {level:80, maxHP:11669, baseAtk:222, defense:773, attr:0.216}
    },
    ascension6: {
      min: {level:80, maxHP:12296, baseAtk:233, defense:815, attr:0.288},
      max: {level:90, maxHP:13226, baseAtk:251, defense:876, attr:0.288}
    }
  };
  return BaseCharacter({
    ...props,
    name: 'Albedo',
    element: 'geo',
    equip: 'sword',
    scaleTable: scaleTable,
    scaleAttr: 'damageBonus.geo',
    attackTalent: {
      description: 'Favonius Bladework - Weiss',
      level: props.attack,
      type: "NormalAtk",
      defaultImpl: {
        talentProperties: {
          comboTable: [
            {dmg:0.367, anim:0.375}, 
            {dmg:0.367, anim:0.333}, 
            {dmg:0.475, anim:0.375}, 
            {dmg:0.498, anim:0.75}, 
            {dmg:0.621, anim:0.875}
          ]
        },
        duration: 1,
        Resolve(result) {
          result = Scaling.ScaleAttackCombo(result);
          result.attributes.element='physical';
          return result;
        }
      },
      chargedImpl: {
        description: 'Favonius Bladework - Weiss - Charged 1-Hit',
        type: "ChargeAtk",
        duration: 0.4,
        Prepare(result) {
          result.talentEffects.push(Tools.CreateTalentSideEffect(result, 'charged2'));
          return result;
        },
        Resolve(result) {
          result = Scaling.ScaleTalent(0.473, result);
          result.attributes.element='physical';
          return result;
        }
      },
      charged2Effect: {
        description: 'Favonius Bladework - Weiss - Charged 2-Hit',
        type: "ChargeAtk",
        duration: 0.4,
        Resolve(result) {
          result = Scaling.ScaleTalent(0.602, result);
          result.attributes.element='physical';
          return result;
        }
      }
    },
    skillTalent: {
      description:'Abiogenesis: Solar Isotoma',
      level: props.skill,
      type: 'Skill',
      duration: 0.8,
      defaultImpl: {
        cooldown: 4,
        Prepare(result) {
          result.attributes.element = 'geo';
          result.effects.push(Tools.CreateTalentSideEffect(result, 'solarIsotoma'));
          return result;
        },
        Resolve(result) {
          result = Scaling.ScaleTalent(1.3, result);
          return result;
        }
      },
      solarIsotomaEffect: {
        description: 'Solar Isotoma',
        entity: 'Albedo\'s Solar Isotoma',
        duration: 30,
        relativeTime: (r => -(r.precedingResult.duration/2)),
        track: {lastTrigger:-999},
        PersistentResolve(result, context) {
          if('Transient Blossom' === result.description || /^Fatal Blossom/.test(result.description))
            return result;
          
          if(result.description === 'Rite of Progeniture: Tectonic Tide') {
            result.logs.push(`Triggered Fatal Blossoms`);
            let precedingResult = result;
            [...Array(7)].forEach((e,i) => {
              const sequenceDelay = [0.13, 0.13, 0.17, 0.3, 0.08, 0.5];
              precedingResult = Tools.CreateTalentSideEffect(precedingResult, 'fatalBlossom', {
                description: 'Fatal Blossom ('+(i+1)+' of 7)',
                entity: 'Albedo\'s Rite of Progeniture: Tectonic Tide',
                relativeTime: sequenceDelay[i]
              });
              result.effects.push(precedingResult);
            });
          } else if(result.attributes.skillPct && result.time-context.track.lastTrigger>2) {
            result.effects.push(Tools.CreateTalentSideEffect(context, 'transientBlossom', {
              precedingResult: result
            }));
            context.track.lastTrigger = result.time;
          }
          return result;
        }
      },
      transientBlossomEffect: {
        description: 'Transient Blossom',
        entity: 'Albedo\'s Solar Isotoma',
        duration: 0.1,
        relativeTime: (r => -r.precedingResult.duration),
        Prepare(result) {
          result.attributes.element = 'geo';
          return result;
        },
        Resolve(result) {
          result.scalingStat = 'defense';
          result = Scaling.ScaleTalent(1.34, result);
          return result;
        }
      }
    },
    burstTalent: {
      description: 'Rite of Progeniture: Tectonic Tide',
      level: props.burst,
      type: 'Burst',
      duration: 2,
      defaultImpl: {
        cooldown: 12,
        Prepare(result) {
          result.attributes.element = 'geo';
          return result;
        },
        Resolve(result) {
          result = Scaling.ScaleTalent(3.67, result);
          return result;
        }
      },
      fatalBlossomEffect: {
        description: 'Fatal Blossom',
        entity: 'Albedo\'s Rite of Progeniture: Tectonic Tide',
        duration: 0.1,
        Prepare(result) {
          result.attributes.element = 'geo';
          return result;
        },
        Resolve(result) {
          result = Scaling.ScaleTalent(0.72, result);
          return result;
        }
      }
    },
    passiveAsc1: {
      description: 'Calcite Might',
      PersistentResolve(result, context) {
        /*if(result.description === 'Transient Blossom') {
          result.logs.push(`Assuming target's HP is below 25%, Calcite Might increases Transient Blossom DMG by 25%`);
          result.attributes.damageBonus.all += 0.25;
        }*/
        return result;
      }
    },
    passiveAsc4: {
      description: 'Homuncular Nature',
      PersistentResolve(result, context) {
        if(result.description === 'Rite of Progeniture: Tectonic Tide') {
          result.effects.push(Tools.CreateSideEffect(result, {
            description: 'Homuncular Nature',
            entity: 'Albedo\'s Homunculat Nature',
            duration: 10,
            PersistentResolve(result, context) {
              result.attributes.elemMastery += 125;
              return result;
            }
          }));
        }
        return result;
      }
    },
    passiveOther: [{
      description: 'Flash of Genius (Albedo)',
      PersistentResolve(result, context) {
        return result;
      }
    }],
    constellations: [
      ...BaseCharacter.Constellation35
    ]
  });
};

export default Albedo;