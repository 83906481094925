import {BaseSet} from './base.js';

const Lavawalker = (props) => {
  
  return BaseSet({
    ...props,
    name: 'Lavawalker',
    two: {
      attributes: {
        resistance: {
          pyro: 0.4
        }
      },
      PersistentResolve(result, context) {
        return result;
      }
    },
    four: {
      PersistentResolve(result, context) {
        if(!this.IsSelf(result)) return result;
        if(result.ongoing.status.filter(e => e.type==="Status" && (e.description==="Fire" || e.description==="Burning")).length>0) {
          result.attributes.damageBonus.all += 0.35;
          result.logs.push("Lavawalker Increases DMG against enemies affected by Pyro by 35%")
        }
        return result;
      }
    }
  });
};

export default Lavawalker;