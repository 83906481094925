import Tools from '../../Tools.js';
import Scaling from '../../Scaling.js';
import BaseCharacter from '../base.js';

//TODO: implement character
var Chongyun = (props) => {
  
  let scaleTable = {
    ascension0: {
      min: {level:1, maxHP:1003, baseAtk:19, defense:54, attr:0},
      max: {level:20, maxHP:2366, baseAtk:48, defense:140, attr:0}
    },
    ascension1: {
      min: {level:20, maxHP:3054, baseAtk:62, defense:180, attr:0},
      max: {level:40, maxHP:4574, baseAtk:93, defense:270, attr:0}
    },
    ascension2: {
      min: {level:40, maxHP:5063, baseAtk:103, defense:299, attr:0.06},
      max: {level:50, maxHP:5824, baseAtk:119, defense:344, attr:0.06}
    },
    ascension3: {
      min: {level:50, maxHP:6475, baseAtk:132, defense:392, attr:0.12},
      max: {level:60, maxHP:7236, baseAtk:147, defense:427, attr:0.12}
    },
    ascension4: {
      min: {level:60, maxHP:7725, baseAtk:158, defense:456, attr:0.12},
      max: {level:70, maxHP:8485, baseAtk:172, defense:501, attr:0.12}
    },
    ascension5: {
      min: {level:70, maxHP:8974, baseAtk:182, defense:530, attr:0.18},
      max: {level:80, maxHP:9734, baseAtk:198, defense:575, attr:0.18}
    },
    ascension6: {
      min: {level:80, maxHP:10223, baseAtk:208, defense:603, attr:0.24},
      max: {level:90, maxHP:10984, baseAtk:223, defense:648, attr:0.24}
    }
  };
  return BaseCharacter({
    ...props,
    name: 'Chongyun',
    element: 'cryo',
    equip: 'claymore',
    scaleTable: scaleTable,
    scaleAttr: 'atkPct',
    attackTalent: {
      description: 'Demonbane',
      level: props.attack,
      type: "NormalAtk",
      defaultImpl: {
        talentProperties: {
          comboTable: [
            {dmg:0.70, anim:0.5}, 
            {dmg:0.631, anim:0.6}, 
            {dmg:0.803, anim:1}, 
            {dmg:1.01, anim:0.75}
          ]
        },
        duration: 1,
        Resolve(result) {
          result = Scaling.ScaleAttackCombo(result);
          result.attributes.element='physical';
          return result;
        }
      },
      chargedImpl: {
        type: "ChargeAtk",
        duration: 0.9,
        Resolve(result) {
          result = Scaling.ScaleTalent(1.02, result);
          result.attributes.element='physical';
          return result;
        }
      },
      spinningImpl: {
        type: "ChargeAtkHold",
        duration: 1,
        Prepare(result) {
          let precedingResult = result;
          let spinAnimation = [0.3, 0.4, 0.4, 0.5, 0.35, 0.5, 0.4, 0.4, 0.5, 0.9];
          spinAnimation.forEach((e,i) => {
            if(i<9) {
              precedingResult = Tools.CreateTalentSideEffect(precedingResult, "spinRotation", {
                duration: e
              });
            } else {
              precedingResult = Tools.CreateTalentSideEffect(precedingResult, "charged", {
                duration: e
              });
            }
            result.talentEffects.push(precedingResult);
          });
          return result;
        },
        Resolve(result) {
          //account for windup animation
          result.setDuration(1);
          return result;
        }
      },
      spinRotationEffect: {
        description: 'Chongyun Spin Attack',
        entity: 'Chongyun',
        duration: 0.3,
        effectExtension: true,
        type: 'ChargeAtkHold',
        Resolve(result) {
          result = Scaling.ScaleTalent(0.563, result);
          result.attributes.element='physical';
          return result;
        }
      }
    },
    skillTalent: {
      description:'Sprit Blade: Chonghua\'s Layered Frost',
      level: props.skill,
      type: 'Skill',
      defaultImpl: {
        Prepare(result) {
          result.attributes.element = 'cryo';
          return result;
        },
        Resolve(result) {
          return result;
        }
      }
    },
    burstTalent: {
      description: 'Spirit Blade: Cloud-Parting Star',
      level: props.burst,
      type: 'Burst',
      defaultImpl: {
        Prepare(result) {
          result.attributes.element = 'cryo';
          return result;
        },
        Resolve(result) {
          return result;
        }
      }
    },
    passiveAsc1: {
      description: 'Steady Breathing',
      PersistentResolve(result, context) {
        return result;
      }
    },
    passiveAsc4: {
      description: 'Rimechaser Blade',
      PersistentResolve(result, context) {
        return result;
      }
    },
    passiveOther: [{
      description: 'Gallant Journey',
      PersistentResolve(result, context) {
        return result;
      }
    }],
    constellations: [
      ...BaseCharacter.Constellation53,
    ]
  });
};

export default Chongyun;