import Tools from '../../Tools.js';
import Scaling from '../../Scaling.js';
import BaseCharacter from '../base.js';

//TODO: implement character
var Diona = (props) => {
  
  let scaleTable = {
    ascension0: {
      min: {level:1, maxHP:802, baseAtk:41, defense:50, attr:0},
      max: {level:20, maxHP:2061, baseAtk:69, defense:129, attr:0}
    },
    ascension1: {
      min: {level:20, maxHP:2661, baseAtk:82, defense:167, attr:0},
      max: {level:40, maxHP:3985, baseAtk:88, defense:250, attr:0}
    },
    ascension2: {
      min: {level:40, maxHP:4411, baseAtk:98, defense:277, attr:0.06},
      max: {level:50, maxHP:5074, baseAtk:113, defense:318, attr:0.06}
    },
    ascension3: {
      min: {level:50, maxHP:5642, baseAtk:125, defense:354, attr:0.12},
      max: {level:60, maxHP:6305, baseAtk:140, defense:396, attr:0.12}
    },
    ascension4: {
      min: {level:60, maxHP:6731, baseAtk:149, defense:422, attr:0.12},
      max: {level:70, maxHP:7393, baseAtk:164, defense:464, attr:0.12}
    },
    ascension5: {
      min: {level:70, maxHP:7818, baseAtk:174, defense:491, attr:0.18},
      max: {level:80, maxHP:8481, baseAtk:188, defense:532, attr:0.18}
    },
    ascension6: {
      min: {level:80, maxHP:8907, baseAtk:198, defense:559, attr:0.24},
      max: {level:90, maxHP:9570, baseAtk:212, defense:601, attr:0.24}
    }
  };
  return BaseCharacter({
    ...props,
    name: 'Diona',
    element: 'cryo',
    equip: 'bow',
    scaleTable: scaleTable,
    scaleAttr: 'damageBonus.cryo',
    attackTalent: {
      description: 'Kätzlein Style',
      level: props.attack,
      type: "NormalAtk",
      defaultImpl: {
        talentProperties: {
          comboTable: [
            {dmg:0.361, anim:0.45}, 
            {dmg:0.335, anim:0.33}, 
            {dmg:0.456, anim:0.9}, 
            {dmg:0.430, anim:0.25}, 
            {dmg:0.538, anim:0.9}
          ]
        },
        duration: 1,
        Resolve(result) {
          result = Scaling.ScaleAttackCombo(result);
          return result;
        }
      },
      aimedImpl: {
        description: 'Kätzlein Style',
        level: props.attack,
        type: "ChargeAtk",
        duration: 0.66,
        Resolve(result) {
          result = Scaling.ScaleTalent(0.439, result);
          return result;
        }
      },
      fullyAimedImpl: {
        description: 'Kätzlein Style',
        level: props.attack,
        type: "ChargeAtkHold",
        duration: 1.75,
        Prepare(result) {
          result.attributes.element = 'cryo';
          return result;
        },
        Resolve(result) {
          result = Scaling.ScaleTalent(1.24, result);
          return result;
        }
      }
    },
    skillTalent: {
      description:'Icy Paws',
      level: props.skill,
      type: 'Skill',
      track: {
        cooldown: 6
      },
      defaultImpl: {
        duration: 0.66,
        Prepare(result) {
          let delay = result.time - result.talent.track.lastUsed;
          if(delay<result.talent.track.cooldown) {
            result = Tools.CreateCooldownEffect(result, {cooldown: result.talent.track.cooldown});
          }
          result.talent.track.lastUsed = result.time;
          result.talent.track.cooldown = 6;
          result.attributes.element = 'cryo';
          return result;
        },
        Resolve(result) {
          return result;
        },
        Finalize(result) {
          result.effects.push(Tools.CreateTalentSideEffect(result, 'paw', {
            description:'Icy Paws - Paw 1-Hit',
            relativeTime:-.1,
          }));
          result.effects.push(Tools.CreateTalentSideEffect(result, 'paw', {
            description:'Icy Paws - Paw 2-Hit',
            relativeTime:-.1,
            Finalize(result) {
              result.effects.push(Tools.CreateTalentSideEffect(result, 'shield', {
                duration: Math.min(1.8+(result.talent.level-1), 2.4) * 2
              }));
              return result;
            }
          }));
          return result;
        }
      },
      holdImpl: {
        description:'Icy Paws - Hold',
        duration: 1,
        Prepare(result) {
          let delay = result.time - result.talent.track.lastUsed;
          if(delay<result.talent.track.cooldown) {
            result = Tools.CreateCooldownEffect(result, {cooldown: result.talent.track.cooldown});
          }
          result.talent.track.lastUsed = result.time;
          result.talent.track.cooldown = 15;
          result.attributes.element = 'cryo';
          return result;
        },
        Resolve(result) {
          return result;
        },
        Finalize(result) {
          result.effects.push(Tools.CreateTalentSideEffect(result, 'paw', {
            description:'Icy Paws - Paw 1-Hit',
            relativeTime:-.2
          }));
          let lastPaw = Tools.CreateTalentSideEffect(result, 'paw', {
            description:'Icy Paws - Paw 2-Hit',
            relativeTime:-.2
          });
          result.effects.push(lastPaw);
          for(let i=0; i<2; i++) {
            let nextPaw = Tools.CreateTalentSideEffect(lastPaw, 'paw', {
              description:'Icy Paws - Paw '+(3+i)+'-Hit'
            });
            lastPaw.effects.push(nextPaw);
          }
          lastPaw.effects.push(Tools.CreateTalentSideEffect(lastPaw, 'paw', {
            description:'Icy Paws - Paw 5-Hit',
            Finalize(result) {
              result.effects.push(Tools.CreateTalentSideEffect(result, 'shield', {
                duration: Math.min(1.8+(result.talent.level-1), 2.4) * 5
              }));
              return result;
            }
          }));
          return result;
        }
      },
      pawEffect: {
        description:'Icy Paws - Paw Hit',
        entity: 'Diona\'s Icy Paws',
        duration: 0.1,
        Prepare(result) {
          result.attributes.element = 'cryo';
          return result;
        },
        Resolve(result) {
          result = Scaling.ScaleTalent(0.419, result);
          return result;
        }
      },
      shieldEffect: {
        description:'Icy Paws - Shield',
        entity: 'Diona\'s Icy Paws',
        duration: 3.6,
        Prepare(result) {
          result.attributes.element = 'cryo';
          return result;
        }
      }
    },
    burstTalent: {
      description: 'Signature Mix',
      level: props.burst,
      type: 'Burst',
      cooldown: 20,
      defaultImpl: {
        duration: 1.15,
        Prepare(result) {
          result.attributes.element = 'cryo';
          return result;
        },
        Resolve(result) {
          return result;
        },
        Finalize(result) {
          result.effects.push(Tools.CreateTalentSideEffect(result, 'initial'));
          return result;
        }
      },
      initialEffect: {
        description: 'Signature Mix - Initial',
        entity: 'Diona\'s Signature Mix',
        duration: 2,
        relativeTime: 0.66,
        Prepare(result) {
          result.attributes.element = 'cryo';
          return result;
        },
        Resolve(result) {
          result = Scaling.ScaleTalent(0.80, result);
          return result;
        },
        Finalize(result) {
          result.effects.push(Tools.CreateTalentSideEffect(result, 'drunkenMist'));
          return result;
        }
      },
      drunkenMistEffect: {
        description: 'Signature Mix - Drunken Mist',
        entity: 'Diona\'s Signature Mix',
        duration: 2,
        talentProperties: {
          tick: 1
        },
        Prepare(result) {
          result.attributes.element = 'cryo';
          return result;
        },
        Resolve(result) {
          result = Scaling.ScaleTalent(0.526, result);
          return result;
        },
        Finalize(result) {
          if(result.talentProperties.tick<6) {
            result.effects.push(Tools.CreateTalentSideEffect(result, 'drunkenMist', {
              talentProperties: {
                tick: result.talentProperties.tick+1
              }
            }));
          }
          return result;
        }
      }
    },
    passiveAsc1: {
      description: 'Cat\'s Tail Secret Menu',
      PersistentResolve(result, context) {
        return result;
      }
    },
    passiveAsc4: {
      description: 'Drunkards\' Farce',
      PersistentResolve(result, context) {
        return result;
      }
    },
    passiveOther: [{
      description: 'Complimentary Bar Food',
      PersistentResolve(result, context) {
        return result;
      }
    }],
    constellations: [
      ...BaseCharacter.Constellation53,
    ]
  });
};

export default Diona;