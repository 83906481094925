import Scaling from '../../Scaling.js';
import BaseWeapon from '../base.js';

const LithicSpear = (props) => {
  
  return BaseWeapon({
    ...props,
    type: 'polearm',
    name: 'Lithic Spear',
    lvl1Atk: 42,
    lvl1Sub: 0.09,
    subType: 'damageBonus.physical',
    PersistentResolve(result, context) {
      /**
       * effect: 'Normal Attack hits have a [20%] chance of causing the next 
       * Charged Attack performed in the following 10s to deal 
       * [40%] increased DMG.'
       */
      if(this.IsSelf(result)) {
      }
      return result;
    }
  });
};

export default LithicSpear;