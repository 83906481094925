import Scaling from '../../Scaling.js';
import BaseWeapon from '../base.js';

const SkywardBlade = (props) => {
  
  return BaseWeapon({
    ...props,
    type: 'sword',
    name: 'Skyward Blade',
    lvl1Atk: 46,
    lvl1Sub: 0.12,
    subType: 'energyRecharge',
    PersistentResolve(result, context) {
      /**
       * effect: 'Crit Rate increased by [4%]. Gains Skypiercing Might upon using 
       * Elemental Burst: Increases Movement SPD by 10%, increases ATK SPD by 
       * 10%, and increases the DMG of Normal and Charged Attack hits by [20%] 
       * for 12s.'
       */
      if(this.IsSelf(result)) {
        let effectValues = {
          critRate: Scaling.ScaleWeaponPassive(0.04, this.refinement),
          atkIncrease: Scaling.ScaleWeaponPassive(0.20, this.refinement)
        }
      }
      return result;
    }
  });
};

export default SkywardBlade;