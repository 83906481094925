import Tools from '../../Tools.js';
import Scaling from '../../Scaling.js';
import BaseCharacter from '../base.js';

var Beidou = (props) => {
  
  let scaleTable = {
    ascension0: {
      min: {level:1, maxHP:1094, baseAtk:19, defense:54, attr:0},
      max: {level:20, maxHP:2811, baseAtk:49, defense:140, attr:0}
    },
    ascension1: {
      min: {level:20, maxHP:3628, baseAtk:63, defense:180, attr:0},
      max: {level:40, maxHP:5435, baseAtk:94, defense:270, attr:0}
    },
    ascension2: {
      min: {level:40, maxHP:6015, baseAtk:104, defense:299, attr:0.06},
      max: {level:50, maxHP:6919, baseAtk:120, defense:344, attr:0.06}
    },
    ascension3: {
      min: {level:50, maxHP:7694, baseAtk:133, defense:392, attr:0.12},
      max: {level:60, maxHP:8597, baseAtk:148, defense:427, attr:0.12}
    },
    ascension4: {
      min: {level:60, maxHP:9178, baseAtk:158, defense:456, attr:0.12},
      max: {level:70, maxHP:10081, baseAtk:174, defense:501, attr:0.12}
    },
    ascension5: {
      min: {level:70, maxHP:10662, baseAtk:184, defense:530, attr:0.18},
      max: {level:80, maxHP:11565, baseAtk:199, defense:575, attr:0.18}
    },
    ascension6: {
      min: {level:80, maxHP:12146, baseAtk:210, defense:603, attr:0.24},
      max: {level:90, maxHP:13050, baseAtk:225, defense:648, attr:0.24}
    }
  };
  return BaseCharacter({
    ...props,
    name: 'Beidou',
    element: 'electro',
    equip: 'claymore',
    scaleTable: scaleTable,
    scaleAttr: 'damageBonus.electro',
    attackTalent: {
      description: 'Oceanborne',
      level: props.attack,
      type: "NormalAtk",
      defaultImpl: {
        talentProperties: {
          comboTable: [
        {dmg:0.711, anim:0.5}, 
        {dmg:0.709, anim:0.6}, 
        {dmg:0.883, anim:1}, 
        {dmg:0.865, anim:0.75}, 
        {dmg:1.12, anim:1}
      ]
        },
        duration: 1,
        Resolve(result) {
          result = Scaling.ScaleAttackCombo(result);
          result.attributes.element='physical';
          return result;
        }
      },
      chargedImpl: {
        type: "ChargeAtk",
        duration: 0.9,
        Resolve(result) {
          result = Scaling.ScaleAttackTalent(1.02, result);
          result.attributes.element='physical';
          return result;
        }
      },
      spinningImpl: {
        description: 'Oceanborne - Windup',
        type: "ChargeAtkHold",
        duration: 1,
        Prepare(result) {
          let precedingResult = result;
          let spinAnimation = [0.3, 0.4, 0.4, 0.5, 0.35, 0.5, 0.4, 0.4, 0.5, 0.9];
          spinAnimation.forEach((e,i) => {
            if(i<9) {
              precedingResult = Tools.CreateTalentSideEffect(precedingResult, "spinRotation", {
                duration: e
              });
            } else {
              precedingResult = Tools.CreateTalentSideEffect(precedingResult, "charged", {
                duration: e
              });
            }
            result.talentEffects.push(precedingResult);
          });
          return result;
        },
        Resolve(result) {
          //account for windup animation
          result.setDuration(1);
          return result;
        }
      },
      spinRotationEffect: {
        description: 'Oceanborne - Spin Attack',
        entity: 'Beidou',
        duration: 0.3,
        effectExtension: true,
        type: 'ChargeAtkHold',
        Resolve(result) {
          result = Scaling.ScaleAttackTalent(0.562, result);
          result.attributes.element='physical';
          return result;
        }
      }
    },
    skillTalent: {
      description:'Tidecaller',
      level: props.skill,
      type: 'Skill',
      defaultImpl: {
        Prepare(result) {
          result.attributes.element = 'electro';
          return result;
        },
        Resolve(result) {
          return result;
        }
      }
    },
    burstTalent: {
      description: 'Stormbreaker',
      level: props.burst,
      type: 'Burst',
      defaultImpl: {
        Prepare(result) {
          result.attributes.element = 'electro';
          return result;
        },
        Resolve(result) {
          return result;
        }
      }
    },
    passiveAsc1: {
      description: 'Retribution',
      PersistentResolve(result, context) {
        return result;
      }
    },
    passiveAsc4: {
      description: 'Lightning Storm',
      PersistentResolve(result, context) {
        return result;
      }
    },
    passiveOther: [{
      description: 'Conqueror of Tides',
      PersistentResolve(result, context) {
        return result;
      }
    }],
    constellations: [
      ...BaseCharacter.Constellation35
    ]
  });
};

export default Beidou;