import {BaseSet} from './base.js';
import Tools from '../Tools.js';

const CrimsonWitchOfFlames = (props) => {
  
  return BaseSet({
    ...props,
    name: 'Crimson Witch of Flames',
    two: {
      attributes: {
        damageBonus: {
          pyro: 0.15
        }
      },
      PersistentResolve(result, context) {
        return result;
      }
    },
    four: {
      PersistentResolve(result, context) {
        if(!this.IsSelf(result)) return result;
        if(result.type==="Reaction" && result.description==="Overloaded") {
          result.attributes.reactionBonus.overloaded += 0.4;
          result.logs.push("Crimson Witch of Flames increases Overloaded by 40%");
        }else if(result.type==="Reaction" && result.description==="Burning") {
          result.attributes.reactionBonus.burning += 0.4;
          result.logs.push("Crimson Witch of Flames increases Burning by 40%");
        } else if(result.reactionMultiplier.melt) {
          result.attributes.reactionBonus.melt += 0.15;
          result.logs.push("Crimson Witch of Flames increases Melt by 15%");
        } else if(result.reactionMultiplier.vaporize) {
          result.attributes.reactionBonus.vaporize += 0.15;
          result.logs.push("Crimson Witch of Flames increases Vaporize by 15%");
        }
        return result;
      },
      PersistentFinalize(result) {
        if(!this.IsSelf(result)) return result;
        if(result.type==="Skill") {
          result.effects.push(Tools.CreateSideEffect(result, {
            description: 'Crimson Witch of Flames 4pc',
            stackable: true,
            maxStacks: 3,
            entity: 'Buffs',
            duration: 10,
            relativeTime: (r => -r.precedingResult.duration),
            type: 'Buff',
            PersistentResolve(result, context) {
              if(result.initiator!==context.initiator) return result;
              let pyroInc = 0.075*context.stacks;
              result.attributes.damageBonus.pyro += pyroInc;
              result.logs.push(context.stacks + " stacks of Crimson Witch of Flames 4pc increases Pyro DMG from by an additionnal "+Math.round((pyroInc)*10000)/100+"%");
              return result;
            },
            logs: ["Crimson Witch of Flames 4pc increases pyro DMG by 50% of 2pc Bonus, max 3 stacks"]
          }));
        }
        return result;
      }
    }
  });
};

export default CrimsonWitchOfFlames;