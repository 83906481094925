import Tools from '../../Tools.js';
import Scaling from '../../Scaling.js';
import HistoryEntry from '../../HistoryEntry.js';
import BaseCharacter from '../base.js';

var Fischl = (props) => {
  
  let scaleTable = {
    ascension0:{
      min: {level:1, maxHP:770, baseAtk:21, defense:50, attr:0},
      max: {level:20, maxHP:1979, baseAtk:53, defense:128, attr:0}
    },
    ascension1:{
      min: {level:20, maxHP:2555, baseAtk:68, defense:165, attr:0},
      max: {level:40, maxHP:3827, baseAtk:102, defense:247, attr:0}
    },
    ascension2:{
      min: {level:40, maxHP:4236, baseAtk:113, defense:274, attr:0.06},
      max: {level:50, maxHP:4872, baseAtk:130, defense:315, attr:0.06}
    },
    ascension3:{
      min: {level:50, maxHP:5418, baseAtk:144, defense:350, attr:0.12},
      max: {level:60, maxHP:6054, baseAtk:161, defense:391, attr:0.12}
    },
    ascension4:{
      min: {level:60, maxHP:6463, baseAtk:172, defense:418, attr:0.12},
      max: {level:70, maxHP:7099, baseAtk:189, defense:459, attr:0.12}
    },
    ascension5:{
      min: {level:70, maxHP:7508, baseAtk:200, defense:485, attr:0.18},
      max: {level:80, maxHP:8144, baseAtk:216, defense:526, attr:0.18}
    },
    ascension6:{
      min: {level:80, maxHP:8553, baseAtk:227, defense:553, attr:0.24},
      max: {level:90, maxHP:9195, baseAtk:244, defense:594, attr:0.24}//estimated
    }
  };
  //TODO: check anim speed
  return BaseCharacter({
    ...props,
    name: 'Fischl',
    element: 'electro',
    equip: 'bow',
    scaleTable: scaleTable,
    scaleAttr: 'atkPct',
    attackTalent: {
      description: 'Bolts Of Downfall',
      level: props.attack,
      type: 'NormalAtk',
      duration: 0.5,
      defaultImpl: {
        talentProperties: {
          comboTable: [
            {dmg:0.441, anim:0.5}, 
            {dmg:0.468, anim:0.6}, 
            {dmg:0.581, anim:1}, 
            {dmg:0.577, anim:0.75}, 
            {dmg:0.721, anim:1}
          ]
        },
        Resolve(result) {
          result = Scaling.ScaleAttackCombo(result);
          result.attributes.element = 'physical';
          return result;
        }
      },
      aimedImpl: {
        description: 'Bolts Of Downfall',
        level: props.attack,
        type: 'ChargeAtk',
        duration: 0.625,
        Resolve(result) {
          result = Scaling.ScaleAttackTalent(0.439, result);
          result.attributes.element = 'physical';
          return result;
        }
      },
      fullyAimedImpl: {
        description: 'Bolts Of Downfall',
        level: props.attack,
        type: 'ChargeAtkHold',
        duration: 1.5,
        Prepare(result) {
          result.attributes.element = 'electro';
          return result;
        },
        Resolve(result) {
          result = Scaling.ScaleTalent(1.24, result);
          return result;
        }
      }
    },
    skillTalent: {
      description:'Nightrider',
      level: props.skill,
      duration: 0.2,
      cooldown: 25,
      type: 'Skill',
      talentProperties: {
        ozInterruptedByBurst: false
      },
      defaultImpl: {
        Prepare(result) {
          result.attributes.element='electro';
          //if Oz is already on the field, wait until he is done.
          let ongoingOz = result.ongoing.effects.filter(r => ["Midnight Phantasmagoria", "Oz attack"].find(f=>r.description.startsWith(f)))[0];
          if(ongoingOz) {
            let v = ongoingOz, z=v;
            while(z) {
              v=z;
              if(v.followingResults) {
                z = v.followingResults.effects.filter(r => ["Midnight Phantasmagoria", "Oz attack"].find(f=>r.description.startsWith(f)))[0];
              } else {
                z = null;
              }
            }
            if(v.time+v.duration>result.time) {
              let idle = Tools.HistoryEntryToResult(HistoryEntry(result.initiator.UseIdle(v.time+v.duration-result.time)), result.precedingResult, result.target);
              idle.logs.push(`Can't spawn Oz while it is already on the field, waiting until he is out`);
              result.talentEffects.push(idle);
            }
          }
          return result;
        },
        Resolve(result) {
          result = Scaling.ScaleTalent(1.15, result);
          return result;
        },
        Finalize(result) {
          if(!result.talentProperties.ozInterruptedByBurst) {
            result.effects.push(Tools.CreateTalentSideEffect(result, "ozAttack", {
              relativeTime:-0.05,
              talentProperties: {
                ozAttackNumber: 1
              }
            }));
          }
          return result;
        }
      },
      ozAttackEffect: {
        description: 'Oz attack',
        entity: 'Fischl\'s Oz',
        duration: 1,
        talentProperties: {
          ozAttackNumber: 1,
          ozInterruptedByBurst: false
        },
        Prepare(result) {
          result.attributes.element = 'electro';
          return result;
        },
        Resolve(result) {
          result = Scaling.ScaleTalent(0.888, result);
          return result;
        },
        Finalize(result) {
          if(!result.talentProperties.ozInterruptedByBurst && result.talentProperties.ozAttackNumber<10) {
            result.effects.push(Tools.CreateTalentSideEffect(result, "ozAttack", {
              description: `Oz attack (${result.talentProperties.ozAttackNumber+1} of 10)`,
              talentProperties: {
                ozAttackNumber: result.talentProperties.ozAttackNumber+1
              }
            }));
          }
          return result;
        }
      }
    },
    burstTalent: {
      description: 'Midnight Phantasmagoria',
      level: props.burst,
      duration: 2,
      cooldown: 15,
      type: 'Burst',
      defaultImpl: {
        Prepare(result) {
          result.attributes.element='electro';
          //if Oz is already on the field, interrupt it.
          let ongoingOz = result.ongoing.effects.filter(r => ["Nightrider", "Oz attack"].find(f=>r.description.startsWith(f)))[0];
          if(ongoingOz) {
            ongoingOz.talentProperties.ozInterruptedByBurst=true;
            ongoingOz.logs.push(`Midnight Phantasmagoria interrupted Nightrider's Oz and spawned a new instance of Oz`)
          }
          let prec = result;
          for(let i=0; i<10; i++) {
            let next = Tools.CreateTalentSideEffect(prec, "ozAttack", {
              description: `Oz attack (${i+1} of 10)`,
              relativeTime:i===0?-0.05:0
            });
            result.effects.push(next);
            prec = next;
          }
          //console.log('Oz\'s attacks', result.effects);
          return result;
        },
        Resolve(result) {
          result = Scaling.ScaleTalent(2.08, result);
          return result;
        }
      },
      ozAttackEffect: {
        description:'Oz attack',
        entity:'Fischl\'s Oz',
        duration:1,
        Prepare(result) {
          result.attributes.element='electro';
          return result;
        },
        Resolve(result) {
          result = Scaling.ScaleTalent(0.888, result);
          return result;
        }
      }
    },
    passiveAsc1: {
      description: 'Stellar Predator',
      PersistentResolve(result, context) {
        return result;
      }
    },
    passiveAsc4: {
      description: 'Lightning Smite',
      PersistentResolve(result, context) {
        return result;
      }
    },
    passiveOther: [{
      description: 'Mein Hausgarten',
      PersistentResolve(result, context) {
        return result;
      }
    }],
    constellations: [
      ...BaseCharacter.Constellation35,
      {
        description: 'Gaze of the Deep',
        level: 1,
        PersistentResolve(result, context) {
          if(result.initiator===context.initiator
             && result.type.startsWith("NormalAtk") 
             && result.ongoing.effects.filter(e => e.entity==='Fischl\'s Oz').length===0) {
            result.effects.push(Tools.CreateSideEffect(result, {
              description: 'Oz joint Attack',
              entity: 'Fischl\'s Oz',
              relativeTime: (r => -r.precedingResult.duration),//-result.duration,
              duration: 0.1,
              Prepare(result) {
                result.attributes.element = 'physical';
                return result;
              },
              Resolve(result) {
                result.attributes.skillPct = 0.22;
                result.logs.push("Fischl Constellation 1 causes Oz to Atk for 22%");
                return result;
              }
            }));
          }
          return result;
        }
      }
    ]
  });
};

export default Fischl;