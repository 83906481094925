import Scaling from '../../Scaling.js';
import BaseWeapon from '../base.js';

const FilletBlade = (props) => {
  
  return BaseWeapon({
    ...props,
    type: 'sword',
    name: 'Fillet Blade',
    lvl1Atk: 39,
    lvl1Sub: 0.077,
    subType: 'atkPct',
    PersistentResolve(result, context) {
      /**
       * effect: 'On hit, has 50% chance to deal [240+40%] ATK DMG to a single 
       * enemy. Can only occur once every [15-1s].'
       */
      if(this.IsSelf(result)) {
      }
      return result;
    }
  });
};

export default FilletBlade;